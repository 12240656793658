import React, { Component } from "react";
import CartSidebar from "../CartSidebar/CartSidebar";
import ProductsContainer from "../ProductsContainer/ProductsContainer";
class Templateone extends Component {
  render() {
    return (
      <>
        <ProductsContainer></ProductsContainer>

        <CartSidebar></CartSidebar>
      </>
    );
  }
}

export default Templateone;
