import * as actionTypes from '../actions/actions';



const initialState = {

    loading: true
    
}

const productsLoaderReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_PRODUCTS_LOADER:
            // let productId = action.payload.productId;
            return {
                loading: action.payload.loading
            }

    }
    return state;
}

export default productsLoaderReducer;