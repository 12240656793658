import { getSelectedProduct } from "../../store/actions/popProduct";
import * as actionTypes from "../../store/actions/actions";
import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import cloneDeep from "lodash/cloneDeep";
import ViewImages from "../ViewImages/ViewImages";
import { BASE_API_URL, ENVIRONMENT } from "../../config";

class Product extends Component {
  constructor(props) {
    super(props);
    if (ENVIRONMENT == "development") {
      console.log(this.props.p);
      console.log(this.props.cartIt);
    }
    this.state = {
      show: false,
      qty: this.props.cartIt?.qty ? this.props.cartIt.qty : 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cartIt?.qty || this.props.cartIt?.qty) {
      if (prevProps.cartIt.qty !== this.props.cartIt.qty) {
        this.setState((prevProps, prevState) => {
          return {
            ...prevState,
            qty: this.props.cartIt.qty,
          };
        });
      }
    }
  }

  showModal = (val) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        show: val,
      };
    });
  };

  handleModal = () => {
    // if (this.props.p.addon_cnt > 0) {
    this.props.setSelectedProduct(this.props.p.id);
    this.props.handleProductPopUp(true);
    // }
  };

  increaseQty = () => {
    if (this.canAddMore()) {
      this.setState(
        (prevState) => {
          let updatedQty = parseInt(prevState.qty) + 1;
          if (ENVIRONMENT == "development") {
            console.log("updating, ", updatedQty);
          }
          return {
            ...prevState,
            qty: updatedQty > 0 ? updatedQty : 0,
          };
        },
        () => {
          this.addToCart();
          //add to cart here
        }
      );
    }
  };

  decreaseQty = () => {
    this.setState(
      (prevState) => {
        let updatedQty = parseInt(prevState.qty) - 1;
        return {
          ...prevState,
          qty: updatedQty > 0 ? updatedQty : 0,
        };
      },
      () => {
        //remove to cart here
        this.addToCart();
        // this.props.removeItemFromCart(null, this.props.p);
      }
    );
  };

  addToCart = () => {
    let prod = cloneDeep(this.props.p);

    let product = {
      ...prod,
      images: [this.props.p.image],

      addons: [],
    };
    if (ENVIRONMENT == "development") {
      console.log("this is product for increasing", product);
    }
    let currentProduct = cloneDeep(product);
    let pageqty = this.state.qty;
    // let pageqty = document.getElementById(-qty").innerHTML;
    // let currentProduct = {};
    // currentProduct["product"] = clonedProduct;
    currentProduct["qty"] = parseInt(pageqty);
    // currentProduct["calculated_price"] = parseFloat(this.props.p.price).toFixed(
    //   3
    // );
    currentProduct["calculated_price"] =
      parseFloat(this.props.p.discount) > 0
        ? parseFloat(this.props.p.discount).toFixed(3)
        : parseFloat(this.props.p.price).toFixed(3);
    currentProduct["calculated_total"] =
      parseInt(currentProduct.qty) *
      parseFloat(currentProduct.calculated_price);
    // currentProduct["calculated_total"] = parseInt(qty) * parseFloat(currentProduct.calculated_price);
    if (ENVIRONMENT == "development") {
      console.log("this is the current product data", currentProduct);
    }
    if (currentProduct.qty > 0) {
      this.props.addItemToCart(null, currentProduct);
    } else {
      this.props.removeItemFromCart(null, currentProduct);
    }
  };

  canAddMore = () => {
    if (ENVIRONMENT == "development") {
      console.log(this.props.p.quantity_check);
      console.log(this.state.qty);
      console.log(this.props.p.quantity);
    }
    if (parseInt(this.props.p.quantity_check) == 1) {
      if (this.state.qty < parseInt(this.props.p.quantity)) {
        return true;
      } else {
        alert(`${this.props.p.quantity} are available`);
      }
    } else {
      return true;
    }
  };
  removeFromQty = () => {};

  render() {
    if (ENVIRONMENT == "development") {
      console.log(this.props.cartIt);
      console.log(this.props.p);
    }
    let cart_count = 0;

    let statusButton = null;

    if (this.props.p.addon_cnt > 0) {
      this.props.cart.items.map((item, i) => {
        if (ENVIRONMENT == "development") {
          console.log(item.id);
        }
        if (item.id == this.props.p.id) {
          if (ENVIRONMENT == "development") {
            console.log("ids matched");
          }

          cart_count += item.qty;
        }
      });
    }

    if (
      (parseInt(this.props.p.quantity_check) == 1 &&
        parseInt(this.props.p.quantity) > 0) ||
      parseInt(this.props.p.quantity_check) == 0
    ) {
      if (ENVIRONMENT == "development") {
        console.log("if condition");
      }
      statusButton = (
        <a
          style={{ cursor: "pointer" }}
          className="add_icon addItem"
          onClick={
            this.props.p.addon_cnt > 0 ? this.handleModal : this.increaseQty
          }
        >
          <span>
            {this.props.lang == "en"
              ? this.props.transaltions.en["Add"]
              : this.props.transaltions.ar["Add"]}
          </span>
        </a>
      );
    } else {
      if (ENVIRONMENT == "development") {
        console.log("else condition");
        console.log(this.props.p);
      }

      statusButton = (
        <a className="add_icon addItem soldOut">
          {this.props.lang == "en"
            ? this.props.transaltions.en["Sold Out"]
            : this.props.transaltions.ar["Sold Out"]}
        </a>
      );
    }

    return (
      <div className="col-md-6 product_container">
        <a className="menu_item modal_dialog">
          <figure>
            <img
              src={this.props.p.image}
              data-src={this.props.p.image}
              alt="thumb"
              className="lazy"
              onClick={() => this.showModal(true)}
            />
          </figure>
          <h3 className="product_title">
            <small>{this.state.qty > 0 ? this.state.qty + " x " : ""}</small>

            <small>{cart_count > 0 ? cart_count + " x " : ""}</small>

            {this.props.lang == "en"
              ? this.props.p.title
              : this.props.p.title_ar}
          </h3>
          <div
            style={{ minHeight: "30px" }}
            onClick={() => {
              if (ENVIRONMENT == "development") {
                console.log("clicked");
              }
              if (
                (this.props.p.addon_cnt <= 0 &&
                  this.props.p.description.length > 32) ||
                this.props.p.addon_cnt > 0
              ) {
                this.handleModal();
              } else {
                if (ENVIRONMENT == "development") {
                  console.log("no long content or addons present");
                }
              }
            }}
          >
            {this.props.lang == "en" ? (
              <>
                <p
                  dangerouslySetInnerHTML={{
                    __html: `${this.props.p.description.substring(3, 30)} ${
                      this.props.p.description.length > 32
                        ? '<i style="cursor: pointer;color: var(--primary-color)" >view more</i>'
                        : ""
                    }`,
                  }}
                  style={{ minHeight: "30px" }}
                >
                  {/* <a>view more</a> */}
                </p>
              </>
            ) : (
              <>
                <p
                  dangerouslySetInnerHTML={{
                    __html: `${this.props.p.description_ar.substring(3, 30)} ${
                      this.props.p.description_ar.length > 32
                        ? '<i style="cursor: pointer;color: var(--primary-color)" >view more</i>'
                        : ""
                    }`,
                  }}
                  style={{ minHeight: "30px" }}
                ></p>
              </>
            )}
          </div>
          {this.props.p.addon_cnt > 0 ? (
            <>
              <strong style={{ color: "var(--primary-color)" }}>
                {this.props.lang == "en"
                  ? this.props.transaltions.en["Price on Selection"]
                  : this.props.transaltions.ar["Price on Selection"]}
              </strong>
              {this.state.qty < 1 && this.state.qty >= 0 ? (
                statusButton
              ) : (
                <>
                  <div
                    className="product_qty_updater"
                    style={
                      {
                        // display: "inline",
                        // border: "none",
                        // borderRadius: "5px",
                        // marginLeft: "5px",
                        // backgroundColor: "#ece8e8",
                        // color: "red",
                      }
                    }
                  >
                    <div
                      className="dec button_inc"
                      style={
                        {
                          // marginLeft: "5px",
                          // marginRight: "3px",
                          // cursor: "pointer",
                          // paddingRight: "5px",
                        }
                      }
                      onClick={this.decreaseQty}
                    >
                      <i className="fa fa-minus"></i>
                    </div>

                    <div
                      className="qty_show"
                      // style={{ marginLeft: "3px", marginRight: "3px" }}
                    >
                      {this.state.qty > 0 ? this.state.qty : 0}
                    </div>
                    <div
                      className="inc button_inc"
                      style={
                        {
                          // marginLeft: "3px",
                          // marginRight: "5px",
                          // cursor: "pointer",
                          // paddingLeft: "5px",
                        }
                      }
                      onClick={this.increaseQty}
                    >
                      <i className="fa fa-plus"></i>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <strike style={{ color: "var(--primary-color)" }}>
                {parseFloat(this.props.p.discount) > 0
                  ? `KWD ${parseFloat(this.props.p.price).toFixed(3)}`
                  : null}
              </strike>{" "}
              <strong className="product_price">
                KWD{" "}
                {parseFloat(this.props.p.discount) > 0
                  ? parseFloat(this.props.p.discount).toFixed(3)
                  : parseFloat(this.props.p.price).toFixed(3)}
              </strong>
              {this.state.qty < 1 && this.state.qty >= 0 ? (
                statusButton
              ) : (
                <>
                  <div
                    className="product_qty_updater"
                    style={
                      {
                        // display: "inline",
                        // border: "none",
                        // borderRadius: "5px",
                        // marginLeft: "5px",
                        // backgroundColor: "#ece8e8",
                        // color: "red",
                      }
                    }
                  >
                    <div
                      className="dec button_inc"
                      style={
                        {
                          // marginLeft: "5px",
                          // marginRight: "3px",
                          // cursor: "pointer",
                          // paddingRight: "5px",
                        }
                      }
                      onClick={this.decreaseQty}
                    >
                      <i className="fa fa-minus"></i>
                    </div>

                    <div className="qty_show">
                      {this.state.qty > 0 ? this.state.qty : 0}
                    </div>
                    {this.props.p.quantity_check == "1" &&
                    this.state.qty < this.props.p.quantity ? (
                      <div
                        className="inc button_inc"
                        style={
                          {
                            // marginLeft: "3px",
                            // marginRight: "5px",
                            // cursor: "pointer",
                            // paddingLeft: "5px",
                          }
                        }
                        onClick={this.increaseQty}
                      >
                        <i className="fa fa-plus"></i>
                      </div>
                    ) : this.props.p.quantity_check == "0" ? (
                      <div
                        className="inc button_inc"
                        style={
                          {
                            // marginLeft: "3px",
                            // marginRight: "5px",
                            // cursor: "pointer",
                            // paddingLeft: "5px",
                          }
                        }
                        onClick={this.increaseQty}
                      >
                        <i className="fa fa-plus"></i>
                      </div>
                    ) : (
                      <div
                        className="inc button_inc"
                        style={
                          {
                            // marginLeft: "3px",
                            // marginRight: "5px",
                            // cursor: "pointer",
                            // paddingLeft: "5px",
                          }
                        }
                      >
                        <i className="fa fa-plus" style={{ color: "grey" }}></i>
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </a>
        {this.state.show ? (
          <ViewImages
            productId={this.props.p.id}
            show={this.state.show}
            setShow={this.showModal}
          ></ViewImages>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang.lang,
    transaltions: state.translations,
    cart: state.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedProduct: (prod_id) => dispatch(getSelectedProduct(prod_id)),
    handleProductPopUp: (val) => {
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: val },
      });
    },
    addItemToCart: (i, product) =>
      dispatch({
        type: actionTypes.ADD_CART_ITEM,
        payload: { item: product, index: i },
      }),
    updateCart: (product) =>
      dispatch({ type: actionTypes.ADD_CART_ITEM, payload: { item: product } }),
    removeItemFromCart: (i, product) =>
      dispatch({
        type: actionTypes.REMOVE_CART_ITEM,
        payload: { item: product, index: i },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);
