import * as actionTypes from '../actions/actions';
import {secureStorage} from '../../locStore';


// if(secureStorage.getItem('lang') !== undefined) {
//     store.dispatch({type: actionTypes.SET_LANGUAGE, payload: {lang: secureStorage.getItem('lang')}});
// } else {
//     secureStorage.setItem('lang', "en");
//     store.dispatch({type: actionTypes.SET_LANGUAGE, payload: {lang: "en"}});

// }

const initialState = {
    multiplier: 1
}

const multiplierReducer = (state = initialState, action) => {   
    switch(action.type) {
        case actionTypes.SET_MULTIPLIER:
            secureStorage.setItem("multiplier", action.payload.multiplier);
            return {
                multiplier: action.payload.multiplier
            }
    }
    return state

}


export default multiplierReducer;