import React, { Component } from "react";
import { Link } from "react-router-dom";
import { BASE_API_URL, ENVIRONMENT } from "../../config";
import { secureStorage } from "../../locStore";
import { cloneDeep } from "lodash";
import Select from "react-select";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions/actions";
import DeliveryMode from "../CartSidebar/DeliveryMode";
import CheckoutCart from "./CheckoutCart/CheckoutCart";
import LoginBeforeForm from "./LoginBeforeForm/LoginBeforeForm";
import CheckoutHeader from "./CheckoutHeader/CheckoutHeader";
import AdditionalInfo from "./AdditionalInfo/AdditionalInfo";
import LoginAfterForm from "./LoginAfterForm/LoginAfterForm";
import { callService } from "../../services";

// import DateFnsUtils from "@date-io/date-fns";

// import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

class CheckoutPage extends Component {
  constructor(props) {
    super(props);
    if (ENVIRONMENT == "development") {
      console.log(this.props);
    }

    let c = new Date();
    if (ENVIRONMENT == "development") {
      console.log(
        `${c.getFullYear()}-${c.getMonth()}-${c.getDate()}T${c.getHours()}:${c.getMinutes()}`
      );
      console.log(c.getMinutes());
    }
    let mins = c.getMinutes();
    if (c.getMinutes() <= 9) {
      mins = "0" + c.getMinutes();
    }
    if (ENVIRONMENT == "development") {
      console.log(mins);
    }
    let initialState = secureStorage.getItem("checkoutData");
    this.state = initialState;
    // this.state = {
    //   show_picker: false,
    //   // selectedDate: `${c.getFullYear()}-${c.getMonth()}-${c.getDate()}T${c.getHours()}:${mins}`,
    //   selectedDate: null,
    //   available_slots: [],
    //   selected_slot: {
    //     label: null,
    //     value: null,
    //   },
    //   fname: null,
    //   lname: null,
    //   email: null,
    //   phone: null,
    //   mobile: null,
    //   country: null,
    //   area: {
    //     label: null,
    //     value: null,
    //   },
    //   block: null,
    //   street: null,
    //   avenue: null,
    //   housetype: "house",
    //   house_number: null,
    //   apartment_name: null,
    //   apartment_floor: null,
    //   apartment_flat: null,
    //   office_name: null,
    //   office_number: null,
    //   office_floor: null,
    //   additional_information: null,
    //   delivery_type: "deliver_now",
    //   deliver_schedule: null,
    //   deliver_date: null,
    //   deliver_time: null,
    //   delivery_charges: 0,
    //   coupon: "",
    //   coupon_err: null,
    //   discount: 0,
    //   payment_method: null,
    //   error_state: {
    //     fname: false,
    //     lname: false,
    //     phone: false,
    //     mobile: false,
    //     email: false,
    //     country: false,
    //     area: false,
    //     governate: false,
    //     block: false,
    //     street: false,
    //     avenue: false,
    //     housetype: false,
    //     payment_method: false,
    //     address_line_1: false,
    //     city: false,
    //     state: false,
    //     house_number: false,
    //     office_number: false,
    //     office_name: false,
    //     office_floor: false,
    //     apartment_name: false,
    //     apartment_flat: false,
    //     apartment_floor: false,
    //     zipcode: false,
    //   },
    //   is_loading: true,
    //   showCartMsg: false,
    //   paymentMethods: [],
    //   // error_state: {
    //   //     first_name: true,
    //   //     last_name: true,
    //   //     phone: true,
    //   //     email: true,
    //   //     country: true,
    //   //     area: true,
    //   //     governate: true,
    //   //     block: true,
    //   //     street: true,
    //   //     avenue: true,
    //   //     housetype: true,
    //   //     payment_method: true,
    //   //     address_line_1: true,
    //   //     city: true,
    //   //     state: true,
    //   //     pincode: true,
    //   //     house_number: true,
    //   //     office_number: true,
    //   //     office_name: true,
    //   //     office_floor: true,
    //   //     apartment_name: true,
    //   //     apartment_flat: true,
    //   //     apartment_floor: true,
    //   //     zipcode: true
    //   // }
    // };
  }

  componentDidMount() {
    if (ENVIRONMENT == "development") {
      console.log("checkout didmount");
      console.log(this.props.delivery_mode);
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (ENVIRONMENT == "development") {
      console.log(this.props);
    }
    if (this.props.cart.items.length <= 0) {
      this.props.history.push(`/`);
    }

    // let c = new Date(this.state.selectedDate);
    // let d = `${c.getFullYear()}-${c.getMonth()}-${c.getDate()}`;
    // let area = null;

    // if(this.props.delivery_location !== null && this.props.delivery_location !== undefined) {
    //     area = this.props.delivery_location.id;
    // };

    // if(area !== null && area !== undefined) {
    //     callService(`https://projects.yellowsoft.in/testrk/app/delivery_time/${area}/${d}`)
    //     .then(resp => {
    //         return resp.json();

    //     })
    //     .then(respJson => {
    //         console.log(respJson);
    //         this.setState(prevState => {
    //             return {
    //                 ...prevState,
    //                 available_slots: respJson
    //             }
    //         })
    //     })
    //     .catch(err => {
    //         console.log(err);
    //     })
    // }
    if (this.props.user.isLoggedIn) {
      callService(`${BASE_API_URL}/addresses/${this.props.user.userdata.id}`)
        .then((resp) => resp.json())
        .then((respJson) => {
          if (ENVIRONMENT == "development") {
            console.log(respJson);
          }
          this.setState((prevState) => {
            let currentState = cloneDeep(prevState);
            return {
              ...currentState,
              address_list: respJson,
            };
          });
        })
        .catch((err) => {
          if (ENVIRONMENT == "development") {
            console.log(err);
          }
        });
    }

    callService(
      `${BASE_API_URL}/cart_check`,
      "POST",
      {},
      {
        products: this.props.cart.items,
      }
    )
      .then((resp) => {
        return resp.json();
      })
      .then((respJson) => {
        if (ENVIRONMENT == "development") {
          console.log("cart_check response");
          console.log(respJson, "cart_check response");
        }
        let calculated_total = 0;
        let proceedOrder = true;
        respJson.products.map((product) => {
          if (ENVIRONMENT == "development") {
            console.log(product);
          }
          // if (product.cu_status == 1) {
          calculated_total += parseFloat(product.calculated_total);
          // }
          if (product.cu_status == 0) {
            proceedOrder = false;
          }
        });

        callService(`${BASE_API_URL}/paymethods`)
          .then((payres) => {
            return payres.json();
          })
          .then((payResJson) => {
            if (ENVIRONMENT == "development") {
              console.log(payResJson);
            }
            let cart = {
              calculated_total: calculated_total,
              items: respJson.products,
              showCartMsg: proceedOrder ? false : true,
            };
            secureStorage.setItem("cart", {
              calculated_total: calculated_total,
              items: respJson.products,
            });
            this.props.reloadCart(cart);
            this.setState((prevState) => {
              let currentState = cloneDeep(prevState);

              return {
                ...currentState,
                is_loading: false,
                showCartMsg: proceedOrder ? false : true,
                paymentMethods: payResJson.methods,
              };
            });
          })
          .catch((err) => {
            if (ENVIRONMENT == "development") {
              console.log(err);
            }
          });

        // replace redux cart and local storage with latest products data
        // product not available ui and delete button
        // order button should be disabled if out of stock products not deleted
      })
      .catch((err) => {
        if (ENVIRONMENT == "development") {
          return console.log(err);
        }
        return;
      });
  }

  componentDidUpdate(prevProps) {
    if (ENVIRONMENT == "development") {
      console.log("checkout didupdate");
    }
    if (
      this.props.user.isLoggedIn != prevProps.user.isLoggedIn ||
      this.props.lang != prevProps.lang
    ) {
      if (this.props.user.isLoggedIn) {
        callService(`${BASE_API_URL}/addresses/${this.props.user.userdata.id}`)
          .then((resp) => resp.json())
          .then((respJson) => {
            if (ENVIRONMENT == "development") {
              console.log(respJson);
            }
            this.setState((prevState) => {
              let currentState = cloneDeep(prevState);
              return {
                ...currentState,
                address_list: respJson,
              };
            });
          })
          .catch((err) => {
            if (ENVIRONMENT == "development") {
              console.log(err);
            }
          });
      }
      if (this.props.checkoutData.delivery_info.delivery_now == "0") {
        this.props.set_deliveryMode({
          type: "delivery_later",
        });
        this.props.set_pickupLocation(null);
      }
    }
  }

  showAuthModal = () => {
    this.props.handleAuthModal(true);
  };

  setDataVariable = (name, value) => {
    let myState = cloneDeep(this.props.checkoutData);
    myState[name] = value;

    // secureStorage.setItem("checkoutData", myState);
    if (name == "coupon") {
      if (!value) {
        myState.discount = 0;
        myState.coupon_err = null;
      }
    } else {
      if (!value) {
        myState.error_state[name] = true;
      } else {
        myState.error_state[name] = false;
      }
    }
    this.props.updateCheckoutData(myState);
  };

  orderPlacement = () => {
    document.getElementById("orderButton").disabled = true;
    callService(
      `${BASE_API_URL}/cart_check`,
      "POST",
      {},
      {
        products: this.props.cart.items,
      }
    )
      .then((resp) => {
        return resp.json();
      })
      .then((respJson) => {
        if (ENVIRONMENT == "development") {
          console.log(respJson);
        }
        let calculated_total = 0;
        let proceedOrder = true;
        respJson.products.map((product) => {
          if (ENVIRONMENT == "development") {
            console.log(product);
          }
          // if (product.cu_status == 1) {
          calculated_total += parseFloat(product.calculated_total);
          // }
          if (product.cu_status == 0) {
            proceedOrder = false;
          }
        });

        let cart = {
          calculated_total: calculated_total,
          items: respJson.products,
        };
        secureStorage.setItem("cart", {
          calculated_total: calculated_total,
          items: respJson.products,
        });
        this.props.reloadCart(cart);
        this.setState(
          (prevState) => {
            let currentState = cloneDeep(prevState);

            return {
              ...currentState,
              is_loading: false,
              showCartMsg: proceedOrder ? false : true,

              // ? "Some products are out of stock and please remove them to place order"
              // : null,
            };
          },
          () => {
            if (proceedOrder) {
              if (ENVIRONMENT == "development") {
                console.log("placing order");
              }
              let products = this.props.cart.items;
              let error_state = {
                fname: this.state.fname ? false : true,
                lname: this.state.lname ? false : true,
                phone: this.state.mobile ? false : true,
                mobile: this.state.mobile ? false : true,
                email: this.state.email ? false : true,
                country: false,
                area: false,
                governate: false,
                block: this.state.block ? false : true,
                street: this.state.street ? false : true,
                // avenue: this.state.avenue ? false : true,
                housetype: false,
                payment_method: this.state.payment_method ? false : true,
                address_line_1: false,
                city: false,
                state: false,
                zipcode: false,
                house_number: false,
                office_number: false,
                office_name: false,
                office_floor: false,
                apartment_name: false,
                apartment_flat: false,
                apartment_floor: false,
                deliver_date: false,
                deliver_time: false,
              };

              let member_error_state = {
                // country: false,
                selected_address: false,

                payment_method: this.state.payment_method ? false : true,

                deliver_date: false,
                deliver_time: false,
              };

              let jsonData = {
                first_name: this.state.fname,
                last_name: this.state.lname ? this.state.lname : "",
                email: this.state.email ? this.state.email : null,
                phone: this.state.mobile ? this.state.mobile : null,
                country: this.state.country ? this.state.country : "",
                area: this.state.area,
                block: this.state.block ? this.state.block : null,
                street: this.state.street ? this.state.street : null,
                avenue: this.state.avenue ? this.state.avenue : "",
                housetype: this.state.housetype ? this.state.housetype : null,
                directions: this.state.additional_information
                  ? this.state.additional_information
                  : "",
                payment_method: this.state.payment_method
                  ? this.state.payment_method
                  : null,
                delivery_type: this.state.delivery_type
                  ? this.state.delivery_type
                  : "delivery",
                // order_type: this.state.
                // payment_type: this.state.payment_type,
                // delivery_timing: '2',
                // delivery_date: '',
                // delivery_time: '',
                cart_value: parseFloat(
                  this.props.cart.calculated_total
                ).toFixed(3),
                coupon_type: "",
                coupon_value: parseFloat(this.state.discount).toFixed(3),
                coupon_code: this.state.coupon,
                discount: parseFloat(this.state.discount).toFixed(3),
                delivery_charges: parseFloat(
                  this.state.delivery_charges
                ).toFixed(3),
                order_total_: parseFloat(
                  this.props.cart.calculated_total +
                    parseFloat(this.state.delivery_charges) -
                    parseFloat(this.state.discount)
                ).toFixed(3),
                member_id: this.props.user.isLoggedIn
                  ? this.props.user.userdata.id
                  : "-1",
                products: products,
                checkout_type: this.props.user.isLoggedIn ? "user" : "guest",
                branch_id: "",
                // payment_type: this.state.pay
                // conversion_currency_code: "Rupees",
                // conversion_rate: 245
              };

              if (this.state.area.id) {
                jsonData["area"] = this.state.area.id ? this.state.area : null;

                jsonData["delivery_charges"] = parseFloat(
                  this.state.delivery_charges
                ).toFixed(3);
              } else {
                error_state.area = true;

                // alert("Please select your area/Location");
                // return
              }

              if (
                this.state.delivery_type == "deliver_later" ||
                this.state.delivery_type == "takeaway"
              ) {
                jsonData["delivery_date"] = this.state.deliver_date
                  ? this.state.deliver_date
                  : null;
                jsonData["delivery_time"] = this.state.deliver_time
                  ? this.state.deliver_time
                  : null;

                jsonData["selected_slot"] = this.state.selected_slot
                  ? this.state.selected_slot
                  : null;

                error_state.deliver_date = this.state.deliver_date
                  ? false
                  : true;
                error_state.deliver_time = this.state.deliver_time
                  ? false
                  : true;
              } else {
                jsonData["delivery_date"] = "";
                jsonData["delivery_time"] = "";

                jsonData["selected_slot"] = "";
              }

              if (this.state.housetype === "house") {
                jsonData["house_number"] = this.state.house_number
                  ? this.state.house_number
                  : null;
                jsonData["house"] = this.state.house_number
                  ? this.state.house_number
                  : null;
                jsonData["office_number"] = "";
                jsonData["office_name"] = "";
                jsonData["office_floor"] = "";
                jsonData["apartment_name"] = "";
                jsonData["apartment_flat"] = "";
                jsonData["apartment_floor"] = "";
                error_state.house_number = this.state.house_number
                  ? false
                  : true;
              } else if (this.state.housetype === "apartment") {
                jsonData["house_number"] = "";
                jsonData["office_number"] = "";
                jsonData["office_name"] = "";
                jsonData["office_floor"] = "";
                jsonData["apartment_name"] = this.state.apartment_name
                  ? this.state.apartment_name
                  : null;
                jsonData["apartment_floor"] = this.state.apartment_floor
                  ? this.state.apartment_floor
                  : null;
                jsonData["apartment_flat"] = this.state.apartment_flat
                  ? this.state.apartment_flat
                  : null;

                jsonData["house"] = this.state.apartment_name
                  ? this.state.apartment_name
                  : null;
                jsonData["floor"] = this.state.apartment_floor
                  ? this.state.apartment_floor
                  : null;
                jsonData["flat"] = this.state.apartment_flat
                  ? this.state.apartment_flat
                  : null;
                error_state.apartment_name = this.state.apartment_name
                  ? false
                  : true;
                error_state.apartment_floor = this.state.apartment_floor
                  ? false
                  : true;
                error_state.apartment_flat = this.state.apartment_flat
                  ? false
                  : true;
              } else if (this.state.housetype === "office") {
                jsonData["house_number"] = "";
                jsonData["office_number"] = this.state.office_number
                  ? this.state.office_number
                  : null;
                jsonData["office_name"] = this.state.office_name
                  ? this.state.office_name
                  : null;
                jsonData["office_floor"] = this.state.office_floor
                  ? this.state.office_floor
                  : null;
                jsonData["flat"] = this.state.office_number
                  ? this.state.office_number
                  : null;
                jsonData["house"] = this.state.office_name
                  ? this.state.office_name
                  : null;
                jsonData["floor"] = this.state.office_floor
                  ? this.state.office_floor
                  : null;
                jsonData["apartment_name"] = "";
                jsonData["apartment_floor"] = "";
                jsonData["apartment_flat"] = "";
                error_state.office_name = this.state.office_name ? false : true;
                error_state.office_number = this.state.office_number
                  ? false
                  : true;
                error_state.office_floor = this.state.office_floor
                  ? false
                  : true;
              } else {
                jsonData["house_number"] = "";
                jsonData["office_number"] = "";
                jsonData["office_name"] = "";
                jsonData["office_floor"] = "";
                jsonData["apartment_name"] = "";
                jsonData["apartment_floor"] = "";
                jsonData["apartment_flat"] = "";

                jsonData["house"] = "";
                jsonData["floor"] = "";
                jsonData["flat"] = "";
              }

              let canSubmitOrder = true;
              if (!this.props.user.isLoggedIn) {
                for (const prope in jsonData) {
                  // if(prope !== 'directions') {
                  if (ENVIRONMENT == "development") {
                    console.log();
                  }
                  if (
                    jsonData[prope] === null ||
                    jsonData[prope] === undefined
                  ) {
                    if (ENVIRONMENT == "development") {
                      console.log(
                        "null/undefined value is ",
                        prope,
                        "----",
                        jsonData[prope]
                      );
                    }
                    canSubmitOrder = false;
                  }

                  // [{"product_id":"134","addon_id":"2899","addon_title":"5 Person","addon_title_ar":"5 أشخاص","price":"6.000"}]
                  // }
                }

                for (const ervar in error_state) {
                  if (error_state[ervar]) {
                    canSubmitOrder = false;
                  }
                }
              } else if (this.props.user.isLoggedIn) {
                jsonData["selected_address"] = this.state.selected_address
                  ? this.state.selected_address
                  : null;
                this.state.selected_address
                  ? (member_error_state["selected_address"] = false)
                  : (member_error_state["selected_address"] = true);
                for (const ervar in member_error_state) {
                  if (member_error_state[ervar]) {
                    canSubmitOrder = false;
                  }
                }
              }
              jsonData["order_total"] = (
                parseFloat(this.props.cart.calculated_total) +
                parseFloat(this.state.delivery_charges) -
                parseFloat(this.state.discount)
              ).toFixed(3);
              if (ENVIRONMENT == "development") {
                console.log(jsonData, "new DAta");
              }
              let jsonAppender = JSON.stringify(jsonData);
              if (ENVIRONMENT == "development") {
                console.log(jsonAppender);
                console.log(error_state);
              }
              if (canSubmitOrder) {
                if (ENVIRONMENT == "development") {
                  console.log("order can be submitted");
                }
                callService(
                  `${BASE_API_URL}/checkout`,
                  "POST",
                  {},
                  JSON.parse(jsonAppender)
                )
                  .then((resp) => {
                    return resp.json();
                  })
                  .then((resJson) => {
                    if (ENVIRONMENT == "development") {
                      console.log(
                        "-----------------return data----------------"
                      );
                      console.log(resJson);
                    }

                    // console.log(this.state.payment_method);
                    // alert(resJson.status);
                    if (resJson.status == "Success") {
                      if (this.state.payment_method == "cod") {
                        if (ENVIRONMENT == "development") {
                          console.log(resJson.invoice_id);
                        }
                        this.props.resetCart();
                        this.props.history.push(
                          `/thankyou/${resJson.invoice_id}`
                          // {
                          //   cod: true,
                          // }
                        );
                      } else {
                        if (ENVIRONMENT == "development") {
                          console.log("this is payment order");
                          console.log(resJson);
                        }
                        callService(
                          `${BASE_API_URL}/payment`,
                          "POST",
                          {},
                          {
                            invoice_id: resJson.invoice_id,
                          }
                        )
                          .then((payRes) => payRes.json())
                          .then((payResJson) => {
                            if (ENVIRONMENT == "development") {
                              console.log(payResJson);
                            }
                            if (payResJson.status == "1") {
                              document.getElementById("orderButton").disabled = false;
                             // alert(payResJson.message);
                              window.location = payResJson.message;
                              
                            } else {
                              document.getElementById(
                                "orderButton"
                              ).disabled = false;
                              alert(payResJson.message);
                            }
                          })
                          .catch((err) => {
                            if (ENVIRONMENT == "development") {
                              console.log(err);
                            }
                            document.getElementById(
                              "orderButton"
                            ).disabled = false;
                          });
                      }
                    } else {
                      if (ENVIRONMENT == "development") {
                        console.log("order failed, Please try again");
                      }
                    }
                    // this.props.history.push(`/order-success/${resJson.id}`, {
                    //   order_total: jsonData["order_total"],
                    //   order_id: resJson.id,
                    // });
                  })
                  .catch((err) => {
                    if (ENVIRONMENT == "development") {
                      console.log(err);
                    }
                    alert("Order failed");
                    document.getElementById("orderButton").disabled = false;
                  });
              } else {
                // alert("Please provide all the required fields");
                document.getElementById("orderButton").disabled = false;

                let curr_state = cloneDeep(this.state);
                this.setState((prevState) => {
                  return {
                    ...prevState,
                    error_state: {
                      ...error_state,
                    },
                  };
                });

                return;
              }
            } else {
            }
          }
        );
        // replace redux cart and local storage with latest products data
        // product not available ui and delete button
        // order button should be disabled if out of stock products not deleted
      })
      .catch((err) => {
        if (ENVIRONMENT == "development") {
          console.log(err);
        }
        document.getElementById("orderButton").disabled = false;
        return;
      });
    // console.log("placing order");
    // let products = this.props.cart.items;
    // let error_state = {
    //   fname: this.state.fname ? false : true,
    //   lname: this.state.lname ? false : true,
    //   phone: this.state.mobile ? false : true,
    //   mobile: this.state.mobile ? false : true,
    //   email: this.state.email ? false : true,
    //   country: false,
    //   area: false,
    //   governate: false,
    //   block: this.state.block ? false : true,
    //   street: this.state.street ? false : true,
    //   avenue: this.state.avenue ? false : true,
    //   housetype: false,
    //   payment_method: this.state.payment_method ? false : true,
    //   address_line_1: false,
    //   city: false,
    //   state: false,
    //   zipcode: false,
    //   house_number: false,
    //   office_number: false,
    //   office_name: false,
    //   office_floor: false,
    //   apartment_name: false,
    //   apartment_flat: false,
    //   apartment_floor: false,
    //   deliver_date: false,
    //   deliver_time: false,
    // };

    // let jsonData = {
    //   first_name: this.state.fname,
    //   last_name: this.state.lname ? this.state.lname : "",
    //   email: this.state.email ? this.state.email : null,
    //   phone: this.state.mobile ? this.state.mobile : null,
    //   country: this.state.country ? this.state.country : "",
    //   area: this.state.area,
    //   block: this.state.block ? this.state.block : null,
    //   street: this.state.street ? this.state.street : null,
    //   avenue: this.state.avenue ? this.state.avenue : "",
    //   housetype: this.state.housetype ? this.state.housetype : null,
    //   directions: this.state.additional_information
    //     ? this.state.additional_information
    //     : "",
    //   payment_method: this.state.payment_method
    //     ? this.state.payment_method
    //     : null,
    //   delivery_type: this.state.delivery_type
    //     ? this.state.delivery_type
    //     : "delivery",
    //   // order_type: this.state.
    //   // payment_type: this.state.payment_type,
    //   // delivery_timing: '2',
    //   // delivery_date: '',
    //   // delivery_time: '',
    //   cart_value: parseFloat(this.props.cart.calculated_total).toFixed(3),
    //   coupon_type: "",
    //   coupon_value: parseFloat(this.state.discount).toFixed(3),
    //   coupon_code: this.state.coupon,
    //   discount: parseFloat(this.state.discount).toFixed(3),
    //   delivery_charges: parseFloat(this.state.delivery_charges).toFixed(3),
    //   order_total_: parseFloat(
    //     this.props.cart.calculated_total +
    //       parseFloat(this.state.delivery_charges) -
    //       parseFloat(this.state.discount)
    //   ).toFixed(3),
    //   member_id: this.props.user.isLoggedIn
    //     ? this.props.user.userdata.id
    //     : "-1",
    //   products: products,
    //   checkout_type: this.props.user.isLoggedIn ? "user" : "guest",
    //   branch_id: "",
    //   // payment_type: this.state.pay
    //   // conversion_currency_code: "Rupees",
    //   // conversion_rate: 245
    // };

    // if (this.state.area.id) {
    //   jsonData["area"] = this.state.area.id ? this.state.area : null;

    //   jsonData["delivery_charges"] = parseFloat(
    //     this.state.delivery_charges
    //   ).toFixed(3);
    // } else {
    //   error_state.area = true;

    //   // alert("Please select your area/Location");
    //   // return
    // }

    // if (
    //   this.state.delivery_type == "deliver_later" ||
    //   this.state.delivery_type == "takeaway"
    // ) {
    //   jsonData["delivery_date"] = this.state.deliver_date
    //     ? this.state.deliver_date
    //     : null;
    //   jsonData["delivery_time"] = this.state.deliver_time
    //     ? this.state.deliver_time
    //     : null;

    //   jsonData["selected_slot"] = this.state.selected_slot
    //     ? this.state.selected_slot
    //     : null;

    //   error_state.deliver_date = this.state.deliver_date ? false : true;
    //   error_state.deliver_time = this.state.deliver_time ? false : true;
    // } else {
    //   jsonData["delivery_date"] = "";
    //   jsonData["delivery_time"] = "";

    //   jsonData["selected_slot"] = "";
    // }

    // if (this.state.housetype === "house") {
    //   jsonData["house_number"] = this.state.house_number
    //     ? this.state.house_number
    //     : null;
    //   jsonData["office_number"] = "";
    //   jsonData["office_name"] = "";
    //   jsonData["office_floor"] = "";
    //   jsonData["apartment_name"] = "";
    //   jsonData["apartment_flat"] = "";
    //   jsonData["apartment_floor"] = "";
    //   error_state.house_number = this.state.house_number ? false : true;
    // } else if (this.state.housetype === "apartment") {
    //   jsonData["house_number"] = "";
    //   jsonData["office_number"] = "";
    //   jsonData["office_name"] = "";
    //   jsonData["office_floor"] = "";
    //   jsonData["apartment_name"] = this.state.apartment_name
    //     ? this.state.apartment_name
    //     : null;
    //   jsonData["apartment_floor"] = this.state.apartment_floor
    //     ? this.state.apartment_floor
    //     : null;
    //   jsonData["apartment_flat"] = this.state.apartment_flat
    //     ? this.state.apartment_flat
    //     : null;
    //   error_state.apartment_name = this.state.apartment_name ? false : true;
    //   error_state.apartment_floor = this.state.apartment_floor ? false : true;
    //   error_state.apartment_flat = this.state.apartment_flat ? false : true;
    // } else if (this.state.housetype === "office") {
    //   jsonData["house_number"] = "";
    //   jsonData["office_number"] = this.state.office_number
    //     ? this.state.office_number
    //     : null;
    //   jsonData["office_name"] = this.state.office_name
    //     ? this.state.office_name
    //     : null;
    //   jsonData["office_floor"] = this.state.office_floor
    //     ? this.state.office_floor
    //     : null;
    //   jsonData["apartment_name"] = "";
    //   jsonData["apartment_floor"] = "";
    //   jsonData["apartment_flat"] = "";
    //   error_state.office_name = this.state.office_name ? false : true;
    //   error_state.office_number = this.state.office_number ? false : true;
    //   error_state.office_floor = this.state.office_floor ? false : true;
    // } else {
    //   jsonData["house_number"] = "";
    //   jsonData["office_number"] = "";
    //   jsonData["office_name"] = "";
    //   jsonData["office_floor"] = "";
    //   jsonData["apartment_name"] = "";
    //   jsonData["apartment_floor"] = "";
    //   jsonData["apartment_flat"] = "";
    // }

    // let canSubmitOrder = true;
    // for (const prope in jsonData) {
    //   // if(prope !== 'directions') {
    //   console.log();
    //   if (jsonData[prope] === null || jsonData[prope] === undefined) {
    //     console.log("null/undefined value is ", prope, "----", jsonData[prope]);
    //     canSubmitOrder = false;
    //   }

    //   // [{"product_id":"134","addon_id":"2899","addon_title":"5 Person","addon_title_ar":"5 أشخاص","price":"6.000"}]
    //   // }
    // }

    // for (const ervar in error_state) {
    //   if (error_state[ervar]) {
    //     canSubmitOrder = false;
    //   }
    // }
    // jsonData["order_total"] = (
    //   parseFloat(this.props.cart.calculated_total) +
    //   parseFloat(this.state.delivery_charges) -
    //   parseFloat(this.state.discount)
    // ).toFixed(3);
    // console.log(jsonData, "new DAta");
    // let jsonAppender = JSON.stringify(jsonData);
    // console.log(jsonAppender);
    // console.log(error_state);
    // if (canSubmitOrder) {
    //   console.log("order can be submitted");
    //   callService(`${BASE_API_URL}/checkout`, {
    //     method: "POST",
    //     body: jsonAppender,
    //     "Content-Type": "application/json",
    //   })
    //     .then((resp) => {
    //       return resp.json();
    //     })
    //     .then((resJson) => {
    //       console.log("-----------------return data----------------");
    //       console.log(resJson);

    //       // alert(resJson.status);
    //       this.props.resetCart();
    //       this.props.history.push("/");
    //       // this.props.history.push(`/order-success/${resJson.id}`, {
    //       //   order_total: jsonData["order_total"],
    //       //   order_id: resJson.id,
    //       // });
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       alert("Order failed");
    //     });
    // } else {
    //   // alert("Please provide all the required fields");
    //   let curr_state = cloneDeep(this.state);
    //   this.setState((prevState) => {
    //     return {
    //       ...prevState,
    //       error_state: {
    //         ...error_state,
    //       },
    //     };
    //   });
    //   return;
    // }
  };

  render() {
    return (
      <>
        <main className="bg_gray">
          <div className="container margin_60_20">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
                <div className="box_order_form">
                  <CheckoutHeader
                    setDataVariable={this.setDataVariable}
                  ></CheckoutHeader>
                  <div
                    style={{
                      clear: "both",
                    }}
                  ></div>
                  {!this.props.user.isLoggedIn ? (
                    <LoginBeforeForm
                      setDataVariable={this.setDataVariable}
                    ></LoginBeforeForm>
                  ) : (
                    <>
                      <LoginAfterForm
                        setDataVariable={this.setDataVariable}
                      ></LoginAfterForm>
                    </>
                  )}
                </div>
                {this.props.delivery_mode.type == "pickup" ? null : !this.props
                    .user.isLoggedIn ? (
                  <AdditionalInfo
                    setDataVariable={this.setDataVariable}
                  ></AdditionalInfo>
                ) : null}
              </div>

              <CheckoutCart
                setDataVariable={this.setDataVariable}
                {...this.props}
              ></CheckoutCart>
            </div>
          </div>
        </main>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // shop: state.shop,
    lang: state.lang.lang,
    settings: state.settings.data,
    shop: state.shop,
    cart: state.cart,
    lang: state.lang.lang,
    translations: state.translations,
    showSignInModal: state.handleSignInModal.show,
    selectedProduct: state.selectedProduct,
    countries: state.countries.data,
    branches: state.branches.items,
    governates: state.governates.data,
    delivery_location: state.deliveryLocation.data.area,
    pickup_location: state.deliveryLocation.data.branch,
    delivery_location_governate: state.deliveryLocation.data.governate,
    delivery_mode: state.deliveryMode,
    currency: state.currency,
    multiplier: state.multiplier,
    user: state.user,
    checkoutData: state.checkoutData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // updateShop: () => dispatch(updateShopDetails()),
    // getCategories: () => dispatch(getCategories()),
    setLang: (lang) =>
      dispatch({ type: actionTypes.SET_LANGUAGE, payload: { lang: lang } }),
    setShowProductPopUp: (show_product_popup) =>
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: show_product_popup },
      }),
    resetCart: () => dispatch({ type: actionTypes.RESET_CART, payload: null }),
    set_deliveryLocation: (location) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_LOCATION,
        payload: { location: location },
      }),
    set_deliveryMode: (modeData) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_MODE,
        payload: { modeData },
      }),
    set_tempDeliveryMode: (modeData) =>
      dispatch({
        type: actionTypes.SET_TEMP_DELIVERY_MODE,
        payload: { modeData },
      }),
    addItemToCart: (i, product) =>
      dispatch({
        type: actionTypes.ADD_CART_ITEM,
        payload: { item: product, index: i },
      }),
    updateCart: (product) =>
      dispatch({ type: actionTypes.ADD_CART_ITEM, payload: { item: product } }),
    removeItemFromCart: (i, product) =>
      dispatch({
        type: actionTypes.REMOVE_CART_ITEM,
        payload: { item: product, index: i },
      }),
    reloadCart: (cart) =>
      dispatch({
        type: actionTypes.SET_CART_ON_RELOAD,
        payload: { cart: cart },
      }),
    popItem: (i) =>
      dispatch({
        type: actionTypes.POPITEM,
        payload: { popIndex: i },
      }),
    handleAuthModal: (val) => {
      dispatch({
        type: actionTypes.HANDLE_AUTH_MODAL,
        payload: { showModal: val },
      });
    },
    updateCheckoutData: (checkoutData) => {
      dispatch({
        type: actionTypes.UPDATE_CHECKOUTDATA,
        payload: { checkoutData: checkoutData },
      });
    },

    set_pickupLocation: (branch) =>
      dispatch({
        type: actionTypes.SET_PICKUP_LOCATION,
        payload: { branch: branch },
      }),

    // setCurrency: (currency) => dispatch({type: actionTypes.SET_CURRENCY, payload: { base_currency: currency}}),
    // setMultiplier: (multiplier) => dispatch({type: actionTypes.SET_MULTIPLIER, payload: { multiplier: multiplier}})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);

export const initialCheckoutData = {
  show_picker: false,
  show: false,
  // selectedDate: `${c.getFullYear()}-${c.getMonth()}-${c.getDate()}T${c.getHours()}:${mins}`,
  selectedDate: null,
  available_slots: [],
  selected_slot: {
    label: null,
    value: null,
  },
  fname: null,
  lname: null,
  email: null,
  phone: null,
  mobile: null,
  country: null,
  area: {
    label: null,
    value: null,
  },
  areaId: {
    label: null,
    value: null,
  },
  block: null,
  street: null,
  avenue: null,
  housetype: "house",
  house_number: null,
  apartment_name: null,
  apartment_floor: null,
  apartment_flat: null,
  office_name: null,
  office_number: null,
  office_floor: null,
  additional_information: null,
  delivery_type: "deliver_now",
  deliver_schedule: null,
  deliver_date: null,
  deliver_time: null,
  delivery_charges: 0,
  coupon: "",
  coupon_err: null,
  discount: 0,
  payment_method: null,
  error_state: {
    fname: false,
    lname: false,
    phone: false,
    mobile: false,
    email: false,
    // country: false,
    area: false,
    // governate: false,
    block: false,
    street: false,
    avenue: false,
    housetype: false,
    payment_method: false,
    // address_line_1: false,
    // city: false,
    // state: false,
    house_number: false,
    office_number: false,
    office_name: false,
    office_floor: false,
    apartment_name: false,
    apartment_flat: false,
    apartment_floor: false,
    // zipcode: false,
    additional_information: false,
    deliver_date: false,
    deliver_time: false,
    payment_method: false,
    selected_address: false,
    branch: false,
  },
  is_loading: true,
  showCartMsg: false,
  paymentMethods: [],
  address_list: [],
  selected_address: null,
  delivery_info: {
    price: 0,
    minimum: 0,
    title: null,
    title_ar: null,
    busy: 0,
    closed: 0,
  },
};
