import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { BASE_API_URL, ENVIRONMENT } from "../../config";

import carouselStyles from "./ViewPhotos.css";
import { callService } from "../../services";
class ViewPhotos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gallery: [],
      loading: true,
    };
  }

  componentDidMount() {
    callService(`${BASE_API_URL}/gallery`)
      .then((resp) => {
        return resp.json();
      })
      .then((respJson) => {
        if (ENVIRONMENT == "development") {
          console.log(respJson);
        }

        this.setState((prevState) => {
          return {
            ...prevState,
            loading: false,
            gallery: respJson.gallery,
          };
        });
      })
      .catch((err) => {
        if (ENVIRONMENT == "development") {
          console.log(err);
        }
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.show != prevProps.show) {
      callService(`${BASE_API_URL}/gallery`)
        .then((resp) => {
          return resp.json();
        })
        .then((respJson) => {
          if (ENVIRONMENT == "development") {
            console.log(respJson);
          }
          // ENVIRONMENT == "development" ? console.log(respJson) : null;
          this.setState((prevState) => {
            return {
              ...prevState,
              loading: false,
              gallery: respJson.gallery,
            };
          });
        })
        .catch((err) => {
          if (ENVIRONMENT == "development") {
            console.log(err);
          }
        });
    }
  }
  render() {
    let mobile = false;
    if (window.matchMedia("screen and (max-width: 990px)").matches) {
      mobile = true;
    }
    let gallery = this.state.gallery.map((img) => {
      return (
        // <div key={img.id} style={{ height: "100%" }}>
        <div key={img.id} style={{ height: "100%" }}>
          <img
            className={carouselStyles.imgContainer}
            src={img.image}
            style={mobile ? {} : { objectFit: "cover", height: "100%" }}
            // style={{ height: "100%" }}
          />
          {/* <p className="legend">{`Legend ${img.id}`}</p> */}
        </div>
      );
    });
    return (
      <Modal
        show={this.props.show}
        onHide={() => this.props.setShow(false)}
        dialogClassName="viewPhotosModal"
        aria-labelledby="example-custom-modal-styling-title"
      >
        {/* <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Custom Modal Styling
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <Carousel
            // className={carouselStyles.carouselSlider}
            showThumbs={false}
          >
            {gallery}
          </Carousel>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ViewPhotos;
