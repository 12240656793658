import React, { Component, Fragment } from "react";
import { Modal } from "react-bootstrap";
import * as actionTypes from "../../store/actions/actions";
import { connect } from "react-redux";
import { getSelectedProduct } from "../../store/actions/popProduct";
import cloneDeep from "lodash/cloneDeep";
import PopupSkeleton from "./PopupSkeleton/PopupSkeleton";
import { BASE_API_URL, ENVIRONMENT } from "../../config";

class ProductModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: this.props.selectedProduct.selectedProduct,
      qty: 1,
      updatedPrice:
        parseFloat(
          this.props.selectedProduct.selectedProduct?.product?.discount
        ) > 0
          ? parseFloat(
              this.props.selectedProduct.selectedProduct?.product?.discount
            ).toFixed(3)
          : parseFloat(
              this.props.selectedProduct.selectedProduct?.product?.price
            ).toFixed(3),
    };
  }

  componentDidMount() {
    if (ENVIRONMENT == "development") {
      console.log(this.props.selectedProduct.selectedProduct);
    }
    let product = this.props.selectedProduct.selectedProduct;
    this.setState((prevProps) => {
      return {
        ...prevProps,
        product: product,
        updatedPrice:
          parseFloat(product?.discount) > 0
            ? parseFloat(product?.discount).toFixed(3)
            : parseFloat(product?.price).toFixed(3),
      };
    });
    if (ENVIRONMENT == "development") {
      console.log("this is state prouct", this.state);
    }
  }

  componentDidUpdate(prevProps) {
    if (ENVIRONMENT == "development") {
      console.log("did update called");
    }
    if (
      prevProps.selectedProduct.selectedProduct !=
      this.props.selectedProduct.selectedProduct
    ) {
      if (ENVIRONMENT == "development") {
        console.log("did update executed");
      }
      this.setState((prevState) => {
        if (ENVIRONMENT == "development") {
          console.log("updating price");
        }
        return {
          ...prevState,
          product: this.props.selectedProduct.selectedProduct,
          qty: 1,
          updatedPrice: parseFloat(
            parseFloat(
              this.props.selectedProduct.selectedProduct?.product?.discount
            ) > 0
              ? parseFloat(
                  this.props.selectedProduct.selectedProduct?.product?.discount
                ).toFixed(3)
              : parseFloat(
                  this.props.selectedProduct.selectedProduct?.product?.price
                ).toFixed(3)
          ).toFixed(3),
        };
      });
    }
  }

  closeModal = () => {
    if (ENVIRONMENT == "development") {
      console.log("closing Modal");
    }
    this.props.handleProductPopUp(false);
    this.props.setSelectedProduct(null);
  };

  updateCheckBoxes = () => {
    this.props.selectedProduct.selectedProduct.addons.map((addon, ai) => {
      let items = this.getCheckedBoxes(`checkboxof-${addon.id}`);
      if (ENVIRONMENT == "development") {
        console.log(items, "getCheckboxes items");
      }
      if (addon.minimum >= 1 && addon.maximum > 1) {
        let checkboxes = document.getElementsByName(`checkboxof-${addon.id}`);
        if (ENVIRONMENT == "development") {
          console.log("checkboxes length  ", checkboxes.length);
          console.log("items length  ", items.length);
        }
        if (items.length == addon.maximum) {
          for (var i = 0; i < checkboxes.length; i++) {
            if (items.includes(checkboxes[i].getAttribute("item"))) {
              if (ENVIRONMENT == "development") {
                console.log("not disabled", checkboxes[i].getAttribute("item"));
              }
            } else {
              checkboxes[i].disabled = true;
            }
          }
        } else {
          for (var i = 0; i < checkboxes.length; i++) {
            if (items.includes(checkboxes[i].getAttribute("item"))) {
              if (ENVIRONMENT == "development") {
                console.log("not disabled", checkboxes[i].getAttribute("item"));
              }
            } else {
              checkboxes[i].disabled = false;
            }
          }
        }

        // var checkboxesChecked = [];
        // // loop over them all
        // for (var i=0; i<checkboxes.length; i++) {
        //     // And stick the checked ones onto an array...
        //     if (checkboxes[i].checked) {
        //         checkboxesChecked.push(checkboxes[i]);
        //     }
        // }

        // if(checkboxesChecked.length == addon.maximum) {
        //     console.log("maximum addons selected");
        //     for(var c= 0; c < checkboxes.length; c++) {
        //         if(checkboxes[c] == checkboxesChecked[0]) {
        //             console.log(true, checkboxes[c]);

        //         } else {
        //             console.log(false, checkboxes[c]);
        //             // checkboxes[c].disabled = true;
        //         }
        //     }

        // }
      }
    });
  };

  getCheckedBoxes(chkboxName) {
    var checkboxes = document.querySelectorAll(
      `input[name=${chkboxName}]:checked`
    );
    let checkboxesChecked = [];
    if (ENVIRONMENT == "development") {
      console.log(checkboxes);
    }
    for (var i = 0; i < checkboxes.length; i++) {
      // And stick the checked ones onto an array...
      if (checkboxes[i].checked) {
        checkboxesChecked.push(checkboxes[i].getAttribute("item"));
      }
    }
    if (ENVIRONMENT == "development") {
      console.log(checkboxesChecked);
    }
    return checkboxesChecked;
  }

  updatePrice = () => {
    let newAddons = this.getAddons();
    if (ENVIRONMENT == "development") {
      console.log(newAddons);
    }
    let newAddonsPrice = this.calculateAddonsPrice(newAddons);
    if (ENVIRONMENT == "development") {
      console.log(newAddonsPrice);
    }
    let productPrice =
      parseFloat(
        this.props.selectedProduct.selectedProduct?.product?.discount
      ) > 0
        ? parseFloat(
            this.props.selectedProduct.selectedProduct?.product?.discount
          ).toFixed(3)
        : parseFloat(
            this.props.selectedProduct.selectedProduct?.product?.price
          ).toFixed(3);
    // newAddons.map((addon, i) => {
    //   addon.items.map((item, i) => {
    //     newAddonsPrice += parseFloat(item.price);
    //   });
    // });
    if (ENVIRONMENT == "development") {
      console.log(newAddonsPrice);
    }
    this.setState((prevState) => {
      return {
        ...prevState,
        updatedPrice: parseFloat(
          // parseInt(prevState.qty ? prevState.qty : 0) *
          parseFloat(newAddonsPrice) + parseFloat(productPrice)
        ).toFixed(3),
      };
    });

    this.updateCheckBoxes();
  };

  updateProductPrice = () => {
    this.updateCheckBoxes();
    let addonPossibilites = this.canAddProduct();
    let canUpdate = true;
    addonPossibilites.map((pos) => {
      if (pos) {
        canUpdate = true;
      } else {
        canUpdate = false;
      }
    });
    if (canUpdate) {
      let addons = this.getAddons();
      if (addons) {
        if (ENVIRONMENT == "development") {
          console.log(addons, "these are selected addons");
        }
        if (addons !== null && addons !== undefined) {
          let newAddonsPrice = 0;
          if (addons.length > 0) {
            newAddonsPrice = this.calculateAddonsPrice(addons);
          }
          if (ENVIRONMENT == "development") {
            console.log(newAddonsPrice);
          }
          let product = this.props.selectedProduct.selectedProduct;
          // let newProductPrice = parseFloat(this.props.selectedProduct.selectedProduct.price) + parseFloat(newAddonsPrice);
          let newProductPrice =
            parseFloat(product.price) > parseFloat(product.discount) &&
            parseFloat(product.discount) != 0 &&
            parseFloat(product.discount) !== undefined &&
            parseFloat(product.discount) !== null
              ? parseFloat(product.discount) + parseFloat(newAddonsPrice)
              : parseFloat(product.price) + parseFloat(newAddonsPrice);
          if (ENVIRONMENT == "development") {
            console.log(newProductPrice, "new Product Price");
          }
          this.setState((prevState) => {
            return {
              ...prevState,
              productUpdatedPrice: newProductPrice,
            };
          });
        }
      } else {
        if (ENVIRONMENT == "development") {
          console.log("no addons");
        }
      }
    } else {
      // alert("Please select all required addons to see product price");
      let addons = this.getAddons();

      let newAddonsPrice = 0;
      if (addons !== undefined || addons !== null) {
        newAddonsPrice = this.calculateAddonsPrice(addons);
      }
      this.setState((prevState) => {
        let product = this.props.selectedProduct.selectedProduct;
        return {
          ...prevState,
          productUpdatedPrice:
            parseFloat(product.price) > parseFloat(product.discount) &&
            parseFloat(product.discount) != 0 &&
            parseFloat(product.discount) !== undefined &&
            parseFloat(product.discount) !== null
              ? parseFloat(product.discount)
              : parseFloat(product.price),
        };
      });
    }
  };

  calculateAddonsPrice = (addons) => {
    let total = 0;
    if (addons !== undefined && addons !== null) {
      addons.map((addon, index) => {
        // if (
        //   addon.items.length >= addon.minimum &&
        //   addon.items.length <= addon.maximum
        // ) {
        addon.items.map((item, i) => {
          total += parseFloat(item.price);
        });
        // }
      });
      if (ENVIRONMENT == "development") {
        console.log(total, "calculated price");
      }
      return total;
    }
  };

  handleQty = (value) => {
    this.setState(
      (prevState) => {
        if (ENVIRONMENT == "development") {
          console.log(prevState.qty);
        }
        let qty = null;
        if (value !== null && value !== undefined) {
          if (prevState.qty < 0) {
            qty = 0;
          } else {
            qty = value;
          }
        } else {
          qty = 0;
        }
        if (ENVIRONMENT == "development") {
          console.log(qty);
        }
        let currentState = cloneDeep(prevState);
        return {
          ...currentState,
          qty: qty,
        };
      },
      () => {
        this.updatePrice();
      }
    );
  };

  decreaseQty = () => {
    this.setState(
      (prevState) => {
        let qty = null;
        if (prevState.qty <= 1) {
          qty = 1;
        } else {
          qty = prevState.qty - 1;
        }
        if (ENVIRONMENT == "development") {
          console.log(qty);
        }
        let currentState = cloneDeep(prevState);
        return {
          ...currentState,
          qty: qty,
        };
      },
      () => {
        this.updatePrice();
      }
    );
  };

  increaseQty = () => {
    this.setState(
      (prevState) => {
        if (ENVIRONMENT == "development") {
          console.log(prevState.qty);
        }
        let qty = null;
        if (prevState.qty < 0) {
          qty = 0;
        } else {
          qty = prevState.qty + 1;
        }
        if (ENVIRONMENT == "development") {
          console.log(qty);
        }
        let currentState = cloneDeep(prevState);
        return {
          ...currentState,
          qty: qty,
        };
      },
      () => {
        this.updatePrice();
      }
    );
  };

  getAddons = () => {
    // console.log(value);
    // let retValue = true;
    let newAddons = this.props.selectedProduct.selectedProduct.addons.map(
      (addon, ai) => {
        let items = this.getCheckedBoxes(`checkboxof-${addon.id}`);
        if (ENVIRONMENT == "development") {
          console.log(items, "getCheckboxes items");
        }
        let newAddonItems = [];
        let prevAddon = cloneDeep(addon);
        items.map((item) => {
          let addonIt = addon.items.filter((addonItem, ii) => {
            return addonItem.id == item;
          });

          newAddonItems = [...newAddonItems, ...addonIt];
        });
        prevAddon.items = newAddonItems;
        return prevAddon;
      }
    );
    if (ENVIRONMENT == "development") {
      console.log(newAddons);
    }
    return newAddons;
  };

  canAddProduct = () => {
    let addonPossibilites =
      this.props.selectedProduct.selectedProduct.addons.map((addon, ai) => {
        let items = this.getCheckedBoxes(`checkboxof-${addon.id}`);
        if (ENVIRONMENT == "development") {
          console.log(items, "getCheckboxes items");
        }
        let newAddonItems = [];
        let prevAddon = cloneDeep(addon);
        if (ENVIRONMENT == "development") {
          console.log("items------", items);
        }
        items.map((item) => {
          let addonIt = addon.items.filter((addonItem, ii) => {
            // if(item === addonItem.id) {
            // console.log(addonItem);
            // console.log(item);
            if (ENVIRONMENT == "development") {
              console.log(addonItem.id);
              console.log(item);
            }
            return addonItem.id == item;
            // }
          });
          // console.log(addonIt, "these are found addon items");

          newAddonItems = [...newAddonItems, ...addonIt];
          // let addonItm = addon.items.filter(addonItem => {
          //     return addonItem.id === item;
          // });
          // console.log(addonItm, "these is addonItm");
          // if(addonItm.length > 0) {
          //     newAddonItems.push(addonItm[0]);
          // }
        });

        prevAddon.items = newAddonItems;
        if (ENVIRONMENT == "development") {
          console.log(prevAddon.items.length, "items length");
          console.log(prevAddon.minimum);
          console.log(prevAddon.maximum);
        }
        if (prevAddon.minimum == 0) {
          // if (prevAddon.items.length <= prevAddon.maximum) {
          return true;
          // }
        } else if (prevAddon.maximum == 0) {
          // if (prevAddon.items.length <= prevAddon.maximum) {
          return true;
          // }
        } else if (
          prevAddon.items.length >= prevAddon.minimum &&
          prevAddon.items.length <= prevAddon.maximum
        ) {
          return true;
        } else {
          return false;
        }
      });
    return addonPossibilites;
  };

  addToCart = (e) => {
    if (ENVIRONMENT == "development") {
      console.log(e);
    }
    let qty = this.state.qty;
    if (ENVIRONMENT == "development") {
      console.log(qty);
    }
    let productUpdatedPrice = this.state.updatedPrice;
    if (ENVIRONMENT == "development") {
      console.log(productUpdatedPrice);
    }
    // let addons =
    let addonPossibilites = this.canAddProduct();
    let canUpdate = true;
    addonPossibilites.map((pos, posInd) => {
      if (pos) {
        // canUpdate = true;
      } else {
        if (ENVIRONMENT == "development") {
          console.log(pos, posInd);
        }
        canUpdate = false;
      }
    });
    if (ENVIRONMENT == "development") {
      console.log("can update, ", canUpdate);
    }
    if (canUpdate) {
      if (this.state.qty > 0) {
        let addons = this.getAddons();
        if (ENVIRONMENT == "development") {
          console.log(addons, "these are selected addons");
        }
        let newAddonsPrice = this.calculateAddonsPrice(addons);
        if (ENVIRONMENT == "development") {
          console.log(newAddonsPrice);
        }

        let prod = cloneDeep(this.props.selectedProduct.selectedProduct);
        let images = cloneDeep(
          this.props.selectedProduct.selectedProduct.product.images
        );
        let product = {
          ...prod.product,
          images: images,

          addons: [...addons],
        };
        if (ENVIRONMENT == "development") {
          console.log("this is product for decreasing", product);
        }
        let currentProduct = cloneDeep(product);
        let pageqty = this.state.qty;
        // let pageqty = document.getElementById(-qty").innerHTML;

        currentProduct["qty"] = parseInt(pageqty);
        currentProduct["calculated_price"] = productUpdatedPrice;
        currentProduct["calculated_total"] =
          parseInt(currentProduct.qty) *
          parseFloat(currentProduct.calculated_price);
        // currentProduct["calculated_total"] = parseInt(qty) * parseFloat(currentProduct.calculated_price);
        if (ENVIRONMENT == "development") {
          console.log("this is the current product data", currentProduct);
        }

        this.props.addItemToCart(currentProduct);

        this.setState(
          (prevState) => {
            let stateClone = cloneDeep(prevState);
            let product = stateClone.product.product;
            return {
              ...prevState,
              isLoading: false,
              qty: 1,
              addtext: true,
              updatePrice: 0,
              product: null,
              // status: "ADDED"
              // productUpdatedPrice: parseFloat(product.price) > parseFloat(product.discount) && parseFloat(product.discount) != 0 && parseFloat(product.discount) !== undefined && parseFloat(product.discount) !== null ? parseFloat(product.discount) : parseFloat(product.price)
            };
          },
          () => {
            this.props.setSelectedProduct(null);
            this.props.setShowProductPopUp(false);
          }
        );
      }
    } else {
      alert("Please select all required addons to see product price");
    }
  };

  render() {
    let addons = this.props.selectedProduct.selectedProduct?.addons?.map(
      (ag, i) => {
        let ad_items = null;
        if (ag.minimum >= 0 && ag.maximum <= 1) {
          ad_items = ag.items?.map((item, ind) => {
            return (
              <li key={ind}>
                <label className="container_radio" for={item.id}>
                  <input
                    type="radio"
                    name={`checkboxof-${ag.id}`}
                    id={item.id}
                    addon={ag.id}
                    item={item.id}
                    price={item.price}
                    onClick={(e) => this.updatePrice()}
                  />
                  <span className="checkmark"></span>
                  <span className="addonItemTitle">
                    {this.props.lang == "en" ? item.title : item.title_ar}
                  </span>
                  <span>+ KWD {item.price}</span>
                  {/* <input type="radio" name="myoptions_1" />
                  <span className="checkmark"></span> */}
                </label>
              </li>
            );
          });
        } else if (ag.minimum >= 0 && ag.maximum > 1) {
          ad_items = ag.items?.map((item, ind) => {
            return (
              <li key={ind}>
                <label className="container_check">
                  <input
                    type="checkbox"
                    name={`checkboxof-${ag.id}`}
                    id={item.id}
                    addon={ag.id}
                    item={item.id}
                    price={item.price}
                    onClick={(e) => this.updatePrice()}
                  />
                  <span className="checkmark"></span>

                  <span className="addonItemTitle">
                    {this.props.lang == "en" ? item.title : item.title_ar}
                  </span>
                  <span>+ KWD {item.price}</span>
                </label>
              </li>
            );
          });
        }
        return (
          <Fragment key={i}>
            <h5>
              {this.props.lang == "en" ? ag.title : ag.title_ar}{" "}
              {` (min-${ag.minimum} | max-${ag.maximum})`}
            </h5>
            <ul className="clearfix">{ad_items}</ul>
          </Fragment>
        );
      }
    );
    return (
      <Modal
        show={this.props.selectedProduct.showProductPopUp}
        onHide={this.closeModal}
        backdrop="static"
        keyboard={false}
        id="modal-dialog"
      >
        {this.props.selectedProduct.selectedProduct !== null &&
        this.props.selectedProduct.selectedProduct !== undefined ? (
          <>
            <Modal.Body
              style={{ padding: 0 }}
              className={this.props.lang == "ar" ? "arabic-modal-dialog" : null}
            >
              <div className="small-dialog-header">
                <h3>
                  {this.props.lang == "en"
                    ? this.props.selectedProduct.selectedProduct.product.title
                    : this.props.selectedProduct.selectedProduct.product
                        .title_ar}
                  {/* {this.state.updatedPrice * this.state.qty} */}
                </h3>
              </div>
              <div className="content">
                {/* <h5>
                  {this.props.lang == "en"
                    ? this.props.translations.en["Quantity"]
                    : this.props.translations.ar["Quantity"]}
                </h5>
                 <div className="numbers-row">
                  <input
                    type="text"
                    value={this.state.qty}
                    id="qty_1"
                    className="qty2 form-control"
                    name="quantity"
                    onChange={(e) => this.handleQty(e.currentTarget.value)}
                  />
                  <div className="inc button_inc" onClick={this.increaseQty}>
                    +
                  </div>
                  <div className="dec button_inc" onClick={this.decreaseQty}>
                    -
                  </div>
                </div> */}

                <div className="row" style={{ margin: "5px" }}>
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        this.props.lang == "en"
                          ? this.props.selectedProduct.selectedProduct.product
                              .description
                          : this.props.selectedProduct.selectedProduct.product
                              .description_ar,
                    }}
                  ></p>
                </div>
                {addons}

                <h5>
                  {this.props.lang == "en"
                    ? this.props.translations.en["Quantity"]
                    : this.props.translations.ar["Quantity"]}
                </h5>

                <div className="numbers-row">
                  <input
                    type="text"
                    value={this.state.qty}
                    id="qty_1"
                    className="qty2 form-control"
                    name="quantity"
                    onChange={(e) => this.handleQty(e.currentTarget.value)}
                  />
                  <div className="inc button_inc" onClick={this.increaseQty}>
                    +
                  </div>
                  <div className="dec button_inc" onClick={this.decreaseQty}>
                    -
                  </div>
                </div>
              </div>
              <div className="footer">
                <div className="row small-gutters">
                  {/* <div className="col-md-4">
                    <button
                      type="reset"
                      className="btn_1 outline full-width mb-mobile"
                      onClick={this.closeModal}
                    >
                      {this.props.lang == "en"
                        ? this.props.translations.en["Cancel"]
                        : this.props.translations.ar["Cancel"]}
                    </button>
                  </div> */}
                  <div className="col-md-12">
                    <button
                      type="reset"
                      className="btn_1 full-width gradientify"
                      onClick={(e) => {
                        this.addToCart(e);
                      }}
                    >
                      {this.props.lang == "en"
                        ? this.props.translations.en["Add to cart"]
                        : this.props.translations.ar["Add to cart"]}
                      {` ( KWD ${this.state.updatedPrice * this.state.qty} )`}
                    </button>
                  </div>
                </div>
              </div>
              <button
                onClick={() => this.closeModal()}
                title="Close (Esc)"
                type="button"
                className="mfp-close"
              ></button>
            </Modal.Body>
            {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary">Understood</Button>
          </Modal.Footer> */}
          </>
        ) : (
          <PopupSkeleton></PopupSkeleton>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedProduct: state.selectedProduct,
    lang: state.lang.lang,
    translations: state.translations,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setSelectedProduct: (prod_id) => dispatch(getSelectedProduct(prod_id)),
    setSelectedProduct: (product) =>
      dispatch({
        type: actionTypes.SET_SELECTED_PRODUCT,
        payload: { product: product },
      }),
    handleProductPopUp: (val) => {
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: val },
      });
    },
    setShowProductPopUp: (show_product_popup) =>
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: show_product_popup },
      }),
    addItemToCart: (product) =>
      dispatch({ type: actionTypes.ADD_CART_ITEM, payload: { item: product } }),
    updateCart: (product) =>
      dispatch({ type: actionTypes.ADD_CART_ITEM, payload: { item: product } }),
    removeItemFromCart: (i, product) =>
      dispatch({
        type: actionTypes.REMOVE_CART_ITEM,
        payload: { item: product, index: i },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductModal);
