import { mode } from "crypto-js";
import { secureStorage } from "../../locStore";
import * as actionTypes from "../actions/actions";
import { ENVIRONMENT } from "../../config";

const initialState = {
  type: "delivery",
  delivery_schedule: false,
  scheduleDate: null,
  scheduleSlot: null,
};

const deliveryModeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DELIVERY_MODE:
      let modeData = action.payload.modeData;
      if (ENVIRONMENT == "development") {
        console.log(
          state,
          "-----------state before delivery mode setting----------------"
        );
      }
      secureStorage.setItem("delivery_mode", {
        ...state,
        ...modeData,
      });
      if (ENVIRONMENT == "development") {
        console.log(
          {
            ...state,
            ...modeData,
          },
          "----------deliveryMode setting checking data------------"
        );
        console.log(
          secureStorage.getItem("delivery_mode"),
          "--------------------"
        );
      }
      return {
        ...state,
        ...modeData,
      };
    case actionTypes.UPDATE_SCHEDULE:
      let scheduleData = action.payload.scheduleData;
      secureStorage.setItem("delivery_mode", {
        ...state,
        ...scheduleData,
      });
      return {
        ...state,
        ...scheduleData,
      };
  }
  return state;
};

export default deliveryModeReducer;
