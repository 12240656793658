import React, { Component } from "react";
import { Link } from "react-router-dom";
import SideMenu from "../SideMenu/SideMenu";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actions";
import { BASE_API_URL, ENVIRONMENT } from "../../../config";
import { callService } from "../../../services";
export class UpdateProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: "",
      phone: "",
      email: "",
      showSuccessMsg: false,
    };
  }

  componentDidMount() {
    if (!this.props.user.isLoggedIn) {
      this.props.handleAuthModal(true);
      if (ENVIRONMENT == "development") {
        console.log(this.props);
      }
      this.props.history.push("/");
    } else {
      if (ENVIRONMENT == "development") {
        console.log();
      }
      callService(
        `${BASE_API_URL}/member_profile?member_id=${this.props.user.userdata.id}`
      )
        .then((resp) => {
          return resp.json();
        })
        .then((respJson) => {
          if (ENVIRONMENT == "development") {
            console.log(respJson);
          }
          this.setState((prevState) => {
            return {
              ...prevState,
              fname: respJson[0].fname,
              email: respJson[0].email,
              phone: respJson[0].phone,
            };
          });
        })
        .catch((err) => {
          if (ENVIRONMENT == "development") {
            console.log(err);
          }
        });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.user.isLoggedIn !== prevProps.user.isLoggedIn) {
      if (!this.props.user.isLoggedIn) {
        this.props.handleAuthModal(true);
        this.props.history.push("/");
      }
    }
  }

  updateProfile = () => {
    callService(
      `${BASE_API_URL}/member`,
      "POST",
      {},
      {
        member_id: this.props.user.userdata.id,
        fname: this.state.fname,
        email: this.state.email,
        phone: this.state.phone,
      }
    )
      .then((resp) => {
        return resp.json();
      })
      .then((respJson) => {
        if (respJson.status == "Success") {
          this.setState((prevState) => {
            return {
              ...prevState,
              showSuccessMsg: true,
            };
          });
        }
      })
      .catch((err) => {
        if (ENVIRONMENT == "development") {
          console.log(err);
        }
      });
  };

  render() {
    return (
      <main className="bg_gray">
        {/* <Container
          style={{ minHeight: "50px", backgroundColor: "skyblue" }}
        ></Container> */}
        <div className="container margin_60_20">
          <div className="row justify-content-center">
            <SideMenu />
            <div className="col-lg-9">
              <div className="box_general write_review">
                <h1 className="add_bottom_15">
                  {this.props.lang == "en"
                    ? this.props.translations.en["Edit Profile"]
                    : this.props.translations.ar["Edit Profile"]}
                </h1>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        {this.props.lang == "en"
                          ? this.props.translations.en["Full Name"]
                          : this.props.translations.ar["Full Name"]}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder={
                          this.props.lang == "en"
                            ? this.props.translations.en["Full Name"]
                            : this.props.translations.ar["Full Name"]
                        }
                        value={this.state.fname}
                        onChange={(e) => {
                          let val = e.currentTarget.value;
                          this.setState((prevState) => {
                            return {
                              ...prevState,
                              fname: val,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        {this.props.lang == "en"
                          ? this.props.translations.en["Email ID"]
                          : this.props.translations.ar["Email ID"]}
                      </label>
                      <input
                        className="form-control"
                        type="email"
                        placeholder={
                          this.props.lang == "en"
                            ? this.props.translations.en["Email ID"]
                            : this.props.translations.ar["Email ID"]
                        }
                        value={this.state.email}
                        onChange={(e) => {
                          let val = e.currentTarget.value;
                          this.setState((prevState) => {
                            return {
                              ...prevState,
                              email: val,
                            };
                          });
                        }}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        {this.props.lang == "en"
                          ? this.props.translations.en["Phone Number"]
                          : this.props.translations.ar["Phone Number"]}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder={
                          this.props.lang == "en"
                            ? this.props.translations.en["Phone Number"]
                            : this.props.translations.ar["Phone Number"]
                        }
                        value={this.state.phone}
                        onChange={(e) => {
                          let val = e.currentTarget.value;
                          this.setState((prevState) => {
                            return {
                              ...prevState,
                              phone: val,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <a className="btn_1" onClick={this.updateProfile}>
                  {this.props.lang == "en"
                    ? this.props.translations.en["Save"]
                    : this.props.translations.ar["Save"]}
                </a>
                {this.state.showSuccessMsg ? (
                  <p style={{ color: "green" }}>Updated successfully</p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations,
    lang: state.lang.lang,
    products: state.products.data,
    categories: state.categories.items,
    cart: state.cart,
    settings: state.settings.data,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setSelectedProduct: (prod_id) => dispatch(getSelectedProduct(prod_id)),
    setLang: (lang) =>
      dispatch({
        type: actionTypes.SET_LANGUAGE,
        payload: { lang: lang },
      }),
    setSelectedProduct: (prod_id) =>
      dispatch({
        type: actionTypes.SET_SELECTED_PRODUCT,
        payload: { product: prod_id },
      }),
    handleProductPopUp: (val) => {
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: val },
      });
    },
    handleAuthModal: (val) => {
      dispatch({
        type: actionTypes.HANDLE_AUTH_MODAL,
        payload: { showModal: val },
      });
    },
    updateUserData: (val) => {
      dispatch({
        type: actionTypes.UPDATE_USERDATA,
        payload: { userdata: val },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProfile);
