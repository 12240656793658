import { secureStorage } from "../../locStore";
import * as actionTypes from "../actions/actions";
import { ENVIRONMENT } from "../../config";

const initialState = {
  isLoggedIn: false,
  userdata: null,
};

const userReducer = (state = initialState, action) => {
  if (ENVIRONMENT == "development") {
    console.log("hit the user reducer");
  }

  switch (action.type) {
    // case actionTypes.SET_CART_ON_RELOAD:
    //     // set secureStorage to redux cart
    // case actionTypes.ADD_CART_ITEM:
    //     //add cart item and update secureStorage
    // case actionTypes.REMOVE_CART_ITEM:
    //     //remove cart item and update secureStorage
    // case actionTypes.UPDATE_CART_ITEM:
    //     // update cart item and update secureStorage
    case actionTypes.UPDATE_USERDATA: {
      secureStorage.setItem("userdata", {
        isLoggedIn: action.payload.userdata?.id ? true : false,
        userdata: action.payload.userdata,
      });
      return {
        isLoggedIn: action.payload.userdata?.id ? true : false,
        userdata: action.payload.userdata,
      };
    }

    case actionTypes.LOGOUT: {
      secureStorage.setItem("userdata", {
        isLoggedIn: false,
        userdata: null,
      });
      return {
        isLoggedIn: false,
        userdata: null,
      };
    }
  }
  return state;
};

export default userReducer;
