import React, { Component } from "react";
import ProductModal from "../ProductModal/ProductModal";
import Product2 from "../Product/Productthumb";
import Product from "../Product/Product";
import Product3 from "../Product/Productthumb2";
import * as actionTypes from "../../store/actions/actions";
import { connect } from "react-redux";
import SearchBar from "../SearchBar/SearchBar";
import { BASE_API_URL, ENVIRONMENT } from "../../config";

class ProductsContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (ENVIRONMENT == "development") {
      console.log("products container loaded");
    }
  }

  componentDidUpdate(prevProps) {
    if (ENVIRONMENT == "development") {
      console.log("products container updated");
    }
    if (this.props.search !== prevProps.search) {
      this.forceUpdate();
    }
  }

  render() {
    if (ENVIRONMENT == "development") {
      console.log(this.props.products.length);
      console.log(this.props.products);
      console.log(this.props.categories);
    }
    let prods = {};
    if (this.props.categories.length > 0) {
      this.props.categories.map((cat, i) => {
        prods[cat.id] = [];
      });
    }
    if (ENVIRONMENT == "development") {
      console.log(prods);
    }
    if (this.props.products.length > 0) {
      this.props.products.map((prod, ind) => {
        prod.categories.map((cat) => {
          prods[cat].push(prod);
        });
      });
    }
    if (ENVIRONMENT == "development") {
      console.log(prods);
    }

    let displayCats = this.props.categories.map((c, catIndex) => {
      let ps = prods[c.id].map((p, prodIndex) => {
        let cartIt = [];
        if (ENVIRONMENT == "development") {
          console.log(p.addon_cnt, "addon count");
        }
        if (parseInt(p.addon_cnt) < 1) {
          cartIt = this.props.cart.items.filter((cartItem, ci) => {
            // if (cartItem.id == p.id) {
            //   console.log(cartItem.id, "cartItem id");
            //   console.log(p.id, "products id");
            //   cartIt = cartItem;
            // }
            return cartItem.id == p.id;
          });
        }
        //alert(this.props.settings.site_theme);
        if (this.props.settings.site_theme == 1) {
          return (
            <Product
              key={prodIndex}
              p={p}
              cartIt={cartIt.length > 0 ? cartIt[0] : { qty: 0 }}
            ></Product>
          );
        } else if (this.props.settings.site_theme == 2) {
          return (
            <Product2
              key={prodIndex}
              p={p}
              cartIt={cartIt.length > 0 ? cartIt[0] : { qty: 0 }}
            ></Product2>
          );
        } else if (this.props.settings.site_theme == 3) {
          return (
            <Product3
              key={prodIndex}
              p={p}
              cartIt={cartIt.length > 0 ? cartIt[0] : { qty: 0 }}
            ></Product3>
          );
        }
      });

      return (
        <section key={catIndex} style={{ position: "relative" }}>
          <h4 className="category_title">
            {this.props.lang == "en" ? c.title : c.title_ar}
          </h4>
          <div className="row">{ps}</div>
          <section
            id={`section-${parseInt(catIndex) + 1}`}
            className="prod_title"
            style={{ position: "absolute", bottom: "60px" }}
          ></section>
        </section>
      );
    });

    let foundProducts = [];
    if (this.props.search.length >= 2) {
      foundProducts = this.props.products.filter((p, pi) => {
        if (ENVIRONMENT == "development") {
          console.log("filtering", p.title, this.props.search);
        }
        return (
          p.title.toLowerCase().includes(this.props.search.toLowerCase()) ||
          p.title_ar.toLowerCase().includes(this.props.search.toLowerCase())
        );
      });
    }

    let searchProducts = foundProducts.map((p, prodIndex) => {
      let cartIt = [];
      if (ENVIRONMENT == "development") {
        console.log(p.addon_cnt, "addon count");
      }
      if (parseInt(p.addon_cnt) < 1) {
        cartIt = this.props.cart.items.filter((cartItem, ci) => {
          // if (cartItem.id == p.id) {
          //   console.log(cartItem.id, "cartItem id");
          //   console.log(p.id, "products id");
          //   cartIt = cartItem;
          // }
          return cartItem.id == p.id;
        });
      }
      //alert(this.props.settings.site_theme);
      if (this.props.settings.site_theme == 1) {
        return (
          <Product
            key={prodIndex}
            p={p}
            cartIt={cartIt.length > 0 ? cartIt[0] : { qty: 0 }}
          ></Product>
        );
      } else if (this.props.settings.site_theme == 2) {
        return (
          <Product2
            key={prodIndex}
            p={p}
            cartIt={cartIt.length > 0 ? cartIt[0] : { qty: 0 }}
          ></Product2>
        );
      } else if (this.props.settings.site_theme == 3) {
        return (
          <Product3
            key={prodIndex}
            p={p}
            cartIt={cartIt.length > 0 ? cartIt[0] : { qty: 0 }}
          ></Product3>
        );
      }
    });

    // console.log("these are searched products", foundProducts);

    return (
      <>
        <div className="col-lg-8 list_menu">
          <SearchBar></SearchBar>
          {this.props.search.length >= 2 ? (
            <section style={{ position: "relative" }}>
              <h4 className="category_title">
                {this.props.lang == "ar" &&
                this.props.translations.ar["Search Results"] != null
                  ? this.props.translations.ar["Search Results"]
                  : "Search Results"}
              </h4>
              {searchProducts.length > 0 ? (
                <div className="row">{searchProducts}</div>
              ) : (
                <div className="text-center">
                  {this.props.lang == "ar" &&
                  this.props.translations.ar["Search Results"] != null
                    ? this.props.translations.ar["No Products Found"]
                    : "No Products Found"}
                </div>
              )}
            </section>
          ) : null}
          <section id={`section-0`} className="prod_title2">
            &nbsp;
          </section>
          {displayCats}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations,
    lang: state.lang.lang,
    products: state.products.data,
    categories: state.categories.items,
    settings: state.settings.data,
    cart: state.cart,
    search: state.searchInput.keyword,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    set_translations: (translations) =>
      dispatch({
        type: actionTypes.SET_TRANSLATIONS,
        payload: { translations: translations },
      }),
    set_shopdata: (shopdata) =>
      dispatch({
        type: actionTypes.UPDATE_SHOP,
        payload: { shopData: shopdata },
      }),
    set_categories: (categories) =>
      dispatch({
        type: actionTypes.SET_CATEGORIES,
        payload: { cats: categories },
      }),
    set_products: (products) =>
      dispatch({
        type: actionTypes.SET_PRODUCTS,
        payload: { products: products },
      }),
    set_banners: (banners) =>
      dispatch({
        type: actionTypes.SET_BANNERS,
        payload: { banners: banners },
      }),
    set_branches: (branches) =>
      dispatch({
        type: actionTypes.SET_BRANCHES,
        payload: { branches: branches },
      }),
    set_settings: (settings) =>
      dispatch({
        type: actionTypes.SET_SETTINGS,
        payload: { settings: settings },
      }),
    set_governates: (governates) =>
      dispatch({
        type: actionTypes.SET_GOVERNATES,
        payload: { governates: governates },
      }),
    set_countries: (countries) =>
      dispatch({
        type: actionTypes.SET_COUNTRIES,
        payload: { countries: countries },
      }),
    set_currencies: (currencies) =>
      dispatch({
        type: actionTypes.SET_CURRENCIES,
        payload: { currencies: currencies },
      }),
    set_selectedCategory: (category) =>
      dispatch({
        type: actionTypes.SET_SELECTED_CATEGORY,
        payload: { category: category },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsContainer);
