import React, { Component } from "react";
import { BASE_API_URL, ENVIRONMENT } from "../../../config";
import { secureStorage } from "../../../locStore";
import { cloneDeep } from "lodash";
import Select from "react-select";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actions";
import { updateDeliveryInfo } from "../../../store/actions/checkout";
import { callService } from "../../../services";

class UserAddressesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address_list: [],
      addressExist: true,
      isLoading: true,
    };
  }

  componentDidMount() {
    if (this.props.user.isLoggedIn) {
      callService(`${BASE_API_URL}/addresses/${this.props.user.userdata.id}`)
        .then((resp) => resp.json())
        .then((respJson) => {
          if (ENVIRONMENT == "development") {
            console.log(respJson);
          }
          this.setState(
            (prevState) => {
              let currentState = cloneDeep(prevState);
              return {
                ...currentState,
                address_list: respJson,
              };
            },
            () => {
              if (this.state.address_list.length > 0) {
                let checkoutData = cloneDeep(this.props.checkoutData);
                checkoutData.selected_address = this.state.address_list[0].id;
                this.props.updateCheckoutData(checkoutData);
                document.getElementById(
                  `address-select-${this.state.address_list[0].id}`
                ).checked = true;
                if (ENVIRONMENT == "development") {
                  console.log("updating delivery info");
                }
                this.props.updateDeliveryInfo(this.state.address_list[0].area);
              } else {
                let checkoutData = cloneDeep(this.props.checkoutData);
                checkoutData.selected_address = null;
                this.props.updateCheckoutData(checkoutData);
                this.setState((prevState) => {
                  return {
                    ...prevState,
                    addressExist: false,
                  };
                });
              }
            }
          );
        })
        .catch((err) => {
          if (ENVIRONMENT == "development") {
            console.log(err);
          }
        });
    }
  }

  componentDidUpdate(prevProps) {
    if (ENVIRONMENT == "development") {
      console.log("update entered");
    }
    if (
      prevProps.checkoutData.selected_address !=
        this.props.checkoutData.selected_address &&
      this.props.checkoutData.selected_address
    ) {
      if (ENVIRONMENT == "development") {
        console.log("update calling");
      }
      let addObj = this.state.address_list.filter((address, index) => {
        return address.id == this.props.checkoutData.selected_address;
      });
      if (addObj.length > 0) {
        this.props.updateDeliveryInfo(addObj[0].area);
      }
    }
  }

  render() {
    let addresses = [];

    if (this.state.address_list.length > 0) {
      addresses = this.state.address_list.map((address, i) => {
        return (
          <label className="col-md-6 add_bottom_25 " key={i}>
            <div className="checkoutAddrDiv">
              <input
                type="radio"
                name="addressSelect"
                id={`address-select-${address.id}`}
                value={address.id}
                style={{ position: "absolute" }}
                onClick={(e) =>
                  this.props.setDataVariable(
                    "selected_address",
                    e.currentTarget.value
                  )
                }
              />
              <div
                for="address-select"
                className="bg-white card addresses-item mb-4 shadow-sm"
              >
                <div className="gold-members p-4">
                  <div className="media">
                    <div className="mr-3">
                      <i className="icofont-ui-home icofont-3x"></i>
                    </div>
                    <div className="media-body">
                      {/* <h6 className="mb-1 text-secondary">{address.title}</h6> */}
                      <p
                        className="text-black"
                        dangerouslySetInnerHTML={{
                          __html: address.address_str,
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              </div>

              <span className="addressMark"></span>
            </div>
          </label>
        );
      });
    }
    if (ENVIRONMENT == "development") {
      console.log(this.state.address_list, "   this is address list");
    }
    return this.state.addressExist ? (
      // <div className="col-xl-6 col-lg-8">

      <>
        <h6 style={{ marginTop: "15px" }}>
          {this.props.lang == "en"
            ? this.props.translations.en["Pick Your Address"]
            : this.props.translations.ar["Pick Your Address"]}
        </h6>
        <div className="row">{addresses}</div>
        {this.props.checkoutData.error_state.selected_address ? (
          <p className="error">
            {this.props.lang == "en"
              ? this.props.translations.en["Address is required"]
              : this.props.translations.ar["Address is required"]}
            Address is required
          </p>
        ) : null}
      </>
    ) : (
      // </div>
      <p>
        {" "}
        {this.props.lang == "en"
          ? this.props.translations.en[
              "No saved addresses, Please add one address to proceed."
            ]
          : this.props.translations.ar[
              "No saved addresses, Please add one address to proceed."
            ]}
      </p>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // shop: state.shop,
    lang: state.lang.lang,
    settings: state.settings.data,
    shop: state.shop,
    cart: state.cart,
    lang: state.lang.lang,
    translations: state.translations,
    showSignInModal: state.handleSignInModal.show,
    selectedProduct: state.selectedProduct,
    countries: state.countries.data,
    branches: state.branches.items,
    governates: state.governates.data,
    delivery_location: state.deliveryLocation.data.area,
    pickup_location: state.deliveryLocation.data.branch,
    delivery_location_governate: state.deliveryLocation.data.governate,
    delivery_mode: state.deliveryMode,
    currency: state.currency,
    multiplier: state.multiplier,
    user: state.user,
    checkoutData: state.checkoutData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // updateShop: () => dispatch(updateShopDetails()),
    // getCategories: () => dispatch(getCategories()),
    updateDeliveryInfo: (id) => dispatch(updateDeliveryInfo(id)),
    setLang: (lang) =>
      dispatch({ type: actionTypes.SET_LANGUAGE, payload: { lang: lang } }),
    setShowProductPopUp: (show_product_popup) =>
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: show_product_popup },
      }),
    resetCart: () => dispatch({ type: actionTypes.RESET_CART, payload: null }),
    set_deliveryLocation: (location) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_LOCATION,
        payload: { location: location },
      }),
    set_deliveryMode: (modeData) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_MODE,
        payload: { modeData },
      }),
    set_tempDeliveryMode: (modeData) =>
      dispatch({
        type: actionTypes.SET_TEMP_DELIVERY_MODE,
        payload: { modeData },
      }),
    addItemToCart: (i, product) =>
      dispatch({
        type: actionTypes.ADD_CART_ITEM,
        payload: { item: product, index: i },
      }),
    updateCart: (product) =>
      dispatch({ type: actionTypes.ADD_CART_ITEM, payload: { item: product } }),
    removeItemFromCart: (i, product) =>
      dispatch({
        type: actionTypes.REMOVE_CART_ITEM,
        payload: { item: product, index: i },
      }),
    reloadCart: (cart) =>
      dispatch({
        type: actionTypes.SET_CART_ON_RELOAD,
        payload: { cart: cart },
      }),
    popItem: (i) =>
      dispatch({
        type: actionTypes.POPITEM,
        payload: { popIndex: i },
      }),
    handleAuthModal: (val) => {
      dispatch({
        type: actionTypes.HANDLE_AUTH_MODAL,
        payload: { showModal: val },
      });
    },
    updateCheckoutData: (checkoutData) => {
      dispatch({
        type: actionTypes.UPDATE_CHECKOUTDATA,
        payload: { checkoutData: checkoutData },
      });
    },
    // setCurrency: (currency) => dispatch({type: actionTypes.SET_CURRENCY, payload: { base_currency: currency}}),
    // setMultiplier: (multiplier) => dispatch({type: actionTypes.SET_MULTIPLIER, payload: { multiplier: multiplier}})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAddressesForm);
