import React, { Component, Fragment } from "react";
import { BASE_API_URL, ENVIRONMENT } from "../../../config";
import { secureStorage } from "../../../locStore";
import { cloneDeep } from "lodash";
import Select from "react-select";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actions";
import { callService } from "../../../services";
class BranchSelectionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branchExist: true,
      isLoading: true,
    };
  }

  componentDidMount() {
    callService(`${BASE_API_URL}/branches`)
      .then((resp) => resp.json())
      .then((respJson) => {
        if (ENVIRONMENT == "development") {
          console.log(respJson);
        }
        this.props.set_branches(respJson);
        if (respJson.length > 0) {
          this.props.set_pickupLocation(respJson[0]);
        }
      })
      .catch((err) => {
        if (ENVIRONMENT == "development") {
          console.log(err);
        }
      });
  }
  componentDidUpdate() {
    // callService(`${BASE_API_URL}/branches`)
    //   .then((resp) => resp.json())
    //   .then((respJson) => {
    //     console.log(respJson);
    //     this.props.set_branches(respJson);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }

  selectedBranch = (branchId) => {
    let branch = null;
    let isBranchExist = this.props.branches.filter((branch) => {
      return branch.id == branchId;
    });
    if (isBranchExist.length > 0) {
      branch = isBranchExist[0];
    }
    if (branch) {
      secureStorage.setItem("pickup_location", branch);
      this.props.set_pickupLocation(branch);
      this.props.set_deliveryLocation({ governate: null, area: null });
      this.props.set_deliveryMode({
        type: "pickup",
        // scheduleDate:
      });
      let currentData = cloneDeep(this.props.checkoutData);
      currentData.error_state.branch = false;
      this.props.updateCheckoutData(currentData);
      // this.setState((prevState) => {
      //   return {
      //     ...prevState,
      //     branch: branch,
      //     branchId: {
      //       label: this.props.lang == "en" ? branch.title : branch.title_ar,
      //       value: branch.id,
      //     },
      //     delivery_charges: branch.area.price,
      //     area: null,
      //     areaId: null,
      //     currentDeliveryMethod: "pickup",
      //   };
      // });
    }
  };
  render() {
    let branchOptions = this.props.branches.map((branch, index) => {
      let item = {
        label: this.props.lang == "en" ? branch.title : branch.title_ar,
        value: branch.id,
      };

      return item;
    });

    let selectedBranch = this.props.pickup_location
      ? {
          label:
            this.props.lang == "en"
              ? this.props.pickup_location.title
              : this.props.pickup_location.title_ar,
          value: this.props.pickup_location.id,
        }
      : null;
    if (ENVIRONMENT == "development") {
      console.log(selectedBranch);
    }
    return (
      <Fragment>
        <label>
          {this.props.lang == "en"
            ? this.props.translations.en["Branch"]
            : this.props.translations.ar["Branch"]}{" "}
          <span className="required">*</span>
        </label>

        <Select
          onChange={(e) => this.selectedBranch(e.value)}
          placeholder={
            this.props.lang === "en"
              ? this.props.translations.en["Select Branch"]
              : this.props.translations.ar["Select Branch"]
          }
          className="custom-select js-example-basic-single"
          id="inputGroupSelect04"
          options={branchOptions}
          value={selectedBranch}
        ></Select>

        {this.props.checkoutData.error_state.branch ? (
          <p className="error">
            {this.props.lang == "en"
              ? this.props.translations.en["Branch is required"]
              : this.props.translations.ar["Branch is requied"]}
            Branch is required
          </p>
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // shop: state.shop,
    lang: state.lang.lang,
    settings: state.settings.data,
    shop: state.shop,
    cart: state.cart,
    lang: state.lang.lang,
    translations: state.translations,
    showSignInModal: state.handleSignInModal.show,
    selectedProduct: state.selectedProduct,
    countries: state.countries.data,
    branches: state.branches.items,
    governates: state.governates.data,
    delivery_location: state.deliveryLocation.data.area,
    pickup_location: state.pickupLocation.data.branch,
    delivery_location_governate: state.deliveryLocation.data.governate,
    delivery_mode: state.deliveryMode,
    currency: state.currency,
    multiplier: state.multiplier,
    user: state.user,
    checkoutData: state.checkoutData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // updateShop: () => dispatch(updateShopDetails()),
    // getCategories: () => dispatch(getCategories()),
    setLang: (lang) =>
      dispatch({ type: actionTypes.SET_LANGUAGE, payload: { lang: lang } }),
    setShowProductPopUp: (show_product_popup) =>
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: show_product_popup },
      }),
    resetCart: () => dispatch({ type: actionTypes.RESET_CART, payload: null }),
    set_deliveryLocation: (location) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_LOCATION,
        payload: { location: location },
      }),
    set_deliveryMode: (modeData) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_MODE,
        payload: { modeData },
      }),
    set_pickupLocation: (branch) =>
      dispatch({
        type: actionTypes.SET_PICKUP_LOCATION,
        payload: { branch: branch },
      }),
    set_branches: (branches) =>
      dispatch({
        type: actionTypes.SET_BRANCHES,
        payload: { branches: branches },
      }),
    set_tempDeliveryMode: (modeData) =>
      dispatch({
        type: actionTypes.SET_TEMP_DELIVERY_MODE,
        payload: { modeData },
      }),
    addItemToCart: (i, product) =>
      dispatch({
        type: actionTypes.ADD_CART_ITEM,
        payload: { item: product, index: i },
      }),
    updateCart: (product) =>
      dispatch({ type: actionTypes.ADD_CART_ITEM, payload: { item: product } }),
    removeItemFromCart: (i, product) =>
      dispatch({
        type: actionTypes.REMOVE_CART_ITEM,
        payload: { item: product, index: i },
      }),
    reloadCart: (cart) =>
      dispatch({
        type: actionTypes.SET_CART_ON_RELOAD,
        payload: { cart: cart },
      }),
    popItem: (i) =>
      dispatch({
        type: actionTypes.POPITEM,
        payload: { popIndex: i },
      }),
    handleAuthModal: (val) => {
      dispatch({
        type: actionTypes.HANDLE_AUTH_MODAL,
        payload: { showModal: val },
      });
    },
    updateCheckoutData: (checkoutData) => {
      dispatch({
        type: actionTypes.UPDATE_CHECKOUTDATA,
        payload: { checkoutData: checkoutData },
      });
    },
    // setCurrency: (currency) => dispatch({type: actionTypes.SET_CURRENCY, payload: { base_currency: currency}}),
    // setMultiplier: (multiplier) => dispatch({type: actionTypes.SET_MULTIPLIER, payload: { multiplier: multiplier}})
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BranchSelectionForm);
