import * as actionTypes from "../actions/actions";

const initialState = {
  keyword: "",
};

const searchInputReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SEARCH_KEYWORD:
      // let banners = action.payload.banners;
      return {
        keyword: action.payload.keyword,
      };
    // return Promise.resolve({
    //     type:'UPDATE_STATE',
    //     key, value
    // });
  }
  return state;
};

export default searchInputReducer;
