import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import * as actionTypes from "../../store/actions/actions";
import { connect } from "react-redux";
import { BASE_API_URL, ENVIRONMENT } from "../../config";

import { secureStorage } from "../../locStore";
import cloneDeep from "lodash.clonedeep";

class InstructionsPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msg: "",
    };
  }

  componentDidMount() {
    if (ENVIRONMENT == "development") {
      console.log(this.props, "did mount called");
    }
    let filteredProductList = this.props.cart.items.filter((item, i) => {
      return this.props.selectedProduct == i;
    });
    let localProduct = null;
    if (filteredProductList.length > 0) {
      localProduct = filteredProductList[0];
      this.setState((prevState) => {
        return {
          ...prevState,
          msg: localProduct.instructions,
        };
      });
    }
    if (ENVIRONMENT == "development") {
      console.log(localProduct);
    }
  }

  componentDidUpdate() {}

  updateVar = (msg) => {
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          msg: msg,
        };
      },
      () => {
        // let currentCart = cloneDeep(this.props.cart);
        // currentCart.items.map((item, i) => {
        //   if (this.props.selectedProduct == i) {
        //     currentCart.items[i]["instructions"] = this.state.msg;
        //   } else {
        //     currentCart.items[i]["instructions"] = null;
        //   }
        // });
        // secureStorage.setItem("cart", {
        //   calculated_total: currentCart.calculated_total,
        //   items: currentCart.items,
        // });
        // this.props.setCartOnReload(currentCart);
      }
    );
  };

  updateInstructions = () => {
    let currentCart = cloneDeep(this.props.cart);

    currentCart.items.map((item, i) => {
      if (this.props.selectedProduct == i) {
        currentCart.items[i]["instructions"] = this.state.msg;
      }
    });

    secureStorage.setItem("cart", {
      calculated_total: currentCart.calculated_total,
      items: currentCart.items,
    });

    this.props.setCartOnReload(currentCart);
    this.props.closeModal();
  };

  render() {
    return (
      <Modal
        show={this.props.showIns}
        // onHide={this.closeModal}
        backdrop="static"
        keyboard={false}
        id="modal-dialog"
      >
        <>
          <Modal.Body
            style={{ padding: 0 }}
            //className={this.props.lang == "ar" ? "arabic-modal-dialog" : null}
          >
            <div className="small-dialog-header">
              <h3>
               {this.props.lang == "ar" &&
                this.props.translations.ar["Add Instructions"] != null
                  ? this.props.translations.ar["Add Instructions"]
                  : "Add Instructions"}
              </h3>
            </div>
            <div className="content">
              <div className="form-group">
                <textarea
                  className="form-control"
                  rows="10"
                  placeholder={
                    this.props.lang == "ar" &&
                    this.props.translations.ar["Your Message"]
                      ? this.props.translations.ar["Your Message"]
                      : "Your Message"
                  }
                  //   placeholder={
                  //     this.props.lang == "en"
                  //       ? this.props.translations.en["Your Message"]
                  //       : this.props.translations.ar["Your Message"]
                  //   }
                  value={this.state.msg}
                  onChange={(e) => this.updateVar(e.currentTarget.value)}
                ></textarea>
              </div>

              {/* <button
                className="btn"
                onClick={() => console.log("button clicked")}
              ></button> */}
            </div>

            <button
              onClick={() => this.updateInstructions()}
              title="Close (Esc)"
              type="button"
              className="mfp-close"
            ></button>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary">Understood</Button>
          </Modal.Footer> */}
        </>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang.lang,
    translations: state.translations,
    cart: state.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleProductPopUp: (val) => {
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: val },
      });
    },
    addItemToCart: (i, product) =>
      dispatch({
        type: actionTypes.ADD_CART_ITEM,
        payload: { item: product, index: i },
      }),
    updateCart: (product) =>
      dispatch({ type: actionTypes.ADD_CART_ITEM, payload: { item: product } }),
    removeItemFromCart: (i, product) =>
      dispatch({
        type: actionTypes.REMOVE_CART_ITEM,
        payload: { item: product, index: i },
      }),
    setCartOnReload: (cart) =>
      dispatch({
        type: actionTypes.SET_CART_ON_RELOAD,
        payload: { cart: cart },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InstructionsPopup);
