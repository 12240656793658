import * as actionTypes from '../actions/actions';
import {secureStorage} from '../../locStore';


// if(secureStorage.getItem('lang') !== undefined) {
//     store.dispatch({type: actionTypes.SET_LANGUAGE, payload: {lang: secureStorage.getItem('lang')}});
// } else {
//     secureStorage.setItem('lang', "en");
//     store.dispatch({type: actionTypes.SET_LANGUAGE, payload: {lang: "en"}});

// }

const initialState = {
    lang: "en"
}

const languageReducer = (state = initialState, action) => {   
    switch(action.type) {
        case actionTypes.SET_LANGUAGE:
            secureStorage.setItem("lang", action.payload.lang);
            let mainBody = document.getElementById('root');
            if(action.payload.lang == "ar") {
                // this.props.setLang("ar")
                mainBody.classList.remove("ltr");
                mainBody.classList.add("rtl");
            } else if(action.payload.lang == "en") {
                // this.props.setLang("en")
                // mainBody.classList.remove("right-to-left");
                mainBody.classList.add("ltr");
                mainBody.classList.remove("rtl");
            }
            return {
                lang: action.payload.lang
            }
    }
    return state

}


export default languageReducer;