import React, { Component } from "react";
import { BASE_API_URL, ENVIRONMENT } from "../../../config";
import { secureStorage } from "../../../locStore";
import { cloneDeep } from "lodash";
import Select from "react-select";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actions";
import { callService } from "../../../services";
class PaymentMethods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      paymentMethods: [],
    };
  }
  componentDidMount() {
    callService(`${BASE_API_URL}/paymethods`)
      .then((payres) => {
        return payres.json();
      })
      .then((payResJson) => {
        if (ENVIRONMENT == "development") {
          console.log(payResJson);
        }
        // let cart = {
        //   calculated_total: calculated_total,
        //   items: respJson.products,
        //   showCartMsg: proceedOrder ? false : true,
        // };
        // secureStorage.setItem("cart", {
        //   calculated_total: calculated_total,
        //   items: respJson.products,
        // });
        // this.props.reloadCart(cart);
        this.setState(
          (prevState) => {
            let currentState = cloneDeep(prevState);

            return {
              ...currentState,
              is_loading: false,
              // showCartMsg: proceedOrder ? false : true,
              paymentMethods: payResJson.methods,
            };
          },
          () => {
            if (this.props.checkoutData.payment_method) {
              // also check it is one of methods if possible

              if(document.getElementById(
                this.props.checkoutData.payment_method
              )){
              document.getElementById(
                this.props.checkoutData.payment_method
              ).checked = true;
              }
            } else {
              if (this.state.paymentMethods.length > 0) {
                document.getElementById(
                  this.state.paymentMethods[0].slug
                ).checked = true;
                let currentData = cloneDeep(this.props.checkoutData);
                currentData.payment_method = this.state.paymentMethods[0].slug;
                this.props.updateCheckoutData(currentData);
              }
            }
          }
        );
      })
      .catch((err) => {
        if (ENVIRONMENT == "development") {
          console.log(err);
        }
      });
  }
  render() {
    let paymethods = this.state.paymentMethods.map((method, i) => {
      return (
        <div
          className="payment_select"
          style={{ marginTop: "15px", marginBottom: "25px" }}
          key={i}
        >
          <label className="container_radio paymentLabel">
            {/* {this.props.lang == "en"
                ? this.props.translations.en["CASH ON DELIVERY"]
                : this.props.translations.ar["CASH ON DELIVERY"]} */}
            {this.props.lang == "en" ? method.title : method.title_ar}
            <input
              type="radio"
              value={method.slug}
              name="payment_method"
              id={method.slug}
              onClick={() => {
                this.props.setDataVariable("payment_method", method.slug);
              }}
            />
            <span className="checkmark"></span>
            {/* <i className="icon_creditcard" style={{ marginLeft: "5px" }}></i> */}
            <img src={method.image} className="payMethod payImage"></img>
          </label>
        </div>
      );
    });

    return (
      <div className="box_order_form" style={{ marginBottom: "30px" }}>
        {/* <div className="head"> */}
        <div className="title">
          <h6>
            {this.props.lang == "en"
              ? this.props.translations.en["Payment Method"]
              : this.props.translations.ar["Payment Method"]}{" "}
            <span className="required">*</span>
          </h6>
          {this.props.checkoutData.error_state.payment_method ? (
            <p className="error">
              {this.props.lang == "ar" &&
              this.props.translations.ar["Payment Method is required"] != null
                ? this.props.translations.ar["Payment Method is required"]
                : "Payment Method is required"}
            </p>
          ) : null}
        </div>
        {/* </div> */}

        <div className="main">{paymethods}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // shop: state.shop,
    lang: state.lang.lang,
    settings: state.settings.data,
    shop: state.shop,
    cart: state.cart,
    lang: state.lang.lang,
    translations: state.translations,
    showSignInModal: state.handleSignInModal.show,
    selectedProduct: state.selectedProduct,
    countries: state.countries.data,
    branches: state.branches.items,
    governates: state.governates.data,
    delivery_location: state.deliveryLocation.data.area,
    pickup_location: state.deliveryLocation.data.branch,
    delivery_location_governate: state.deliveryLocation.data.governate,
    delivery_mode: state.deliveryMode,
    currency: state.currency,
    multiplier: state.multiplier,
    user: state.user,
    checkoutData: state.checkoutData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // updateShop: () => dispatch(updateShopDetails()),
    // getCategories: () => dispatch(getCategories()),
    setLang: (lang) =>
      dispatch({ type: actionTypes.SET_LANGUAGE, payload: { lang: lang } }),
    setShowProductPopUp: (show_product_popup) =>
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: show_product_popup },
      }),
    resetCart: () => dispatch({ type: actionTypes.RESET_CART, payload: null }),
    set_deliveryLocation: (location) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_LOCATION,
        payload: { location: location },
      }),
    set_deliveryMode: (modeData) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_MODE,
        payload: { modeData },
      }),
    set_tempDeliveryMode: (modeData) =>
      dispatch({
        type: actionTypes.SET_TEMP_DELIVERY_MODE,
        payload: { modeData },
      }),
    addItemToCart: (i, product) =>
      dispatch({
        type: actionTypes.ADD_CART_ITEM,
        payload: { item: product, index: i },
      }),
    updateCart: (product) =>
      dispatch({ type: actionTypes.ADD_CART_ITEM, payload: { item: product } }),
    removeItemFromCart: (i, product) =>
      dispatch({
        type: actionTypes.REMOVE_CART_ITEM,
        payload: { item: product, index: i },
      }),
    reloadCart: (cart) =>
      dispatch({
        type: actionTypes.SET_CART_ON_RELOAD,
        payload: { cart: cart },
      }),
    popItem: (i) =>
      dispatch({
        type: actionTypes.POPITEM,
        payload: { popIndex: i },
      }),
    handleAuthModal: (val) => {
      dispatch({
        type: actionTypes.HANDLE_AUTH_MODAL,
        payload: { showModal: val },
      });
    },
    updateCheckoutData: (checkoutData) => {
      dispatch({
        type: actionTypes.UPDATE_CHECKOUTDATA,
        payload: { checkoutData: checkoutData },
      });
    },
    // setCurrency: (currency) => dispatch({type: actionTypes.SET_CURRENCY, payload: { base_currency: currency}}),
    // setMultiplier: (multiplier) => dispatch({type: actionTypes.SET_MULTIPLIER, payload: { multiplier: multiplier}})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethods);
