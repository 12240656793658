import React, { Component, Fragment } from "react";
import { Modal } from "react-bootstrap";
import * as actionTypes from "../../store/actions/actions";
import { connect } from "react-redux";
import "./AuthModal.css";
import { BASE_API_URL, ENVIRONMENT } from "../../config";
import { useLocation } from "react-router-dom";
import { callService } from "../../services";

// const RouteWrapper = (props) => {
//   return {};
// };

export class AuthModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
      fname: null,
      email: null,
      password: null,
      phone: null,
      modalType:
        "signin" /** possile values --------- signin, signup, forgot_password */,
      errors: {
        fname: false,
        email: false,
        password: false,
        phone: false,
      },
      error_msg: {
        signin: null,
        signup: null,
        forgot: null,
        forgot_err: null,
      },
    };
    // console.log(this.handleSubmit());
    if (ENVIRONMENT == "development") {
      console.log("this are auth props--------------------------", this.props);
    }
  }

  checkField = (k) => {
    let obj = {};
    if (
      this.state[k] == "" ||
      this.state[k] == null ||
      this.state[k] == undefined
    ) {
      obj["k"] = true;
    } else {
      obj["k"] = false;
    }

    this.setState((prevState) => {
      return {
        ...prevState,
        errors: {
          ...prevState.errors,
          ...obj,
        },
        error_msg: {
          ...prevState.error_msg,
        },
      };
    });
  };

  handleState = (key, val) => {
    if (ENVIRONMENT == "development") {
      console.log("handling State");
    }
    let obj = {};
    obj[key] = val;
    let errors = {};
    let checkwords = ["email", "phone", "fname", "password"];
    if (ENVIRONMENT == "development") {
      console.log(checkwords.includes(key), "------", key);
    }
    if (checkwords.includes(key)) {
      if (ENVIRONMENT == "development") {
        console.log(key, " exists");
      }
      if (val == "" || val == null || val == undefined) {
        if (ENVIRONMENT == "development") {
          console.log("setting true");
        }
        errors[key] = true;
      } else {
        if (ENVIRONMENT == "development") {
          console.log("setting false");
        }

        errors[key] = false;
      }
    }
    if (ENVIRONMENT == "development") {
      console.log(errors);
    }
    this.setState((prevState) => {
      return {
        ...prevState,
        ...obj,
        errors: {
          ...prevState.errors,
          ...errors,
        },
        error_msg: {
          ...prevState.error_msg,
          signin: null,
          signup: null,
          forgot: null,
          forgot_err: null,
        },
      };
    });
  };

  handleModal = () => {
    if (ENVIRONMENT == "development") {
      console.log("closing Modal");
    }
    this.setState((prevState) => {
      return {
        ...prevState,
        showModal: false,
        fname: null,
        email: null,
        password: null,
        phone: null,
        modalType: "signin",
        errors: {
          fname: false,
          email: false,
          password: false,
          phone: false,
        },
        error_msg: {
          ...prevState.error_msg,
        },
      };
    });
    this.props.handleAuthModal(false);
  };

  handleSubmit = (e) => {
    if (this.state.modalType == "signup") {
      let canHit = true;
      for (let i in this.state.errors) {
        if (ENVIRONMENT == "development") {
          console.log(this.state.errors[i]);
        }
        if (this.state.errors[i]) {
          canHit = false;
        }
      }
      if (canHit) {
        if (ENVIRONMENT == "development") {
          console.log(
            JSON.stringify({
              fname: this.state.fname,
              email: this.state.email,
              phone: this.state.phone,
              password: this.state.password,
            })
          );
        }
        callService(
          `${BASE_API_URL}/register`,
          "POST",
          {
            method: "POST",
            body: JSON.stringify({
              fname: this.state.fname,
              email: this.state.email,
              phone: this.state.phone,
              password: this.state.password,
            }),
          },
          {
            fname: this.state.fname,
            email: this.state.email,
            phone: this.state.phone,
            password: this.state.password,
          }
        )
          .then((resp) => {
            return resp.json();
          })
          .then((respJson) => {
            if (ENVIRONMENT == "development") {
              console.log(respJson);
            }
            if (respJson.status == "Success") {
              // this.setState((prevState) => {
              //   return {
              //     ...prevState,
              //     showModal: false,
              //     fname: null,
              //     // email: null,
              //     // password: null,
              //     phone: null,
              //     modalType: "signin",
              //     errors: {
              //       ...prevState.errors,
              //       fname: false,
              //       email: false,
              //       password: false,
              //       phone: false,
              //     },
              //     error_msg: {
              //       ...prevState.error_msg,
              //     },
              //   };
              // });
              this.props.updateUserData(respJson.member);
              this.props.handleAuthModal(false);
            } else {
              this.setState((prevState) => {
                return {
                  ...prevState,

                  errors: {
                    ...prevState.errors,
                  },
                  error_msg: {
                    ...prevState.error_msg,
                    signin: respJson.message,
                  },
                };
              });
            }
          })
          .catch((err) => {
            if (ENVIRONMENT == "development") {
              console.log(err);
            }
          });
      }
    } else if (this.state.modalType == "signin") {
      let canHit = true;
      if (this.state.errors.email || this.state.errors.password) {
        canHit = false;
      }
      if (canHit) {
        if (ENVIRONMENT == "development") {
          console.log(
            JSON.stringify({
              email: this.state.email,
              password: this.state.password,
            })
          );
        }
        callService(
          `${BASE_API_URL}/login`,
          "POST",
          {},
          {
            email: this.state.email,
            password: this.state.password,
          }
        )
          .then((resp) => {
            return resp.json();
          })
          .then((respJson) => {
            if (ENVIRONMENT == "development") {
              console.log(respJson);
            }
            if (respJson.status == "Success") {
              this.props.updateUserData(respJson.member);
              this.props.handleAuthModal(false);
            } else {
              this.setState((prevState) => {
                return {
                  ...prevState,

                  errors: {
                    ...prevState.errors,
                  },
                  error_msg: {
                    ...prevState.error_msg,
                    signin: respJson.message,
                  },
                };
              });
            }
          })
          .catch((err) => {
            if (ENVIRONMENT == "development") {
              console.log(err);
            }
          });
      }
    }
  };

  resetPassword = () => {
    if (ENVIRONMENT == "development") {
      console.log("resetting password");
    }
    callService(`${BASE_API_URL}/recovery`, {
      method: "POST",
      body: JSON.stringify({
        email: this.state.email,
      }),
    })
      .then((resp) => {
        return resp.json();
      })
      .then((respJson) => {
        if (ENVIRONMENT == "development") {
          console.log(respJson);
        }
        if (respJson.status == "Success") {
          this.setState((prevState) => {
            return {
              ...prevState,
              errors: {
                ...prevState.errors,
                fname: false,
                email: false,
                password: false,
                phone: false,
              },
              error_msg: {
                ...prevState.error_msg,
                forgot: respJson.message,
              },
            };
          });
          // this.props.updateUserData(respJson.member);
          // this.props.handleAuthModal(false);
        } else {
          this.setState((prevState) => {
            return {
              ...prevState,

              errors: {
                ...prevState.errors,
              },
              error_msg: {
                ...prevState.error_msg,
                signin: null,
                signup: null,
                forgot: null,
                forgot_err: respJson.message,
              },
            };
          });
        }
      })
      .catch((err) => {
        if (ENVIRONMENT == "development") {
          console.log(err);
        }
      });
  };

  render() {
    return (
      <Modal
        show={this.props.auth.showModal}
        onHide={() => {}}
        backdrop="static"
        keyboard={false}
        id="modal-dialog"
        className="authModal"
      >
        <div className="small-dialog-header">
          <h3>
            {this.state.modalType == "signin"
              ? this.props.lang == "en"
                ? this.props.translations.en["Sign In"]
                : this.props.translations.ar["Sign In"]
              : this.state.modalType !== "forgot_password"
              ? this.props.lang == "en"
                ? this.props.translations.en["Sign Up"]
                : this.props.translations.ar["Sign Up"]
              : this.props.lang == "en"
              ? this.props.translations.en["Reset Password"]
              : this.props.translations.ar["Reset Password"]}
          </h3>
        </div>
        <Modal.Body>
          <form autoComplete="off">
            <div
              className={
                this.state.modalType !== "forgot_password"
                  ? "sing-in-wrapper"
                  : null
              }
            >
              {/* <a href="#0" className="social_bt facebook">
                Login with Facebook
              </a>
              <a href="#0" className="social_bt google">
                Login with Google
              </a>
              <div className="divider">
                <span>Or</span>
              </div> */}
              {this.state.modalType !== "forgot_password" ? (
                <>
                  {this.state.modalType == "signup" ? (
                    <div className="form-group">
                      <label>
                        {this.props.lang == "en"
                          ? this.props.translations.en["Name"]
                          : this.props.translations.ar["Name"]}
                      </label>
                      <input
                        type="text"
                        className="form-control customInput"
                        name="name"
                        id="name"
                        onChange={(e) =>
                          this.handleState("fname", e.currentTarget.value)
                        }
                        value={this.state.fname}
                        autoComplete={false}
                      />
                      {/* <i className="icon_phone_alt"></i> */}
                      <i className="fas fa-user inputIcon"></i>
                    </div>
                  ) : null}
                  {this.state.errors.fname ? (
                    <p style={{ color: "red" }}>Name is required</p>
                  ) : null}
                  {this.state.modalType == "signup" ? (
                    <div className="form-group">
                      <label>
                        {this.props.lang == "en"
                          ? this.props.translations.en["Phone"]
                          : this.props.translations.ar["Phone"]}
                      </label>
                      <input
                        type="number"
                        className="form-control customInput"
                        name="phone"
                        id="phone"
                        maxLength="8"
                        minLength="8"
                        onChange={(e) =>
                          this.handleState("phone", e.currentTarget.value)
                        }
                        value={this.state.phone}
                        autoComplete={false}
                      />
                      {/* <i className="icon_phone_alt"></i> */}
                      <i className="fas fa-mobile inputIcon"></i>
                    </div>
                  ) : null}
                  {this.state.errors.phone ? (
                    <p style={{ color: "red" }}>Phone is required</p>
                  ) : null}
                  <div className="form-group">
                    <label>
                      {this.props.lang == "en"
                        ? this.props.translations.en["Email"]
                        : this.props.translations.ar["Email"]}
                    </label>
                    <input
                      type="email"
                      className="form-control customInput"
                      name="email"
                      id="email"
                      value={this.state.email}
                      onChange={(e) =>
                        this.handleState("email", e.currentTarget.value)
                      }
                      value={this.state.email}
                      autoComplete={false}
                    />
                    <i className="icon_mail_alt inputIcon"></i>
                  </div>
                  {this.state.errors.email ? (
                    <p style={{ color: "red" }}>Email is required</p>
                  ) : null}
                  <div className="form-group">
                    <label>
                      {this.props.lang == "en"
                        ? this.props.translations.en["Password"]
                        : this.props.translations.ar["Password"]}
                    </label>
                    <input
                      type="password"
                      className="form-control customInput"
                      name="password"
                      id="password"
                      value={this.state.password}
                      onChange={(e) => {
                        this.handleState("password", e.currentTarget.value);
                      }}
                      value={this.state.password}
                      autoComplete={false}
                    />
                    <i className="icon_lock_alt inputIcon"></i>
                  </div>
                  {this.state.errors.password ? (
                    <p style={{ color: "red" }}>Password is required</p>
                  ) : null}
                  <div className="clearfix add_bottom_15">
                    {/* <div className="checkboxes float-left remember">
                      <label className="container_check rememberMeLabel">
                        {this.props.lang == "en"
                          ? this.props.translations.en["Remember me"]
                          : this.props.translations.ar["Remember me"]}
                        <input type="checkbox" />
                        <span className="checkmark rememberCheckMark"></span>
                      </label>
                    </div> */}
                    <div className="float-right">
                      <a
                        id="forgot"
                        onClick={() =>
                          this.handleState("modalType", "forgot_password")
                        }
                      >
                        {this.props.lang == "en"
                          ? this.props.translations.en["Forgot Password?"]
                          : this.props.translations.ar["Forgot Password?"]}
                      </a>
                    </div>
                  </div>
                  {this.state.error_msg.signin ? (
                    <div style={{ color: "red" }}>
                      {" "}
                      {this.state.error_msg.signin}
                    </div>
                  ) : null}
                  {this.state.error_msg.signup ? (
                    <div style={{ color: "red" }}>
                      {" "}
                      {this.state.error_msg.signup}
                    </div>
                  ) : null}
                  {this.state.error_msg.forgot ? (
                    <div style={{ color: "red" }}>
                      {" "}
                      {this.state.error_msg.forgot}
                    </div>
                  ) : null}
                  <div className="text-center">
                    <input
                      type="submit"
                      value={
                        this.state.modalType == "signin"
                          ? this.props.lang == "en"
                            ? this.props.translations.en["Log In"]
                            : this.props.translations.ar["Log In"]
                          : this.props.lang == "en"
                          ? this.props.translations.en["Sign Up"]
                          : this.props.translations.ar["Sign Up"]
                      }
                      className="btn_1 full-width mb_5"
                      onClick={(e) => {
                        e.preventDefault();
                        let proceed = true;
                        if (this.state.modalType == "signin") {
                          if (
                            this.state.errors.email ||
                            this.state.errors.password
                          ) {
                            proceed = false;
                          }
                        } else if (this.state.modalType == "signup") {
                          if (
                            this.state.errors.email ||
                            this.state.errors.fname ||
                            this.state.errors.phone ||
                            this.state.errors.password
                          ) {
                            proceed = false;
                          }
                        }
                        if (proceed) {
                          this.handleSubmit();
                        }
                      }}
                    />
                    {this.state.modalType == "signin"
                      ? this.props.lang == "en"
                        ? this.props.translations.en["Don’t have an account?"]
                        : this.props.translations.ar["Don’t have an account?"]
                      : this.props.lang == "en"
                      ? this.props.translations.en["Already have an account?"]
                      : this.props.translations.ar["Already have an account?"]}
                    {this.state.modalType == "signin" ? (
                      <a
                        onClick={() => this.handleState("modalType", "signup")}
                        style={{ color: "red" }}
                      >
                        {this.props.lang == "en"
                          ? this.props.translations.en["Sign up"]
                          : this.props.translations.ar["Sign up"]}
                      </a>
                    ) : (
                      <a
                        onClick={() => this.handleState("modalType", "signin")}
                        style={{ color: "red" }}
                      >
                        {this.props.lang == "en"
                          ? this.props.translations.en["Sign in"]
                          : this.props.translations.ar["Sign in"]}
                      </a>
                    )}
                  </div>
                </>
              ) : null}
              {this.state.modalType == "forgot_password" ? (
                <div
                  id="forgot_pw"
                  style={{ display: "block", padding: "15px" }}
                >
                  <div className="form-group">
                    <label>
                      {this.props.lang == "en"
                        ? this.props.translations.en[
                            "Please confirm login email below"
                          ]
                        : this.props.translations.ar[
                            "Please confirm login email below"
                          ]}
                    </label>
                    <input
                      type="email"
                      className="form-control customInput"
                      name="email_forgot"
                      id="email_forgot"
                      onChange={(e) => {
                        this.handleState("email", e.currentTarget.value);
                      }}
                      value={this.state.email}
                      autoComplete={false}
                    />
                    <i className="icon_mail_alt"></i>
                  </div>
                  {this.state.errors.email ? (
                    <p style={{ color: "red" }}>Email is required</p>
                  ) : null}
                  <p>
                    {this.props.lang == "en"
                      ? this.props.translations.en[
                          "You will receive an email containing a link allowing you to reset your password to a new preferred one."
                        ]
                      : this.props.translations.ar[
                          "You will receive an email containing a link allowing you to reset your password to a new preferred one."
                        ]}
                  </p>

                  {this.state.error_msg.forgot ? (
                    <div style={{ color: "green" }}>
                      {" "}
                      {this.state.error_msg.forgot}
                    </div>
                  ) : null}
                  {this.state.error_msg.forgot_err ? (
                    <div style={{ color: "red" }}>
                      {" "}
                      {this.state.error_msg.forgot_err}
                    </div>
                  ) : null}

                  <div className="text-center">
                    <input
                      type="submit"
                      value="Reset Password"
                      className="btn_1"
                      // onClick={this.resetPassword}
                      onClick={(e) => {
                        e.preventDefault();
                        if (!this.state.errors.email) {
                          this.resetPassword();
                        }
                      }}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </form>
        </Modal.Body>

        <button
          title="Close (Esc)"
          type="button"
          id="authClose"
          className="mfp-close"
          onClick={() => this.handleModal()}
        ></button>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedProduct: state.selectedProduct,
    lang: state.lang.lang,
    translations: state.translations,
    settings: state.settings.data,
    auth: state.auth,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setSelectedProduct: (prod_id) => dispatch(getSelectedProduct(prod_id)),
    setLang: (lang) =>
      dispatch({
        type: actionTypes.SET_LANGUAGE,
        payload: { lang: lang },
      }),
    setSelectedProduct: (prod_id) =>
      dispatch({
        type: actionTypes.SET_SELECTED_PRODUCT,
        payload: { product: prod_id },
      }),
    handleProductPopUp: (val) => {
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: val },
      });
    },
    handleAuthModal: (val) => {
      dispatch({
        type: actionTypes.HANDLE_AUTH_MODAL,
        payload: { showModal: val },
      });
    },
    updateUserData: (val) => {
      dispatch({
        type: actionTypes.UPDATE_USERDATA,
        payload: { userdata: val },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthModal);
