import React, { Component } from "react";
import SideMenu from "../SideMenu/SideMenu";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actions";
import { BASE_API_URL, ENVIRONMENT } from "../../../config";
import { callService } from "../../../services";
export class TrackOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      orders: [],
      statuses: [
        "Pending",
        "Order Accepted",
        "Parcel Ready",
        "Delivered",
        "Cancelled",
      ],
      showNotFound: false,
    };
  }

  componentDidMount() {}

  getOrders = () => {
    if (this.state.search && this.state.search !== "") {
      callService(`${BASE_API_URL}/track/${this.state.search}`)
        .then((resp) => resp.json())
        .then((respJson) => {
          if (ENVIRONMENT == "development") {
            console.log(respJson);
          }

          this.setState((prevState) => {
            return {
              ...prevState,
              orders: respJson,
              showNotFound: respJson.length > 0 ? false : true,
            };
          });
        })
        .catch((err) => {
          if (ENVIRONMENT == "development") {
            console.log(err);
          }
        });
    }
  };

  render() {
    let orders = this.state.orders.map((order, i) => {
      let prods = order.name.map((prod, ind) => {
        return (
          <p key={ind}>
            {this.props.lang == "en" ? prod.product_name : prod.product_name_ar}
          </p>
        );
      });
      return (
        <tr key={i}>
          <td className="text-center">{order.id}</td>
          <td className="text-center">{prods}</td>
          <td className="text-center">KWD {order.total_price}</td>
          <td className="text-center">
            {this.state.statuses[order.delivery]}
            <br />
            <Link to={`/view-order/${order.id}`}>
              <strong>
                {this.props.lang == "en"
                  ? this.props.translations.en["View"]
                  : this.props.translations.ar["View"]}
              </strong>
            </Link>
          </td>
        </tr>
      );
    });

    return (
      <main className="bg_gray">
        <div className="container margin_60_20">
          <div className="row justify-content-center">
            <SideMenu />
            <div className="col-lg-9">
              <div className="box_general write_review">
                <h1 className="add_bottom_15">
                  {this.props.lang == "en"
                    ? this.props.translations.en["Track Order"]
                    : this.props.translations.ar["Track Order"]}
                </h1>
                <div className="row add_bottom_25">
                  <div className="col-sm-8">
                    <input
                      autocomplete="off"
                      type="text"
                      name="phone"
                      className="form-control"
                      placeholder={
                        this.props.lang == "en"
                          ? this.props.translations.en[
                              "Enter Mobile Number / Order Id"
                            ]
                          : this.props.translations.ar[
                              "Enter Mobile Number / Order Id"
                            ]
                      }
                      value={this.state.search}
                      onChange={(e) => {
                        let val = e.currentTarget.value;
                        this.setState((prevState) => {
                          return {
                            ...prevState,
                            search: val,
                          };
                        });
                      }}
                    />
                  </div>
                  <div className="col-sm-4 trackButton">
                    <input
                      type="submit"
                      className="btn_1"
                      name="submit"
                      value={
                        this.props.lang == "en"
                          ? this.props.translations.en["Track Order"]
                          : this.props.translations.ar["Track Order"]
                      }
                      onClick={this.getOrders}
                    />
                  </div>
                </div>
                {this.state.orders.length > 0 &&
                this.state.search != "" &&
                this.state.search ? (
                  <div className="row">
                    <div className="col-md-12">
                      <h4>
                        {this.props.lang == "en"
                          ? this.props.translations.en["ORDER STATUS"]
                          : this.props.translations.ar["ORDER STATUS"]}
                      </h4>
                      <table
                        id="orders"
                        className="display table table-bordered table-striped"
                        cellspacing="0"
                      >
                        <thead>
                          <tr>
                            <th className="text-center">
                              {this.props.lang == "en"
                                ? this.props.translations.en["Order Id"]
                                : this.props.translations.ar["Order Id"]}
                            </th>
                            <th className="text-center">
                              {this.props.lang == "en"
                                ? this.props.translations.en["Items"]
                                : this.props.translations.ar["Items"]}
                            </th>
                            <th className="text-center">
                              {this.props.lang == "en"
                                ? this.props.translations.en["Price"]
                                : this.props.translations.ar["Price"]}
                            </th>
                            <th className="text-center">
                              {this.props.lang == "en"
                                ? this.props.translations.en["Status"]
                                : this.props.translations.ar["Status"]}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* <tr>
                          <td className="text-center">352</td>
                          <td className="text-center">GR7</td>
                          <td className="text-center">KWD 25.000</td>
                          <td className="text-center">Pending</td>
                        </tr> */}

                          {orders}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : null}

                {this.state.showNotFound ? (
                  <p style={{ textAlign: "center" }}>No Orders Found :(</p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations,
    lang: state.lang.lang,
    products: state.products.data,
    categories: state.categories.items,
    cart: state.cart,
    settings: state.settings.data,
  };
};

export default connect(mapStateToProps, null)(TrackOrder);
