import React, { Component } from "react";
import { BASE_API_URL, ENVIRONMENT } from "../../../config";
import { secureStorage } from "../../../locStore";
import { cloneDeep } from "lodash";
import Select from "react-select";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actions";
import DatePicker from "react-date-picker";
import { callService } from "../../../services";

class ScheduleForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      available_slots: [],
      showTimeSlots: true,
      selectedDate: new Date(),
      minDate: new Date(),
      selectedSlot: {
        label: null,
        value: null,
      },
    };
  }
  componentDidMount() {
    let d = new Date();
    let requestDate = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
    callService(`${BASE_API_URL}/delivery_slots/${requestDate}`)
      .then((res) => res.json())
      .then((resJson) => {
        if (ENVIRONMENT == "development") {
          console.log(resJson);
        }
        if (resJson[0].timings.length == 0) {
          alert(
            "No time slots available for thi date. Please select another date."
          );
        }
        let checkoutData = cloneDeep(this.props.checkoutData);

        checkoutData.deliver_time = resJson[0].timings[0].value;

        this.props.updateCheckoutData(checkoutData);

        this.setState(
          (prevState) => {
            let currentState = cloneDeep(prevState);
            return {
              ...currentState,
              selectedDate: d,
              available_slots: resJson[0].timings,
              showTimeSlots: true,
              selectedSlot:
                resJson[0].timings.length > 0
                  ? {
                      label: resJson[0].timings[0].string,
                      value: resJson[0].timings[0].value,
                    }
                  : {
                      label: null,
                      value: null,
                    },
            };
          },
          () => {
            this.props.update_schedule({
              selectedDate: d,
              selectedSlot:
                resJson[0].timings.length > 0
                  ? {
                      label: resJson[0].timings[0].string,
                      value: resJson[0].timings[0].value,
                    }
                  : {
                      label: null,
                      value: null,
                    },
            });
          }
        );
      })
      .catch((err) => {
        if (ENVIRONMENT == "development") {
          console.log(err);
        }
      });
  }

  handleDateChange = (event) => {
    if (ENVIRONMENT == "development") {
      console.log(event);
      console.log(new Date(event).getDate());
    }
    let d = new Date(event);
    let requestDate = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
    let checkoutData = cloneDeep(this.props.checkoutData);
    checkoutData.deliver_date = requestDate;

    // this.props.set_pickupLocation(null);

    this.props.updateCheckoutData(checkoutData);
    if (event) {
      callService(`${BASE_API_URL}/delivery_slots/${requestDate}`)
        .then((res) => res.json())
        .then((resJson) => {
          if (ENVIRONMENT == "development") {
            console.log(resJson);
          }
          let checkoutData = cloneDeep(this.props.checkoutData);
          // checkoutData.deliver_date=this.state.selectedDate;
          checkoutData.deliver_time = resJson[0].timings[0].value;

          // this.props.set_pickupLocation(null);

          this.props.updateCheckoutData(checkoutData);
          this.setState(
            (prevState) => {
              let currentState = cloneDeep(prevState);
              return {
                ...currentState,
                selectedDate: event,
                available_slots: resJson[0].timings,
                showTimeSlots: true,
                selectedSlot:
                  resJson[0].timings.length > 0
                    ? {
                        label: resJson[0].timings[0].string,
                        value: resJson[0].timings[0].value,
                      }
                    : {
                        label: null,
                        value: null,
                      },
              };
            },
            () => {
              this.props.update_schedule({
                selectedDate: event,
                selectedSlot:
                  resJson[0].timings.length > 0
                    ? {
                        label: resJson[0].timings[0].string,
                        value: resJson[0].timings[0].value,
                      }
                    : {
                        label: null,
                        value: null,
                      },
              });
            }
          );
        })
        .catch((err) => {
          if (ENVIRONMENT == "development") {
            console.log(err);
          }
        });
    }
    // console.log(event);
    // let d = new Date(event);
    // console.log(
    //   `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}T${d.getHours()}-${d.getMinutes()}`
    // );
    // if (
    //   this.props.delivery_location !== null &&
    //   this.props.delivery_location !== undefined
    // ) {
    //   let area = this.props.delivery_location.id;
    //   let date = `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}`;
    // } else {
    //   alert("Please select delivery location first");
    // }
  };

  selectedTimeSlot = (timeSlot) => {
    if (ENVIRONMENT == "development") {
      console.log(timeSlot);
    }
    this.setState(
      (prevState) => {
        let currentState = cloneDeep(prevState);
        return {
          ...currentState,

          selectedSlot: timeSlot,
        };
      },
      () => {
        let checkoutData = cloneDeep(this.props.checkoutData);
        // checkoutData.deliver_date=this.state.selectedDate;
        checkoutData.deliver_time = timeSlot.value;

        // this.props.set_pickupLocation(null);

        this.props.updateCheckoutData(checkoutData);

        this.props.update_schedule({
          selectedDate: this.state.selectedDate,
          selectedSlot: timeSlot,
        });
        // scheduleDate:
      }
    );
  };

  render() {
    let slotOptions = this.state.available_slots.map((slot, index) => {
      let retslot = {
        label: slot.string,
        value: slot.value,
      };
      return retslot;
    });

    // let selectedTimeSlot = this.
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            marginTop: "20px",
          }}
        >
          <div style={{ flex: 1.5, display: "flex", alignItems: "center" }}>
            <span>
              <label>
                {this.props.lang == "ar" &&
                this.props.translations.ar["Schedule Later"] != null
                  ? this.props.translations.ar["Schedule Later"]
                  : "Schedule Later"}{" "}
                :{" "}
              </label>
            </span>
          </div>
          <div style={{ flex: 2 }}>
            <DatePicker
              onChange={this.handleDateChange}
              value={this.state.selectedDate}
              minDate={this.state.minDate}
              clearIcon={null}
              id="deli_date"
            />
          </div>
        </div>

        {this.state.showTimeSlots ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              marginTop: "20px",
            }}
          >
            <div style={{ flex: 1.5, display: "flex", alignItems: "center" }}>
              <span>
                <label>
                  {this.props.lang == "ar" &&
                  this.props.translations.ar["Delivery Time"] != null
                    ? this.props.translations.ar["Delivery Time"]
                    : "Delivery Time"}{" "}
                  :{" "}
                </label>
              </span>
            </div>
            <div style={{ flex: 2 }}>
              <Select
                onChange={(e) => this.selectedTimeSlot(e)}
                placeholder={
                  this.props.lang === "en"
                    ? this.props.translations.en["Select Your TimeSlot"]
                    : this.props.translations.ar["Select Your TimeSlot"]
                }
                className="custom-select js-example-basic-single"
                id="inputGroupSelectForTime"
                options={slotOptions}
                value={this.props.delivery_mode.selectedSlot}
              ></Select>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // shop: state.shop,
    lang: state.lang.lang,
    settings: state.settings.data,
    shop: state.shop,
    cart: state.cart,
    lang: state.lang.lang,
    translations: state.translations,
    showSignInModal: state.handleSignInModal.show,
    selectedProduct: state.selectedProduct,
    countries: state.countries.data,
    branches: state.branches.items,
    governates: state.governates.data,
    delivery_location: state.deliveryLocation.data.area,
    pickup_location: state.deliveryLocation.data.branch,
    delivery_location_governate: state.deliveryLocation.data.governate,
    delivery_mode: state.deliveryMode,
    currency: state.currency,
    multiplier: state.multiplier,
    user: state.user,
    checkoutData: state.checkoutData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // updateShop: () => dispatch(updateShopDetails()),
    // getCategories: () => dispatch(getCategories()),
    setLang: (lang) =>
      dispatch({ type: actionTypes.SET_LANGUAGE, payload: { lang: lang } }),
    setShowProductPopUp: (show_product_popup) =>
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: show_product_popup },
      }),
    resetCart: () => dispatch({ type: actionTypes.RESET_CART, payload: null }),
    set_deliveryLocation: (location) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_LOCATION,
        payload: { location: location },
      }),
    set_deliveryMode: (modeData) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_MODE,
        payload: { modeData },
      }),
    set_tempDeliveryMode: (modeData) =>
      dispatch({
        type: actionTypes.SET_TEMP_DELIVERY_MODE,
        payload: { modeData },
      }),
    addItemToCart: (i, product) =>
      dispatch({
        type: actionTypes.ADD_CART_ITEM,
        payload: { item: product, index: i },
      }),
    updateCart: (product) =>
      dispatch({ type: actionTypes.ADD_CART_ITEM, payload: { item: product } }),
    removeItemFromCart: (i, product) =>
      dispatch({
        type: actionTypes.REMOVE_CART_ITEM,
        payload: { item: product, index: i },
      }),
    reloadCart: (cart) =>
      dispatch({
        type: actionTypes.SET_CART_ON_RELOAD,
        payload: { cart: cart },
      }),
    popItem: (i) =>
      dispatch({
        type: actionTypes.POPITEM,
        payload: { popIndex: i },
      }),
    handleAuthModal: (val) => {
      dispatch({
        type: actionTypes.HANDLE_AUTH_MODAL,
        payload: { showModal: val },
      });
    },
    updateCheckoutData: (checkoutData) => {
      dispatch({
        type: actionTypes.UPDATE_CHECKOUTDATA,
        payload: { checkoutData: checkoutData },
      });
    },
    update_schedule: (scheduleData) =>
      dispatch({
        type: actionTypes.UPDATE_SCHEDULE,
        payload: { scheduleData },
      }),
    // setCurrency: (currency) => dispatch({type: actionTypes.SET_CURRENCY, payload: { base_currency: currency}}),
    // setMultiplier: (multiplier) => dispatch({type: actionTypes.SET_MULTIPLIER, payload: { multiplier: multiplier}})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleForm);
