import React from "react";

function ScrollTop() {
  const ScrollTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div id="toTop" className="detail_page visible" onClick={ScrollTop}></div>
  );
  //   <div id="toTop" className="detail_page visible"></div>
}

export default ScrollTop;
