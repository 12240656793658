import * as actionTypes from '../actions/actions';



const initialState = {
    data: []
}

const currenciesReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_CURRENCIES:
            let currencies = action.payload.currencies;
            return {
                data: currencies
            }

    }
    return state;
}

export default currenciesReducer;