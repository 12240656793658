import { secureStorage } from "../../locStore";
import * as actionTypes from "../actions/actions";
import { ENVIRONMENT } from "../../config";

const initialState = {
  data: { governate: null, area: null },
};

const deliveryLocationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DELIVERY_LOCATION:
      let location = action.payload.location;
      if (ENVIRONMENT == "development") {
        console.log(
          "-----------------------------------------------------------------------------"
        );
        console.log(secureStorage.getItem("delivery_location"));
        console.log(secureStorage.getItem("pickup_location"));
        console.log(
          "-----------------------------------------------------------------------------"
        );
      }
      secureStorage.setItem("pickup_location", null);
      secureStorage.setItem("delivery_location", location);

      if (ENVIRONMENT == "development") {
        console.log(
          "-----------------------------------------------------------------------------"
        );
        console.log(secureStorage.getItem("delivery_location"));
        console.log(secureStorage.getItem("pickup_location"));
        console.log(
          "-----------------------------------------------------------------------------"
        );
      }

      return {
        data: location,
      };
  }
  return state;
};

export default deliveryLocationReducer;
