import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import "./PopupSkeleton.css";
import * as actionTypes from "../../../store/actions/actions";
import { connect } from "react-redux";
import { BASE_API_URL, ENVIRONMENT } from "../../../config";

class PopupSkeleton extends Component {
  constructor(props) {
    super(props);
  }

  closeModal = () => {
    if (ENVIRONMENT == "development") {
      console.log("closing Modal");
    }
    this.props.handleProductPopUp(false);
    this.props.setSelectedProduct(null);
  };
  render() {
    return (
      <Modal
        id="PopupSkeleton"
        show={this.props.selectedProduct.showProductPopUp}
        // show={true}
        onHide={this.closeModal}
        // backdrop="static"
        keyboard={false}
      >
        {/* <Modal.Header
          closeButton
          className="small-dialog-header skelPopupHeader animate"
        ></Modal.Header> */}
        <Modal.Body
          style={{
            border: "none",
            height: "80vh",
            width: "100%",
            padding: 0,
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
          }}
        >
          {/* <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        > */}
          {/* <h5 className="shimmer" style={{ padding: 0 }}>
            Loading
          </h5> */}

          <div
            className="row animate"
            style={{
              height: "60px",
              width: "100%",
              margin: 0,
              marginBottom: "15px",
            }}
          ></div>
          <div className="skelProdRow">
            <div className="skelProd">
              <div className="skelCatItem animate"></div>
              <div
                className="skelCatItem animate"
                style={{ height: "5px", width: "70%", alignSelf: "flex-start" }}
              ></div>
              <div
                className="skelCatItem animate"
                style={{ height: "5px", width: "70%", alignSelf: "flex-start" }}
              ></div>
            </div>
          </div>

          <div style={{ margin: "20px" }}>
            <div className="skelCatItem animate"></div>

            <div
              className="skelCatItem animate"
              style={{ height: "5px", width: "70%", alignSelf: "flex-start" }}
            ></div>
            <div
              className="skelCatItem animate"
              style={{ height: "5px", width: "70%", alignSelf: "flex-start" }}
            ></div>

            <div className="skelCatItem animate"></div>
            <div
              className="skelCatItem animate"
              style={{ height: "5px", width: "70%", alignSelf: "flex-start" }}
            ></div>
            <div
              className="skelCatItem animate"
              style={{ height: "5px", width: "70%", alignSelf: "flex-start" }}
            ></div>

            <div className="skelCatItem animate"></div>
            <div
              className="skelCatItem animate"
              style={{ height: "5px", width: "70%", alignSelf: "flex-start" }}
            ></div>
            <div
              className="skelCatItem animate"
              style={{ height: "5px", width: "70%", alignSelf: "flex-start" }}
            ></div>
          </div>
          <button
            // onClick={() => this.closeModal()}
            title="Close (Esc)"
            type="button"
            className="mfp-close"
          ></button>
        </Modal.Body>
      </Modal>
    );
  }
}

// const PopupSkeleton = () => {
//   return (
//     <Modal
//       id="PopupSkeleton"
//       show={true}
//       onHide={false}
//       // backdrop="static"
//       keyboard={false}
//     >
//       {/* <Modal.Header
//         closeButton
//         className="small-dialog-header skelPopupHeader animate"
//       ></Modal.Header> */}
//       <Modal.Body style={{ height: "100vh", display: "flex" }}>
//         <div
//           style={{
//             display: "flex",
//             flex: 1,
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <h5 className="shimmer">Loading</h5>
//         </div>
//       </Modal.Body>
//     </Modal>
//   );
// };
const mapStateToProps = (state) => {
  return {
    selectedProduct: state.selectedProduct,
    lang: state.lang.lang,
    translations: state.translations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedProduct: (lang) =>
      dispatch({
        type: actionTypes.SET_SELECTED_PRODUCT,
        payload: { product: lang },
      }),
    handleProductPopUp: (val) => {
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: val },
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PopupSkeleton);

// <Modal
//       id="PopupSkeleton"
//       show={true}
//       onHide={false}
//       backdrop="static"
//       keyboard={false}
//     >
//       {/* <Modal.Header
//         closeButton
//         className="small-dialog-header skelPopupHeader animate"
//       ></Modal.Header> */}

//       <Modal.Body className="skelPopupContent">
//         <div
//           style={{
//             background: "rgba(0, 0, 0, 0.2)",
//             padding: "5px",
//             boxSizing: "border-box",
//             height: "auto",
//           }}
//           className="animate"
//         >
//           <div className="skelPopupBody animate" style={{ width: "90%" }}></div>
//           <div className="skelPopupBody animate" style={{ width: "60%" }}></div>
//           <div className="skelPopupBody animate" style={{ width: "50%" }}></div>
//           {/* <div className="skelPopupBody animate" style={{ width: "70%" }}></div>
//           <div className="skelPopupBody animate" style={{ width: "70%" }}></div> */}
//         </div>

//         <div className="skelPopupBody animate" style={{ width: "40%" }}></div>
//         <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
//           <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
//             <div
//               className="skelPopupBody animate"
//               style={{ width: "25px", height: "25px" }}
//             ></div>
//           </div>
//           <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
//             <div
//               className="skelPopupBody animate"
//               style={{ width: "25px", height: "25px" }}
//             ></div>
//           </div>
//           <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
//             <div
//               className="skelPopupBody animate"
//               style={{ width: "25px", height: "25px" }}
//             ></div>
//           </div>
//           <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
//             <div
//               className="skelPopupBody animate"
//               style={{ width: "25px", height: "25px" }}
//             ></div>
//           </div>
//         </div>
//         <div className="skelPopupBody animate" style={{ width: "40%" }}></div>
//         <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
//           <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
//             <div
//               className="skelPopupBody animate"
//               style={{ width: "25px", height: "25px" }}
//             ></div>
//           </div>
//           <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
//             <div
//               className="skelPopupBody animate"
//               style={{ width: "25px", height: "25px" }}
//             ></div>
//           </div>
//           <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
//             <div
//               className="skelPopupBody animate"
//               style={{ width: "25px", height: "25px" }}
//             ></div>
//           </div>
//           <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
//             <div
//               className="skelPopupBody animate"
//               style={{ width: "25px", height: "25px" }}
//             ></div>
//           </div>
//         </div>
//         <div className="skelPopupBody animate" style={{ width: "40%" }}></div>
//         <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
//           <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
//             <div
//               className="skelPopupBody animate"
//               style={{ width: "25px", height: "25px" }}
//             ></div>
//           </div>
//           <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
//             <div
//               className="skelPopupBody animate"
//               style={{ width: "25px", height: "25px" }}
//             ></div>
//           </div>
//           <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
//             <div
//               className="skelPopupBody animate"
//               style={{ width: "25px", height: "25px" }}
//             ></div>
//           </div>
//           <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
//             <div
//               className="skelPopupBody animate"
//               style={{ width: "25px", height: "25px" }}
//             ></div>
//           </div>
//         </div>
//       </Modal.Body>
//     </Modal>
