import React, { Component } from "react";
import { BASE_API_URL, ENVIRONMENT } from "../../../config";
import { secureStorage } from "../../../locStore";
import { cloneDeep } from "lodash";
import Select from "react-select";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actions";
import { updateDeliveryInfo } from "../../../store/actions/checkout";
import { callService } from "../../../services";

class AreaSelectionForm extends Component {
  constructor(props) {
    super(props);
    console.log(
      this.props.delivery_location,
      "--------------from area selection form-------------------"
    );
  }

  componentDidMount() {
    if (this.props.delivery_location) {
      let areaId = this.props.delivery_location.id;

      // this.props.updateDeliverInfo(areaId);
      this.updateDeliverInfo(areaId);
    }
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props.delivery_location?.id !== prevProps.delivery_location?.id) {
  //     let areaId = this.props.delivery_location.id;

  //     // this.props.updateDeliverInfo(areaId);
  //     this.updateDeliverInfo(areaId);
  //   }
  // }

  updateDeliverInfo = (areaId) => {
    callService(`${BASE_API_URL}/delivery_info/${areaId}`)
      .then((res) => res.json())
      .then((resJson) => {
        if (ENVIRONMENT == "development") {
          console.log("this is delivery Id === ", areaId);
          console.log("this is delivery_info response === ", resJson);
        }
        let currentState = cloneDeep(this.props.checkoutData);
        currentState.delivery_info = {
          ...currentState.delivery_info,
          ...resJson,
          // delivery_now: "0",
        };
        currentState.error_state.area = false;
        this.props.set_deliveryLocation({
          governate: this.props.delivery_governate,
          area: this.props.delivery_location,
        });
        this.props.updateCheckoutData(currentState);
        if (ENVIRONMENT == "development") {
          console.log("hitting delivery area action");
        }

        if (resJson.delivery_now == "0") {
          this.props.set_deliveryMode({
            type: "delivery_later",
          });
        }
        // this.props.updateDeliveryInfo(areaId);
      })
      .catch((err) => {
        if (ENVIRONMENT == "development") {
          console.log(err);
        }
      });
  };

  selectedLocation = (areaId) => {
    let governate = null;
    let area = null;
    this.props.governates.map((gov) => {
      let isAreaExist = gov.list.filter((area) => {
        return area.id === areaId;
      });

      if (Array.isArray(isAreaExist) && isAreaExist.length > 0) {
        governate = gov;
        area = isAreaExist[0];
      }
    });
    if (ENVIRONMENT == "development") {
      console.log(governate);
      console.log(area);
    }
    secureStorage.setItem("delivery_location", {
      governate: governate,
      area: area,
    });
    this.props.set_deliveryLocation({ governate: governate, area: area });
    if (ENVIRONMENT == "development") {
      console.log("setting area , ", area);
    }

    // let myState = cloneDeep(this.props.checkoutData);
    // secureStorage.setItem("checkoutData", myState);

    this.updateDeliverInfo(areaId);
  };

  render() {
    let options = this.props.governates.map((governate, index) => {
      let item = {
        label: this.props.lang == "en" ? governate.title : governate.title_ar,
      };
      let options = governate.list.map((area, index) => {
        let option = {
          label: this.props.lang == "en" ? area.title : area.title_ar,
          value: area.id,
        };
        return option;
      });

      item["options"] = options;
      return item;
    });
    return (
      <>
        <label>
          {this.props.lang == "en"
            ? this.props.translations.en["Area"]
            : this.props.translations.ar["Area"]}{" "}
          <span className="required">*</span>
        </label>

        <Select
          onChange={(e) => this.selectedLocation(e.value)}
          placeholder={
            this.props.lang === "en"
              ? this.props.translations.en["Select Area"]
              : this.props.translations.ar["Select Area"]
          }
          className="custom-select js-example-basic-single"
          id="inputGroupSelect04"
          options={options}
          // value={this.props.checkout.areaId}
          value={
            this.props.delivery_location
              ? {
                  label:
                    this.props.lang == "en"
                      ? this.props.delivery_location.title
                      : this.props.delivery_location.title_ar,
                  value: this.props.delivery_location.id,
                }
              : null
          }
        ></Select>
        {this.props.checkoutData.error_state.area ? (
          <p className="error">
            {this.props.lang == "en"
              ? this.props.translations.en["Area is required"]
              : this.props.translations.ar["Area is requied"]}
            Area is required
          </p>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // shop: state.shop,
    lang: state.lang.lang,
    settings: state.settings.data,
    shop: state.shop,
    cart: state.cart,
    lang: state.lang.lang,
    translations: state.translations,
    showSignInModal: state.handleSignInModal.show,
    selectedProduct: state.selectedProduct,
    countries: state.countries.data,
    branches: state.branches.items,
    governates: state.governates.data,
    delivery_location: state.deliveryLocation.data.area,
    delivery_governate: state.deliveryLocation.data.governate,
    pickup_location: state.deliveryLocation.data.branch,
    delivery_location_governate: state.deliveryLocation.data.governate,
    delivery_mode: state.deliveryMode,
    currency: state.currency,
    multiplier: state.multiplier,
    user: state.user,
    checkoutData: state.checkoutData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // updateShop: () => dispatch(updateShopDetails()),
    // getCategories: () => dispatch(getCategories()),
    updateDeliveryInfo: (id) => dispatch(updateDeliveryInfo(id)),
    setLang: (lang) =>
      dispatch({ type: actionTypes.SET_LANGUAGE, payload: { lang: lang } }),
    setShowProductPopUp: (show_product_popup) =>
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: show_product_popup },
      }),
    resetCart: () => dispatch({ type: actionTypes.RESET_CART, payload: null }),
    set_deliveryLocation: (location) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_LOCATION,
        payload: { location: location },
      }),
    set_deliveryMode: (modeData) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_MODE,
        payload: { modeData },
      }),
    set_tempDeliveryMode: (modeData) =>
      dispatch({
        type: actionTypes.SET_TEMP_DELIVERY_MODE,
        payload: { modeData },
      }),
    addItemToCart: (i, product) =>
      dispatch({
        type: actionTypes.ADD_CART_ITEM,
        payload: { item: product, index: i },
      }),
    updateCart: (product) =>
      dispatch({ type: actionTypes.ADD_CART_ITEM, payload: { item: product } }),
    removeItemFromCart: (i, product) =>
      dispatch({
        type: actionTypes.REMOVE_CART_ITEM,
        payload: { item: product, index: i },
      }),
    reloadCart: (cart) =>
      dispatch({
        type: actionTypes.SET_CART_ON_RELOAD,
        payload: { cart: cart },
      }),
    popItem: (i) =>
      dispatch({
        type: actionTypes.POPITEM,
        payload: { popIndex: i },
      }),
    handleAuthModal: (val) => {
      dispatch({
        type: actionTypes.HANDLE_AUTH_MODAL,
        payload: { showModal: val },
      });
    },
    updateCheckoutData: (checkoutData) => {
      dispatch({
        type: actionTypes.UPDATE_CHECKOUTDATA,
        payload: { checkoutData: checkoutData },
      });
    },
    // setCurrency: (currency) => dispatch({type: actionTypes.SET_CURRENCY, payload: { base_currency: currency}}),
    // setMultiplier: (multiplier) => dispatch({type: actionTypes.SET_MULTIPLIER, payload: { multiplier: multiplier}})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AreaSelectionForm);
