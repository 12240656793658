import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { BASE_API_URL, ENVIRONMENT } from "../../config";

import carouselStyles from "./ViewImages.css";
import { indexOf } from "lodash";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { callService } from "../../services";
class ViewImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gallery: [],
      loading: true,
      photoIndex: 0,
      isOpen: false,
    };
  }

  componentDidMount() {
    callService(`${BASE_API_URL}/product/${this.props.productId}`)
      .then((resp) => {
        return resp.json();
      })
      .then((respJson) => {
        if (ENVIRONMENT == "development") {
          console.log(respJson);
        }
        this.setState((prevState) => {
          return {
            ...prevState,
            loading: false,
            gallery: respJson.images,
          };
        });
      })
      .catch((err) => {
        if (ENVIRONMENT == "development") {
          console.log(err);
        }
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.show != prevProps.show) {
      callService(`${BASE_API_URL}/product/${this.props.productId}`)
        .then((resp) => {
          return resp.json();
        })
        .then((respJson) => {
          if (ENVIRONMENT == "development") {
            console.log(respJson);
          }
          this.setState((prevState) => {
            return {
              ...prevState,
              loading: false,
              gallery: respJson.images,
            };
          });
        })
        .catch((err) => {
          if (ENVIRONMENT == "development") {
            console.log(err);
          }
        });
    }
  }
  render() {
    const { photoIndex, isOpen } = this.state;
    let mobile = false;
    if (window.matchMedia("screen and (max-width: 990px)").matches) {
      mobile = true;
    }
    let gallery = this.state.gallery.map((img, i) => {
      return img;
    });
    return !this.state.loading ? (
      <div>
        {this.props.show && (
          <Lightbox
            mainSrc={gallery[photoIndex]}
            nextSrc={gallery[(photoIndex + 1) % gallery.length]}
            prevSrc={
              gallery[(photoIndex + gallery.length - 1) % gallery.length]
            }
            onCloseRequest={() => this.props.setShow(false)}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + gallery.length - 1) % gallery.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % gallery.length,
              })
            }
          />
        )}
      </div>
    ) : null;
  }
}

export default ViewImages;
