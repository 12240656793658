import React, { Component } from "react";
import { Link } from "react-router-dom";
class OrderNotFound extends Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "50px",
        }}
      >
        <h2 style={{ flex: 1, marginBottom: "60px" }}>Order Not Found</h2>

        {/* <p style={{ flex: 1 }}>Please Try Placing Order Again</p> */}

        <Link to={`/checkout`}>
          <button
            style={{
              flex: 1,
              padding: "10px 20px",
              border: "none",
              borderRadius: "7px",
              background: "linear-gradient(to right, #f3723b, #e54750)",
            }}
          >
            Go To Home
          </button>
        </Link>
      </div>
    );
  }
}

export default OrderNotFound;
