import * as actionTypes from "../actions/actions";
import { secureStorage } from "../../locStore";
import cloneDeep from "lodash/cloneDeep";
import { ENVIRONMENT } from "../../config";

const initialState = {
  en: {},
  ar: {},
};

const translationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TRANSLATIONS:
      // set secureStorage to redux cart
      if (ENVIRONMENT == "development") {
        console.log(action.payload.translations, "payload cart ");
      }

      let storeClone = cloneDeep(action.payload.translations);
      if (ENVIRONMENT == "development") {
        console.log(storeClone, "deep clone store");
      }
      return storeClone;
  }
  return state;
};

export default translationReducer;
