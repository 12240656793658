import { secureStorage } from "../../locStore";
import * as actionTypes from "../actions/actions";

const initialState = {
  data: { branch: null },
};

const pickupReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PICKUP_LOCATION:
      let branch = action.payload.branch;
      secureStorage.setItem("pickup_location", branch);
      
      secureStorage.setItem("delivery_location", {
        governate: null,
        area: null,
      });
      return {
        data: {
          branch: branch ? { ...branch } : null,
        },
      };
  }
  return state;
};

export default pickupReducer;
