import React, { Component } from "react";
import { BASE_API_URL } from "../../../config";
import { secureStorage } from "../../../locStore";
import { cloneDeep } from "lodash";
import Select from "react-select";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actions";
import { Link } from "react-router-dom";
class CheckoutHeader extends Component {
  constructor(props) {
    super(props);
  }
  showAuthModal = () => {
    this.props.handleAuthModal(true);
  };
  render() {
    return (
      <div className="head">
        <div
          className="title"
          style={{
            marginBottom: "20px",
          }}
        >
          <>
            <div
            /*style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                flex: 1,
              }}*/
            >
              <span>
                <b style={{ fontSize: "25px" }}>
                  {!this.props.user.isLoggedIn
                    ? this.props.lang == "en"
                      ? this.props.translations.en["GUEST CHECKOUT"]
                      : this.props.translations.ar["GUEST CHECKOUT"]
                    : null}

                  {this.props.user.isLoggedIn
                    ? this.props.lang == "en"
                      ? this.props.translations.en["CHECKOUT"]
                      : this.props.translations.ar["CHECKOUT"]
                    : null}
                </b>
              </span>

              {!this.props.user.isLoggedIn ? (
                <a
                  onClick={this.showAuthModal}
                  id="sign-in"
                  className="login checkoutLogin"
                ></a>
              ) : null}
              {this.props.user.isLoggedIn ? (
                this.props.delivery_mode.type != "pickup" ? (
                  <Link to={`/add-address`}>
                    
                      <button
                        type="button"
                       className="btn_1 fl_ri"
                      >
                        {this.props.user.isLoggedIn
                    ? this.props.lang == "en"
                      ? this.props.translations.en["Add Address"]
                      : this.props.translations.ar["Add Address"]
                    : null}
                      </button>
                 
                  </Link>
                ) : null
              ) : null}
            </div>

            {/* <button type="button">login</button> */}
          </>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // shop: state.shop,
    lang: state.lang.lang,
    settings: state.settings.data,
    shop: state.shop,
    cart: state.cart,
    lang: state.lang.lang,
    translations: state.translations,
    showSignInModal: state.handleSignInModal.show,
    selectedProduct: state.selectedProduct,
    countries: state.countries.data,
    branches: state.branches.items,
    governates: state.governates.data,
    delivery_location: state.deliveryLocation.data.area,
    pickup_location: state.deliveryLocation.data.branch,
    delivery_location_governate: state.deliveryLocation.data.governate,
    delivery_mode: state.deliveryMode,
    currency: state.currency,
    multiplier: state.multiplier,
    user: state.user,
    checkoutData: state.checkoutData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // updateShop: () => dispatch(updateShopDetails()),
    // getCategories: () => dispatch(getCategories()),
    setLang: (lang) =>
      dispatch({ type: actionTypes.SET_LANGUAGE, payload: { lang: lang } }),
    setShowProductPopUp: (show_product_popup) =>
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: show_product_popup },
      }),
    resetCart: () => dispatch({ type: actionTypes.RESET_CART, payload: null }),
    set_deliveryLocation: (location) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_LOCATION,
        payload: { location: location },
      }),
    set_deliveryMode: (modeData) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_MODE,
        payload: { modeData },
      }),
    set_tempDeliveryMode: (modeData) =>
      dispatch({
        type: actionTypes.SET_TEMP_DELIVERY_MODE,
        payload: { modeData },
      }),
    addItemToCart: (i, product) =>
      dispatch({
        type: actionTypes.ADD_CART_ITEM,
        payload: { item: product, index: i },
      }),
    updateCart: (product) =>
      dispatch({ type: actionTypes.ADD_CART_ITEM, payload: { item: product } }),
    removeItemFromCart: (i, product) =>
      dispatch({
        type: actionTypes.REMOVE_CART_ITEM,
        payload: { item: product, index: i },
      }),
    reloadCart: (cart) =>
      dispatch({
        type: actionTypes.SET_CART_ON_RELOAD,
        payload: { cart: cart },
      }),
    popItem: (i) =>
      dispatch({
        type: actionTypes.POPITEM,
        payload: { popIndex: i },
      }),
    handleAuthModal: (val) => {
      dispatch({
        type: actionTypes.HANDLE_AUTH_MODAL,
        payload: { showModal: val },
      });
    },
    updateCheckoutData: (checkoutData) => {
      dispatch({
        type: actionTypes.UPDATE_CHECKOUTDATA,
        payload: { checkoutData: checkoutData },
      });
    },
    // setCurrency: (currency) => dispatch({type: actionTypes.SET_CURRENCY, payload: { base_currency: currency}}),
    // setMultiplier: (multiplier) => dispatch({type: actionTypes.SET_MULTIPLIER, payload: { multiplier: multiplier}})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutHeader);
