import React, { Component } from "react";
import { BASE_API_URL, ENVIRONMENT } from "../../../config";
import { secureStorage } from "../../../locStore";
import { cloneDeep } from "lodash";
import Select from "react-select";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actions";
class MainFields extends Component {
  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>
                {this.props.lang == "en"
                  ? this.props.translations.en["First Name"]
                  : this.props.translations.ar["First Name"]}{" "}
                <span className="required">*</span>
              </label>
              <input
                className="form-control"
                placeholder={
                  this.props.lang == "en"
                    ? this.props.translations.en["First Name"]
                    : this.props.translations.ar["First Name"]
                }
                onChange={(e) =>
                  this.props.setDataVariable("fname", e.currentTarget.value)
                }
                value={this.props.checkoutData.fname}
                // value={this.state.fname}
              />
              {this.props.checkoutData.error_state.fname ? (
                <p className="error">
                  {this.props.lang == "en"
                    ? this.props.translations.en["First Name is required"]
                    : this.props.translations.ar["First Name is required"]}
                  First Name is required
                </p>
              ) : null}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>
                {this.props.lang == "en"
                  ? this.props.translations.en["Last Name"]
                  : this.props.translations.ar["Last Name"]}{" "}
                <span className="required">*</span>
              </label>
              <input
                className="form-control"
                placeholder={
                  this.props.lang == "en"
                    ? this.props.translations.en["Last Name"]
                    : this.props.translations.ar["Last Name"]
                }
                onChange={(e) =>
                  this.props.setDataVariable("lname", e.currentTarget.value)
                }
                value={this.props.checkoutData.lname}
              />
              {this.props.checkoutData.error_state.lname ? (
                <p className="error">
                  {this.props.lang == "en"
                    ? this.props.translations.en["Last Name is required"]
                    : this.props.translations.ar["Last Name is required"]}
                  Last Name is required
                </p>
              ) : null}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>
                {this.props.lang == "en"
                  ? this.props.translations.en["Email Address"]
                  : this.props.translations.ar["Email Address"]}
              </label>
              <input
                className="form-control"
                placeholder={
                  this.props.lang == "en"
                    ? this.props.translations.en["Email Address"]
                    : this.props.translations.ar["Email Address"]
                }
                value={this.props.checkoutData.email}
                onChange={(e) =>
                  this.props.setDataVariable("email", e.currentTarget.value)
                }
              />
              {this.props.checkoutData.error_state.email ? (
                <p className="error">
                  {this.props.lang == "en"
                    ? this.props.translations.en["Email Address is required"]
                    : this.props.translations.ar["Email Address is required"]}
                  Email is required
                </p>
              ) : null}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>
                {this.props.lang == "en"
                  ? this.props.translations.en["Phone"]
                  : this.props.translations.ar["Phone"]}{" "}
                <span className="required">*</span>
              </label>
              <input
                className="form-control"
                placeholder={
                  this.props.lang == "en"
                    ? this.props.translations.en["Phone"]
                    : this.props.translations.ar["Phone"]
                }
                maxLength="8"
                minLength="8"
                value={this.props.checkoutData.mobile}
                onChange={(e) =>
                  this.props.setDataVariable("mobile", e.currentTarget.value)
                }
              />
              {this.props.checkoutData.error_state.mobile ? (
                <p className="error">
                  {this.props.lang == "en"
                    ? this.props.translations.en["Phone is required"]
                    : this.props.translations.ar["Phone is required"]}
                  Phone is required
                </p>
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // shop: state.shop,
    lang: state.lang.lang,
    settings: state.settings.data,
    shop: state.shop,
    cart: state.cart,
    lang: state.lang.lang,
    translations: state.translations,
    showSignInModal: state.handleSignInModal.show,
    selectedProduct: state.selectedProduct,
    countries: state.countries.data,
    branches: state.branches.items,
    governates: state.governates.data,
    delivery_location: state.deliveryLocation.data.area,
    pickup_location: state.deliveryLocation.data.branch,
    delivery_location_governate: state.deliveryLocation.data.governate,
    delivery_mode: state.deliveryMode,
    currency: state.currency,
    multiplier: state.multiplier,
    user: state.user,
    checkoutData: state.checkoutData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // updateShop: () => dispatch(updateShopDetails()),
    // getCategories: () => dispatch(getCategories()),
    setLang: (lang) =>
      dispatch({ type: actionTypes.SET_LANGUAGE, payload: { lang: lang } }),
    setShowProductPopUp: (show_product_popup) =>
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: show_product_popup },
      }),
    resetCart: () => dispatch({ type: actionTypes.RESET_CART, payload: null }),
    set_deliveryLocation: (location) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_LOCATION,
        payload: { location: location },
      }),
    set_deliveryMode: (modeData) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_MODE,
        payload: { modeData },
      }),
    set_tempDeliveryMode: (modeData) =>
      dispatch({
        type: actionTypes.SET_TEMP_DELIVERY_MODE,
        payload: { modeData },
      }),
    addItemToCart: (i, product) =>
      dispatch({
        type: actionTypes.ADD_CART_ITEM,
        payload: { item: product, index: i },
      }),
    updateCart: (product) =>
      dispatch({ type: actionTypes.ADD_CART_ITEM, payload: { item: product } }),
    removeItemFromCart: (i, product) =>
      dispatch({
        type: actionTypes.REMOVE_CART_ITEM,
        payload: { item: product, index: i },
      }),
    reloadCart: (cart) =>
      dispatch({
        type: actionTypes.SET_CART_ON_RELOAD,
        payload: { cart: cart },
      }),
    popItem: (i) =>
      dispatch({
        type: actionTypes.POPITEM,
        payload: { popIndex: i },
      }),
    handleAuthModal: (val) => {
      dispatch({
        type: actionTypes.HANDLE_AUTH_MODAL,
        payload: { showModal: val },
      });
    },
    updateCheckoutData: (checkoutData) => {
      dispatch({
        type: actionTypes.UPDATE_CHECKOUTDATA,
        payload: { checkoutData: checkoutData },
      });
    },
    // setCurrency: (currency) => dispatch({type: actionTypes.SET_CURRENCY, payload: { base_currency: currency}}),
    // setMultiplier: (multiplier) => dispatch({type: actionTypes.SET_MULTIPLIER, payload: { multiplier: multiplier}})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainFields);
