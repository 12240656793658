import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actionTypes from "../../store/actions/actions";
class Footer extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <footer>
        <div className="wave footer"></div>
        <div className="container margin_60_40 fix_mobile">
          <div className="row add_bottom_25">
            <div className="col-lg-6"></div>
            <div className="col-lg-6">
              <ul className="additional_links">
                <li>
                  <Link to={`/terms-and-conditions`}>
                    {this.props.lang == "en"
                      ? this.props.translations.en["Terms And Conditions"]
                      : this.props.translations.ar["Terms And Conditions"]}
                  </Link>
                </li>
                <li>
                  <Link to={`/privacy-policy`}>
                    {this.props.lang == "en"
                      ? this.props.translations.en["Privacy"]
                      : this.props.translations.ar["Privacy"]}
                  </Link>
                </li>
                <li>
                  <span>
                    ©{" "}
                    {this.props.lang == "en"
                      ? this.props.settings.title
                      : this.props.settings.title_ar}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations,
    lang: state.lang.lang,
    settings: state.settings.data,
  };
};

export default connect(mapStateToProps, null)(Footer);
