import React, { Component } from "react";
import { BASE_API_URL, ENVIRONMENT } from "../../../config";
import { secureStorage } from "../../../locStore";
import { cloneDeep } from "lodash";
import Select from "react-select";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actions";
import MainFields from "../MainFields/MainFields";
import AddressFields from "../AddressFields/AddressFields";
import AreaSelectionForm from "../AreaSelectionForm/AreaSelectionForm";
import BranchSelectionForm from "../BranchSelectionForm/BranchSelectionForm";
import AdditionalInfo from "../AdditionalInfo/AdditionalInfo";
class LoginBeforeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      housetype: null,
    };
  }

  componentDidMount() {}
  render() {
    // let options = this.props.governates.map((governate, index) => {
    //   let item = {
    //     label: this.props.lang == "en" ? governate.title : governate.title_ar,
    //   };
    //   let options = governate.list.map((area, index) => {
    //     let option = {
    //       label: this.props.lang == "en" ? area.title : area.title_ar,
    //       value: area.id,
    //     };
    //     return option;
    //   });

    //   item["options"] = options;
    //   return item;
    // });

    // let slotOptions = this.state.available_slots.map((slot, index) => {
    //   let retslot = {
    //     label: slot.string,
    //     value: slot.value,
    //   };
    //   return retslot;
    // });
    return (
      <div className="main">
        {this.props.delivery_mode.type == "delivery" ||
        this.props.delivery_mode.type == "delivery_later" ? (
          <>
            <MainFields
              setDataVariable={this.props.setDataVariable}
            ></MainFields>

            <AreaSelectionForm
              setDataVariable={this.props.setDataVariable}
            ></AreaSelectionForm>

            <AddressFields
              setDataVariable={this.props.setDataVariable}
            ></AddressFields>
          </>
        ) : null}

        {this.props.delivery_mode.type == "pickup" ? (
          <>
            <MainFields
              setDataVariable={this.props.setDataVariable}
            ></MainFields>
            <BranchSelectionForm
              setDataVariable={this.props.setDataVariable}
            ></BranchSelectionForm>
          </>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // shop: state.shop,
    lang: state.lang.lang,
    settings: state.settings.data,
    shop: state.shop,
    cart: state.cart,
    lang: state.lang.lang,
    translations: state.translations,
    showSignInModal: state.handleSignInModal.show,
    selectedProduct: state.selectedProduct,
    countries: state.countries.data,
    branches: state.branches.items,
    governates: state.governates.data,
    delivery_location: state.deliveryLocation.data.area,
    pickup_location: state.deliveryLocation.data.branch,
    delivery_location_governate: state.deliveryLocation.data.governate,
    delivery_mode: state.deliveryMode,
    currency: state.currency,
    multiplier: state.multiplier,
    user: state.user,
    checkoutData: state.checkoutData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // updateShop: () => dispatch(updateShopDetails()),
    // getCategories: () => dispatch(getCategories()),
    setLang: (lang) =>
      dispatch({ type: actionTypes.SET_LANGUAGE, payload: { lang: lang } }),
    setShowProductPopUp: (show_product_popup) =>
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: show_product_popup },
      }),
    resetCart: () => dispatch({ type: actionTypes.RESET_CART, payload: null }),
    set_deliveryLocation: (location) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_LOCATION,
        payload: { location: location },
      }),
    set_deliveryMode: (modeData) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_MODE,
        payload: { modeData },
      }),
    set_tempDeliveryMode: (modeData) =>
      dispatch({
        type: actionTypes.SET_TEMP_DELIVERY_MODE,
        payload: { modeData },
      }),
    addItemToCart: (i, product) =>
      dispatch({
        type: actionTypes.ADD_CART_ITEM,
        payload: { item: product, index: i },
      }),
    updateCart: (product) =>
      dispatch({ type: actionTypes.ADD_CART_ITEM, payload: { item: product } }),
    removeItemFromCart: (i, product) =>
      dispatch({
        type: actionTypes.REMOVE_CART_ITEM,
        payload: { item: product, index: i },
      }),
    reloadCart: (cart) =>
      dispatch({
        type: actionTypes.SET_CART_ON_RELOAD,
        payload: { cart: cart },
      }),
    popItem: (i) =>
      dispatch({
        type: actionTypes.POPITEM,
        payload: { popIndex: i },
      }),
    handleAuthModal: (val) => {
      dispatch({
        type: actionTypes.HANDLE_AUTH_MODAL,
        payload: { showModal: val },
      });
    },
    updateCheckoutData: (checkoutData) => {
      dispatch({
        type: actionTypes.UPDATE_CHECKOUTDATA,
        payload: { checkoutData: checkoutData },
      });
    },
    // setCurrency: (currency) => dispatch({type: actionTypes.SET_CURRENCY, payload: { base_currency: currency}}),
    // setMultiplier: (multiplier) => dispatch({type: actionTypes.SET_MULTIPLIER, payload: { multiplier: multiplier}})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginBeforeForm);
