import { cloneDeep } from "lodash";
import * as actionTypes from "../actions/actions";
import { ENVIRONMENT } from "../../config";

const initialState = {
  data: {
    id: null,
    filter: "sort by",
  },
};

const selectedCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_CATEGORY:
      if (ENVIRONMENT == "development") {
        console.log(state);
      }
      let category = action.payload.category;
      category.filter = "Sort By";
      return {
        data: category,
      };

    case actionTypes.SET_FILTER:
      let cat = cloneDeep(state.data);
      cat.filter = action.payload.filter;
      return {
        data: cat,
      };
  }
  return state;
};

export default selectedCategoryReducer;
