import React, { Component } from "react";
import { Link } from "react-router-dom";
import { BASE_API_URL, ENVIRONMENT } from "../../../config";

import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actions";
export class SideMenu extends Component {
  constructor(props) {
    super(props);
  }

  logout = () => {
    this.props.logout();
  };
  render() {
    let mobile = false;
    if (window.matchMedia("screen and (max-width: 990px)").matches) {
      mobile = true;
    }
    return this.props.user.isLoggedIn ? (
      !mobile ? (
        <>
          <div className="col-lg-3">
            <div className="box_general write_review">
              <h1 className="add_bottom_15">
                {this.props.lang == "en"
                  ? this.props.translations.en["My Account"]
                  : this.props.translations.ar["My Account"]}
              </h1>
              <div className="row">
                <div className="col-md-12 add_bottom_25">
                  <ul className="accoutUl">
                    <li>
                      <Link to="/update-profile">
                        {this.props.lang == "en"
                          ? this.props.translations.en["Update Profile"]
                          : this.props.translations.ar["Update Profile"]}
                      </Link>
                    </li>
                    <li>
                      <Link to="/my-orders">
                        {this.props.lang == "en"
                          ? this.props.translations.en["My Orders"]
                          : this.props.translations.ar["My Orders"]}
                      </Link>
                    </li>
                    <li>
                      <Link to="/my-addresses">
                        {this.props.lang == "en"
                          ? this.props.translations.en["My Addresses"]
                          : this.props.translations.ar["My Addresses"]}
                      </Link>
                    </li>
                    <li>
                      <Link to="/track-order">
                        {this.props.lang == "en"
                          ? this.props.translations.en["Track Order"]
                          : this.props.translations.ar["Track Order"]}
                      </Link>
                    </li>
                    <li>
                      <Link to="/change-password">
                        {this.props.lang == "en"
                          ? this.props.translations.en["Change Password"]
                          : this.props.translations.ar["Change Password"]}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {this.props.user.isLoggedIn ? (
                <a className="btn_1" onClick={this.logout}>
                  {this.props.lang == "en"
                    ? this.props.translations.en["Logout"]
                    : this.props.translations.ar["Logout"]}
                </a>
              ) : null}
            </div>
          </div>
        </>
      ) : null
    ) : null;
  }
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations,
    lang: state.lang.lang,
    products: state.products.data,
    categories: state.categories.items,
    cart: state.cart,
    settings: state.settings.data,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setSelectedProduct: (prod_id) => dispatch(getSelectedProduct(prod_id)),
    setLang: (lang) =>
      dispatch({
        type: actionTypes.SET_LANGUAGE,
        payload: { lang: lang },
      }),
    setSelectedProduct: (prod_id) =>
      dispatch({
        type: actionTypes.SET_SELECTED_PRODUCT,
        payload: { product: prod_id },
      }),
    handleProductPopUp: (val) => {
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: val },
      });
    },
    handleAuthModal: (val) => {
      dispatch({
        type: actionTypes.HANDLE_AUTH_MODAL,
        payload: { showModal: val },
      });
    },
    updateUserData: (val) => {
      dispatch({
        type: actionTypes.UPDATE_USERDATA,
        payload: { userdata: val },
      });
    },
    logout: () => {
      dispatch({
        type: actionTypes.LOGOUT,
        payload: null,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
