import { cloneDeep } from "lodash";
import { secureStorage } from "../../locStore";
import * as actionTypes from "../actions/actions";

const initialState = {
  show_picker: false,
  show: false,
  // selectedDate: `${c.getFullYear()}-${c.getMonth()}-${c.getDate()}T${c.getHours()}:${mins}`,
  selectedDate: null,
  available_slots: [],
  selected_slot: {
    label: null,
    value: null,
  },
  fname: null,
  lname: null,
  email: null,
  phone: null,
  mobile: null,
  country: null,
  area: {
    label: null,
    value: null,
  },
  areaId: {
    label: null,
    value: null,
  },
  block: null,
  street: null,
  avenue: null,
  housetype: "house",
  house_number: null,
  apartment_name: null,
  apartment_floor: null,
  apartment_flat: null,
  office_name: null,
  office_number: null,
  office_floor: null,
  additional_information: null,
  delivery_type: "deliver_now",
  deliver_schedule: null,
  deliver_date: null,
  deliver_time: null,
  delivery_charges: 0,
  coupon: "",
  coupon_err: null,
  discount: 0,
  payment_method: "cod",
  error_state: {
    fname: false,
    lname: false,
    // phone: false,
    mobile: false,
    email: false,
    // country: false,
    area: false,
    // governate: false,
    block: false,
    street: false,
    avenue: false,
    housetype: false,
    // address_line_1: false,
    // city: false,
    // state: false,
    house_number: false,
    office_number: false,
    office_name: false,
    office_floor: false,
    apartment_name: false,
    apartment_flat: false,
    apartment_floor: false,
    // zipcode: false,
    additional_information: false,
    deliver_date: false,
    deliver_time: false,
    payment_method: false,
    selected_address: false,
    branch: false,
    selectedDate: false,
    selectedSlot: false,
  },
  is_loading: true,
  showCartMsg: false,
  paymentMethods: [],
  address_list: [],
  selected_address: null,
  delivery_info: {
    price: 0,
    minimum: 0,
    title: null,
    title_ar: null,
    closed: 0,
    busy: 0,
    delivery_now: "0",
    delivery_time: null,
    preparation_time: null
  },
};

const checkoutDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_CHECKOUTDATA: {
      //   let currentState = cloneDeep(state);
      secureStorage.setItem("checkoutData", action.payload.checkoutData);
      return action.payload.checkoutData;
    }
  }
  return state;
};

export default checkoutDataReducer;
