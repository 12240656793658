import * as actionTypes from "../actions/actions";

const initialState = {
  showModal: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    // case actionTypes.SET_CART_ON_RELOAD:
    //     // set secureStorage to redux cart
    // case actionTypes.ADD_CART_ITEM:
    //     //add cart item and update secureStorage
    // case actionTypes.REMOVE_CART_ITEM:
    //     //remove cart item and update secureStorage
    // case actionTypes.UPDATE_CART_ITEM:
    //     // update cart item and update secureStorage
    case actionTypes.HANDLE_AUTH_MODAL: {
      return {
        showModal: action.payload.showModal,
      };
    }
  }
  return state;
};

export default authReducer;
