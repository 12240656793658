import React, { Component } from "react";

import { Route, Link, Switch, Redirect } from "react-router-dom";
import { BASE_API_URL, ENVIRONMENT } from "../../config";

import Navbar from "../Navbar/Navbar";
import IndexPage from "../IndexPage/IndexPage";

import * as actionTypes from "../../store/actions/actions";
import { connect } from "react-redux";

import { cloneDeep } from "lodash";
import Footer from "../Footer/Footer";

import AfterFoot from "../AfterFoot/AfterFoot";
import About from "../About/About";
import Contact from "../Contact/Contact";
import TrackOrder from "../MyAccount/TrackOrder/TrackOrder";
import CheckoutPage from "../CheckoutPage/CheckoutPage";
import PopupSkeleton from "../ProductModal/PopupSkeleton/PopupSkeleton";
import MyAccount from "../MyAccount/MyAccount";
import UpdateProfile from "../MyAccount/UpdateProfile/UpdateProfile";
import MyOrders from "../MyAccount/MyOrders/MyOrders";
import ChangePassword from "../MyAccount/ChangePassword/ChangePassword";
import MyAddresses from "../MyAccount/MyAddresses/MyAddresses";
import ViewOrder from "../MyAccount/ViewOrder/ViewOrder";
import AddAddress from "../MyAccount/AddAddress/AddAddress";
import EditAddress from "../MyAccount/EditAddress/EditAddress";
import PostOrderPage from "../PostOrderPage/PostOrderPage";
import Thankyou from "../PostOrderPage/Thankyou/Thankyou";
import Failed from "../PostOrderPage/Failed/Failed";
import OrderNotFound from "../PostOrderPage/OrderNotFound/OrderNotFound";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "../TermsAndconditions/TermsAndConditions";
import { callService } from "../../services";
// import Test from "../Test";

class RootPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: true,
    };
  }

  componentDidMount() {
    // let testPromise = this.getPromise(
    //   "https://jsonplaceholder.typicode.com/todos/1"
    // );
    // testPromise.then((respooooo) => console.log(respooooo));
    // callService(`${BASE_API_URL}`)
    //   .then((resp) => resp.json())
    //   .then((respJson) => console.log(respJson))
    //   .catch((err) => console.log(err));
    let settingsPromise = this.getPromise(`${BASE_API_URL}`);
    let homePromise = this.getPromise(`${BASE_API_URL}/products_all`);
    let branchesPromise = this.getPromise(`${BASE_API_URL}/branches`);

    Promise.all([settingsPromise, homePromise, branchesPromise])
      .then((respon) => {
        if (ENVIRONMENT == "development") {
          console.log(respon[0], "this is settings response");
          console.log(respon[1], "this is home response");
          console.log("these are categories", respon[1].categories);
        }
        let settingsValue = this.props.set_settings(respon[0].settings);
        let galleryValue = this.props.set_gallery(respon[0].gallery);
        let categoriesValue = this.props.set_categories(respon[1].categories);
        let productsValue = this.props.set_products(respon[1].products);
        let translationsValue = this.props.set_translations(respon[0].words);
        let governatesValue = this.props.set_governates(respon[0].areas);
        let branchesValue = this.props.set_branches(respon[2]);
        if (respon[2] > 0) {
          this.props.set_pickupLocation(respon[2][0]);
        }
        if (
          settingsValue &&
          categoriesValue &&
          translationsValue &&
          productsValue &&
          governatesValue &&
          branchesValue &&
          galleryValue
          //   governatesValue &&
          //   selectedCategory
        ) {
          let head = document.getElementsByTagName("head");

          let title = document.getElementsByTagName("title")[0];
          let html = document.getElementsByTagName("html")[0];
          // let fav = document.createElement("link")
          let fav = document.getElementById("favicon");
          let theme_color = document.getElementById("theme_color");
          fav.setAttribute("href", respon[0].settings.favicon);
          let dynamicTheme = document.getElementById("dynamicTheme");
          dynamicTheme.innerHTML = `:root{--primary-color: ${respon[0].settings.color1}; --secondary-color: ${respon[0].settings.color2}; --third-color: ${respon[0].settings.color3}; --fourth-color: ${respon[0].settings.color4};}`;
          theme_color.setAttribute("content", respon[0].settings.color1);
          if (this.props.lang == "en") {
            title.innerHTML = respon[0].settings.title;
            html.setAttribute("data-theme", "ltr");
          } else if (this.props.lang == "ar") {
            title.innerHTML = respon[0].settings.title_ar;
            html.setAttribute("data-theme", "rtl");
          }

          return true;
        } else {
          return true;
        }
      })
      .then((value) => {
        if (ENVIRONMENT == "development") {
          console.log("this is loading set: ", value);
        }
        if (value) {
          // document.getElementById("iniloader").remove();
          this.setState((prevState) => {
            return {
              ...prevState,
              isloading: false,
              // isloading: true,
            };
          });
        }
      })
      .then(() => {
        let booter = document.getElementById("boot");
        booter.style.display = "none";
      })
      .catch((err) => {
        if (ENVIRONMENT == "development") {
          console.log(err);
        }
      });
  }

  getPromise = (url) => {
    let prom = callService(url)
      .then((res) => {
        let jso = res.json();
        return jso;
      })
      .catch((err) => {
        if (ENVIRONMENT == "development") {
          console.log(err);
        }
      });
    return prom;
  };

  render() {
    let routeProps = null;
    return this.state.isloading ? null : (
      <>
        <Navbar />
        <Switch>
          <Route
            path="/"
            component={(props) => {
              routeProps = props;
              return <IndexPage {...props}></IndexPage>;
            }}
            exact
          />
          <Route
            path="/about"
            component={(props) => <About {...props}></About>}
            exact
          />
          <Route
            path="/contact"
            component={(props) => <Contact {...props}></Contact>}
            exact
          />

          <Route
            path="/terms-and-conditions"
            component={(props) => (
              <TermsAndConditions {...props}></TermsAndConditions>
            )}
            exact
          />

          <Route
            path="/privacy-policy"
            component={(props) => <PrivacyPolicy {...props}></PrivacyPolicy>}
            exact
          />

          <Route
            path="/track-order"
            component={(props) => <TrackOrder {...props} />}
            exact
          />
          <Route
            path="/checkout"
            component={(props) => <CheckoutPage {...props} />}
            exact
          />

          <Route
            path="/edit-address/:id"
            component={(props) => <EditAddress {...props} />}
            exact
          />

          <Route
            path="/myaccount"
            component={(props) => <MyAccount {...props} />}
            exact
          />

          <Route
            path="/update-profile"
            component={(props) => <UpdateProfile {...props} />}
            exact
          />

          <Route
            path="/my-orders"
            component={(props) => <MyOrders {...props} />}
            exact
          />
          <Route
            path="/view-order/:id"
            component={(props) => <ViewOrder {...props} />}
            exact
          />

          <Route
            path="/my-addresses"
            component={(props) => <MyAddresses {...props} />}
            exact
          />

          <Route
            path="/add-address"
            component={(props) => <AddAddress {...props} />}
            exact
          />

          <Route
            path="/edit-address/:id"
            component={(props) => <EditAddress {...props} />}
            exact
          />

          <Route
            path="/order-success"
            component={(props) => <PostOrderPage {...props} />}
            exact
          />

          <Route
            path="/thankyou/:id"
            component={(props) => <Thankyou {...props} />}
            exact
          />

          <Route
            path="/failed/:id"
            component={(props) => <Failed {...props} />}
            exact
          />

          <Route
            path="/order-not-found/:id"
            component={(props) => <OrderNotFound {...props} />}
            exact
          />

          <Route
            path="/change-password"
            component={(props) => <ChangePassword {...props} />}
            exact
          />

          <Redirect to="/" />
        </Switch>

        {/* Footer */}
        <Footer></Footer>
        {/* <PopupSkeleton></PopupSkeleton> */}
        <AfterFoot {...routeProps}></AfterFoot>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations,
    lang: state.lang.lang,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    set_translations: (translations) =>
      dispatch({
        type: actionTypes.SET_TRANSLATIONS,
        payload: { translations: translations },
      }),
    set_shopdata: (shopdata) =>
      dispatch({
        type: actionTypes.UPDATE_SHOP,
        payload: { shopData: shopdata },
      }),
    set_pickupLocation: (branch) =>
      dispatch({
        type: actionTypes.SET_PICKUP_LOCATION,
        payload: { branch: branch },
      }),
    set_deliveryLocation: (location) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_LOCATION,
        payload: { location: location },
      }),
    set_categories: (categories) =>
      dispatch({
        type: actionTypes.SET_CATEGORIES,
        payload: { cats: categories },
      }),
    set_gallery: (gallery) =>
      dispatch({
        type: actionTypes.SET_GALLERY,
        payload: { gallery: gallery },
      }),
    set_products: (products) =>
      dispatch({
        type: actionTypes.SET_PRODUCTS,
        payload: { products: products },
      }),
    set_banners: (banners) =>
      dispatch({
        type: actionTypes.SET_BANNERS,
        payload: { banners: banners },
      }),
    set_branches: (branches) =>
      dispatch({
        type: actionTypes.SET_BRANCHES,
        payload: { branches: branches },
      }),
    set_settings: (settings) =>
      dispatch({
        type: actionTypes.SET_SETTINGS,
        payload: { settings: settings },
      }),
    set_governates: (governates) =>
      dispatch({
        type: actionTypes.SET_GOVERNATES,
        payload: { governates: governates },
      }),
    set_countries: (countries) =>
      dispatch({
        type: actionTypes.SET_COUNTRIES,
        payload: { countries: countries },
      }),
    set_currencies: (currencies) =>
      dispatch({
        type: actionTypes.SET_CURRENCIES,
        payload: { currencies: currencies },
      }),
    set_selectedCategory: (category) =>
      dispatch({
        type: actionTypes.SET_SELECTED_CATEGORY,
        payload: { category: category },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RootPage);
