import { mode } from "crypto-js";
import { secureStorage } from "../../locStore";
import * as actionTypes from "../actions/actions";

const initialState = {
  type: "delivery",
};

const tempDeliveryModeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TEMP_DELIVERY_MODE:
      let modeData = action.payload.modeData;
      secureStorage.setItem("delivery_mode", {
        ...state,
        ...modeData,
      });
      return {
        ...state,
        ...modeData,
      };
  }
  return state;
};

export default tempDeliveryModeReducer;
