import * as actionTypes from '../actions/actions';



const initialState = {
    show: false
}

const handleSignInModaReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.HANDLE_SIGNIN_MODAL:
            // let countries = action.payload.countries;
            return {
                // show: action.payload.show
                show: !state.show
                
            }

    }
    return state;
}

export default handleSignInModaReducer;