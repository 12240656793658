import React, { Component } from "react";
import { connect } from "react-redux";
import { BASE_API_URL, ENVIRONMENT } from "../../config";

import { secureStorage } from "../../locStore";
import { Link } from "react-router-dom";
import * as actionTypes from "../../store/actions/actions";
import { initialCheckoutData } from "../CheckoutPage/CheckoutPage";
import { callService } from "../../services";
class PostOrderPage extends Component {
  constructor(props) {
    super(props);
    if (ENVIRONMENT == "development") {
      console.log(this.props.match.params.id);
    }
    this.state = {
      data: null,
      loading: true,
    };
    if (ENVIRONMENT == "development") {
      console.log(this.props);
    }
  }

  componentDidMount() {
    if (ENVIRONMENT == "development") {
      console.log("this is did mount");
    }

    if (
      this.props.location.state &&
      parseInt(this.props.location.state.id) > 0
    ) {
      callService(`${BASE_API_URL}/order/${this.props.location.state.id}`)
        .then((resp) => resp.json())
        .then((respJson) => {
          if (ENVIRONMENT == "development") {
            console.log(respJson);
          }
          if (respJson.status == "Success") {
            if (parseInt(respJson.invoice.payment) == 1) {
              this.setState((prevState) => {
                return {
                  ...prevState,
                  data: respJson,
                  loading: false,
                };
              });
              this.props.resetCart();
              secureStorage.setItem("checkoutData", initialCheckoutData);
            } else {
              alert("Order has failed, Please try again");
              this.props.history.push("/checkout");
            }
          } else {
            if (ENVIRONMENT == "development") {
              console.log("status not equal to success");
            }
            // alert("Order has failed, Please try again");
            // this.props.history.push("/");

            if (respJson.status == "Failed") {
              // if (!this.props.location.state.cod) {
              this.props.history.push(
                `/order-not-found/${this.props.location.state.id}`
              );
              // }
            }
          }
        })
        .catch((err) => {
          if (ENVIRONMENT == "development") {
            console.log(err);
          }
        });
    } else {
      // console.log(this.props);
      if (ENVIRONMENT == "development") {
        console.log("came to mount else");
      }

      this.props.history.push("/");
    }
  }

  componentDidUpdate(prevProps) {
    if (ENVIRONMENT == "development") {
      console.log("this is did update");
      console.log(prevProps);
    }
    if (
      this.props.location.state &&
      parseInt(this.props.location.state.id) > 0 &&
      prevProps.location.state.id != this.props.location.state.id
    ) {
      callService(`${BASE_API_URL}/order/${this.props.location.state.id}`)
        .then((resp) => resp.json())
        .then((respJson) => {
          if (ENVIRONMENT == "development") {
            console.log("check payment response");
            console.log(respJson);
          }
          if (respJson.status == "Success") {
            if (respJson.invoice.payment_method == "cod") {
              this.setState((prevState) => {
                return {
                  ...prevState,
                  data: respJson,
                  loading: false,
                };
              });
            } else if (
              parseInt(respJson.invoice.payment) == 1 &&
              respJson.invoice.payment_method != "cod"
            ) {
              this.setState((prevState) => {
                return {
                  ...prevState,
                  data: respJson,
                  loading: false,
                };
              });
            } else {
              if (ENVIRONMENT == "development") {
                console.log("came to update else");
              }
              // this.props.history.push("/");
              alert("Order has failed, Please try again");
              this.props.history.push("/checkout");
            }
          } else {
            if (ENVIRONMENT == "development") {
              console.log("status not equal to success");
            }
            // this.props.history.push("/");
            // alert("Order has failed, Please try again");
            // this.props.history.push("/checkout");
            if (respJson.status == "Failed") {
              alert("order not found");
            } else {
              alert("Order has failed, Please try again");
            }
          }
        })
        .catch((err) => {
          if (ENVIRONMENT == "development") {
            console.log(err);
          }
        });
    } else {
      if (ENVIRONMENT == "development") {
        console.log(this.props);
      }
      // this.props.history.push("/");
    }
  }
  render() {
    let products = [];
    if (!this.state.loading) {
      products = this.state.data.products.map((p, i) => {
        return (
          <tr key={i}>
            <td className="text-center">{i + 1}</td>
            <td className="text-left">
              {p.product_name}
              {/* GR7 
              <br />
              Code : <br />
              &nbsp;&nbsp;&nbsp;&nbsp; */}
            </td>
            <td className="text-center">{p.quantity}</td>
            <td className="text-center">{p.price} KD</td>
            <td className="text-right">{p.total} KD</td>
          </tr>
        );
      });
    }
    return !this.state.loading ? (
      <main className="bg_gray">
        <div className="container margin_60_20">
          <div className="row justify-content-center">
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
              <div className="box_general write_review">
                <h1 className="add_bottom_15" style={{ textAlign: "center" }}>
                  {this.props.lang == "ar" &&
                  this.props.translations.ar["Thank You"] != null
                    ? this.props.translations.ar["Thank You"]
                    : "Thank You"}
                </h1>
                <div className="row">
                  <div className="col-md-6 pull-left">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>
                            {this.props.lang == "ar" &&
                            this.props.translations.ar[
                              "Pickup/Delivery Info"
                            ] != null
                              ? this.props.translations.ar[
                                  "Pickup/Delivery Info"
                                ]
                              : "Pickup/Delivery Info"}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            dangerouslySetInnerHTML={{
                              __html: this.state.data.invoice.address_str,
                            }}
                          >
                            {/* Test T<br />
                        Area: Abbasiya, Block: 1<br />
                        Street: 2, Avenue: 3<br />
                        House: 4<br />
                        Directions: 5<br />
                        Ph: 12345678
                        <br />
                        E: chandu.guttikonda@gmail.com */}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-6 pull-left">
                    <table className="table table-bordered text-center">
                      <thead>
                        <tr>
                          <th>
                            {this.props.lang == "ar" &&
                            this.props.translations.ar["Order Number"] != null
                              ? this.props.translations.ar["Order Number"]
                              : "Order Number"}
                          </th>
                          <th>
                            {this.props.lang == "ar" &&
                            this.props.translations.ar["Payment Method"] != null
                              ? this.props.translations.ar["Payment Method"]
                              : "Payment Method"}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{this.state.data.invoice.id}</td>
                          <td>{this.state.data.invoice.payment_method}</td>
                          {/* <td>Cash on Delivery</td> */}
                        </tr>
                      </tbody>
                    </table>
                    <table className="table table-bordered text-center">
                      <thead>
                        <tr>
                          <th>
                            {this.props.lang == "ar" &&
                            this.props.translations.ar["Order Date"] != null
                              ? this.props.translations.ar["Order Date"]
                              : "Order Date"}
                          </th>
                          <th>
                            {this.props.lang == "ar" &&
                            this.props.translations.ar[
                              "Delivery/Pickup Date"
                            ] != null
                              ? this.props.translations.ar[
                                  "Delivery/Pickup Date"
                                ]
                              : "Delivery/Pickup Date"}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{this.state.data.invoice.now}</td>
                          <td>
                            {this.state.data.invoice.delivery_date}{" "}
                            {this.state.data.invoice.delivery_time}{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <table className="table text-center">
                      <thead>
                        <tr>
                          <th className="text-center">
                            {this.props.lang == "ar" &&
                            this.props.translations.ar["Sr No"] != null
                              ? this.props.translations.ar["Sr No"]
                              : "Sr No"}
                          </th>
                          <th className="text-center">
                            {this.props.lang == "ar" &&
                            this.props.translations.ar["Item Name"] != null
                              ? this.props.translations.ar["Item Name"]
                              : "Item Name"}
                          </th>
                          <th className="text-center">
                            {this.props.lang == "ar" &&
                            this.props.translations.ar["Quantity"] != null
                              ? this.props.translations.ar["Quantity"]
                              : "Quantity"}
                          </th>
                          <th
                            className="text-center"
                            style={{ width: "110px" }}
                          >
                            {this.props.lang == "ar" &&
                            this.props.translations.ar["Unit Price"] != null
                              ? this.props.translations.ar["Unit Price"]
                              : "Unit Price"}
                          </th>
                          <th className="text-right" style={{ width: "110px" }}>
                            {this.props.lang == "ar" &&
                            this.props.translations.ar["Total Price"] != null
                              ? this.props.translations.ar["Total Price"]
                              : "Total Price"}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {products}
                        <tr>
                          <td colSpan="4" className="al_ri">
                            {this.props.lang == "ar" &&
                            this.props.translations.ar["Discount"] != null
                              ? this.props.translations.ar["Discount"]
                              : "Discount"}{" "}
                            (-)
                          </td>
                          <td className="al_ri">
                            {this.state.data.invoice.discount} KD
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="4" className="al_ri">
                            {this.props.lang == "ar" &&
                            this.props.translations.ar["Delivery Charges"] !=
                              null
                              ? this.props.translations.ar["Delivery Charges"]
                              : "Delivery Charges"}{" "}
                            (+)
                          </td>
                          <td className="al_ri">
                            {this.state.data.invoice.delivery_charges} KD
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="4" className="al_ri">
                            {this.props.lang == "ar" &&
                            this.props.translations.ar["Grand Total"] != null
                              ? this.props.translations.ar["Grand Total"]
                              : "Grand Total"}
                          </td>
                          <td className="al_ri">
                            {this.state.data.invoice.total_price} KD
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <Link to={`/checkout`}>
                      <button className="btn_1">
                        {this.props.lang == "ar" &&
                        this.props.translations.ar["Go To Home"] != null
                          ? this.props.translations.ar["Go To Home"]
                          : "Go To Home"}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3"></div>
          </div>
        </div>
      </main>
    ) : null;
  }
}

const mapStateToProps = (state) => {
  return {
    // shop: state.shop,
    lang: state.lang.lang,
    settings: state.settings.data,
    shop: state.shop,
    cart: state.cart,
    lang: state.lang.lang,
    translations: state.translations,
    showSignInModal: state.handleSignInModal.show,
    selectedProduct: state.selectedProduct,
    countries: state.countries.data,
    branches: state.branches.items,
    governates: state.governates.data,
    delivery_location: state.deliveryLocation.data.area,
    delivery_location_governate: state.deliveryLocation.data.governate,
    currency: state.currency,
    multiplier: state.multiplier,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // updateShop: () => dispatch(updateShopDetails()),
    // getCategories: () => dispatch(getCategories()),
    setLang: (lang) =>
      dispatch({ type: actionTypes.SET_LANGUAGE, payload: { lang: lang } }),
    setShowProductPopUp: (show_product_popup) =>
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: show_product_popup },
      }),
    resetCart: () => dispatch({ type: actionTypes.RESET_CART, payload: null }),
    set_deliveryLocation: (location) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_LOCATION,
        payload: { location: location },
      }),
    addItemToCart: (i, product) =>
      dispatch({
        type: actionTypes.ADD_CART_ITEM,
        payload: { item: product, index: i },
      }),
    updateCart: (product) =>
      dispatch({ type: actionTypes.ADD_CART_ITEM, payload: { item: product } }),
    removeItemFromCart: (i, product) =>
      dispatch({
        type: actionTypes.REMOVE_CART_ITEM,
        payload: { item: product, index: i },
      }),
    reloadCart: (cart) =>
      dispatch({
        type: actionTypes.SET_CART_ON_RELOAD,
        payload: { cart: cart },
      }),
    popItem: (i) =>
      dispatch({
        type: actionTypes.POPITEM,
        payload: { popIndex: i },
      }),
    // setCurrency: (currency) => dispatch({type: actionTypes.SET_CURRENCY, payload: { base_currency: currency}}),
    // setMultiplier: (multiplier) => dispatch({type: actionTypes.SET_MULTIPLIER, payload: { multiplier: multiplier}})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostOrderPage);
