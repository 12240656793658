import * as actionTypes from '../actions/actions';
import {secureStorage} from '../../locStore';

const initialState = {
    base_currency: "KWD"
}

const currencyReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_CURRENCY:
            secureStorage.setItem('base_currency', action.payload.base_currency);
           return {
               base_currency: action.payload.base_currency
           }
    }
    return state;
}

export default currencyReducer;