import React, { Component } from "react";
import * as actionTypes from "../../store/actions/actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import DeliveryMode from "./DeliveryMode";
import InstructionsPopup from "../InstructionsPopup/InstructionsPopup";
import { ENVIRONMENT } from "../../config";
class CartSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMobileCart: false,
      showIns: false,
      selectedProduct: null,
    };
    // console.log(this.props);
  }

  closeModal = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        selectedProduct: null,
        showIns: false,
      };
    });
  };

  handleMobileCart = () => {
    if (ENVIRONMENT == "development") {
      console.log("mobile cart handled");
    }
    this.setState((prevState) => {
      return {
        ...prevState,
        showMobileCart: !prevState.showMobileCart,
      };
    });
  };

  showDeliveryModal = (val) => {
    this.props.set_tempDeliveryMode({
      type:
        this.props.delivery_mode.type == "delivery" &&
        this.props.settings.pickup == "1"
          ? "pickup"
          : "delivery",
      // scheduleDate:
    });
    this.setState((prevState) => {
      return {
        ...prevState,
        show: val,
      };
    });
  };

  showFirstTimeDeliveryModel = (val) => {
    this.props.set_tempDeliveryMode({
      type: "delivery",
      // scheduleDate:
    });
    this.setState((prevState) => {
      return {
        ...prevState,
        show: val,
      };
    });
  };

  // decreaseQty = (product, index) => {
  //   let currentProduct = cloneDeep(product);

  //   console.log(index);
  //   this.props.removeItemFromCart(currentProduct, index);
  // };

  increaseQty = (i, p) => {
    if (p.addons.length > 0) {
      if (ENVIRONMENT == "development") {
        console.log("addons exist for this product - increasing");
      }
      let currentProduct = cloneDeep(p);
      let qty = currentProduct.qty + 1;
      currentProduct.qty = qty;
      currentProduct["calculated_total"] =
        parseInt(currentProduct.qty) *
        parseFloat(currentProduct.calculated_price);
      // if (!qty < 0) {
      this.props.addItemToCart(i, currentProduct);
    } else if (p.addons.length <= 0) {
      if (ENVIRONMENT == "development") {
        console.log("no addons for this product");
      }
      let currentProduct = cloneDeep(p);
      let qty = currentProduct.qty + 1;
      currentProduct.qty = qty;
      currentProduct["calculated_total"] =
        parseInt(currentProduct.qty) *
        parseFloat(currentProduct.calculated_price);
      // if (!qty < 0) {
      this.props.addItemToCart(null, currentProduct);
      // } else {
      //   this.props.removeItemFromCart(null, currentProduct);
      // }
      // }
    }
    // this.addToCart();
  };

  decreaseQty = (i, p) => {
    if (p.addons.length > 0) {
      if (ENVIRONMENT == "development") {
        console.log("addons exist for this product-, decreasing");
      }
      let currentProduct = cloneDeep(p);
      let qty = currentProduct.qty - 1;
      currentProduct.qty = qty;
      // currentProduct["calculated_price"] = parseFloat(p.price).toFixed(3);
      currentProduct["calculated_total"] =
        parseInt(currentProduct.qty) *
        parseFloat(currentProduct.calculated_price);
      if (qty <= 0) {
        this.props.removeItemFromCart(i, currentProduct);
      } else if (qty > 0) {
        this.props.addItemToCart(i, currentProduct);
      }
    } else if (p.addons.length <= 0) {
      if (ENVIRONMENT == "development") {
        console.log("no addons for this product");
      }
      let currentProduct = cloneDeep(p);
      let qty = currentProduct.qty - 1;
      currentProduct.qty = qty;
      // currentProduct["calculated_price"] = parseFloat(p.price).toFixed(3);
      currentProduct["calculated_total"] =
        parseInt(currentProduct.qty) *
        parseFloat(currentProduct.calculated_price);
      if (qty <= 0) {
        this.props.removeItemFromCart(i, currentProduct);
      } else if (qty > 0) {
        this.props.addItemToCart(null, currentProduct);
      }
    }

    // this.addToCart();
  };

  deleteProduct = (i, product) => {
    if (ENVIRONMENT == "development") {
      console.log(i);
    }
    this.props.removeItemFromCart(i, product);
  };

  showInstructionPopup = (id) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        showIns: true,
        selectedProduct: id,
      };
    });
  };

  render() {
    let cartList = [];
    cartList = this.props.cart.items.map((item, i) => {
      let addons = [];
      // if (Array.isArray(item.addons)) {
      //   if (item.addons.length > 0) {
      //     addons = item.addons.map((addon, ind) => {
      //       let addon_items = [];
      //       let items_length = addon.items.length;
      //       addon_items = addon.items.map((addon_item, index) => {
      //         if (index != items_length - 1) {
      //           return this.props.lang == "en"
      //             ? `${addon_item.title},`
      //             : `${addon_item.title_ar},`;
      //         } else {
      //           return this.props.lang == "en"
      //             ? `${addon_item.title},`
      //             : `${addon_item.title_ar}, `;
      //         }
      //       });
      //       return this.props.lang == "en" ? (
      //         <>
      //           <span style={{ width: "100%" }}>
      //             {/* <b style={{ color: "#767171" }}>{`${addon.title}`}</b> :{" "} */}
      //             {`${addon_items}`}
      //           </span>
      //           <br />
      //         </>
      //       ) : (
      //         <>
      //           <span style={{ width: "100%" }}>
      //             {/* <b style={{ color: "#767171" }}>{`${addon.title}`}</b> :{" "} */}
      //             {`${addon_items}`}
      //           </span>
      //           <br />
      //         </>
      //       );
      //     });
      //   }
      // }
      // console.log(addons);

      let addons_ar = [];
      item.addons.map((addon, ind) => {
        addon.items.map((addon_item, index) => {
          addons.push(addon_item.title);
          addons_ar.push(addon_item.title_ar);
        });
      });
      return (
        <li key={i}>
          <div
            className="sidecart_qtyChanger"
            style={{
              display: "inline",
              border: "none",
              borderRadius: "5px",
              // marginLeft: "5px",
              backgroundColor: "#ece8e8",
              // color: "red",
              color: "var(--primary-color)",
              // padding: "5px 3px",
              padding: "2px 8px",
            }}
          >
            {item.qty > 1 ? (
              <span
                className="dec button_inc"
                style={{
                  marginLeft: "5px",
                  marginRight: "3px",
                  // float: "left"
                  width: "12px",
                  cursor: "pointer",
                }}
                onClick={() => this.decreaseQty(i, item)}
              >
                -
              </span>
            ) : null}
            {item.qty <= 1 ? (
              <i
                className="deleter fas fa-trash"
                style={{
                  marginLeft: "5px",
                  marginRight: "3px",
                  // float: "left"
                  cursor: "pointer",
                }}
                onClick={() => this.decreaseQty(i, item)}
              ></i>
            ) : null}
            <span
              style={{
                marginLeft: "3px",
                marginRight: "3px",
                // float: "left",
                midWidth: "17px",
                width: "17px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {item.qty}
            </span>
            {item.quantity_check == "1" && item.qty < item.quantity ? (
              <span
                className="inc button_inc"
                style={{
                  marginLeft: "3px",
                  marginRight: "5px",
                  cursor: "pointer",
                  //  float: "right"
                }}
                onClick={() => this.increaseQty(i, item)}
              >
                +
              </span>
            ) : item.quantity_check == "0" ? (
              <span
                className="inc button_inc"
                style={{
                  marginLeft: "3px",
                  marginRight: "5px",
                  cursor: "pointer",
                  //  float: "right"
                }}
                onClick={() => this.increaseQty(i, item)}
              >
                +
              </span>
            ) : (
              <span
                className="inc button_inc"
                style={{
                  marginLeft: "3px",
                  marginRight: "5px",
                  cursor: "pointer",
                  //  float: "right"
                  color: "grey",
                }}
                // onClick={() => this.increaseQty(i, item)}
              >
                +
              </span>
            )}
          </div>
          <a className="product_title" style={{ maxWidth: "200px" }}>
            {this.props.lang == "en"
              ? // <p>{item.title}</p>
                item.title
              : // <p>{item.title_ar}</p>
                item.title_ar}
            <small
              style={{
                display: "block",
                textOverflow: "ellipsis",
                msTextOverflow: "ellipsis",

                whiteSpace: "nowrap",
                width: "100%",
                float: "left",
                overflow: "hidden",
              }}
            >
              {this.props.lang == "en" ? addons.join(",") : addons_ar.join(",")}
            </small>
            <i
              className="fa fa-edit"
              onClick={() => {
                this.showInstructionPopup(i);
              }}
            ></i>
            {/* <br />
              
              <i className="product_title">{addons}</i> */}
          </a>
          <span>{parseFloat(item.calculated_total).toFixed(3)}</span>
        </li>
      );
    });

    let items = this.props.cart.items.map((item, index) => {
      if (ENVIRONMENT == "development") {
        console.log(index + "item");
      }
      return (
        // <li key={index}><img src="img/favicon.ico" onClick={() => this.decreaseQty(item, index)}/><a>{item.qty}x {this.props.lang == "en" ? item.translations.en.title: item.translations.ar.title}</a><span>KWD {item.calculated_total.toFixed(3)}</span></li>
        // <li key={index}>
        //     <div><i aria-hidden="true" className="icon_close_alt2" onClick={() => this.decreaseQty(item, index)} style={{color: 'red', cursor: 'pointer'}} ></i>{item.qty} x {this.props.lang == "en" ? item.translations.en.title: item.translations.ar.title} </div>
        //     <div>{(item.calculated_total * this.props.multiplier.multiplier).toFixed(3)}</div>
        // </li>
        <li className="listOs" key={index}>
          <div className="leftOs">
            {/* <i
              aria-hidden="true"
              className="icon_close_alt2"
              onClick={() => this.decreaseQty(item, index)}
              style={{ color: "red", cursor: "pointer" }}
            ></i> */}
            <div
              style={{
                display: "inline",
                border: "none",
                borderRadius: "5px",
                marginLeft: "5px",
                backgroundColor: "#ece8e8",
                color: "red",
                padding: "5px 3px",
              }}
            >
              <span
                className="dec button_inc"
                style={{ marginLeft: "5px", marginRight: "3px" }}
                onClick={() => this.decreaseQty(index, item)}
              >
                -
              </span>
              <span style={{ marginLeft: "3px", marginRight: "3px" }}>
                {this.state.qty > 0 ? this.state.qty : 0}
              </span>
              <span
                className="inc button_inc"
                style={{ marginLeft: "3px", marginRight: "5px" }}
                onClick={() => this.increaseQty(index, item)}
              >
                +
              </span>
            </div>
            {item.qty} x {this.props.lang == "en" ? item.title : item.title_ar}{" "}
          </div>
          <div className="rightOs">{item.calculated_total.toFixed(3)}</div>
        </li>
      );
    });

    return (
      <div className="col-lg-4" id="sidebar_fixed">
        <div className="box_order mobile_fixed stickyCart">
          <div className="head">
            <h3>
              {this.props.lang == "en"
                ? this.props.translations.en["Order Summary"]
                : this.props.translations.ar["Order Summary"]}
            </h3>
            <a href="#0" className="close_panel_mobile">
              <i className="icon_close"></i>
            </a>
          </div>
          <div className="main sidecart_main">
            {cartList.length > 0 ? (
              <>
                <ul className="clearfix">{cartList}</ul>
                {this.props.user.isLoggedIn ? (
                  <>
                    {this.props.delivery_mode.type == "delivery" ||
                    this.props.delivery_mode.type == "delivery_later" ? (
                      <Link
                        to="/checkout"
                        className="btn_1 gradient full-width mb_5"
                      >
                        {this.props.lang == "en"
                          ? this.props.translations.en["Order Now"]
                          : this.props.translations.ar["Order Now"]}{" "}
                        - KWD{" "}
                        {parseFloat(this.props.cart.calculated_total).toFixed(
                          3
                        )}
                      </Link>
                    ) : null}
                    {this.props.delivery_mode.type == "pickup" ? (
                      <Link
                        to="/checkout"
                        className="btn_1 gradient full-width mb_5"
                      >
                        {this.props.lang == "en"
                          ? this.props.translations.en["Order Now"]
                          : this.props.translations.ar["Order Now"]}{" "}
                        - KWD{" "}
                        {parseFloat(this.props.cart.calculated_total).toFixed(
                          3
                        )}
                      </Link>
                    ) : null}
                  </>
                ) : null}

                {!this.props.user.isLoggedIn ? (
                  <>
                    <>
                      {this.props.delivery_mode.type == "delivery" ||
                      this.props.delivery_mode.type == "delivery_later" ? (
                        !this.props.delivery_location ? (
                          <a
                            className="btn_1 gradient full-width mb_5"
                            onClick={() =>
                              this.showFirstTimeDeliveryModel(true)
                            }
                          >
                            {this.props.lang == "en"
                              ? this.props.translations.en["Start Ordering"]
                              : this.props.translations.ar[
                                  "Start Ordering"
                                ]}{" "}
                            - KWD{" "}
                            {parseFloat(
                              this.props.cart.calculated_total
                            ).toFixed(3)}
                          </a>
                        ) : (
                          <Link
                            to="/checkout"
                            className="btn_1 gradient full-width mb_5"
                          >
                            {this.props.lang == "en"
                              ? this.props.translations.en["Order Now"]
                              : this.props.translations.ar["Order Now"]}{" "}
                            - KWD{" "}
                            {parseFloat(
                              this.props.cart.calculated_total
                            ).toFixed(3)}
                          </Link>
                        )
                      ) : null}
                    </>

                    <>
                      {this.props.delivery_mode.type == "pickup" ? (
                        !this.props.pickup_location ? (
                          <a
                            className="btn_1 gradient full-width mb_5"
                            onClick={() => this.showDeliveryModal("delivery")}
                          >
                            {this.props.lang == "en"
                              ? this.props.translations.en["Start Ordering"]
                              : this.props.translations.ar[
                                  "Start Ordering"
                                ]}{" "}
                            - KWD{" "}
                            {parseFloat(
                              this.props.cart.calculated_total
                            ).toFixed(3)}
                          </a>
                        ) : (
                          <Link
                            to="/checkout"
                            className="btn_1 gradient full-width mb_5"
                          >
                            {this.props.lang == "en"
                              ? this.props.translations.en["Order Now"]
                              : this.props.translations.ar["Order Now"]}{" "}
                            - KWD{" "}
                            {parseFloat(
                              this.props.cart.calculated_total
                            ).toFixed(3)}
                          </Link>
                        )
                      ) : null}
                    </>

                    {this.props.delivery_mode.type == "delivery" ||
                    this.props.delivery_mode.type == "delivery_later" ? (
                      this.props.delivery_location ? (
                        this.props.settings.pickup == "1" ? (
                          <a
                            className="btn_1 gradient full-width mb_5"
                            onClick={() => this.showDeliveryModal(true)}
                          >
                            {this.props.lang == "ar" &&
                            this.props.translations.ar["Switch To Pickup"]
                              ? this.props.translations.ar["Switch To Pickup"]
                              : "Switch To Pickup"}{" "}
                          </a>
                        ) : null
                      ) : null
                    ) : null}

                    {this.props.delivery_mode.type == "pickup" ? (
                      this.props.pickup_location ? (
                        <a
                          className="btn_1 gradient full-width mb_5"
                          onClick={() => this.showDeliveryModal(true)}
                        >
                         {this.props.lang == "ar" &&
                  this.props.translations.ar["Switch To Delivery"] != null
                    ? this.props.translations.ar["Switch To Delivery"]
                    : "Switch To Delivery"}
                            {" "}
                        </a>
                      ) : null
                    ) : null}

                    {this.props.delivery_location ? (
                      // <Fragment style={{ backgroundColor: "black" }}>
                      <div style={{ width: "100%", textAlign: "center", direction:"ltr" }}>
                        <span>
                          <b>{`
                      ${
                        this.props.lang == "en"
                          ? this.props.delivery_location.title
                          : this.props.delivery_location.title_ar
                      } -  KWD ${this.props.delivery_location.price} - ${
                            this.props.lang == "en"
                              ? this.props.checkoutData.delivery_info.title
                              : this.props.checkoutData.delivery_info.title_ar
                          }`}</b>
                        </span>
                      </div>
                    ) : null}
                    {this.props.pickup_location ? (
                      <div style={{ width: "100%", textAlign: "center", direction:"ltr" }}>
                        <span>
                          <b>{`Pickup Location - ${
                            this.props.lang == "en"
                              ? this.props.pickup_location.title
                              : this.props.pickup_location.title_ar
                          }`}</b>
                        </span>
                      </div>
                    ) : null}
                  </>
                ) : null}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  // height: "350px",
                  flexDirection: "column",
                  // marginTop: "100px",
                }}
              >
                <img src="/img/checkout_empty.jpg" width="80%"></img>

                <p
                  style={{
                    // marginBottom: "50px",
                    // marginTop: "50px",
                    backgroundColor: "var(--primary-color)",
                    color: "white",
                    padding: "10px",
                  }}
                >
                  {this.props.lang == "ar" &&
                  this.props.translations.ar["ADD ITEMS TO CHECKOUT"] != null
                    ? this.props.translations.ar["ADD ITEMS TO CHECKOUT"]
                    : "ADD ITEMS TO CHECKOUT"}
                </p>
              </div>
            )}
          </div>
        </div>
        <>
          {this.state.showMobileCart ? (
            <div
              className="box_order mobile_fixed"
              style={{ display: "block" }}
            >
              <div className="head">
                <h3>                  {this.props.lang == "ar" &&
                  this.props.translations.ar["Order Summary"] != null
                    ? this.props.translations.ar["Order Summary"]
                    : "Order Summary"}</h3>
                <a className="close_panel_mobile">
                  <i className="icon_close" onClick={this.handleMobileCart}></i>
                </a>
              </div>

              <div className="main">
                <ul className="clearfix">{cartList}</ul>

                <div className="btn_1_mobile">
                  {this.props.user.isLoggedIn ? (
                    <>
                      {this.props.delivery_mode.type == "delivery" ||
                      this.props.delivery_mode.type == "delivery_later" ? (
                        <Link
                          to="/checkout"
                          className="btn_1 gradient full-width mb_5"
                        >
                          {this.props.lang == "en"
                            ? this.props.translations.en["Order Now"]
                            : this.props.translations.ar["Order Now"]}{" "}
                          - KWD{" "}
                          {parseFloat(this.props.cart.calculated_total).toFixed(
                            3
                          )}
                        </Link>
                      ) : null}
                      {this.props.delivery_mode.type == "pickup" ? (
                        <Link
                          to="/checkout"
                          className="btn_1 gradient full-width mb_5"
                        >
                          {this.props.lang == "en"
                            ? this.props.translations.en["Order Now"]
                            : this.props.translations.ar["Order Now"]}{" "}
                          - KWD{" "}
                          {parseFloat(this.props.cart.calculated_total).toFixed(
                            3
                          )}
                        </Link>
                      ) : null}
                    </>
                  ) : null}

                  {!this.props.user.isLoggedIn ? (
                    <>
                      <>
                        {this.props.delivery_mode.type == "delivery" ||
                        this.props.delivery_mode.type == "delivery_later" ? (
                          !this.props.delivery_location ? (
                            <a
                              className="btn_1 gradient full-width mb_5"
                              onClick={() => this.showDeliveryModal(true)}
                            >
                              {this.props.lang == "en"
                                ? this.props.translations.en["Start Ordering"]
                                : this.props.translations.ar[
                                    "Start Ordering"
                                  ]}{" "}
                              - KWD{" "}
                              {parseFloat(
                                this.props.cart.calculated_total
                              ).toFixed(3)}
                            </a>
                          ) : (
                            <Link
                              to="/checkout"
                              className="btn_1 gradient full-width mb_5"
                            >
                              {this.props.lang == "en"
                                ? this.props.translations.en["Order Now"]
                                : this.props.translations.ar["Order Now"]}{" "}
                              - KWD{" "}
                              {parseFloat(
                                this.props.cart.calculated_total
                              ).toFixed(3)}
                            </Link>
                          )
                        ) : null}
                      </>
                      <>
                        {this.props.delivery_mode.type == "pickup" ? (
                          !this.props.pickup_location ? (
                            <a
                              className="btn_1 gradient full-width mb_5"
                              onClick={() => this.showDeliveryModal(true)}
                            >
                              {this.props.lang == "en"
                                ? this.props.translations.en["Start Ordering"]
                                : this.props.translations.ar[
                                    "Start Ordering"
                                  ]}{" "}
                              - KWD{" "}
                              {parseFloat(
                                this.props.cart.calculated_total
                              ).toFixed(3)}
                            </a>
                          ) : (
                            <Link
                              to="/checkout"
                              className="btn_1 gradient full-width mb_5"
                            >
                              {this.props.lang == "en"
                                ? this.props.translations.en["Order Now"]
                                : this.props.translations.ar["Order Now"]}{" "}
                              - KWD{" "}
                              {parseFloat(
                                this.props.cart.calculated_total
                              ).toFixed(3)}
                            </Link>
                          )
                        ) : null}
                      </>
                      {this.props.delivery_mode.type == "delivery" ||
                      this.props.delivery_mode.type == "delivery_later" ? (
                        this.props.delivery_location ? (
                          this.props.settings.pickup == "1" ? (
                            <a
                              className="btn_1 gradient full-width mb_5"
                              onClick={() => this.showDeliveryModal(true)}
                            >
                              
                              {this.props.lang == "ar" &&
                            this.props.translations.ar["Switch To Pickup"]
                              ? this.props.translations.ar["Switch To Pickup"]
                              : "Switch To Pickup"}{" "}
                            </a>
                          ) : null
                        ) : null
                      ) : null}

                      {this.props.delivery_mode.type == "pickup" ? (
                        this.props.pickup_location ? (
                          <a
                            className="btn_1 gradient full-width mb_5"
                            onClick={() => this.showDeliveryModal(true)}
                          >
                            
                            {this.props.lang == "ar" &&
                  this.props.translations.ar["Switch To Delivery"] != null
                    ? this.props.translations.ar["Switch To Delivery"]
                    : "Switch To Delivery"}
                            {" "}
                          </a>
                        ) : null
                      ) : null}
                    </>
                  ) : null}

                  {this.props.delivery_location ? (
                    // <Fragment style={{ backgroundColor: "black" }}>
                    <div style={{ width: "100%", textAlign: "center", direction:"ltr" }}>
                      <span>
                        <b>{`
                      ${
                        this.props.lang == "en"
                          ? this.props.delivery_location.title
                          : this.props.delivery_location.title_ar
                      } -  KWD ${this.props.delivery_location.price} - ${
                          this.props.lang == "en"
                            ? this.props.checkoutData.delivery_info.title
                            : this.props.checkoutData.delivery_info.title_ar
                        }`}</b>
                      </span>
                    </div>
                  ) : null}
                  {this.props.pickup_location ? (
                    <div style={{ width: "100%", textAlign: "center", direction:"ltr" }}>
                      <span>
                        <b>{`Pickup Location - ${
                          this.props.lang == "en"
                            ? this.props.pickup_location.title
                            : this.props.pickup_location.title_ar
                        }`}</b>
                      </span>
                    </div>
                  ) : null}
                  <div className="text-center">
                    <small>
                      {this.props.lang == "en"
                        ? this.props.translations.en[
                            "No money charged on this steps"
                          ]
                        : this.props.translations.ar[
                            "No money charged on this steps"
                          ]}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </>
        <>
          {this.props.cart.items.length > 0 ? (
            <div className="btn_reserve_fixed">
              <a
                onClick={this.handleMobileCart}
                className="btn_1 gradient full-width"
              >
                {this.props.lang == "en"
                  ? this.props.translations.en["View Basket"]
                  : this.props.translations.ar["View Basket"]}
                {` ( ${this.props.cart.items.length} - KWD ${parseFloat(
                  this.props.cart.calculated_total
                ).toFixed(3)} )`}
              </a>
            </div>
          ) : null}
        </>

        <DeliveryMode
          show={this.state.show}
          setShow={this.showDeliveryModal}
        ></DeliveryMode>
        {this.state.showIns ? (
          <InstructionsPopup
            selectedProduct={this.state.selectedProduct}
            showIns={this.state.showIns}
            closeModal={this.closeModal}
          ></InstructionsPopup>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations,
    lang: state.lang.lang,
    products: state.products.data,
    categories: state.categories.items,
    settings: state.settings.data,
    cart: state.cart,
    delivery_location: state.deliveryLocation.data.area,
    pickup_location: state.pickupLocation.data.branch,
    delivery_location_governate: state.deliveryLocation.data.governate,
    delivery_mode: state.deliveryMode,
    user: state.user,
    checkoutData: state.checkoutData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    set_translations: (translations) =>
      dispatch({
        type: actionTypes.SET_TRANSLATIONS,
        payload: { translations: translations },
      }),
    set_shopdata: (shopdata) =>
      dispatch({
        type: actionTypes.UPDATE_SHOP,
        payload: { shopData: shopdata },
      }),
    set_categories: (categories) =>
      dispatch({
        type: actionTypes.SET_CATEGORIES,
        payload: { cats: categories },
      }),
    set_products: (products) =>
      dispatch({
        type: actionTypes.SET_PRODUCTS,
        payload: { products: products },
      }),
    set_banners: (banners) =>
      dispatch({
        type: actionTypes.SET_BANNERS,
        payload: { banners: banners },
      }),
    set_branches: (branches) =>
      dispatch({
        type: actionTypes.SET_BRANCHES,
        payload: { branches: branches },
      }),
    set_settings: (settings) =>
      dispatch({
        type: actionTypes.SET_SETTINGS,
        payload: { settings: settings },
      }),
    set_governates: (governates) =>
      dispatch({
        type: actionTypes.SET_GOVERNATES,
        payload: { governates: governates },
      }),
    set_countries: (countries) =>
      dispatch({
        type: actionTypes.SET_COUNTRIES,
        payload: { countries: countries },
      }),
    set_currencies: (currencies) =>
      dispatch({
        type: actionTypes.SET_CURRENCIES,
        payload: { currencies: currencies },
      }),
    set_selectedCategory: (category) =>
      dispatch({
        type: actionTypes.SET_SELECTED_CATEGORY,
        payload: { category: category },
      }),
    set_tempDeliveryMode: (modeData) =>
      dispatch({
        type: actionTypes.SET_TEMP_DELIVERY_MODE,
        payload: { modeData },
      }),
    addItemToCart: (i, product) =>
      dispatch({
        type: actionTypes.ADD_CART_ITEM,
        payload: { item: product, index: i },
      }),
    updateCart: (product) =>
      dispatch({ type: actionTypes.ADD_CART_ITEM, payload: { item: product } }),
    removeItemFromCart: (i, product) =>
      dispatch({
        type: actionTypes.REMOVE_CART_ITEM,
        payload: { item: product, index: i },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartSidebar);
