import React, { Component, Fragment } from "react";
import { BASE_API_URL, ENVIRONMENT } from "../../../config";
import { secureStorage } from "../../../locStore";
import { cloneDeep } from "lodash";
import Select from "react-select";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actions";
import PaymentMethods from "../PaymentMethods/PaymentMethods";
import ScheduleForm from "../ScheduleForm/ScheduleForm";
import ErrorPop from "../ErrorPop/ErrorPop";
import InstructionsPopup from "../../InstructionsPopup/InstructionsPopup";
import { callService } from "../../../services";

class CheckoutCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      error_msg: "",
      showIns: false,
      selectedProduct: null,
    };
  }

  componentDidMount() {
    if (ENVIRONMENT == "development") {
      console.log(this.props.history, "---------------history props");
    }
  }

  setDeliveryMode = (val) => {
    // let currentState = cloneDeep(this.props.checkoutData);
    this.props.set_deliveryMode({
      type: val,
    });
    if (val == "pickup") {
      let checkoutData = cloneDeep(this.props.checkoutData);
      checkoutData.delivery_info = {
        minimum: 0,
        title: null,
        title_ar: null,
        busy: 0,
        closed: 0,
        price: 0,
      };
      this.props.set_deliveryLocation({ governate: null, area: null });
      // this.props.set_pickupLocation(null);

      this.props.updateCheckoutData(checkoutData);
    } else if (val == "delivery" || val == "delivery_later") {
      this.props.set_pickupLocation(null);
    }
  };

  checkCoupon = () => {
    if (ENVIRONMENT == "development") {
      console.log(
        `${BASE_API_URL}/coupon?coupon=${this.props.checkoutData.coupon}&amount=${this.props.cart.calculated_total}`
      );
    }
    callService(
      `${BASE_API_URL}/coupon?coupon=${this.props.checkoutData.coupon}&amount=${this.props.cart.calculated_total}`,
      "POST",
      {},
      {
        coupon: this.props.checkoutData.coupon,
        amount: this.props.cart.calculated_total,
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((resp) => {
        if (ENVIRONMENT == "development") {
          console.log(resp);
        }
        if (resp.status == "Success") {
          let currentState = cloneDeep(this.props.checkoutData);
          currentState.discount = parseFloat(resp.coupon_value).toFixed(3);
          currentState.coupon_err = null;
          this.props.updateCheckoutData(currentState);
        } else if (resp.status == "Failed") {
          let currentState = cloneDeep(this.props.checkoutData);
          currentState.discount = 0;
          currentState.coupon_err = resp.message;
          this.props.updateCheckoutData(currentState);
        }
      })
      .catch((err) => {
        if (ENVIRONMENT == "development") {
          console.log(err);
        }
      });
  };

  increaseQty = (i, p) => {
    if (p.addons.length > 0) {
      if (ENVIRONMENT == "development") {
        console.log("addons exist for this product - increasing");
      }
      let currentProduct = cloneDeep(p);
      let qty = currentProduct.qty + 1;
      currentProduct.qty = qty;
      currentProduct["calculated_total"] =
        parseInt(currentProduct.qty) *
        parseFloat(currentProduct.calculated_price);
      // if (!qty < 0) {
      this.props.addItemToCart(i, currentProduct);
    } else if (p.addons.length <= 0) {
      if (ENVIRONMENT == "development") {
        console.log("no addons for this product");
      }
      let currentProduct = cloneDeep(p);
      let qty = currentProduct.qty + 1;
      currentProduct.qty = qty;
      currentProduct["calculated_total"] =
        parseInt(currentProduct.qty) *
        parseFloat(currentProduct.calculated_price);
      // if (!qty < 0) {
      this.props.addItemToCart(null, currentProduct);
      // } else {
      //   this.props.removeItemFromCart(null, currentProduct);
      // }
      // }
    }
    // this.addToCart();
  };

  decreaseQty = (i, p) => {
    if (p.addons.length > 0) {
      if (ENVIRONMENT == "development") {
        console.log("addons exist for this product-, decreasing");
      }
      let currentProduct = cloneDeep(p);
      let qty = currentProduct.qty - 1;
      currentProduct.qty = qty;
      // currentProduct["calculated_price"] = parseFloat(p.price).toFixed(3);
      currentProduct["calculated_total"] =
        parseInt(currentProduct.qty) *
        parseFloat(currentProduct.calculated_price);
      if (qty <= 0) {
        this.props.removeItemFromCart(i, currentProduct);
      } else if (qty > 0) {
        this.props.addItemToCart(i, currentProduct);
      }
    } else if (p.addons.length <= 0) {
      if (ENVIRONMENT == "development") {
        console.log("no addons for this product");
      }
      let currentProduct = cloneDeep(p);
      let qty = currentProduct.qty - 1;
      currentProduct.qty = qty;
      // currentProduct["calculated_price"] = parseFloat(p.price).toFixed(3);
      currentProduct["calculated_total"] =
        parseInt(currentProduct.qty) *
        parseFloat(currentProduct.calculated_price);
      if (qty <= 0) {
        this.props.removeItemFromCart(i, currentProduct);
      } else if (qty > 0) {
        this.props.addItemToCart(null, currentProduct);
      }
    }

    // this.addToCart();
  };

  orderCheckout = () => {
    document.getElementById("orderButton").disabled = true;

    // let checkoutData = this.props.checkoutData;
    let checkUrl = `${BASE_API_URL}/cart_check`;
    if (this.props.user.isLoggedIn) {
      if (!this.props.checkoutData.selected_address) {
        this.setState((prevState) => {
          return {
            ...prevState,
            show: true,
            error_msg: `Please Select Your Address to proceed`,
          };
        });
        return;
      }

      if (
        this.props.delivery_mode.type == "delivery" ||
        this.props.delivery_mode.type == "delivery_later"
      ) {
        if (this.props.checkoutData.selected_address) {
          checkUrl = `${BASE_API_URL}/cart_check/${this.props.checkoutData.selected_address}`;
        } else {
          let currentState = cloneDeep(this.props.checkoutData);
          currentState.error_state.selected_address = true;
          currentState.error_state.area = false;
          currentState.error_state.branch = false;
          this.props.updateCheckoutData(currentState);
          document.getElementById("orderButton").disabled = false;
          return;
        }
      } else if (this.props.delivery_mode.type == "pickup") {
        if (this.props.pickup_location) {
          // checkUrl = `${BASE_API_URL}/cart_check/${this.props.pickup_location.id}`;
          checkUrl = `${BASE_API_URL}/cart_check/${this.props.pickup_location.id}`;
        } else {
          let currentState = cloneDeep(this.props.checkoutData);
          currentState.error_state.selected_address = false;
          currentState.error_state.area = false;
          currentState.error_state.branch = true;
          this.props.updateCheckoutData(currentState);
          document.getElementById("orderButton").disabled = false;
          return;
        }
      }
    } else {
      if (
        this.props.delivery_mode.type == "delivery" ||
        this.props.delivery_mode.type == "delivery_later"
      ) {
        if (this.props.delivery_location) {
          checkUrl = `${BASE_API_URL}/cart_check/${this.props.delivery_location.id}`;
        } else {
          let currentState = cloneDeep(this.props.checkoutData);
          currentState.error_state.area = true;
          currentState.error_state.selected_address = false;
          currentState.error_state.branch = false;
          this.props.updateCheckoutData(currentState);
          document.getElementById("orderButton").disabled = false;
          return;
        }
      } else if (this.props.delivery_mode.type == "pickup") {
        if (this.props.pickup_location) {
          // checkUrl = `${BASE_API_URL}/cart_check/${this.props.pickup_location.id}`;
          checkUrl = `${BASE_API_URL}/cart_check`;
        } else {
          if (ENVIRONMENT == "development") {
            console.log(this.props.pickup_location);
            console.log("executing pick not exist");
          }
          let currentState = cloneDeep(this.props.checkoutData);
          if (ENVIRONMENT == "development") {
            console.log("setting branch true");
          }

          currentState.error_state.branch = true;
          currentState.error_state.area = false;
          currentState.error_state.selected_address = false;
          this.props.updateCheckoutData(currentState);
          document.getElementById("orderButton").disabled = false;
          return;
        }
      }
    }

    let url_checks = [];

    callService(
      checkUrl,
      "POST",
      {},
      {
        products: this.props.cart.items,
      }
    )
      .then((resp) => {
        return resp.json();
      })
      .then((respJson) => {
        if (ENVIRONMENT == "development") {
          console.log(respJson);
        }
        let calculated_total = 0;
        let proceedOrder = true;
        respJson.products.map((product) => {
          if (ENVIRONMENT == "development") {
            console.log(product);
          }
          // if (product.cu_status == 1) {
          calculated_total += parseFloat(product.calculated_total);
          // }
          if (product.cu_status == 0) {
            proceedOrder = false;
          }
        });

        let cart = {
          calculated_total: calculated_total,
          items: respJson.products,
        };
        secureStorage.setItem("cart", {
          calculated_total: calculated_total,
          items: respJson.products,
        });
        this.props.reloadCart(cart);
        if (this.props.checkoutData) {
          console.log(cart, "cart reloaded");
        }
        // if (
        //   this.props.delivery_mode.type == "delivery" ||
        //   this.props.delivery_mode.type == "delivery_later"
        // ) {
        //   if (
        //     parseFloat(cart.calculated_total) <
        //     parseFloat(this.props.checkoutData.delivery_info.minimum)
        //   ) {
        //     proceedOrder = false;
        //     console.log("order total is less than minimum order amount");
        //   } else {
        //     console.log("order amount is satisfied");
        //   }
        // }

        // if (
        //   this.props.checkoutData.delivery_info.busy == 1 ||
        //   this.props.checkoutData.delivery_info.closed == 1
        // ) {
        //   proceedOrder = false;
        //   console.log("Shop is Busy/Closed");
        // } else {
        //   console.log("order amount is satisfied");
        // }

        // return; ---------this is stopping point
        if (proceedOrder) {
          if (ENVIRONMENT == "development") {
            console.log("placing order");
          }
          let products = this.props.cart.items;
          let error_state = {
            fname: false,
            lname: false,
            phone: false,
            mobile: false,
            email: false,
            country: false,
            area: false,
            block: false,
            street: false,
            avenue: false,
            housetype: false,
            house_number: false,
            office_number: false,
            office_name: false,
            office_floor: false,
            apartment_name: false,
            apartment_flat: false,
            apartment_floor: false,
            additional_information: false,
            deliver_date: false,
            deliver_time: false,
            payment_method: this.props.checkoutData.payment_method
              ? false
              : true,
            selected_address: false,
            branch: false,
            selectedDate: false,
            selectedSlot: false,
          };

          let jsonData = {
            first_name: this.props.checkoutData.fname
              ? this.props.checkoutData.fname
              : "",
            last_name: this.props.checkoutData.lname
              ? this.props.checkoutData.lname
              : "",
            email: this.props.checkoutData.email
              ? this.props.checkoutData.email
              : "",
            phone: this.props.checkoutData.mobile
              ? this.props.checkoutData.mobile
              : "",
            country: this.props.checkoutData.country
              ? this.props.checkoutData.country
              : "",
            area: this.props.delivery_location
              ? this.props.delivery_location
              : "",
            block: this.props.checkoutData.block
              ? this.props.checkoutData.block
              : "",
            street: this.props.checkoutData.street
              ? this.props.checkoutData.street
              : "",
            avenue: this.props.checkoutData.avenue
              ? this.props.checkoutData.avenue
              : "",
            housetype: this.props.checkoutData.housetype
              ? this.props.checkoutData.housetype
              : "house",
            directions: this.props.checkoutData.additional_information
              ? this.props.checkoutData.additional_information
              : "",
            payment_method: this.props.checkoutData.payment_method
              ? this.props.checkoutData.payment_method
              : "",
            delivery_type: this.props.delivery_mode.type
              ? this.props.delivery_mode.type
              : "delivery",
            // order_type: this.props.checkoutData.
            // payment_type: this.props.checkoutData.payment_type,
            // delivery_timing: '2',
            // delivery_date: '',
            // delivery_time: '',
            cart_value: parseFloat(this.props.cart.calculated_total).toFixed(3),
            coupon_type: "",
            coupon_value: parseFloat(this.props.checkoutData.discount).toFixed(
              3
            ),
            coupon_code: this.props.checkoutData.coupon
              ? this.props.checkoutData.coupon
              : "",
            discount: parseFloat(this.props.checkoutData.discount).toFixed(3),
            delivery_charges: parseFloat(
              this.props.checkoutData.delivery_info.price
            ).toFixed(3),
            order_total_: parseFloat(
              parseFloat(this.props.cart.calculated_total).toFixed(3) +
                parseFloat(this.props.checkoutData.delivery_info.price).toFixed(
                  3
                ) -
                parseFloat(this.props.checkoutData.discount).toFixed(3)
            ).toFixed(3),
            member_id: this.props.user.isLoggedIn
              ? this.props.user.userdata.id
              : "-1",
            selected_address: this.props.checkoutData.selected_address
              ? this.props.checkoutData.selected_address
              : "",
            products: products,
            checkout_type: this.props.user.isLoggedIn ? "user" : "guest",
            branch_id: this.props.pickup_location
              ? this.props.pickup_location.id
              : "",
            // payment_type: this.props.checkoutData.pay
            // conversion_currency_code: "Rupees",
            // conversion_rate: 245
          };

          // delivery date and time setting

          if (
            this.props.delivery_mode.type == "delivery_later" ||
            this.props.delivery_mode.type == "pickup"
          ) {
            jsonData["delivery_date"] = this.props.checkoutData.deliver_date
              ? this.props.checkoutData.deliver_date
              : "";
            jsonData["delivery_time"] = this.props.checkoutData.deliver_time
              ? this.props.checkoutData.deliver_time
              : "";

            jsonData["selected_slot"] = this.props.delivery_mode.selectedDate
              ? this.props.delivery_mode.selectedDate
              : "";

            error_state.deliver_date = this.props.delivery_mode.selectedDate
              ? false
              : true;
            error_state.deliver_time = this.props.delivery_mode.selectedSlot
              ? false
              : true;
          } else {
            jsonData["delivery_date"] = "";
            jsonData["delivery_time"] = "";

            jsonData["selected_slot"] = "";
          }

          // Data Setting
          if (this.props.user.isLoggedIn) {
            // if login true

            if (
              this.props.delivery_mode.type == "delivery" ||
              this.props.delivery_mode.type == "delivery_later"
            ) {
              if (this.props.checkoutData.selected_address) {
                jsonData["selected_address"] = this.props.checkoutData
                  .selected_address
                  ? this.props.checkoutData.selected_address
                  : null;
              } else {
                error_state.selected_address = true;
              }
            } else if (this.props.delivery_mode.type == "pickup") {
            }
          } else {
            // if login false

            if (
              this.props.delivery_mode.type == "delivery" ||
              this.props.delivery_mode.type == "delivery_later"
            ) {
              // delivery date and time setting

              // house type data setting
              if (this.props.checkoutData.housetype === "house") {
                jsonData["house_number"] = this.props.checkoutData.house_number
                  ? this.props.checkoutData.house_number
                  : null;
                jsonData["house"] = this.props.checkoutData.house_number
                  ? this.props.checkoutData.house_number
                  : null;
                jsonData["office_number"] = "";
                jsonData["office_name"] = "";
                jsonData["office_floor"] = "";
                jsonData["apartment_name"] = "";
                jsonData["apartment_flat"] = "";
                jsonData["apartment_floor"] = "";
                error_state.house_number = this.props.checkoutData.house_number
                  ? false
                  : true;
              } else if (this.props.checkoutData.housetype === "apartment") {
                jsonData["house_number"] = "";
                jsonData["office_number"] = "";
                jsonData["office_name"] = "";
                jsonData["office_floor"] = "";
                jsonData["apartment_name"] = this.props.checkoutData
                  .apartment_name
                  ? this.props.checkoutData.apartment_name
                  : null;
                jsonData["apartment_floor"] = this.props.checkoutData
                  .apartment_floor
                  ? this.props.checkoutData.apartment_floor
                  : null;
                jsonData["apartment_flat"] = this.props.checkoutData
                  .apartment_flat
                  ? this.props.checkoutData.apartment_flat
                  : null;

                jsonData["house"] = this.props.checkoutData.apartment_name
                  ? this.props.checkoutData.apartment_name
                  : null;
                jsonData["floor"] = this.props.checkoutData.apartment_floor
                  ? this.props.checkoutData.apartment_floor
                  : null;
                jsonData["flat"] = this.props.checkoutData.apartment_flat
                  ? this.props.checkoutData.apartment_flat
                  : null;
                error_state.apartment_name = this.props.checkoutData
                  .apartment_name
                  ? false
                  : true;
                error_state.apartment_floor = this.props.checkoutData
                  .apartment_floor
                  ? false
                  : true;
                error_state.apartment_flat = this.props.checkoutData
                  .apartment_flat
                  ? false
                  : true;
              } else if (this.props.checkoutData.housetype === "office") {
                jsonData["house_number"] = "";
                jsonData["office_number"] = this.props.checkoutData
                  .office_number
                  ? this.props.checkoutData.office_number
                  : null;
                jsonData["office_name"] = this.props.checkoutData.office_name
                  ? this.props.checkoutData.office_name
                  : null;
                jsonData["office_floor"] = this.props.checkoutData.office_floor
                  ? this.props.checkoutData.office_floor
                  : null;
                jsonData["flat"] = this.props.checkoutData.office_number
                  ? this.props.checkoutData.office_number
                  : null;
                jsonData["house"] = this.props.checkoutData.office_name
                  ? this.props.checkoutData.office_name
                  : null;
                jsonData["floor"] = this.props.checkoutData.office_floor
                  ? this.props.checkoutData.office_floor
                  : null;
                jsonData["apartment_name"] = "";
                jsonData["apartment_floor"] = "";
                jsonData["apartment_flat"] = "";
                error_state.office_name = this.props.checkoutData.office_name
                  ? false
                  : true;
                error_state.office_number = this.props.checkoutData
                  .office_number
                  ? false
                  : true;
                error_state.office_floor = this.props.checkoutData.office_floor
                  ? false
                  : true;
              } else {
                jsonData["house_number"] = "";
                jsonData["office_number"] = "";
                jsonData["office_name"] = "";
                jsonData["office_floor"] = "";
                jsonData["apartment_name"] = "";
                jsonData["apartment_floor"] = "";
                jsonData["apartment_flat"] = "";

                jsonData["house"] = "";
                jsonData["floor"] = "";
                jsonData["flat"] = "";
              }

              // delivery area data setting
              if (this.props.delivery_location) {
                jsonData["area"] = this.props.delivery_location
                  ? this.props.delivery_location
                  : null;

                jsonData["delivery_charges"] = parseFloat(
                  this.props.checkoutData.delivery_info.price
                ).toFixed(3);
              } else {
                error_state.area = true;
              }
            } else if (this.props.delivery_mode.type == "pickup") {
            }
          }

          let canSubmitOrder = true;

          // if (!this.props.user.isLoggedIn) {
          // for (const prope in jsonData) {

          //   console.log();
          //   if (jsonData[prope] === null || jsonData[prope] === undefined) {
          //     console.log(
          //       "null/undefined value is ",
          //       prope,
          //       "----",
          //       jsonData[prope]
          //     );
          //     canSubmitOrder = false;
          //   }

          // }

          let beforeLoginCheckoutFields = [
            "first_name",
            "last_name",
            "email",
            "phone",
            "area",
            "block",
            "street",
          ];

          let beforeLoginPickupFields = [
            "first_name",
            "last_name",
            "email",
            "phone",
          ];

          let afterLoginCheckoutFields = ["selected_address"];

          if (this.props.user.isLoggedIn) {
            if (
              this.props.delivery_mode.type == "delivery" ||
              this.props.delivery_mode.type == "delivery_later"
            ) {
              if (ENVIRONMENT == "development") {
                console.log("executing after login");
              }
              afterLoginCheckoutFields.map((field, i) => {
                if (!jsonData[field]) {
                  if (ENVIRONMENT == "development") {
                    console.log(
                      "null/undefined value is ",
                      field,
                      "----",
                      jsonData[field]
                    );
                  }
                  canSubmitOrder = false;

                  error_state[field] = true;
                }
              });
            } else {
            }
          } else {
            if (
              this.props.delivery_mode.type == "delivery" ||
              this.props.delivery_mode.type == "delivery_later"
            ) {
              beforeLoginCheckoutFields.map((field, i) => {
                if (!jsonData[field]) {
                  if (ENVIRONMENT == "development") {
                    console.log(
                      "null/undefined value is ",
                      field,
                      "----",
                      jsonData[field]
                    );
                  }
                  canSubmitOrder = false;
                  if (field == "first_name") {
                    error_state.fname = true;
                  } else if (field == "last_name") {
                    error_state.lname = true;
                  } else if (field == "phone") {
                    error_state.mobile = true;
                  } else {
                    error_state[field] = true;
                  }
                }
              });
            } else {
              beforeLoginPickupFields.map((field, i) => {
                if (!jsonData[field]) {
                  if (ENVIRONMENT == "development") {
                    console.log(
                      "null/undefined value is ",
                      field,
                      "----",
                      jsonData[field]
                    );
                  }
                  canSubmitOrder = false;
                  if (field == "first_name") {
                    error_state.fname = true;
                  } else if (field == "last_name") {
                    error_state.lname = true;
                  } else if (field == "phone") {
                    error_state.mobile = true;
                  } else {
                    error_state[field] = true;
                  }
                }
              });
            }
          }

          if (
            this.props.delivery_mode.type == "pickup" ||
            this.props.delivery_mode.type == "delivery_later"
          ) {
            if (!this.props.delivery_mode.selectedDate) {
              error_state.selectedDate = true;
            }
            if (!this.props.delivery_mode.selectedSlot) {
              error_state.selectedSlot = true;
            }
          }

          for (const ervar in error_state) {
            if (error_state[ervar]) {
              canSubmitOrder = false;
            }
          }

          jsonData["order_total"] = parseFloat(
            parseFloat(this.props.cart.calculated_total) +
              parseFloat(this.props.checkoutData.delivery_info.price) -
              parseFloat(this.props.checkoutData.discount)
          ).toFixed(3);
          if (ENVIRONMENT == "development") {
            console.log(jsonData, "new DAta");
          }
          let jsonAppender = JSON.stringify(jsonData);
          if (ENVIRONMENT == "development") {
            console.log(jsonAppender);
            console.log(error_state);
          }
          let currentCheckoutData = cloneDeep(this.props.checkoutData);
          currentCheckoutData.error_state = {
            ...currentCheckoutData.error_state,
            ...error_state,
          };
          this.props.updateCheckoutData(currentCheckoutData);
          if (canSubmitOrder) {
            if (ENVIRONMENT == "development") {
              console.log("order can be submitted");
            }
            callService(
              `${BASE_API_URL}/checkout`,
              "POST",
              {},
              JSON.parse(jsonAppender)
            )
              .then((resp) => {
                return resp.json();
              })
              .then((resJson) => {
                if (ENVIRONMENT == "development") {
                  console.log("-----------------return data----------------");
                  console.log(resJson);
                }

                // console.log(this.state.payment_method);
                // alert(resJson.status);
                if (resJson.status == "Success") {
                  // return;
                  if (this.props.checkoutData.payment_method == "cod") {
                    if (ENVIRONMENT == "development") {
                      console.log(resJson.invoice_id);
                    }
                    this.props.resetCart();

                    this.props.history.push(
                      `/thankyou/${resJson.invoice_id}`
                      // {
                      //   cod: true,
                      // }
                    );
                  } else {
                    if (ENVIRONMENT == "development") {
                      console.log("this is payment order");
                      console.log(resJson);
                    }
                    callService(
                      `${BASE_API_URL}/payment`,
                      "POST",
                      {},
                      {
                        invoice_id: resJson.invoice_id,
                      }
                    )
                      .then((payRes) => payRes.json())
                      .then((payResJson) => {
                        if (ENVIRONMENT == "development") {
                          console.log(payResJson);
                        }
                        if (payResJson.status == "1") {
                          document.getElementById(
                            "orderButton"
                          ).disabled = false;
                         //alert(payResJson.message);
                          window.location = payResJson.message;
                        } else {
                          document.getElementById(
                            "orderButton"
                          ).disabled = false;
                          alert(payResJson.message);
                        }
                      })
                      .catch((err) => {
                        if (ENVIRONMENT == "development") {
                          console.log(err);
                        }
                        document.getElementById("orderButton").disabled = false;
                      });
                  }
                } else {
                  if (ENVIRONMENT == "development") {
                    console.log("order failed, Please try again");
                  }

                  this.setState(
                    (prevState) => {
                      return {
                        ...prevState,
                        error_msg: resJson.message,
                        show: true,
                      };
                    },
                    () => {
                      document.getElementById("orderButton").disabled = false;
                    }
                  );
                }
                // this.props.history.push(`/order-success/${resJson.id}`, {
                //   order_total: jsonData["order_total"],
                //   order_id: resJson.id,
                // });
              })
              .catch((err) => {
                if (ENVIRONMENT == "development") {
                  console.log(err);
                }
                alert("Order failed");
                document.getElementById("orderButton").disabled = false;
              });
          } else {
            // alert("Please provide all the required fields");
            document.getElementById("orderButton").disabled = false;

            let curr_state = cloneDeep(this.state);
            this.setState((prevState) => {
              return {
                ...prevState,
                error_state: {
                  ...error_state,
                },
              };
            });

            return;
          }
        } else {
        }

        // replace redux cart and local storage with latest products data
        // product not available ui and delete button
        // order button should be disabled if out of stock products not deleted
      })
      .catch((err) => {
        if (ENVIRONMENT == "development") {
          console.log(err);
        }
        document.getElementById("orderButton").disabled = false;
        return;
      });
  };

  closeModal = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        selectedProduct: null,
        showIns: false,
      };
    });
  };

  closeErrPop = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        show: false,
      };
    });
  };

  showInstructionPopup = (id) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        showIns: true,
        selectedProduct: id,
      };
    });
  };

  render() {
    let deliveryModes = {};

    let deliveryModeOptions = [];
    if (this.props.checkoutData.delivery_info.delivery_now != "0") {
      deliveryModes["delivery"] = {
        label:
          this.props.lang == "ar" &&
          this.props.translations.ar["Deliver Now"] != null
            ? this.props.translations.ar["Deliver Now"]
            : "Deliver Now",
        value: "delivery",
      };
      deliveryModeOptions.push({
        label:
          this.props.lang == "ar" &&
          this.props.translations.ar["Deliver Now"] != null
            ? this.props.translations.ar["Deliver Now"]
            : "Deliver Now",
        value: "delivery",
      });
    }
    deliveryModes["delivery_later"] = {
      label:
        this.props.lang == "ar" &&
        this.props.translations.ar["Deliver Later"] != null
          ? this.props.translations.ar["Deliver Later"]
          : "Deliver Later",
      value: "delivery_later",
    };
    deliveryModeOptions.push({
      label:
        this.props.lang == "ar" &&
        this.props.translations.ar["Deliver Later"] != null
          ? this.props.translations.ar["Deliver Later"]
          : "Deliver Later",
      value: "delivery_later",
    });

    if (this.props.settings.pickup == "1") {
      deliveryModes.pickup = {
        label:
          this.props.lang == "ar" &&
          this.props.translations.ar["Pick Up"] != null
            ? this.props.translations.ar["Pick Up"]
            : "Pick Up",
        value: "pickup",
      };
      deliveryModeOptions.push({
        label:
          this.props.lang == "ar" &&
          this.props.translations.ar["Pick Up"] != null
            ? this.props.translations.ar["Pick Up"]
            : "Pick Up",
        value: "pickup",
      });
    }

    let cartList = [];
    let proceedOrder = true;
    cartList = this.props.cart.items.map((item, i) => {
      if (item.cu_status == 0) {
        proceedOrder = false;
      }

      let addons = [];

      let addons_ar = [];
      item.addons.map((addon, ind) => {
        addon.items.map((addon_item, index) => {
          addons.push(addon_item.title);
          addons_ar.push(addon_item.title_ar);
        });
      });
      return (
        <Fragment key={i}>
          <li
            key={i}
            style={
              addons.length > 0
                ? { position: "relative", marginBottom: "0px" }
                : { position: "relative" }
            }
          >
            {!item.cu_status == 0 ? (
              <div
                className="sidecart_qtyChanger"
                style={{
                  display: "inline",
                  border: "none",
                  borderRadius: "5px",
                  // marginLeft: "5px",
                  backgroundColor: "#ece8e8",
                  // color: "red",
                  color: "var(--primary-color)",
                  // padding: "5px 3px",
                  padding: "2px 5px",
                  // float: "left",
                }}
              >
                {item.qty > 1 ? (
                  <span
                    className="dec button_inc"
                    style={{
                      marginLeft: "5px",
                      marginRight: "3px",
                      // float: "left"
                      width: "12px",
                      cursor: "pointer",
                    }}
                    onClick={() => this.decreaseQty(i, item)}
                  >
                    -
                  </span>
                ) : null}
                {item.qty <= 1 ? (
                  <i
                    className="deleter fas fa-trash"
                    style={{
                      marginLeft: "5px",
                      marginRight: "3px",
                      // float: "left"
                      cursor: "pointer",
                    }}
                    onClick={() => this.decreaseQty(i, item)}
                  ></i>
                ) : null}
                <span
                  style={{
                    marginLeft: "3px",
                    marginRight: "3px",
                    // float: "left",
                    midWidth: "17px",
                    width: "17px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {item.qty}
                </span>
                {item.quantity_check == "1" &&
                item.qty < parseInt(item.quantity) ? (
                  <span
                    className="inc button_inc"
                    style={{
                      marginLeft: "3px",
                      marginRight: "5px",
                      cursor: "pointer",
                      //  float: "right"
                    }}
                    onClick={() => this.increaseQty(i, item)}
                  >
                    +
                  </span>
                ) : item.quantity_check == "0" ? (
                  <span
                    className="inc button_inc"
                    style={{
                      marginLeft: "3px",
                      marginRight: "5px",
                      cursor: "pointer",
                      //  float: "right"
                    }}
                    onClick={() => this.increaseQty(i, item)}
                  >
                    +
                  </span>
                ) : (
                  <span
                    className="inc button_inc"
                    style={{
                      marginLeft: "3px",
                      marginRight: "5px",
                      cursor: "pointer",
                      //  float: "right"
                      color: "grey",
                    }}
                    // onClick={() => this.increaseQty(i, item)}
                  >
                    +
                  </span>
                )}
              </div>
            ) : (
              <></>
            )}
            {!item.cu_status == 0 ? (
              // <a>
              //   {item.qty} x{" "}
              //   {this.props.lang == "en" ? item.title : item.title_ar}
              // </a>
              <a className="product_title" style={{ maxWidth: "200px" }}>
                {this.props.lang == "en"
                  ? // <p>{item.title}</p>
                    item.title
                  : // <p>{item.title_ar}</p>
                    item.title_ar}
                <small
                  style={{
                    display: "block",
                    textOverflow: "ellipsis",
                    msTextOverflow: "ellipsis",

                    whiteSpace: "nowrap",
                    width: "100%",
                    float: "left",
                    overflow: "hidden",
                  }}
                >
                  {this.props.lang == "en"
                    ? addons.join(",")
                    : addons_ar.join(",")}
                </small>
                <i
                  className="fa fa-edit"
                  onClick={() => {
                    this.showInstructionPopup(i);
                  }}
                ></i>
                {/* <br /> */}
                {/* <i className="product_title">{addons}</i> */}
              </a>
            ) : (
              // <a className="cartItemTitle">
              //   {item.qty} x{" "}
              //   {this.props.lang == "en" ? item.title : item.title_ar}
              // </a>
              <a
                className="cartItemTitle product_title"
                style={{ maxWidth: "200px" }}
              >
                {this.props.lang == "en"
                  ? // <p>{item.title}</p>
                    item.title
                  : // <p>{item.title_ar}</p>
                    item.title_ar}
                <small style={{ display: "block" }}>
                  {this.props.lang == "en"
                    ? addons.join(",")
                    : addons_ar.join(",")}
                </small>
                {/* <br /> */}
                {/* <i className="product_title">{addons}</i> */}
              </a>
            )}
            {!item.cu_status == 0 ? (
              <span>{parseFloat(item.calculated_total).toFixed(3)}</span>
            ) : (
              <></>
            )}

            {item.cu_status == 0 ? (
              <>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    width: "100%",
                    height: "100%",
                    // backgroundColor: "rgb(197 35 35 / 62%)",
                  }}
                >
                  <i
                    className="deleter fas fa-trash"
                    style={{
                      // marginLeft: "5px",
                      // marginRight: "3px",
                      // float: "left"
                      cursor: "pointer",
                      // color: "red",

                      color: "var(--primary-color)",
                      fontSize: "18px",
                      width: "75px",
                      textAlign: "center",
                    }}
                    onClick={() => this.props.popItem(i)}
                  ></i>

                  <p className="outOfStock">Out Of Stock</p>
                </div>
                {/* <p style={{ textAlign: "center", color: "white" }}>
                Out Of Stock | Remove
              </p> */}
              </>
            ) : (
              <></>
            )}
          </li>

          {/* {item.cu_status == 0 ? (
            <p style={{ textAlign: "center" }}>
              Out Of Stock
              <i
                className="deleter fas fa-trash"
                style={{
                  marginLeft: "5px",
                  marginRight: "3px",
                  // float: "left"
                  cursor: "pointer",
                  color: "red",
                }}
                onClick={() => this.props.popItem(i)}
              ></i>
            </p>
          ) : (
            <></>
          )} */}
          {/* {addons.length > 0 ? (
            <li
              style={{
                marginLeft: "87px",
                overflow: "hidden",
                marginRight: "30px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                fontSize: "10px",
              }}
            >
              {this.props.lang == "en" ? addons.join(",") : addons_ar.join(",")}
            </li>
          ) : null} */}
        </Fragment>
      );
    });
    return (
      <div className="col-xl-4 col-lg-4 checkoutCartList" id="sidebar_fixed">
        <div className="box_order">
          <div className="head">
            <h3>
              {this.props.lang == "en"
                ? this.props.translations.en["Order Summary"]
                : this.props.translations.ar["Order Summary"]}
            </h3>
            {/* <div>{this.props.lang == "en" ? this.props.translations.en["Address Details"] : this.props.translations.ar["Address Details"]}Pizzeria da Alfredo</div> */}
          </div>

          <div className="main">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                marginTop: "20px",
              }}
            >
              <div style={{ flex: 1.5, display: "flex", alignItems: "center" }}>
                <span>
                  <label>
                    {this.props.lang == "en"
                      ? this.props.translations.en["Delivery Type"]
                      : this.props.translations.ar["Delivery Type"]}{" "}
                    :{" "}
                  </label>
                </span>
              </div>
              <div style={{ flex: 2 }}>
                <Select
                  onChange={(e) => this.setDeliveryMode(e.value)}
                  placeholder={
                    this.props.lang === "en"
                      ? this.props.translations.en["Select Area"]
                      : this.props.translations.ar["Select Area"]
                  }
                  className="custom-select js-example-basic-single"
                  id="inputGroupSelect04"
                  options={deliveryModeOptions}
                  value={deliveryModes[this.props.delivery_mode.type]}
                ></Select>
              </div>
            </div>

            {this.props.delivery_mode.type == "delivery" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                  marginTop: "20px",
                }}
              >
                <div
                  style={{ flex: 1.5, display: "flex", alignItems: "center" }}
                >
                  <span>
                    <label>
                      {this.props.lang == "en"
                        ? this.props.translations.en["Delivery Time"]
                        : this.props.translations.ar["Delivery Time"]}{" "}
                      :{" "}
                    </label>
                  </span>
                </div>
                <div style={{ flex: 2 }}>
                  <i>{this.props.checkoutData.delivery_info.delivery_time}</i>
                </div>
              </div>
            ) : null}

            {this.props.delivery_mode.type == "delivery_later" ||
            this.props.delivery_mode.type == "pickup" ? (
              <ScheduleForm
                setDataVariable={this.props.setDataVariable}
              ></ScheduleForm>
            ) : null}
            <hr />
            <ul className="clearfix">
              {this.state.is_loading ? (
                <>
                  <div
                    className="skelCatItem animate"
                    style={{
                      height: "5px",
                      width: "70%",
                      alignSelf: "flex-start",
                    }}
                  ></div>
                </>
              ) : (
                cartList
              )}
            </ul>

            <div className="form-group">
              <label>
                {this.props.lang == "en"
                  ? this.props.translations.en["Have Coupon Code ?"]
                  : this.props.translations.ar["Have Coupon Code ?"]}
              </label>
              <input
                className="form-control"
                placeholder={
                  this.props.lang == "en"
                    ? this.props.translations.en["Enter Coupon Code"]
                    : this.props.translations.ar["Enter Coupon Code"]
                }
                value={this.props.checkoutData.coupon}
                onChange={(e) => {
                  this.props.setDataVariable("coupon", e.currentTarget.value);
                }}
              />
              <p style={{ color: "red" }}>
                {this.props.checkoutData.coupon_err !== null &&
                this.props.checkoutData.coupon_err !== undefined
                  ? this.props.checkoutData.coupon_err
                  : null}
              </p>
              <button
                className="form-control"
                onClick={() => {
                  this.checkCoupon();
                }}
                style={{ marginTop: "15px" }}
              >
                {this.props.lang == "en"
                  ? this.props.translations.en["Check Coupon"]
                  : this.props.translations.ar["Check Coupon"]}
              </button>
            </div>

            <ul className="clearfix">
              <li>
                {this.props.lang == "en"
                  ? this.props.translations.en["Subtotal"]
                  : this.props.translations.ar["Subtotal"]}
                <span>
                  {this.props.currency.base_currency}{" "}
                  {parseFloat(
                    this.props.cart.calculated_total *
                      this.props.multiplier.multiplier
                  ).toFixed(3)}
                </span>
              </li>
              {this.props.delivery_mode.type === "delivery_later" ||
              this.props.delivery_mode.type == "delivery" ? (
                <li>
                  {this.props.lang == "en"
                    ? this.props.translations.en["Delivery Fee"]
                    : this.props.translations.ar["Delivery Fee"]}{" "}
                  <span>
                    {this.props.currency.base_currency}{" "}
                    {/* {parseFloat(this.state.delivery_charges).toFixed(3)} */}
                    {parseFloat(
                      this.props.checkoutData.delivery_info.price
                    ).toFixed(3)}
                  </span>
                </li>
              ) : null}
              {this.props.checkoutData.discount > 0 ? (
                <li>
                  {this.props.lang == "en"
                    ? this.props.translations.en["Discount"]
                    : this.props.translations.ar["Discount"]}
                  <span>
                    {this.props.currency.base_currency}{" "}
                    {parseFloat(
                      this.props.checkoutData.discount *
                        this.props.multiplier.multiplier
                    ).toFixed(3)}
                  </span>
                </li>
              ) : null}
              <li className="total">
                {this.props.lang == "en"
                  ? this.props.translations.en["Total"]
                  : this.props.translations.ar["Total"]}
                <span>
                  {this.props.currency.base_currency}{" "}
                  {this.props.delivery_mode.type == "delivery" ||
                  this.props.delivery_mode.type == "delivery_later"
                    ? (
                        (parseFloat(this.props.cart.calculated_total) +
                          parseFloat(
                            this.props.checkoutData.delivery_info.price
                          ) -
                          parseFloat(this.props.checkoutData.discount)) *
                        this.props.multiplier.multiplier
                      ).toFixed(3)
                    : (
                        (parseFloat(this.props.cart.calculated_total) -
                          parseFloat(this.props.checkoutData.discount)) *
                        this.props.multiplier.multiplier
                      ).toFixed(3)}
                </span>
              </li>
              {/* <li className="total">Total<span>KWD {parseFloat(this.props.cart.calculated_total).toFixed(3)}</span></li>
                        <li className="total">Total<span>KWD {parseFloat(this.state.delivery_charges).toFixed(3)}</span></li>
                        <li className="total">Total<span>KWD { parseFloat(this.state.discount).toFixed(3)}</span></li> */}
            </ul>

            <PaymentMethods
              setDataVariable={this.props.setDataVariable}
            ></PaymentMethods>

            {proceedOrder ? (
              <button
                type="button"
                id="orderButton"
                onClick={() => this.orderCheckout()}
                className="btn_1 gradient full-width mb_5"
                // {proceedOrder ? {...}: {disabled: "true"}}
              >
                {this.props.lang == "en"
                  ? this.props.translations.en["Order Now"]
                  : this.props.translations.ar["Order Now"]}
              </button>
            ) : (
              <>
                <button
                  type="button"
                  id="orderButton"
                  onClick={() => this.orderNow()}
                  className="btn_1 gradient full-width mb_5"
                  // {proceedOrder ? {...}: {disabled: "true"}}
                  disabled
                >
                  {this.props.lang == "en"
                    ? this.props.translations.en["Order Now"]
                    : this.props.translations.ar["Order Now"]}
                </button>

                <p style={{ color: "red", textAlign: "center" }}>
                  Some products are out of stock and please remove them to place
                  order
                </p>
              </>
            )}
            <div className="text-center">
              <small>
                {this.props.lang == "en"
                  ? this.props.translations.en["Or Call Us At"]
                  : this.props.translations.ar["Or Call Us At"]}{" "}
                <strong>{this.props.shop.contact_phone}</strong>
              </small>
            </div>
          </div>
        </div>

        <ErrorPop
          show={this.state.show}
          closeErrPop={() => this.closeErrPop()}
          errorMsg={this.state.error_msg}
        ></ErrorPop>
        {this.state.showIns ? (
          <InstructionsPopup
            selectedProduct={this.state.selectedProduct}
            showIns={this.state.showIns}
            closeModal={this.closeModal}
          ></InstructionsPopup>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // shop: state.shop,
    lang: state.lang.lang,
    settings: state.settings.data,
    shop: state.shop,
    cart: state.cart,
    lang: state.lang.lang,
    translations: state.translations,
    showSignInModal: state.handleSignInModal.show,
    selectedProduct: state.selectedProduct,
    countries: state.countries.data,
    branches: state.branches.items,
    governates: state.governates.data,
    delivery_location: state.deliveryLocation.data.area,
    pickup_location: state.pickupLocation.data.branch,
    delivery_location_governate: state.deliveryLocation.data.governate,
    delivery_mode: state.deliveryMode,
    currency: state.currency,
    multiplier: state.multiplier,
    user: state.user,
    checkoutData: state.checkoutData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // updateShop: () => dispatch(updateShopDetails()),
    // getCategories: () => dispatch(getCategories()),
    setLang: (lang) =>
      dispatch({ type: actionTypes.SET_LANGUAGE, payload: { lang: lang } }),
    setShowProductPopUp: (show_product_popup) =>
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: show_product_popup },
      }),
    resetCart: () => dispatch({ type: actionTypes.RESET_CART, payload: null }),
    set_deliveryLocation: (location) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_LOCATION,
        payload: { location: location },
      }),
    set_deliveryMode: (modeData) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_MODE,
        payload: { modeData },
      }),
    set_tempDeliveryMode: (modeData) =>
      dispatch({
        type: actionTypes.SET_TEMP_DELIVERY_MODE,
        payload: { modeData },
      }),
    addItemToCart: (i, product) =>
      dispatch({
        type: actionTypes.ADD_CART_ITEM,
        payload: { item: product, index: i },
      }),
    updateCart: (product) =>
      dispatch({ type: actionTypes.ADD_CART_ITEM, payload: { item: product } }),
    removeItemFromCart: (i, product) =>
      dispatch({
        type: actionTypes.REMOVE_CART_ITEM,
        payload: { item: product, index: i },
      }),
    reloadCart: (cart) =>
      dispatch({
        type: actionTypes.SET_CART_ON_RELOAD,
        payload: { cart: cart },
      }),
    popItem: (i) =>
      dispatch({
        type: actionTypes.POPITEM,
        payload: { popIndex: i },
      }),
    handleAuthModal: (val) => {
      dispatch({
        type: actionTypes.HANDLE_AUTH_MODAL,
        payload: { showModal: val },
      });
    },
    updateCheckoutData: (checkoutData) => {
      dispatch({
        type: actionTypes.UPDATE_CHECKOUTDATA,
        payload: { checkoutData: checkoutData },
      });
    },
    set_deliveryLocation: (location) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_LOCATION,
        payload: { location: location },
      }),
    set_pickupLocation: (branch) =>
      dispatch({
        type: actionTypes.SET_PICKUP_LOCATION,
        payload: { branch: branch },
      }),
    // setCurrency: (currency) => dispatch({type: actionTypes.SET_CURRENCY, payload: { base_currency: currency}}),
    // setMultiplier: (multiplier) => dispatch({type: actionTypes.SET_MULTIPLIER, payload: { multiplier: multiplier}})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutCart);
