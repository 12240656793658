import "./App.css";
//import Navbar from "./containers/Navbar/Navbar";
//import Banner from "./containers/Banner/Banner";
//import CategoryNav from "./containers/CategoryNav/CategoryNav";
//import CartSidebar from "./containers/CartSidebar/CartSidebar";
//import ProductsContainer from "./containers/ProductsContainer/ProductsContainer";

import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import RootPage from "./containers/RootPage/RootPage";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
    };
  }

  componentDidMount() {
    // this.new_script("/assets/js/jquery.min.js");
    // this.new_script(
    //   "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"
    // )
    //   .then(function () {
    //     var demoClass = document.getElementById("democlass");
    //     if (demoClass != null) {
    //       demoClass.remove();
    //     }
    //     let scrps = [
    //       // "/assets/js/jquery.min.js",
    //       "js/common_scripts.js",
    //       "/js/common_func.js",
    //       "/validate.js",
    //       "/js/sticky_sidebar.js",
    //       "/js/sticky-kit.js",
    //       "/js/specific_detail.js",
    //     ];
    //     let adders = document.createElement("div");
    //     var att = document.createAttribute("id"); // Create a "class" attribute
    //     att.value = "democlass"; // Set the value of the class attribute
    //     adders.setAttributeNode(att);
    //     scrps.map((scrp) => {
    //       const script = document.createElement("script");
    //       script.src = scrp;
    //       if (
    //         scrp !==
    //         "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"
    //       ) {
    //         script.async = true;
    //         adders.appendChild(script);
    //       } else {
    //         document.body.appendChild(script);
    //       }
    //       // } else {
    //       //     document.head.apped
    //       // }
    //       // script.async = true;
    //     });
    //     document.body.appendChild(adders);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }

  // loadDynamicScript = (callback) => {
  //   const existingScript = document.getElementById("scriptId");

  //   if (!existingScript) {
  //     const script = document.createElement("script");
  //     script.src = "url"; // URL for the third-party library being loaded.
  //     script.id = "libraryName"; // e.g., googleMaps or stripe
  //     document.body.appendChild(script);

  //     script.onload = () => {
  //       if (callback) callback();
  //     };
  //   }

  //   if (existingScript && callback) callback();
  // };

  // new_script = (src) => {
  //   return new Promise(function (resolve, reject) {
  //     var jq = document.getElementById("jq");
  //     if (jq != null) {
  //       resolve();
  //     } else {
  //       let adders = document.createElement("div");
  //       var att = document.createAttribute("id"); // Create a "class" attribute
  //       att.value = "jq"; // Set the value of the class attribute
  //       adders.setAttributeNode(att);
  //       var script = document.createElement("script");
  //       script.src = src;
  //       script.addEventListener("load", function () {
  //         resolve();
  //       });
  //       script.addEventListener("error", function (e) {
  //         reject(e);
  //       });
  //       adders.appendChild(script);
  //       document.body.appendChild(adders);
  //     }
  //   });
  // };

  handleModal = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        showModal: !prevState.showModal,
      };
    });
  };
  render() {
    return (
      <>
        <BrowserRouter>
          <RootPage></RootPage>
        </BrowserRouter>
      </>
    );
  }
}

export default App;
