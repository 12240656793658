import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions/actions";

class SearchBar extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {}

  render() {
    return (
      <div className="input-group">
        <input
          type="text"
          className="form-control search_box"
          placeholder={this.props.lang == "ar" &&  this.props.translations.ar["Search Products"]!=null
          ? this.props.translations.ar["Search Products"]
          : "Search Products"}
          aria-label={this.props.lang == "ar" &&  this.props.translations.ar["Search Products"]!=null
          ? this.props.translations.ar["Search Products"]
          : "Search Products"}
          aria-describedby="basic-addon2"
          value={this.props.search}
          onChange={(e) => this.props.set_search(e.currentTarget.value)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations,
    lang: state.lang.lang,
    products: state.products.data,
    categories: state.categories.items,
    settings: state.settings.data,
    cart: state.cart,
    search: state.searchInput.keyword,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    set_search: (search) =>
      dispatch({
        type: actionTypes.SET_SEARCH_KEYWORD,
        payload: { keyword: search },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
