import { BASE_API_URL, getCookie } from "../../config";
import * as actionTypes from "../actions/actions";
import CryptoJS from 'crypto-js';
import { callService } from "../../services";

export const syncPopUpProduct = (resultJson) => {
  return {
    type: actionTypes.SET_SELECTED_PRODUCT,
    payload: {
      product: resultJson,
    },
  };
};

export const getSelectedProduct = (prod_id) => {
  console.log(prod_id);
  return (dispatch) => {
    if (prod_id !== null && prod_id !== undefined) {
      // console.log(`${BASE_API_URL}/products_addons/${prod_id}`);
      console.log(`${BASE_API_URL}/product/${prod_id}`);

      let date = new Date;
  let strTime = date.toLocaleString("en-US", {
    timeZone: `Asia/Kuwait`
  });
  let encKey = "jdsdas4gsdgs3323j"; 
  let text = "weby_"+strTime;
  let iv = CryptoJS.enc.Hex.parse("FgLFXEr1MZl2mEnk");
  //console.log(iv,"yy");
  var encryptedText = CryptoJS.AES.encrypt(text, encKey, { iv: iv }).toString();
      // callService(`${BASE_API_URL}/products_addons/${prod_id}`, {
        callService(`${BASE_API_URL}/product/${prod_id}`, "GET")
        .then((result) => {
          return result.json();
        })
        .then((resultJson) => {
          console.log(resultJson);
          // this.props.updateShop(resultJson);
          // resultJson.discount = resultJson.price;
          // resultJson.price = resultJson.old_price;
          dispatch(syncPopUpProduct(resultJson));
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      dispatch({
        type: actionTypes.SET_SELECTED_PRODUCT,
        payload: { product: null },
      });
    }
  };
};
