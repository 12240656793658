import React, { Component } from "react";
import * as actionTypes from "../../store/actions/actions";
import { connect } from "react-redux";
import SideNav from "react-simple-sidenav";
import { Link } from "react-router-dom";
import MobileNav from "../MobileNav/MobileNav";
import { BASE_API_URL, ENVIRONMENT } from "../../config";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNav: false,
      showSearch: false,
    };
    if (ENVIRONMENT == "development") {
      console.log(this.props.user.userdata);
    }
  }

  setRTL = (lang) => {
    let mainBody = document.getElementById("root");
    let main = document.getElementsByTagName("body")[0];
    let html = document.getElementsByTagName("html")[0];
    let title = document.getElementsByTagName("title")[0];
    if (ENVIRONMENT == "development") {
      console.log(this.props.settings.title);
    }
    if (lang == "ar") {
      this.props.setLang("ar");
      if (ENVIRONMENT == "development") {
        console.log("setting arabic data theme");
      }
      html.setAttribute("data-theme", "rtl");
      mainBody.classList.remove("ltr");
      mainBody.classList.add("rtl");
      main.classList.remove("ltr");
      main.classList.add("rtl");
      title.innerHTML = this.props.settings.title_ar;
    } else if (lang == "en") {
      this.props.setLang("en");
      mainBody.classList.remove("rtl");
      mainBody.classList.add("ltr");
      main.classList.remove("rtl");
      main.classList.add("ltr");
      if (ENVIRONMENT == "development") {
        console.log("setting english data theme");
      }

      html.setAttribute("data-theme", "ltr");
      title.innerHTML = this.props.settings.title;
    }
  };

  handleSideBar = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        showNav: !prevState.showNav,
      };
    });
  };

  handleSearchBar = (val) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        showSearch: !prevState.showSearch,
      };
    });
  };

  setShowNav = (val) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        showNav: val,
      };
    });
  };

  handleSearch = (keyword) => {
    if (keyword != "") {
      // this.setState((prevState) => {
      //     return {
      //         ...prevState,
      //         search: keyWord,
      //         products: []
      //     }
      this.props.set_search(keyword);
    } else {
      this.props.set_search(null);
    }
  };

  handleCurrency = (e) => {
    if (ENVIRONMENT == "development") {
      console.log("calling handle currecy");
      console.log(e.target.value);
    }

    let current_currency = null;
    this.props.currencies.map((cur, ind) => {
      if (cur.currency == e.target.value) {
        current_currency = cur;
      }
    });

    if (current_currency !== null && current_currency !== undefined) {
      this.props.setCurrency(current_currency.currency);
      this.props.setMultiplier(current_currency.price);
    }

    // this.props.setCurrency(cur.currency);
    // this.props.setMultiplier(cur.price);
  };

  showAuthModal = () => {
    this.props.handleAuthModal(true);
  };

  render() {
    let mobile = false;
    if (window.matchMedia("screen and (max-width: 990px)").matches) {
      mobile = true;
    }

    let menuItems = [
      <Link
        onClick={() => {
          this.setShowNav(false);
        }}
        style={{ padding: "10px 20px", color: "#000000" }}
        to={`/`}
      >
        {/* Home */}
        {this.props.lang == "en"
          ? this.props.translations.en["Home"]
          : this.props.translations.ar["Home"]}
      </Link>,
      <Link
        onClick={() => {
          this.setShowNav(false);
        }}
        style={{ padding: "10px 20px", color: "#000000" }}
        to={`/order-status`}
      >
        {/* Order Status */}
        {this.props.lang == "en"
          ? this.props.translations.en["Order Status"]
          : this.props.translations.ar["Order Status"]}
      </Link>,
      <Link
        onClick={() => {
          this.setShowNav(false);
        }}
        style={{ padding: "10px 20px", color: "#000000" }}
        to={`/about-us`}
      >
        About Us
        {this.props.lang == "en"
          ? this.props.translations.en["About Us"]
          : this.props.translations.ar["About Us"]}
      </Link>,
      <Link
        onClick={() => {
          this.setShowNav(false);
        }}
        style={{ padding: "10px 20px", color: "#000000" }}
        to={`/contact-us`}
      >
        Contact Us
        {this.props.lang == "en"
          ? this.props.translations.en["Contact Us"]
          : this.props.translations.ar["Contact Us"]}
      </Link>,
      <a>
        <i className="fa fa-whatsapp"></i>
        <i className="fa fa-facebook"></i>
        <i className="fa fa-twitter"></i>
        <i className="fa fa-instagram"></i>
        <i className="fa fa-snapchat"></i>
      </a>,
      // <Link onClick={() => {this.setShowNav(false);}} style={{ padding: "10px 20px", color: "#000000"}} to={`/`}>ar</Link>
    ];

    if (this.props.lang == "en") {
      // menuItems.push(<Link onClick={() =>{this.setShowNav(false); this.setRTL("ar")}} style={{ padding: "10px 20px", color: "#000000"}}>ar</Link>);
      menuItems.push(
        <a
          onClick={() => {
            this.setShowNav(false);
            this.setRTL("ar");
          }}
          style={{ padding: "10px 20px", color: "#000000" }}
        >
          <img className="languageSelect" src="/img/Ar.png" />
        </a>
      );
    } else {
      // menuItems.push(<Link onClick={() =>{this.setShowNav(false); this.setRTL("en")}} style={{ padding: "10px 20px", color: "#000000"}}>en</Link>);
      menuItems.push(
        <a
          onClick={() => {
            this.setShowNav(false);
            this.setRTL("en");
          }}
          //   style={{ padding: "10px 20px", color: "#000000" }}
        >
          <img id="languageSelect" src="/img/En.png" />
        </a>
      );
    }
    // menuItems.push(
    //   <Link>
    //     <div className="styled-select currency-selector">
    //       <select onChange={(e) => this.handleCurrency(e)}>
    //         {/* <option value="US Dollars" selected>US Dollars</option>
    //                 <option value="Euro">Euro</option> */}
    //         {currencies_list}
    //       </select>
    //     </div>
    //   </Link>
    // );

    let sideBar = (
      <SideNav
        className="hello"
        showNav={this.state.showNav}
        onHideNav={() => this.setShowNav(false)}
        navStyle={{ maxWidth: "100% !important", minwidth: "100% !important" }}
        // title={titleNode}
        // items={['Order Status', 'About Us', 'Contact Us']}
        // items={[
        // <Link onClick={() => {this.setShowNav(false); this.props.displaySearch(false)}} style={{ padding: "10px 20px", color: "#000000"}} to={`/order-status`}>{this.props.lang == "en" ? this.props.translations.en["ORDER STATUS"] : this.props.translations.ar["ORDER STATUS"]}</Link>,
        // <Link onClick={() => {this.setShowNav(false); this.props.displaySearch(false)}} style={{ padding: "10px 20px", color: "#000000"}} to={`/about-us`}>{this.props.lang == "en" && this.props.translations.en["About Us"] ? this.props.translations.en["About Us"] : this.props.lang == "en" && this.props.translations.ar["About Us"] ? this.props.translations.ar["About Us"] : "About Us"}</Link>,
        // <Link onClick={() => {this.setShowNav(false); this.props.displaySearch(false)}} style={{ padding: "10px 20px", color: "#000000"}} to={`/contact-us`}>{this.props.lang == "en" ? this.props.translations.en["Contact US"] : this.props.translations.ar["Contact US"]}</Link>,
        // <Link onClick={() => {this.setShowNav(false);}} style={{ padding: "10px 20px", color: "#000000"}} to={`/`}>Home</Link>,
        // <Link onClick={() => {this.setShowNav(false);}} style={{ padding: "10px 20px", color: "#000000"}} to={`/order-status`}>order status</Link>,
        // <Link onClick={() => {this.setShowNav(false); }} style={{ padding: "10px 20px", color: "#000000"}} to={`/about-us`}>about us</Link>,
        // <Link onClick={() => {this.setShowNav(false); }} style={{ padding: "10px 20px", color: "#000000"}} to={`/contact-us`}>contact us</Link>,
        // <Link onClick={() => {this.setShowNav(false);}} style={{ padding: "10px 20px", color: "#000000"}} to={`/`}>ar</Link>,

        // <Link onClick={() => {this.setShowNav(false); }} style={{ padding: "10px 20px", color: "#000000"}} to={`/about-us`}>about us</Link>,
        // <Link onClick={() => {this.setShowNav(false); }} style={{ padding: "10px 20px", color: "#000000"}} to={`/contact-us`}>contact us</Link>,
        // <Link onClick={() => {this.setShowNav(false);}} style={{ padding: "10px 20px", color: "#000000"}} to={`/order-status`}>order status</Link>,
        // <Link onClick={() => {this.setShowNav(false); }} style={{ padding: "10px 20px", color: "#000000"}} to={`/about-us`}>about us</Link>,
        // <Link onClick={() => {this.setShowNav(false); }} style={{ padding: "10px 20px", color: "#000000"}} to={`/contact-us`}>contact us</Link>

        // ]}
        items={menuItems}
        // titleStyle={{ backgroundColor: "white" }}
        itemStyle={{
          backgroundColor: "#fff",
          color: "black",
          padding: "0px",
          marginTop: "10px",
          listStyle: "none",
        }}
        itemHoverStyle={{ backgroundColor: "#eee" }}
        openFromRight={this.props.lang == "ar" ? true : false}
        style={{ overflow: "hidden", overflowY: "scroll", zIndex: 20 }}
        // navStyle={{backgroundColor: "green",display: "inline"}}
        children={
          <MobileNav
            {...this.props}
            menuItems={menuItems}
            handleSidebar={this.handleSideBar}
          ></MobileNav>
        }
      />
    );
    return (
      <>
        {mobile ? sideBar : null}
        <header className="header_in clearfix">
          <div className="container">
            <div id="logo">
              <Link to="/">
                <img src={this.props.settings.image} alt="image" />
              </Link>
            </div>
            <div className="layer"></div>
            <ul id="top_menu">
              {!this.props.user.isLoggedIn ? (
                <li>
                  <a
                    onClick={this.showAuthModal}
                    id="sign-in"
                    className="login"
                  >
                    {/* Sign In */}
                  </a>
                </li>
              ) : (
                <li>
                  <Link to={`/myaccount`}>
                    {/* <i className="fas fa-user" style={{ fontSize: "18px" }}></i> */}
                    <img src="/img/user.png" width="22" />
                  </Link>
                </li>
              )}
              <li>
                {this.props.lang == "en" ? (
                  <img
                    className="languageSelect"
                    src="/img/Ar.png"
                    height="25"
                    onClick={() => this.setRTL("ar")}
                  />
                ) : (
                  <img
                    className="languageSelect"
                    src="/img/En.png"
                    height="25"
                    onClick={() => this.setRTL("en")}
                  />
                )}
              </li>
            </ul>

            <a className="open_close" onClick={this.handleSideBar}>
              <i className="icon_menu"></i>
              <span>Menu</span>
            </a>
            <nav className="main-menu">
              <div id="header_menu">
                <a className="open_close" onClick={this.handleSideBar}>
                  <i className="icon_close"></i>
                  <span>Menu</span>
                </a>
              </div>
              <ul>
                <li className="submenu">
                  <Link to="/" className="show-submenu">
                    {this.props.lang == "en"
                      ? this.props.translations.en["Home"]
                      : this.props.translations.ar["Home"]}
                  </Link>
                </li>
                <li className="submenu">
                  <Link to={"/about"} className="show-submenu">
                    {this.props.lang == "en"
                      ? this.props.translations.en["About us"]
                      : this.props.translations.ar["About us"]}
                  </Link>
                </li>
                <li className="submenu">
                  <Link to="/contact" className="show-submenu">
                    {this.props.lang == "en"
                      ? this.props.translations.en["Contact"]
                      : this.props.translations.ar["Contact"]}
                  </Link>
                </li>
                <li className="submenu">
                  <Link to="/track-order" className="show-submenu">
                    {this.props.lang == "en"
                      ? this.props.translations.en["Track Order"]
                      : this.props.translations.ar["Track Order"]}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </header>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedProduct: state.selectedProduct,
    lang: state.lang.lang,
    translations: state.translations,
    settings: state.settings.data,
    auth: state.auth,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setSelectedProduct: (prod_id) => dispatch(getSelectedProduct(prod_id)),
    setLang: (lang) =>
      dispatch({
        type: actionTypes.SET_LANGUAGE,
        payload: { lang: lang },
      }),
    setSelectedProduct: (prod_id) =>
      dispatch({
        type: actionTypes.SET_SELECTED_PRODUCT,
        payload: { product: prod_id },
      }),
    handleProductPopUp: (val) => {
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: val },
      });
    },
    handleAuthModal: (val) => {
      dispatch({
        type: actionTypes.HANDLE_AUTH_MODAL,
        payload: { showModal: val },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
