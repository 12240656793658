import React, { Component, Fragment } from "react";
import * as actionTypes from "../../store/actions/actions";
import { connect } from "react-redux";
import ViewPhotos from "../ViewPhotos/ViewPhotos";
import LightBox from "../ViewPhotos/LightBox/LightBox";
import GalleryBox from "../ViewPhotos/GalleryBox/GalleryBox";
class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }
  showModal = (val) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        show: val,
      };
    });
  };
  render() {
    let mobile = false;
    let bannerstr=this.props.settings.banner;
    if (window.matchMedia("screen and (max-width: 990px)").matches) {
      mobile = true;
      bannerstr=this.props.settings.mobile_banner;
    }
    return (
      <>
        <div
          className="hero_in detail_page background-image"
          data-background={`url(${bannerstr})`}
          style={{ backgroundImage: `url(${bannerstr})` }}
        >
          <div
            className="wrapper opacity-mask"
            data-opacity-mask="rgba(0, 0, 0, 0.5)"
          >
            <div className="container">
              <div className="main_info">
                <div className="row mobileBanner">
                  <div className="col-xl-4 col-lg-5 col-md-6">
                   
                    <h1 id="bannerTitle">
                      {this.props.lang == "en"
                        ? this.props.settings.title
                        : this.props.settings.title_ar}
                    </h1>
                    {/* ITALIAN - 27 Old Gloucester St, 4530 -{" "} */}

                    {/* <a
                      href="https://www.google.com/maps/dir//Assistance+%E2%80%93+H%C3%B4pitaux+De+Paris,+3+Avenue+Victoria,+75004+Paris,+Francia/@48.8606548,2.3348734,14z/data=!4m15!1m6!3m5!1s0x47e66e1de36f4147:0xb6615b4092e0351f!2sAssistance+Publique+-+H%C3%B4pitaux+de+Paris+(AP-HP)+-+Si%C3%A8ge!8m2!3d48.8568376!4d2.3504305!4m7!1m0!1m5!1m1!1s0x47e67031f8c20147:0xa6a9af76b1e2d899!2m2!1d2.3504327!2d48.8568361"
                      target="blank"
                    >
                      Get directions
                    </a>{" "}
                    */}
                  </div>
                  {this.props.gallery.items.length > 0 ?
                  <div className="col-xl-8 col-lg-7 col-md-6 viewPhotosDiv">
                    <div className="buttons clearfix" id="viewPhotos">
                      <span className="magnific-gallery">
                        
                        <a
                          className="btn_hero"
                          title="Photo title"
                          data-effect="mfp-zoom-in"
                          onClick={() => this.showModal(true)}
                        >
                          <i className="icon_image"></i>
                          {this.props.lang == "en"
                            ? this.props.translations.en["View photos"]
                            : this.props.translations.ar["View photos"]}
                        </a>
                        <a
                          href="img/detail_2.jpg"
                          title="Photo title"
                          data-effect="mfp-zoom-in"
                        ></a>
                        <a
                          href="img/detail_3.jpg"
                          title="Photo title"
                          data-effect="mfp-zoom-in"
                        ></a>
                      </span>
                     
                    </div>
                  </div>
                   :null}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <ViewPhotos
          show={this.state.show}
          setShow={this.showModal}
        ></ViewPhotos> */}

        {/* <LightBox show={this.state.show} setShow={this.showModal}></LightBox> */}
        <GalleryBox
          show={this.state.show}
          setShow={this.showModal}
        ></GalleryBox>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang.lang,
    settings: state.settings.data,
    gallery: state.gallery,
    translations: state.translations,
    delivery_mode: state.deliveryMode,
    delivery_location: state.deliveryLocation.data.area,
    pickup_location: state.pickupLocation.data.branch,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, null)(Banner);
