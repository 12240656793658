import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { BASE_API_URL } from "../../../config";
import { SRLWrapper } from "simple-react-lightbox";
import carouselStyles from "../ViewPhotos.css";
import { callService } from "../../../services";
class LightBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gallery: [],
      loading: true,
    };
  }

  componentDidMount() {
    callService(`${BASE_API_URL}/gallery`)
      .then((resp) => {
        return resp.json();
      })
      .then((respJson) => {
        console.log(respJson);
        this.setState((prevState) => {
          return {
            ...prevState,
            loading: false,
            gallery: respJson.gallery,
          };
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.show != prevProps.show) {
      callService(`${BASE_API_URL}/gallery`)
        .then((resp) => {
          return resp.json();
        })
        .then((respJson) => {
          console.log(respJson);
          this.setState((prevState) => {
            return {
              ...prevState,
              loading: false,
              gallery: respJson.gallery,
            };
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  render() {
    let mobile = false;
    if (window.matchMedia("screen and (max-width: 990px)").matches) {
      mobile = true;
    }
    let gallery = this.state.gallery.map((img) => {
      return (
        // <div key={img.id} style={{ height: "100%" }}>
        <div key={img.id} style={{ height: "100%" }}>
          <img
            className={carouselStyles.imgContainer}
            src={img.image}
            style={mobile ? {} : { objectFit: "cover" }}
            // style={{ height: "100%" }}
          />
          {/* <p className="legend">{`Legend ${img.id}`}</p> */}
        </div>
      );
    });
    return <SRLWrapper>{gallery}</SRLWrapper>;
  }
}

export default LightBox;
