import React, { Component } from "react";
import { Link } from "react-router-dom";
import SideMenu from "../SideMenu/SideMenu";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actions";
import Select from "react-select";
import { BASE_API_URL, ENVIRONMENT } from "../../../config";
import { add } from "lodash";
import { callService } from "../../../services";

class EditAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address_title: null,
      areaId: null,
      area: null,
      block: null,
      street: null,
      avenue: null,
      housetype: "House",
      house: null,
      flat: null,
      floor: null,
      house_number: null,
      apartment_name: null,
      apartment_floor: null,
      apartment_flat: null,
      office_name: null,
      office_number: null,
      office_floor: null,
      additional_information: null,

      error_state: {
        area: false,
        // governate: false,
        block: false,
        street: false,
        avenue: false,
        housetype: false,
        house: false,
        flat: false,
        floor: false,
        house_number: false,
        office_number: false,
        office_name: false,
        office_floor: false,
        apartment_name: false,
        apartment_flat: false,
        apartment_floor: false,
      },
    };
  }

  componentDidMount() {
    if (!this.props.user.isLoggedIn) {
      this.props.handleAuthModal(true);
      this.props.history.push("/");
    } else {
      callService(
        `${BASE_API_URL}/addresses/${this.props.user.userdata.id}/${this.props.match.params.id}`
      )
        .then((resp) => resp.json())
        .then((respJson) => {
          if (ENVIRONMENT == "development") {
            console.log("this is executing");
          }
          // console.log(respJson);
          // if (respJson.length > 0) {
          // this.props.history.push("/my-addresses");
          let addr = respJson[0];
          this.setState((prevState) => {
            return {
              ...prevState,
              error_state: {
                ...prevState.error_state,
              },
              areaId: {
                label:
                  this.props.lang == "en" ? addr.area_tit : addr.area_tit_ar,
                value: addr.id,
              },
              area_title: addr.area_tit,
              area_title_ar: addr.area_tit_ar,
              address_title: addr.title,
              area: addr.id,
              block: addr.block,
              street: addr.street,
              avenue: addr.avenue,
              housetype: addr.type,
              house: addr.house,
              flat: addr.flat,
              floor: addr.floor,
              house_number: addr.house,
              apartment_name: null,
              apartment_floor: null,
              apartment_flat: null,
              office_name: null,
              office_number: null,
              office_floor: null,
              additional_information: addr.directions,
              address_str: add.address_str,
            };
          });
          // }
        })
        .catch((err) => {
          if (ENVIRONMENT == "development") {
            console.log(err);
          }
        });
    }
  }

  componentDidUpdate(prevProps) {
    if (ENVIRONMENT == "development") {
      console.log("executing update");
    }
    if (
      this.props.user.isLoggedIn !== prevProps.user.isLoggedIn ||
      this.props.lang != prevProps.lang
    ) {
      if (ENVIRONMENT == "development") {
        console.log("setting state");
      }
      if (this.props.lang == "en") {
        this.setState((prevState) => {
          return {
            ...prevState,
            areaId: {
              ...prevState.areaId,
              label: prevState.area_title,
            },
            error_state: {
              ...prevState.error_state,
            },
          };
        });
      } else {
        this.setState((prevState) => {
          return {
            ...prevState,
            areaId: {
              ...prevState.areaId,
              label: prevState.area_title_ar,
            },
            error_state: {
              ...prevState.error_state,
            },
          };
        });
      }
      if (!this.props.user.isLoggedIn) {
        this.props.handleAuthModal(true);
        this.props.history.push("/");
      }
    }
  }

  selectedLocation = (areaId) => {
    let governate = null;
    let area = null;
    this.props.governates.map((gov) => {
      let isAreaExist = gov.list.filter((area) => {
        return area.id === areaId;
      });

      if (Array.isArray(isAreaExist) && isAreaExist.length > 0) {
        governate = gov;
        area = isAreaExist[0];
      }
    });
    if (ENVIRONMENT == "development") {
      console.log(governate);
      console.log(area);
    }

    // this.props.set_deliveryLocation({ governate: governate, area: area });
    this.setState((prevState) => {
      return {
        ...prevState,
        area: area,
        areaId: {
          label: this.props.lang == "en" ? area.title : area.title_ar,
          value: area.id,
        },
      };
    });
  };

  setHouseType = (houztype) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        housetype: houztype,
      };
    });
  };

  setDataVariable = (name, value) => {
    this.setState((prevState) => {
      if (ENVIRONMENT == "development") {
        console.log("changing picker status");
      }
      let obj = {};
      prevState["error_state"][name] = false;
      obj[name] = value;
      return {
        ...prevState,
        ...obj,
      };
    });
  };

  addAddress = () => {
    if (ENVIRONMENT == "development") {
      console.log(
        JSON.stringify({
          // member id
          member_id: this.props.user.userdata.id,
          address_id: this.props.match.params.id,
          title: this.state.address_title,
          area: this.state.area.id,
          block: this.state.block,
          street: this.state.street,
          avenue: this.state.avenue,
          type: this.state.housetype,
          house: this.state.house,
          floor: this.state.floor,
          flat: this.state.flat,
          // house_number: this.state.house_number,
          // apartment_name: this.state.apartment_name,
          // apartment_floor: this.state.apartment_floor,
          // apartment_flat: this.state.apartment_flat,
          // office_name: this.state.office_name,
          // office_number: this.state.office_number,
          // office_floor: this.state.office_floor,
          directions: this.state.additional_information,
        })
      );
    }
    callService(
      `${BASE_API_URL}/address`,
      "POST",
      {},
      {
        // member id
        member_id: this.props.user.userdata.id,
        address_id: this.props.match.params.id,
        title: this.state.address_title,
        area: this.state.area.id,
        block: this.state.block,
        street: this.state.street,
        avenue: this.state.avenue,
        type: this.state.housetype,
        house: this.state.house,
        floor: this.state.floor,
        flat: this.state.flat,
        // house_number: this.state.house_number,
        // apartment_name: this.state.apartment_name,
        // apartment_floor: this.state.apartment_floor,
        // apartment_flat: this.state.apartment_flat,
        // office_name: this.state.office_name,
        // office_number: this.state.office_number,
        // office_floor: this.state.office_floor,
        directions: this.state.additional_information,
      }
    )
      .then((resp) => resp.json())
      .then((respJson) => {
        if (ENVIRONMENT == "development") {
          console.log(respJson);
        }
        if (respJson.status == "Success") {
          this.props.history.push("/my-addresses");
        }
      })
      .catch((err) => {
        if (ENVIRONMENT == "development") {
          console.log(err);
        }
      });
  };
  render() {
    let options = this.props.governates.map((governate, index) => {
      let item = {
        label: this.props.lang == "en" ? governate.title : governate.title_ar,
      };
      let options = governate.list.map((area, index) => {
        let option = {
          label: this.props.lang == "en" ? area.title : area.title_ar,
          value: area.id,
        };
        return option;
      });

      item["options"] = options;
      return item;
    });
    return (
      <main className="bg_gray">
        <div className="container margin_60_20">
          <div className="row justify-content-center">
            <SideMenu />
            <div className="col-lg-9">
              <div className="box_general write_review">
                <h1 className="add_bottom_15">
                  {this.props.lang == "en"
                    ? this.props.translations.en["Edit Address"]
                    : this.props.translations.ar["Edit Address"]}
                  {/* <a href="#" className="btn_1 small float-right">
                    Add Address
                  </a> */}
                </h1>
                <div className="row">
                  <div className="col-md-4 add_bottom_15">
                    <div className="form-group">
                      <label>
                        {this.props.lang == "en"
                          ? this.props.translations.en["Address Title"]
                          : this.props.translations.ar["Address Title"]}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          this.props.lang == "en"
                            ? this.props.translations.en["Address Title"]
                            : this.props.translations.ar["Address Title"]
                        }
                        onChange={(e) =>
                          this.setDataVariable(
                            "address_title",
                            e.currentTarget.value
                          )
                        }
                        value={this.state.address_title}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 add_bottom_15">
                    <div className="form-group">
                      <div className="custom_select clearfix">
                        <label>
                          {this.props.lang == "en"
                            ? this.props.translations.en["Area"]
                            : this.props.translations.ar["Area"]}
                        </label>
                        <Select
                          onChange={(e) => this.selectedLocation(e.value)}
                          placeholder={
                            this.props.lang === "en"
                              ? this.props.translations.en["Select Area"]
                              : this.props.translations.ar["Select Area"]
                          }
                          className="custom-select js-example-basic-single"
                          id="inputGroupSelect04"
                          options={options}
                          value={this.state.areaId}
                        ></Select>
                        {/* <select className="wide form-control">
                          <option value="">
                            Your Area
                            {this.props.lang == "en"
                              ? this.props.translations.en["Your Area"]
                              : this.props.translations.ar["Your Area"]}
                          </option>
                          <option value="London">London</option>
                          <option value="Paris">Paris</option>
                          <option value="Madrid">Madrid</option>
                          <option value="Berlin">Berlin</option>
                          <option value="Rome">Rome</option>
                        </select> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 add_bottom_15">
                    <div className="form-group">
                      <label>
                        {this.props.lang == "en"
                          ? this.props.translations.en["Block"]
                          : this.props.translations.ar["Block"]}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your Block"
                        onChange={(e) =>
                          this.setDataVariable("block", e.currentTarget.value)
                        }
                        value={this.state.block}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 add_bottom_15">
                    <div className="form-group">
                      <label>
                        {this.props.lang == "en"
                          ? this.props.translations.en["Street"]
                          : this.props.translations.ar["Street"]}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your Street"
                        onChange={(e) =>
                          this.setDataVariable("street", e.currentTarget.value)
                        }
                        value={this.state.street}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 add_bottom_15">
                    <div className="form-group">
                      <label>
                        {this.props.lang == "en"
                          ? this.props.translations.en["Avenue"]
                          : this.props.translations.ar["Avenue"]}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your Avenue"
                        onChange={(e) =>
                          this.setDataVariable("avenue", e.currentTarget.value)
                        }
                        value={this.state.avenue}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 add_bottom_15">
                    <div className="form-group">
                      <div className="custom_select clearfix">
                        <label>
                          {this.props.lang == "en"
                            ? this.props.translations.en["Select Any"]
                            : this.props.translations.ar["Select Any"]}
                        </label>
                        <select
                          className="wide form-control"
                          onChange={(e) =>
                            this.setHouseType(e.currentTarget.value)
                          }
                        >
                          <option value="House">House</option>
                          <option value="Building">Building</option>
                          <option value="Office">Office</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {this.state.housetype == "House" ? (
                    <div className="col-md-4 add_bottom_15">
                      <div className="form-group">
                        <label>
                          {this.props.lang == "en"
                            ? this.props.translations.en["House Number"]
                            : this.props.translations.ar["House Number"]}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="House Number"
                          onChange={(e) =>
                            this.setDataVariable("house", e.currentTarget.value)
                          }
                          value={this.state.house}
                        />
                      </div>
                    </div>
                  ) : null}
                  {this.state.housetype == "Building" ? (
                    <div className="col-md-4 add_bottom_15">
                      <div className="form-group">
                        <label>
                          {this.props.lang == "en"
                            ? this.props.translations.en["Building Number"]
                            : this.props.translations.ar["Building Number"]}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Building Number"
                          onChange={(e) =>
                            this.setDataVariable("house", e.currentTarget.value)
                          }
                          value={this.state.house}
                        />
                      </div>
                    </div>
                  ) : null}
                  {this.state.housetype == "Office" ? (
                    <div className="col-md-4 add_bottom_15">
                      <div className="form-group">
                        <label>
                          {this.props.lang == "en"
                            ? this.props.translations.en["Office Number"]
                            : this.props.translations.ar["Office Number"]}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Office Number"
                          onChange={(e) =>
                            this.setDataVariable("house", e.currentTarget.value)
                          }
                          value={this.state.house}
                        />
                      </div>
                    </div>
                  ) : null}
                  {this.state.housetype != "House" ? (
                    <>
                      <div className="col-md-4 add_bottom_15">
                        <div className="form-group">
                          <label>
                            {this.props.lang == "en"
                              ? this.props.translations.en["Floor Number"]
                              : this.props.translations.ar["Floor Number"]}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Floor Number"
                            onChange={(e) =>
                              this.setDataVariable(
                                "floor",
                                e.currentTarget.value
                              )
                            }
                            value={this.state.floor}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 add_bottom_15">
                        <div className="form-group">
                          <label>
                            {this.props.lang == "en"
                              ? this.props.translations.en["Flat Number"]
                              : this.props.translations.ar["Flat Number"]}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Flat Number"
                            onChange={(e) =>
                              this.setDataVariable(
                                "flat",
                                e.currentTarget.value
                              )
                            }
                            value={this.state.flat}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                  <div className="col-md-4 add_bottom_15">
                    <div className="form-group">
                      <label>
                        {this.props.lang == "en"
                          ? this.props.translations.en["Extra Directions"]
                          : this.props.translations.ar["Extra Directions"]}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Directions"
                        onChange={(e) =>
                          this.setDataVariable(
                            "additional_information",
                            e.currentTarget.value
                          )
                        }
                        value={this.state.additional_information}
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-4 add_bottom_15">
                    <div className="form-group">
                      <label>Phone Number*</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Number"
                      />
                    </div>
                  </div> */}
                </div>
                <a className="btn_1" onClick={this.addAddress}>
                  Save
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations,
    lang: state.lang.lang,
    products: state.products.data,
    categories: state.categories.items,
    cart: state.cart,
    settings: state.settings.data,
    user: state.user,
    governates: state.governates.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setSelectedProduct: (prod_id) => dispatch(getSelectedProduct(prod_id)),
    setLang: (lang) =>
      dispatch({
        type: actionTypes.SET_LANGUAGE,
        payload: { lang: lang },
      }),
    setSelectedProduct: (prod_id) =>
      dispatch({
        type: actionTypes.SET_SELECTED_PRODUCT,
        payload: { product: prod_id },
      }),
    handleProductPopUp: (val) => {
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: val },
      });
    },
    handleAuthModal: (val) => {
      dispatch({
        type: actionTypes.HANDLE_AUTH_MODAL,
        payload: { showModal: val },
      });
    },
    updateUserData: (val) => {
      dispatch({
        type: actionTypes.UPDATE_USERDATA,
        payload: { userdata: val },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAddress);
