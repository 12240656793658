import * as actionTypes from '../actions/actions';



const initialState = {
    items: []
}

const galleryReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_GALLERY:
            let gallery = action.payload.gallery;
            return {
                items: gallery
            }

    }
    return state;
}

export default galleryReducer;