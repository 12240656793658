import * as actionTypes from '../actions/actions';



const initialState = {

    productId: null
    
}

const qtyIndicatorReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_QUANTITY_INDICATOR_ID:
            let productId = action.payload.productId;
            return {
                productId: productId
            }

    }
    return state;
}

export default qtyIndicatorReducer;