import { getSelectedProduct } from "../../store/actions/popProduct";
import * as actionTypes from "../../store/actions/actions";
import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import cloneDeep from "lodash/cloneDeep";
import ViewImages from "../ViewImages/ViewImages";
import { BASE_API_URL, ENVIRONMENT } from "../../config";

class Productthumb extends Component {
  constructor(props) {
    super(props);
    if (ENVIRONMENT == "development") {
      console.log(this.props.p);
      console.log(this.props.cartIt);
    }
    this.state = {
      show: false,
      qty: this.props.cartIt?.qty ? this.props.cartIt.qty : 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cartIt?.qty || this.props.cartIt?.qty) {
      if (prevProps.cartIt.qty !== this.props.cartIt.qty) {
        this.setState((prevProps, prevState) => {
          return {
            ...prevState,
            qty: this.props.cartIt.qty,
          };
        });
      }
    }
  }

  showModal = (val) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        show: val,
      };
    });
  };

  handleModal = () => {
    // if (this.props.p.addon_cnt > 0) {
    this.props.setSelectedProduct(this.props.p.id);
    this.props.handleProductPopUp(true);
    // }
  };

  increaseQty = () => {
    if (this.canAddMore()) {
      this.setState(
        (prevState) => {
          let updatedQty = parseInt(prevState.qty) + 1;
          if (ENVIRONMENT == "development") {
            console.log("updating, ", updatedQty);
          }
          return {
            ...prevState,
            qty: updatedQty > 0 ? updatedQty : 0,
          };
        },
        () => {
          this.addToCart();
          //add to cart here
        }
      );
    }
  };

  decreaseQty = () => {
    this.setState(
      (prevState) => {
        let updatedQty = parseInt(prevState.qty) - 1;
        return {
          ...prevState,
          qty: updatedQty > 0 ? updatedQty : 0,
        };
      },
      () => {
        //remove to cart here
        this.addToCart();
        // this.props.removeItemFromCart(null, this.props.p);
      }
    );
  };

  addToCart = () => {
    let prod = cloneDeep(this.props.p);

    let product = {
      ...prod,
      images: [this.props.p.image],

      addons: [],
    };
    if (ENVIRONMENT == "development") {
      console.log("this is product for increasing", product);
    }
    let currentProduct = cloneDeep(product);
    let pageqty = this.state.qty;
    // let pageqty = document.getElementById(-qty").innerHTML;
    // let currentProduct = {};
    // currentProduct["product"] = clonedProduct;
    currentProduct["qty"] = parseInt(pageqty);
    // currentProduct["calculated_price"] = parseFloat(this.props.p.price).toFixed(
    //   3
    // );
    currentProduct["calculated_price"] =
      parseFloat(this.props.p.discount) > 0
        ? parseFloat(this.props.p.discount).toFixed(3)
        : parseFloat(this.props.p.price).toFixed(3);
    currentProduct["calculated_total"] =
      parseInt(currentProduct.qty) *
      parseFloat(currentProduct.calculated_price);
    // currentProduct["calculated_total"] = parseInt(qty) * parseFloat(currentProduct.calculated_price);
    if (ENVIRONMENT == "development") {
      console.log("this is the current product data", currentProduct);
    }
    if (currentProduct.qty > 0) {
      this.props.addItemToCart(null, currentProduct);
    } else {
      this.props.removeItemFromCart(null, currentProduct);
    }
  };

  canAddMore = () => {
    if (ENVIRONMENT == "development") {
      console.log(this.props.p.quantity_check);
      console.log(this.state.qty);
      console.log(this.props.p.quantity);
    }
    if (parseInt(this.props.p.quantity_check) == 1) {
      if (this.state.qty < parseInt(this.props.p.quantity)) {
        return true;
      } else {
        alert(`${this.props.p.quantity} are available`);
      }
    } else {
      return true;
    }
  };
  removeFromQty = () => {};

  render() {
    if (ENVIRONMENT == "development") {
      console.log(this.props.cartIt);
      console.log(this.props.p);
    }

    let cart_count = 0;

    let statusButton = null;

    if (this.props.p.addon_cnt > 0) {
      this.props.cart.items.map((item, i) => {
        if (ENVIRONMENT == "development") {
          console.log(item.id);
        }
        if (item.id == this.props.p.id) {
          if (ENVIRONMENT == "development") {
            console.log("ids matched");
          }

          cart_count += item.qty;
        }
      });
    }

    if (
      (parseInt(this.props.p.quantity_check) == 1 &&
        parseInt(this.props.p.quantity) > 0) ||
      parseInt(this.props.p.quantity_check) == 0
    ) {
      if (ENVIRONMENT == "development") {
        console.log("if condition");
      }
      statusButton = (
        <a
          style={{ cursor: "pointer" }}
          className="add_icon addItem2"
          onClick={
            this.props.p.addon_cnt > 0 ? this.handleModal : this.increaseQty
          }
        >
          <span>
            {this.props.lang == "en"
              ? this.props.transaltions.en["Add"]
              : this.props.transaltions.ar["Add"]}
          </span>
        </a>
      );
    } else {
      if (ENVIRONMENT == "development") {
        console.log("else condition");
        console.log(this.props.p);
      }

      statusButton = (
        <a className="add_icon addItem2 soldOut">
          {this.props.lang == "en"
            ? this.props.transaltions.en["Sold Out"]
            : this.props.transaltions.ar["Sold Out"]}
        </a>
      );
    }
    return (
      <div
        className="col-xl-4 col-lg-4 col-md-4 col-sm-6 itm_thumb theme2"
        style={{ marginBottom: "10px" }}
      >
        <div className="strip strip1">
          <figure onClick={() => this.showModal(true)}>
            <img
              src={this.props.p.image}
              data-src={this.props.p.image}
              alt="thumb"
              className="lazy"
              onClick={() => this.showModal(true)}
            />
            <a className="strip_info modal_dialog">
              {this.state.qty > 0 && (
                <small>
                  {this.state.qty > 0 ? this.state.qty + " x " : ""}
                </small>
              )}
              <div className="item_title">
                <h3>
                  <small>
                    {this.props.p.addon_cnt > 0
                      ? cart_count + " x"
                      : this.state.qty > 0
                      ? this.state.qty + " x"
                      : ""}
                  </small>
                  {this.props.lang == "en"
                    ? this.props.p.title
                    : this.props.p.title_ar}
                </h3>

                <small style={{ minHeight: "10px" }}>
                  {/* <a>view more</a> */}
                  {this.props.lang == "en"
                    ? this.props.p.about
                    : this.props.p.about_ar}
                </small>
              </div>
            </a>
          </figure>

          <div style={{ clear: "both" }}></div>
          <div className="row pricebox">
            <div className="pPrice1">
              {this.props.p.addon_cnt > 0 ? (
                <>
                  <strong style={{ color: "var(--primary-color)" }}>
                    {this.props.lang == "en"
                      ? this.props.transaltions.en["Price on Selection"]
                      : this.props.transaltions.ar["Price on Selection"]}
                  </strong>
                </>
              ) : (
                <>
                  {parseFloat(this.props.p.discount) > 0 && (
                    <strike style={{ color: "var(--primary-color)" }}>
                      {parseFloat(this.props.p.discount) > 0
                        ? `KWD ${parseFloat(this.props.p.price).toFixed(3)}`
                        : null}
                    </strike>
                  )}

                  <strong className="product_price">
                    KWD{" "}
                    {parseFloat(this.props.p.discount) > 0
                      ? parseFloat(this.props.p.discount).toFixed(3)
                      : parseFloat(this.props.p.price).toFixed(3)}
                  </strong>
                  {/* {!this.props.cartIt ? ( */}
                </>
              )}
            </div>

            <div className="pPrice2">
              {this.props.p.addon_cnt > 0 ? (
                <>
                  {this.state.qty < 1 && this.state.qty >= 0 ? (
                    statusButton
                  ) : (
                    <>
                      <div className="product_qty_updater2">
                        <div
                          className="dec button_inc"
                          onClick={this.decreaseQty}
                        >
                          <i className="fa fa-minus"></i>
                        </div>

                        <div className="qty_show">
                          {this.state.qty > 0 ? this.state.qty : 0}
                        </div>
                        <div
                          className="inc button_inc"
                          onClick={this.increaseQty}
                        >
                          <i className="fa fa-plus"></i>
                        </div>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  {/* {!this.props.cartIt ? ( */}
                  {this.state.qty < 1 && this.state.qty >= 0 ? (
                    statusButton
                  ) : (
                    <>
                      <div className="product_qty_updater2">
                        <div
                          className="dec button_inc"
                          onClick={this.decreaseQty}
                        >
                          <i className="fa fa-minus"></i>
                        </div>

                        <div className="qty_show">
                          {this.state.qty > 0 ? this.state.qty : 0}
                        </div>
                        {this.props.p.quantity_check == "1" &&
                        this.state.qty < this.props.p.quantity ? (
                          <div
                            className="inc button_inc"
                            onClick={this.increaseQty}
                          >
                            <i className="fa fa-plus"></i>
                          </div>
                        ) : this.props.p.quantity_check == "0" ? (
                          <div
                            className="inc button_inc"
                            onClick={this.increaseQty}
                          >
                            <i className="fa fa-plus"></i>
                          </div>
                        ) : (
                          <div className="inc button_inc">
                            <i
                              className="fa fa-plus"
                              style={{ color: "grey" }}
                            ></i>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div style={{ clear: "both" }}></div>
        </div>
        {this.state.show ? (
          <ViewImages
            productId={this.props.p.id}
            show={this.state.show}
            setShow={this.showModal}
          ></ViewImages>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang.lang,
    transaltions: state.translations,
    cart: state.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedProduct: (prod_id) => dispatch(getSelectedProduct(prod_id)),
    handleProductPopUp: (val) => {
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: val },
      });
    },
    addItemToCart: (i, product) =>
      dispatch({
        type: actionTypes.ADD_CART_ITEM,
        payload: { item: product, index: i },
      }),
    updateCart: (product) =>
      dispatch({ type: actionTypes.ADD_CART_ITEM, payload: { item: product } }),
    removeItemFromCart: (i, product) =>
      dispatch({
        type: actionTypes.REMOVE_CART_ITEM,
        payload: { item: product, index: i },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Productthumb);
