import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actions";
import { BASE_API_URL, ENVIRONMENT } from "../../../config";

class Thankyou extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    // this.props.resetCart();
    if (ENVIRONMENT == "development") {
      console.log("came to thank you page");
    }
    this.props.history.push(`/order-success`, {
      id: this.props.match.params.id,
      // cod: this.props.location.state.cod,
    });
  }
  componentDidUpdate(prevProps) {
    //   if(this.props.match.param)
    if (ENVIRONMENT == "development") {
      console.log("came to thank you page - didupdate");
    }

    if (
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params?.id != prevProps.match.params?.id
    ) {
      // this.props.resetCart();

      this.props.history.push(`/order-success`, {
        id: this.props.match.params.id,
        // cod: this.props.location.state.cod,
      });
    }
  }
  render() {
    return <div></div>;
  }
}

const mapStateToProps = (state) => {
  return {
    // shop: state.shop,
    lang: state.lang.lang,
    settings: state.settings.data,
    shop: state.shop,
    cart: state.cart,
    lang: state.lang.lang,
    translations: state.translations,
    showSignInModal: state.handleSignInModal.show,
    selectedProduct: state.selectedProduct,
    countries: state.countries.data,
    branches: state.branches.items,
    governates: state.governates.data,
    delivery_location: state.deliveryLocation.data.area,
    delivery_location_governate: state.deliveryLocation.data.governate,
    currency: state.currency,
    multiplier: state.multiplier,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // updateShop: () => dispatch(updateShopDetails()),
    // getCategories: () => dispatch(getCategories()),
    setLang: (lang) =>
      dispatch({ type: actionTypes.SET_LANGUAGE, payload: { lang: lang } }),
    setShowProductPopUp: (show_product_popup) =>
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: show_product_popup },
      }),
    resetCart: () => dispatch({ type: actionTypes.RESET_CART, payload: null }),
    set_deliveryLocation: (location) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_LOCATION,
        payload: { location: location },
      }),
    addItemToCart: (i, product) =>
      dispatch({
        type: actionTypes.ADD_CART_ITEM,
        payload: { item: product, index: i },
      }),
    updateCart: (product) =>
      dispatch({ type: actionTypes.ADD_CART_ITEM, payload: { item: product } }),
    removeItemFromCart: (i, product) =>
      dispatch({
        type: actionTypes.REMOVE_CART_ITEM,
        payload: { item: product, index: i },
      }),
    reloadCart: (cart) =>
      dispatch({
        type: actionTypes.SET_CART_ON_RELOAD,
        payload: { cart: cart },
      }),
    popItem: (i) =>
      dispatch({
        type: actionTypes.POPITEM,
        payload: { popIndex: i },
      }),
    // setCurrency: (currency) => dispatch({type: actionTypes.SET_CURRENCY, payload: { base_currency: currency}}),
    // setMultiplier: (multiplier) => dispatch({type: actionTypes.SET_MULTIPLIER, payload: { multiplier: multiplier}})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Thankyou);
