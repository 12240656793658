import React, { Component } from "react";
import { BASE_API_URL, ENVIRONMENT } from "../../config";

import * as actionTypes from "../../store/actions/actions";
import { connect } from "react-redux";

import { cloneDeep, initial } from "lodash";
import Footer from "../Footer/Footer";

import AfterFoot from "../AfterFoot/AfterFoot";
import Navbar from "../Navbar/Navbar";
import Banner from "../Banner/Banner";
import CategoryNav from "../CategoryNav/CategoryNav";
import CartSidebar from "../CartSidebar/CartSidebar";
import ProductsContainer from "../ProductsContainer/ProductsContainer";
import Templateone from "../Templateone/Templateone";
import Templatetwo from "../Templatetwo/Templatetwo";
class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollingManual: true,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate() {
    // window.removeEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  updateScrollingState = (val) => {
    this.setState((prevState) => {
      return {
        scrollingManual: val,
      };
    });
  };

  handleScroll = () => {
    // if (this.state.scrollingManual) {
    let elOfCats = [];
    let posOfCats = this.props.categories.map((c, i) => {
      let el = document.getElementById(`section-${i}`);
      let catEl = document.getElementById(`cat-${i}`);
      elOfCats.push(catEl);
      // if (i == 0) {
      //   console.log(el.getBoundingClientRect());
      // }
      return el.getBoundingClientRect();
    });

    posOfCats.map((pos, i) => {
      if (ENVIRONMENT == "development") {
        console.log(pos, i);
      }
      if (i <= posOfCats.length - 2) {
        if (posOfCats[i].top < 90 && posOfCats[i + 1].top > 90) {
          elOfCats[i].classList.add("active");
          if (ENVIRONMENT == "development") {
            console.log("calling active");
          }
        } else {
          elOfCats[i].classList.remove("active");
          if (ENVIRONMENT == "development") {
            console.log("calling remove");
          }
        }
      } else {
        if (posOfCats[i].top <= 90) {
          elOfCats[i].classList.add("active");
        } else {
          elOfCats[i].classList.remove("active");
        }
        // if (posOfCats[i].bottom > 0 && posOfCats) {
        //   elOfCats[i].classList.add("active");
        //   console.log("calling active");
        // } else {
        //   elOfCats[i].classList.add("remove");
        //   console.log("calling remove");
        // }
      }
    });
    // }

    // let sideBarEl = document.getElementById("sidebar_fixed");
    // let sideBarBounds = sideBarEl.getBoundingClientRect();

    // console.log(sideBarBounds, "sidebar bounds ");
    // if (sideBarBounds.top < 90) {
    //   // sideBarEl.style.left = sideBarBounds.left;
    //   sideBarEl.style.top = 90;
    //   sideBarEl.style.position = "sticky";
    // } else {
    //   // sideBarEl.style.left = "initial";
    //   sideBarEl.style.top = "initial";
    //   sideBarEl.style.position = "initial";
    // }
    return;
  };

  render() {
    return (
      <>
        <main>
          <Banner></Banner>

          <CategoryNav handleScroll={this.handleScroll}></CategoryNav>

          <div className="bg_gray">
            <div className="container margin_detail">
              <div className="row">
                {/* product Container */}
                {/* <ProductsContainer></ProductsContainer> */}
                {/* cart sidebar */}
                {/* <CartSidebar></CartSidebar> */}
                <Templateone></Templateone>
                {/* <Templatetwo></Templatetwo> */}
              </div>
            </div>
          </div>

          {/* Reviews */}
        </main>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations,
    lang: state.lang.lang,
    categories: state.categories.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    set_translations: (translations) =>
      dispatch({
        type: actionTypes.SET_TRANSLATIONS,
        payload: { translations: translations },
      }),
    set_shopdata: (shopdata) =>
      dispatch({
        type: actionTypes.UPDATE_SHOP,
        payload: { shopData: shopdata },
      }),
    set_pickupLocation: (branch) =>
      dispatch({
        type: actionTypes.SET_PICKUP_LOCATION,
        payload: { branch: branch },
      }),
    set_categories: (categories) =>
      dispatch({
        type: actionTypes.SET_CATEGORIES,
        payload: { cats: categories },
      }),
    set_products: (products) =>
      dispatch({
        type: actionTypes.SET_PRODUCTS,
        payload: { products: products },
      }),
    set_banners: (banners) =>
      dispatch({
        type: actionTypes.SET_BANNERS,
        payload: { banners: banners },
      }),
    set_branches: (branches) =>
      dispatch({
        type: actionTypes.SET_BRANCHES,
        payload: { branches: branches },
      }),
    set_settings: (settings) =>
      dispatch({
        type: actionTypes.SET_SETTINGS,
        payload: { settings: settings },
      }),
    set_governates: (governates) =>
      dispatch({
        type: actionTypes.SET_GOVERNATES,
        payload: { governates: governates },
      }),
    set_countries: (countries) =>
      dispatch({
        type: actionTypes.SET_COUNTRIES,
        payload: { countries: countries },
      }),
    set_currencies: (currencies) =>
      dispatch({
        type: actionTypes.SET_CURRENCIES,
        payload: { currencies: currencies },
      }),
    set_selectedCategory: (category) =>
      dispatch({
        type: actionTypes.SET_SELECTED_CATEGORY,
        payload: { category: category },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);
