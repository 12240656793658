import * as actionTypes from "../actions/actions";

const initialState = {
  data: [],
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PRODUCTS:
      let products = action.payload.products;
      return {
        data: products,
      };
  }
  return state;
};

export default productsReducer;
