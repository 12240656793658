import React, { Component } from "react";
import SideMenu from "../SideMenu/SideMenu";
import { connect } from "react-redux";
import { BASE_API_URL, ENVIRONMENT } from "../../../config";
import { callService } from "../../../services";
class ViewOrder extends Component {
  constructor(props) {
    super(props);
    if (ENVIRONMENT == "development") {
      console.log(this.props.match.params.id);
    }
    this.state = {
      data: null,
      loading: true,
    };
  }

  componentDidMount() {
    callService(`${BASE_API_URL}/order/${this.props.match.params.id}`)
      .then((resp) => resp.json())
      .then((respJson) => {
        if (ENVIRONMENT == "development") {
          console.log(respJson);
        }

        this.setState((prevState) => {
          return {
            ...prevState,
            data: respJson,
            loading: false,
          };
        });
      })
      .catch((err) => {
        if (ENVIRONMENT == "development") {
          console.log(err);
        }
      });
  }

  render() {
    let products = [];
    if (!this.state.loading) {
      products = this.state.data.products.map((p, i) => {
        return (
          <tr key={i}>
            <td className="text-center">{i + 1}</td>
            <td className="text-left">
              {p.product_name}
              {/* GR7 
              <br />
              Code : <br />
              &nbsp;&nbsp;&nbsp;&nbsp; */}
            </td>
            <td className="text-center">{p.quantity}</td>
            <td className="text-center">{p.price} KD</td>
            <td className="text-right al-ri">{p.total} KD</td>
          </tr>
        );
      });
    }
    // let mobile = false;
    // if (window.matchMedia("screen and (max-width: 990px)").matches) {
    //   mobile = true;
    // }
    return !this.state.loading ? (
      <main className="bg_gray">
        <div className="container margin_60_20">
          <div className="row justify-content-center">
            <SideMenu />
            <div className="col-lg-9">
              <div className="box_general write_review">
                <h1 className="add_bottom_15">
                  {this.props.lang == "ar" &&
                  this.props.translations.ar["View Invoice"] != null
                    ? this.props.translations.ar["View Invoice"]
                    : "View Invoice"}
                </h1>
                <div className="row">
                  <div className="col-md-6 pull-left">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>
                            {this.props.lang == "ar" &&
                            this.props.translations.ar[
                              "Pickup/Delivery Info"
                            ] != null
                              ? this.props.translations.ar[
                                  "Pickup/Delivery Info"
                                ]
                              : "Pickup/Delivery Info"}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            dangerouslySetInnerHTML={{
                              __html: this.state.data.invoice.address_str,
                            }}
                          >
                            {/* Test T<br />
                            Area: Abbasiya, Block: 1<br />
                            Street: 2, Avenue: 3<br />
                            House: 4<br />
                            Directions: 5<br />
                            Ph: 12345678
                            <br />
                            E: chandu.guttikonda@gmail.com */}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-6 pull-left">
                    <table className="table table-bordered text-center">
                      <thead>
                        <tr>
                          <th>
                            {this.props.lang == "ar" &&
                            this.props.translations.ar["Order Number"] != null
                              ? this.props.translations.ar["Order Number"]
                              : "Order Number"}
                          </th>
                          <th>
                            {this.props.lang == "ar" &&
                            this.props.translations.ar["Payment Method"] != null
                              ? this.props.translations.ar["Payment Method"]
                              : "Payment Method"}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{this.state.data.invoice.id}</td>
                          <td>{this.state.data.invoice.payment_method}</td>
                          {/* <td>Cash on Delivery</td> */}
                        </tr>
                      </tbody>
                    </table>
                    <table className="table table-bordered text-center">
                      <thead>
                        <tr>
                          <th>
                            {this.props.lang == "ar" &&
                            this.props.translations.ar["Order Date"] != null
                              ? this.props.translations.ar["Order Date"]
                              : "Order Date"}
                          </th>
                          <th>
                            {this.props.lang == "ar" &&
                            this.props.translations.ar[
                              "Delivery/Pickup Date"
                            ] != null
                              ? this.props.translations.ar[
                                  "Delivery/Pickup Date"
                                ]
                              : "Delivery/Pickup Date"}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{this.state.data.invoice.now}</td>
                          <td>
                            {this.state.data.invoice.delivery_date}{" "}
                            {this.state.data.invoice.delivery_time}{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <table className="table text-center">
                      <thead>
                        <tr>
                          <th className="text-center">
                            {this.props.lang == "ar" &&
                            this.props.translations.ar["Sr No"] != null
                              ? this.props.translations.ar["Sr No"]
                              : "Sr No"}
                          </th>
                          <th className="text-center">
                            {this.props.lang == "ar" &&
                            this.props.translations.ar["Item Name"] != null
                              ? this.props.translations.ar["Item Name"]
                              : "Item Name"}
                          </th>
                          <th className="text-center">
                            {this.props.lang == "ar" &&
                            this.props.translations.ar["Quantity"] != null
                              ? this.props.translations.ar["Quantity"]
                              : "Quantity"}
                          </th>
                          <th
                            className="text-center"
                            style={{ width: "110px" }}
                          >
                            {this.props.lang == "ar" &&
                            this.props.translations.ar["Unit Price"] != null
                              ? this.props.translations.ar["Unit Price"]
                              : "Unit Price"}
                          </th>
                          <th className="text-right" style={{ width: "110px" }}>
                            {this.props.lang == "ar" &&
                            this.props.translations.ar["Total Price"] != null
                              ? this.props.translations.ar["Total Price"]
                              : "Total Price"}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* <tr>
                          <td className="text-center">1</td>
                          <td className="text-left">
                            GR7
                            <br />
                            Code : <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          <td className="text-center">1</td>
                          <td className="text-center">25.000 KD</td>
                          <td className="text-right">25.000 KD</td>
                        </tr> */}
                        {products}
                        <tr>
                          <td colSpan="4" className="al_ri">
                            {this.props.lang == "ar" &&
                            this.props.translations.ar["Discount"] != null
                              ? this.props.translations.ar["Discount"]
                              : "Discount"}{" "}
                            (-)
                          </td>
                          <td className="al_ri">
                            {this.state.data.invoice.discount} KD
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="4" className="al_ri">
                            {this.props.lang == "ar" &&
                            this.props.translations.ar["Delivery Charges"] !=
                              null
                              ? this.props.translations.ar["Delivery Charges"]
                              : "Delivery Charges"}{" "}
                            (+)
                          </td>
                          <td className="al_ri">
                            {this.state.data.invoice.delivery_charges} KD
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="4" className="al_ri">
                            {this.props.lang == "ar" &&
                            this.props.translations.ar["Grand Total"] != null
                              ? this.props.translations.ar["Grand Total"]
                              : "Grand Total"}
                          </td>
                          <td className="al_ri">
                            {this.state.data.invoice.total_price} KD
                          </td>
                        </tr>
                      </tbody>
                      {/* <tfoot>
                        <tr>
                          <td className="text-right" colspan="4">
                            Sub Total
                          </td>
                          <td className="text-right">25.000 KD</td>
                        </tr>
                        <tr>
                          <td className="text-right" colspan="4">
                            Discount(-)
                          </td>
                          <td className="text-right">0.000 KD</td>
                        </tr>
                        <tr>
                          <td className="text-right" colspan="4">
                            Delivery Charges(+)
                          </td>
                          <td className="text-right">0.000 KD</td>
                        </tr>
                        <tr>
                          <td className="text-right" colspan="4">
                            Grand Total
                          </td>
                          <td className="text-right">25.000 KD</td>
                        </tr>
                      </tfoot>
                     */}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    ) : null;
  }
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations,
    lang: state.lang.lang,
    products: state.products.data,
    categories: state.categories.items,
    cart: state.cart,
    settings: state.settings.data,
  };
};

export default connect(mapStateToProps, null)(ViewOrder);
