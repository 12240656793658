import * as actionTypes from '../actions/actions';



const initialState = {
    items: []
}

const bannersReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_BANNERS:
            let banners = action.payload.banners;
            return {
                items: banners
            }

    }
    return state;
}

export default bannersReducer;