import React, { Component } from "react";
import { Link } from "react-router-dom";
import { BASE_API_URL, ENVIRONMENT } from "../../config";
import { connect } from "react-redux";
import { callService } from "../../services";
class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      name: null,
      subject: null,
      msg: null,
      phone: null,
      showmsg: false,
      showerr: false,
      error_state: {
        email: false,
        name: false,
        subject: false,
        phone: false,
        msg: null,
      },
      // clearholes: null
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (ENVIRONMENT == "development") {
      console.log(this.props);
    }
    // let mBanner = document.getElementsByClassName("mBanner mobile");
    // mBanner[0].style.visibility = 'hidden';
    // mBanner[0].style.height = "60px";
  }

  componentWillUnmount() {
    // let mBanner = document.getElementsByClassName("mBanner mobile");
    // mBanner[0].style.removeProperty('visibility');
    // mBanner[0].style.removeProperty('height');
  }

  updateVar = (val, key) => {
    let checkwords = ["name", "email", "subject", "phone", "msg"];
    if (ENVIRONMENT == "development") {
      console.log(key, "------", val);
    }
    let errors = {};
    if (checkwords.includes(key)) {
      if (ENVIRONMENT == "development") {
        console.log(key, " exists");
      }
      if (val == "" || val == null || val == undefined) {
        if (ENVIRONMENT == "development") {
          console.log("setting true");
        }
        errors[key] = true;
      } else {
        if (ENVIRONMENT == "development") {
          console.log("setting false");
        }

        errors[key] = false;
      }
    }
    if (ENVIRONMENT == "development") {
      console.log(errors);
    }
    this.setState((prevState) => {
      let obj = {};
      obj[key] = val;
      return {
        ...prevState,
        ...obj,
        error_state: {
          ...prevState.error_state,
          ...errors,
        },
      };
    });
  };

  submitForm = () => {
    // console.log(
    //   `${BASE_API_URL}/contact?name=${this.state.name}&email=${this.state.email}&phone=${this.state.phone}&message=${this.state.msg}`
    // );
    let canHit = true;
    let error_state = this.state.error_state;
    for (const prope in this.state.error_state) {
      // if(prope !== 'directions') {
      if (ENVIRONMENT == "development") {
        console.log();
      }
      if (error_state[prope]) {
        // console.log("null/undefined value is ", prope, "----", jsonData[prope]);
        canHit = false;
      }

      // [{"product_id":"134","addon_id":"2899","addon_title":"5 Person","addon_title_ar":"5 أشخاص","price":"6.000"}]
      // }
    }
    if (canHit) {
      callService(
        `${BASE_API_URL}/contact`,
        "POST",
        {},
        {
          name: this.state.name,
          email: this.state.email,
          phone: this.state.phone,
          subject: this.state.subject,
          message: this.state.msg,
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((resp) => {
          if (ENVIRONMENT == "development") {
            console.log(resp);
          }
          if (resp.status == "success" || resp.status == "Success") {
            this.setState((prevState) => {
              return {
                ...prevState,
                email: "",
                name: "",
                subject: "",
                msg: "",
                phone: "",
                // showerr: false,
                // error_state: {
                //   email: false,
                //   name: false,
                //   subject: false,
                //   phone: false,
                //   msg: null,
                // },
                showmsg: true,
              };
            });
            if (ENVIRONMENT == "development") {
              console.log("state updated");
            }
          } else {
            this.setState((prevState) => {
              return {
                ...prevState,
                showerr: true,
              };
            });
          }
        })
        .catch((err) => {
          if (ENVIRONMENT == "development") {
            console.log(err);
          }
        });
    }
  };

  render() {
    return (
      <>
        <section className="checkout">
          <div className="container">
            {/* <div className="row"> */}

            {/* <div className="checkoutHeader"> */}

            {/* </div> */}

            <div className="checkoutFields">
              <div className="row" style={{ margin: "5px" }}>
                <div className="col-lg-2 col-md-2"></div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <h4>
                    {this.props.lang == "en"
                      ? this.props.translations.en["Contact us"]
                      : this.props.translations.ar["Contact us"]}
                  </h4>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-2 col-md-2"></div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <div className="row" style={{ margin: "5px" }}>
                    <h4>
                      {this.props.lang == "en"
                        ? this.props.translations.en[
                            "We are here to assist you"
                          ]
                        : this.props.translations.ar[
                            "We are here to assist you"
                          ]}
                    </h4>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby=""
                      placeholder={
                        this.props.lang == "en"
                          ? this.props.translations.en["Your Name"]
                          : this.props.translations.ar["Your Name"]
                      }
                      value={this.state.name}
                      onChange={(e) =>
                        this.updateVar(e.currentTarget.value, "name")
                      }
                    />
                  </div>
                  {this.state.error_state.name ? (
                    <p className="error">
                      {this.props.lang == "en"
                        ? this.props.translations.en["Name is required"]
                        : this.props.translations.ar["Name is required"]}
                      Name is required
                    </p>
                  ) : null}
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby=""
                      placeholder={
                        this.props.lang == "en"
                          ? this.props.translations.en["Your E-mail Address"]
                          : this.props.translations.ar["Your E-mail Address"]
                      }
                      value={this.state.email}
                      onChange={(e) =>
                        this.updateVar(e.currentTarget.value, "email")
                      }
                    />
                  </div>
                  {this.state.error_state.email ? (
                    <p className="error">
                      {this.props.lang == "en"
                        ? this.props.translations.en["Email is required"]
                        : this.props.translations.ar["Email is required"]}
                      Email is required
                    </p>
                  ) : null}
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby=""
                      placeholder={
                        this.props.lang == "en"
                          ? this.props.translations.en["Your Phone Number"]
                          : this.props.translations.ar["Your Phone Number"]
                      }
                      value={this.state.phone}
                      onChange={(e) =>
                        this.updateVar(e.currentTarget.value, "phone")
                      }
                    />
                  </div>
                  {this.state.error_state.phone ? (
                    <p className="error">
                      {this.props.lang == "en"
                        ? this.props.translations.en["Phone Number is required"]
                        : this.props.translations.ar[
                            "Phone Number is required"
                          ]}
                      Phone Number is required
                    </p>
                  ) : null}
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby=""
                      placeholder={
                        this.props.lang == "en"
                          ? this.props.translations.en["Your Subject"]
                          : this.props.translations.ar["Your Subject"]
                      }
                      value={this.state.subject}
                      onChange={(e) =>
                        this.updateVar(e.currentTarget.value, "subject")
                      }
                    />
                  </div>
                  {this.state.error_state.subject ? (
                    <p className="error">
                      {this.props.lang == "en"
                        ? this.props.translations.en["Subject is required"]
                        : this.props.translations.ar["Subject is required"]}
                      Subject is required
                    </p>
                  ) : null}
                  <div className="form-group">
                    {/* <input type="text" className="form-control" aria-describedby="" placeholder="Your Message" value={null} /> */}
                    <textarea
                      className="form-control"
                      rows="10"
                      placeholder={
                        this.props.lang == "en"
                          ? this.props.translations.en["Your Message"]
                          : this.props.translations.ar["Your Message"]
                      }
                      value={this.state.msg}
                      onChange={(e) =>
                        this.updateVar(e.currentTarget.value, "msg")
                      }
                    ></textarea>
                  </div>
                  {this.state.error_state.msg ? (
                    <p className="error">
                      {this.props.lang == "en"
                        ? this.props.translations.en["Message is required"]
                        : this.props.translations.ar["Message is required"]}
                      Message is required
                    </p>
                  ) : null}
                  <div className="form-group">
                    {/* <input type="button" className="form-control"  aria-describedby="" placeholder="Your Message" value={null} /> */}
                    <button
                      className="btn btn-primary"
                      onClick={this.submitForm}
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        padding: "8px 0px",
                        border: "1px",
                        color: "white",
                        backgroundImage:
                          "linear-gradient(to right, var(--fourth-color), var(--third-color))",
                      }}
                    >
                      {/* GET A CALLBACK */}
                      {this.props.lang == "en"
                        ? this.props.translations.en["GET A CALLBACK"]
                        : this.props.translations.ar["GET A CALLBACK"]}
                    </button>
                    {this.state.showmsg ? (
                      <p style={{ color: "green" }}>
                        {this.props.lang == "en"
                          ? this.props.translations.en[
                              "Successfully Submitted. We will contact you as soon as possible"
                            ]
                          : this.props.translations.ar[
                              "Successfully Submitted. We will contact you as soon as possible"
                            ]}
                      </p>
                    ) : null}
                    {this.state.showerr ? (
                      <p style={{ color: "red" }}>
                        {this.props.lang == "en"
                          ? this.props.translations.en[
                              "Failed. Please try again"
                            ]
                          : this.props.translations.ar[
                              "Failed. Please try again"
                            ]}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-4 col-sm-12 col-xs-12"
                  style={{ margin: "15px" }}
                >
                  <div className="row">
                    <h4>
                      {this.props.lang == "en"
                        ? this.props.translations.en["Customer Service"]
                        : this.props.translations.ar["Customer Service"]}
                    </h4>
                  </div>
                  <div className="row">
                    <p>
                      {this.props.lang == "en"
                        ? this.props.translations.en["Phone"]
                        : this.props.translations.ar["Phone"]}
                      : {this.props.settings.phone}
                    </p>

                    <p></p>
                    <p></p>
                  </div>

                  <div className="row">
                    <p>
                      {this.props.lang == "en"
                        ? this.props.translations.en["Email"]
                        : this.props.translations.ar["Email"]}
                      : {this.props.settings.email}
                    </p>
                  </div>

                  <div className="row">
                    <h4>
                      {this.props.lang == "en"
                        ? this.props.translations.en["Address"]
                        : this.props.translations.ar["Address"]}
                      :<br />
                    </h4>
                  </div>
                  <div className="row">
                    <p>Marina Mall, Salmiya</p>
                  </div>

                  {/* <div className="row" id="mapper">
                    <div style={{ width: "100%" }}>
                      <iframe
                        width="100%"
                        height="300"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                        src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=salmiya,%20kuwait+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                      ></iframe>
                    </div>
                  </div> */}
                </div>
                <div className="col-lg-2 col-md-2"></div>
              </div>
            </div>

            {/* </div> */}
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // shop: state.shop,
    lang: state.lang.lang,
    settings: state.settings.data,
    shop: state.shop,
    cart: state.cart,
    lang: state.lang.lang,
    translations: state.translations,
    showSignInModal: state.handleSignInModal.show,
    selectedProduct: state.selectedProduct,
  };
};

export default connect(mapStateToProps, null)(Contact);
