import React, { Component } from "react";
import { Link } from "react-router-dom";
import SideMenu from "../SideMenu/SideMenu";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actions";
import Select from "react-select";
import { BASE_API_URL, ENVIRONMENT } from "../../../config";
import cloneDeep from "lodash.clonedeep";
import { callService } from "../../../services";

class AddAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address_title: null,
      areaId: null,
      area: null,
      block: null,
      street: null,
      avenue: null,
      housetype: "House",
      house: null,
      flat: null,
      floor: null,
      house_number: null,
      apartment_name: null,
      apartment_floor: null,
      apartment_flat: null,
      office_name: null,
      office_number: null,
      office_floor: null,
      additional_information: null,
      error_state: {
        address_title: false,
        area: false,
        // governate: false,
        block: false,
        street: false,
        avenue: false,
        housetype: false,
        house: false,
        flat: false,
        floor: false,
        house_number: false,
        office_number: false,
        office_name: false,
        office_floor: false,
        apartment_name: false,
        apartment_flat: false,
        apartment_floor: false,
      },
    };
  }

  componentDidMount() {
    if (!this.props.user.isLoggedIn) {
      this.props.handleAuthModal(true);
      this.props.history.push("/");
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.user.isLoggedIn !== prevProps.user.isLoggedIn) {
      if (!this.props.user.isLoggedIn) {
        this.props.handleAuthModal(true);
        this.props.history.push("/");
      }
    }
  }

  selectedLocation = (areaId) => {
    let governate = null;
    let area = null;
    this.props.governates.map((gov) => {
      let isAreaExist = gov.list.filter((area) => {
        return area.id === areaId;
      });

      if (Array.isArray(isAreaExist) && isAreaExist.length > 0) {
        governate = gov;
        area = isAreaExist[0];
      }
    });
    if (ENVIRONMENT == "development") {
      console.log(governate);
      console.log(area);
    }

    // this.props.set_deliveryLocation({ governate: governate, area: area });
    this.setState((prevState) => {
      return {
        ...prevState,
        area: area,
        areaId: {
          label: this.props.lang == "en" ? area.title : area.title_ar,
          value: area.id,
        },
      };
    });
  };

  setHouseType = (houztype) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        housetype: houztype,
      };
    });
  };

  setDataVariable = (name, value) => {
    this.setState((prevState) => {
      if (ENVIRONMENT == "development") {
        console.log("changing picker status");
      }
      // if (value == "" || value == null || value == undefined) {

      // prevState["error_state"][name] = false;
      let currentState = cloneDeep(this.state);
      currentState[name] = value;
      if (value == "" || value == null || value == undefined) {
        currentState["error_state"][name] = true;
      }
      return currentState;
      // obj[name] = value;
      // return {
      //   ...prevState,
      //   ...obj,
      // };
    });
  };

  addAddress = () => {
    // console.log(
    //   JSON.stringify({
    //     // member id
    //     member_id: this.props.user.userdata.id,
    //     title: this.state.address_title,
    //     area: this.state.area.id,
    //     block: this.state.block,
    //     street: this.state.street,
    //     avenue: this.state.avenue,
    //     type: this.state.housetype,
    //     house: this.state.house,
    //     floor: this.state.floor,
    //     flat: this.state.flat,
    //     // house_number: this.state.house_number,
    //     // apartment_name: this.state.apartment_name,
    //     // apartment_floor: this.state.apartment_floor,
    //     // apartment_flat: this.state.apartment_flat,
    //     // office_name: this.state.office_name,
    //     // office_number: this.state.office_number,
    //     // office_floor: this.state.office_floor,
    //     directions: this.state.additional_information,
    //   })
    // );

    let fields = [
      "address_title",
      "area",
      "block",
      "street",
      "avenue",
      "housetype",
      "house",
      "floor",
      "flat",
    ];
    let proceed = true;

    let error_obj = {};
    fields.map((field, i) => {
      if (!this.state[field]) {
        proceed = false;
        error_obj[field] = true;
      }
    });
    if (ENVIRONMENT == "development") {
      console.log(error_obj, "____________________________________________");
    }

    this.setState(
      (prevState) => {
        let currentState = cloneDeep(prevState);
        return {
          ...currentState,
          error_state: {
            ...currentState.error_state,
            ...error_obj,
          },
        };
      },
      () => {
        if (proceed) {
          if (ENVIRONMENT == "development") {
            console.log("proceeding");
          }
          callService(
            `${BASE_API_URL}/address`,
            "POST",
            {},
            {
              // member id
              member_id: this.props.user.userdata.id,
              title: this.state.address_title,
              area: this.state.area.id,
              block: this.state.block,
              street: this.state.street,
              avenue: this.state.avenue,
              type: this.state.housetype,
              house: this.state.house,
              floor: this.state.floor,
              flat: this.state.flat,
              // house_number: this.state.house_number,
              // apartment_name: this.state.apartment_name,
              // apartment_floor: this.state.apartment_floor,
              // apartment_flat: this.state.apartment_flat,
              // office_name: this.state.office_name,
              // office_number: this.state.office_number,
              // office_floor: this.state.office_floor,
              directions: this.state.additional_information,
            }
          )
            .then((resp) => resp.json())
            .then((respJson) => {
              if (ENVIRONMENT == "development") {
                console.log(respJson);
              }
              if (respJson.status == "Success") {
                this.props.history.push("/my-addresses");
              }
            })
            .catch((err) => {
              if (ENVIRONMENT == "development") {
                console.log(err);
              }
            });
        }
      }
    );
  };

  render() {
    let options = this.props.governates.map((governate, index) => {
      let item = {
        label: this.props.lang == "en" ? governate.title : governate.title_ar,
      };
      let options = governate.list.map((area, index) => {
        let option = {
          label: this.props.lang == "en" ? area.title : area.title_ar,
          value: area.id,
        };
        return option;
      });

      item["options"] = options;
      return item;
    });
    return (
      <main className="bg_gray">
        <div className="container margin_60_20">
          <div className="row justify-content-center">
            <SideMenu />
            <div className="col-lg-9">
              <div className="box_general write_review">
                <h1 className="add_bottom_15">
                  {this.props.lang == "en"
                    ? this.props.translations.en["Add Address"]
                    : this.props.translations.ar["Add Address"]}
                  {/* <a href="#" className="btn_1 small float-right">
                    Add Address
                  </a> */}
                </h1>
                <div className="row">
                  <div className="col-md-4 add_bottom_15">
                    <div className="form-group">
                      <label>
                        {this.props.lang == "en"
                          ? this.props.translations.en["Address Title"]
                          : this.props.translations.ar["Address Title"]}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          this.props.lang == "en"
                            ? this.props.translations.en["Address Title"]
                            : this.props.translations.ar["Address Title"]
                        }
                        onChange={(e) =>
                          this.setDataVariable(
                            "address_title",
                            e.currentTarget.value
                          )
                        }
                        value={this.state.address_title}
                      />
                      {this.state.error_state.address_title ? (
                        <p className="error">
                          {this.props.lang == "en"
                            ? this.props.translations.en[
                                "Address tile is required"
                              ]
                            : this.props.translations.ar[
                                "Address title is required"
                              ]}
                          Address title is required
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4 add_bottom_15">
                    <div className="form-group">
                      <div className="custom_select clearfix">
                        <label>
                          {this.props.lang == "en"
                            ? this.props.translations.en["Area"]
                            : this.props.translations.ar["Area"]}
                        </label>
                        <Select
                          onChange={(e) => this.selectedLocation(e.value)}
                          placeholder={
                            this.props.lang === "en"
                              ? this.props.translations.en["Select Area"]
                              : this.props.translations.ar["Select Area"]
                          }
                          className="custom-select js-example-basic-single"
                          id="inputGroupSelect04"
                          options={options}
                          value={this.state.areaId}
                        ></Select>
                        {/* <select className="wide form-control">
                          <option value="">
                            Your Area
                            {this.props.lang == "en"
                              ? this.props.translations.en["Your Area"]
                              : this.props.translations.ar["Your Area"]}
                          </option>
                          <option value="London">London</option>
                          <option value="Paris">Paris</option>
                          <option value="Madrid">Madrid</option>
                          <option value="Berlin">Berlin</option>
                          <option value="Rome">Rome</option>
                        </select> */}
                        {this.state.error_state.area ? (
                          <p className="error">
                            {this.props.lang == "en"
                              ? this.props.translations.en["Area is required"]
                              : this.props.translations.ar["Area is required"]}
                            Area is required
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 add_bottom_15">
                    <div className="form-group">
                      <label>
                        {this.props.lang == "en"
                          ? this.props.translations.en["Block"]
                          : this.props.translations.ar["Block"]}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your Block"
                        onChange={(e) =>
                          this.setDataVariable("block", e.currentTarget.value)
                        }
                        value={this.state.block}
                      />
                      {this.state.error_state.block ? (
                        <p className="error">
                          {this.props.lang == "en"
                            ? this.props.translations.en["Block is required"]
                            : this.props.translations.ar["Block is required"]}
                          Block is required
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4 add_bottom_15">
                    <div className="form-group">
                      <label>
                        {this.props.lang == "en"
                          ? this.props.translations.en["Street"]
                          : this.props.translations.ar["Street"]}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your Street"
                        onChange={(e) =>
                          this.setDataVariable("street", e.currentTarget.value)
                        }
                        value={this.state.street}
                      />
                      {this.state.error_state.street ? (
                        <p className="error">
                          {this.props.lang == "en"
                            ? this.props.translations.en["Street is required"]
                            : this.props.translations.ar["Street is required"]}
                          Street is required
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4 add_bottom_15">
                    <div className="form-group">
                      <label>
                        {this.props.lang == "en"
                          ? this.props.translations.en["Avenue"]
                          : this.props.translations.ar["Avenue"]}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your Avenue"
                        onChange={(e) =>
                          this.setDataVariable("avenue", e.currentTarget.value)
                        }
                        value={this.state.avenue}
                      />
                      {this.state.error_state.avenue ? (
                        <p className="error">
                          {this.props.lang == "en"
                            ? this.props.translations.en["Avenue is required"]
                            : this.props.translations.ar["Avenue is required"]}
                          Avenue is required
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4 add_bottom_15">
                    <div className="form-group">
                      <div className="custom_select clearfix">
                        <label>
                          {this.props.lang == "en"
                            ? this.props.translations.en["Select Any"]
                            : this.props.translations.ar["Select Any"]}
                        </label>
                        <select
                          className="wide form-control"
                          onChange={(e) =>
                            this.setHouseType(e.currentTarget.value)
                          }
                        >
                          <option value="House">House</option>
                          <option value="Building">Building</option>
                          <option value="Office">Office</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {this.state.housetype == "House" ? (
                    <div className="col-md-4 add_bottom_15">
                      <div className="form-group">
                        <label>
                          {this.props.lang == "en"
                            ? this.props.translations.en["House Number"]
                            : this.props.translations.ar["House Number"]}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="House Number"
                          onChange={(e) =>
                            this.setDataVariable("house", e.currentTarget.value)
                          }
                          value={this.state.house}
                        />
                        {this.state.error_state.house ? (
                          <p className="error">
                            {this.props.lang == "en"
                              ? this.props.translations.en[
                                  "House Number is required"
                                ]
                              : this.props.translations.ar[
                                  "House Number is required"
                                ]}
                            House Number is required
                          </p>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                  {this.state.housetype == "Building" ? (
                    <div className="col-md-4 add_bottom_15">
                      <div className="form-group">
                        <label>
                          {this.props.lang == "en"
                            ? this.props.translations.en["Building Number"]
                            : this.props.translations.ar["Building Number"]}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Building Number"
                          onChange={(e) =>
                            this.setDataVariable("house", e.currentTarget.value)
                          }
                          value={this.state.house}
                        />
                        {this.state.error_state.house ? (
                          <p className="error">
                            {this.props.lang == "en"
                              ? this.props.translations.en[
                                  "Building Number is required"
                                ]
                              : this.props.translations.ar[
                                  "Building Number is required"
                                ]}
                            Building Number is required
                          </p>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                  {this.state.housetype == "Office" ? (
                    <div className="col-md-4 add_bottom_15">
                      <div className="form-group">
                        <label>
                          {this.props.lang == "en"
                            ? this.props.translations.en["Office Number"]
                            : this.props.translations.ar["Office Number"]}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Office Number"
                          onChange={(e) =>
                            this.setDataVariable("house", e.currentTarget.value)
                          }
                          value={this.state.house}
                        />
                        {this.state.error_state.house ? (
                          <p className="error">
                            {this.props.lang == "en"
                              ? this.props.translations.en[
                                  "Office Number is required"
                                ]
                              : this.props.translations.ar[
                                  "Office Number is required"
                                ]}
                            Office Number is required
                          </p>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                  {this.state.housetype != "House" ? (
                    <>
                      <div className="col-md-4 add_bottom_15">
                        <div className="form-group">
                          <label>
                            {this.props.lang == "en"
                              ? this.props.translations.en["Floor Number"]
                              : this.props.translations.ar["Floor Number"]}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Floor Number"
                            onChange={(e) =>
                              this.setDataVariable(
                                "floor",
                                e.currentTarget.value
                              )
                            }
                            value={this.state.floor}
                          />
                          {this.state.error_state.floor ? (
                            <p className="error">
                              {this.props.lang == "en"
                                ? this.props.translations.en[
                                    "Floor Number is required"
                                  ]
                                : this.props.translations.ar[
                                    "Floor Number is required"
                                  ]}
                              Floor Number is required
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4 add_bottom_15">
                        <div className="form-group">
                          <label>
                            {this.props.lang == "en"
                              ? this.props.translations.en["Flat Number"]
                              : this.props.translations.ar["Flat Number"]}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Flat Number"
                            onChange={(e) =>
                              this.setDataVariable(
                                "flat",
                                e.currentTarget.value
                              )
                            }
                            value={this.state.flat}
                          />
                          {this.state.error_state.flat ? (
                            <p className="error">
                              {this.props.lang == "en"
                                ? this.props.translations.en[
                                    "Flat Number is required"
                                  ]
                                : this.props.translations.ar[
                                    "Flat Number is required"
                                  ]}
                              Flat Number is required
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </>
                  ) : null}
                  <div className="col-md-4 add_bottom_15">
                    <div className="form-group">
                      <label>
                        {this.props.lang == "en"
                          ? this.props.translations.en["Extra Directions"]
                          : this.props.translations.ar["Extra Directions"]}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Directions"
                        onChange={(e) =>
                          this.setDataVariable(
                            "additional_information",
                            e.currentTarget.value
                          )
                        }
                        value={this.state.additional_information}
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-4 add_bottom_15">
                    <div className="form-group">
                      <label>Phone Number*</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Number"
                      />
                    </div>
                  </div> */}
                </div>
                <a className="btn_1" onClick={this.addAddress}>
                  Save
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations,
    lang: state.lang.lang,
    products: state.products.data,
    categories: state.categories.items,
    cart: state.cart,
    settings: state.settings.data,
    user: state.user,
    governates: state.governates.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setSelectedProduct: (prod_id) => dispatch(getSelectedProduct(prod_id)),
    setLang: (lang) =>
      dispatch({
        type: actionTypes.SET_LANGUAGE,
        payload: { lang: lang },
      }),
    setSelectedProduct: (prod_id) =>
      dispatch({
        type: actionTypes.SET_SELECTED_PRODUCT,
        payload: { product: prod_id },
      }),
    handleProductPopUp: (val) => {
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: val },
      });
    },
    handleAuthModal: (val) => {
      dispatch({
        type: actionTypes.HANDLE_AUTH_MODAL,
        payload: { showModal: val },
      });
    },
    updateUserData: (val) => {
      dispatch({
        type: actionTypes.UPDATE_USERDATA,
        payload: { userdata: val },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAddress);
