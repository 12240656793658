import React, { Component } from "react";
import { BASE_API_URL } from "../../../config";
import { secureStorage } from "../../../locStore";
import { cloneDeep } from "lodash";
import Select from "react-select";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actions";
class HouseTypeSelection extends Component {
  constructor(props) {
    super(props);
  }

  setHouseType = (houztype) => {
    let myState = cloneDeep(this.props.checkoutData);
    myState.housetype = houztype;
    // secureStorage.setItem("checkoutData", myState);
    this.props.updateCheckoutData(myState);
  };

  render() {
    return (
      <>
        <div
          className="row"
          style={{
            textAlign: "center",
            height: "45px",
            margin: 0,
            marginBottom: "10px",
          }}
        >
          <div
            onClick={() => {
              this.setHouseType("house");
            }}
            className="col-4"
            style={
              this.props.checkoutData.housetype == "house"
                ? {
                    borderRadius: "7px",
                    backgroundImage:
                      "linear-gradient(to right, var(--third-color), var(--fourth-color))",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color:"#fff"
                  }
                : {
                    borderRadius: "7px",

                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }
            }
          >
            <i className="fas fa-home" style={{ marginRight: "10px" }}></i>
            {this.props.lang == "en"
              ? this.props.translations.en["HOME"]
              : this.props.translations.ar["HOME"]}
          </div>
          <div
            onClick={() => {
              this.setHouseType("office");
            }}
            className="col-4"
            style={
              this.props.checkoutData.housetype == "office"
                ? {
                    borderRadius: "7px",
                    backgroundImage:
                      "linear-gradient(to right, var(--third-color), var(--fourth-color))",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color:"#fff"
                  }
                : {
                    borderRadius: "7px",

                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }
            }
          >
            <i className="fas fa-briefcase" style={{ marginRight: "10px" }}></i>
            {this.props.lang == "en"
              ? this.props.translations.en["OFFICE"]
              : this.props.translations.ar["OFFICE"]}
          </div>
          <div
            onClick={() => {
              this.setHouseType("apartment");
            }}
            className="col-4"
            style={
              this.props.checkoutData.housetype == "apartment"
                ? {
                    borderRadius: "7px",
                    backgroundImage:
                      "linear-gradient(to right, var(--third-color), var(--fourth-color))",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color:"#fff"
                  }
                : {
                    borderRadius: "7px",

                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }
            }
          >
            <i className="fas fa-building" style={{ marginRight: "10px" }}></i>
            {this.props.lang == "en"
              ? this.props.translations.en["APARTMENT"]
              : this.props.translations.ar["APARTMENT"]}
          </div>
        </div>
        {this.props.checkoutData.housetype === "house" ? (
          <>
            <div className="form-group">
              <label>
                {this.props.lang == "en"
                  ? this.props.translations.en["House Number"]
                  : this.props.translations.ar["House Number"]}{" "}
                <span className="required">*</span>
              </label>
              <input
                className="form-control"
                placeholder={
                  this.props.lang == "en"
                    ? this.props.translations.en["House Number"]
                    : this.props.translations.ar["House Number"]
                }
                value={this.props.checkoutData.house_number}
                onChange={(e) => {
                  this.props.setDataVariable(
                    "house_number",
                    e.currentTarget.value
                  );
                }}
              />
              {this.props.checkoutData.error_state.house_number ? (
                <p className="error">
                  {this.props.lang == "en"
                    ? this.props.translations.en["House Number is required"]
                    : this.props.translations.ar["House Number is required"]}
                  House Number is required
                </p>
              ) : null}
            </div>
          </>
        ) : null}
        {this.props.checkoutData.housetype === "office" ? (
          <>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    {this.props.lang == "en"
                      ? this.props.translations.en["Office Name"]
                      : this.props.translations.ar["Office Name"]}
                    <span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    placeholder={
                      this.props.lang == "en"
                        ? this.props.translations.en["Office Name"]
                        : this.props.translations.ar["Office Name"]
                    }
                    value={this.props.checkoutData.office_name}
                    onChange={(e) => {
                      this.props.setDataVariable(
                        "office_name",
                        e.currentTarget.value
                      );
                    }}
                  />
                  {this.props.checkoutData.error_state.office_name ? (
                    <p className="error">
                      {this.props.lang == "en"
                        ? this.props.translations.en["Office Name is required"]
                        : this.props.translations.ar["Office Name is required"]}
                      Office Name is required
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    {this.props.lang == "en"
                      ? this.props.translations.en["Office Number"]
                      : this.props.translations.ar["Office Number"]}{" "}
                    <span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    placeholder={
                      this.props.lang == "en"
                        ? this.props.translations.en["Office Number"]
                        : this.props.translations.ar["Office Number"]
                    }
                    value={this.props.checkoutData.office_number}
                    onChange={(e) => {
                      this.props.setDataVariable(
                        "office_number",
                        e.currentTarget.value
                      );
                    }}
                  />
                  {this.props.checkoutData.error_state.office_number ? (
                    <p className="error">
                      {this.props.lang == "en"
                        ? this.props.translations.en[
                            "Office Number is required"
                          ]
                        : this.props.translations.ar[
                            "Office Number is required"
                          ]}
                      Office Number is required
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    {this.props.lang == "en"
                      ? this.props.translations.en["Office Floor"]
                      : this.props.translations.ar["Office Floor"]}
                    <span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    placeholder={
                      this.props.lang == "en"
                        ? this.props.translations.en["Office Floor"]
                        : this.props.translations.ar["Office Floor"]
                    }
                    value={this.props.checkoutData.office_floor}
                    onChange={(e) => {
                      this.props.setDataVariable(
                        "office_floor",
                        e.currentTarget.value
                      );
                    }}
                  />
                  {this.props.checkoutData.error_state.office_floor ? (
                    <p className="error">
                      {this.props.lang == "en"
                        ? this.props.translations.en["Office Floor is required"]
                        : this.props.translations.ar["Office Floor"]}
                      Office Floor is required
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </>
        ) : null}
        {this.props.checkoutData.housetype === "apartment" ? (
          <>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    {this.props.lang == "en"
                      ? this.props.translations.en["Apartment Name"]
                      : this.props.translations.ar["Apartment Name"]}

                    <span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    placeholder={
                      this.props.lang == "en"
                        ? this.props.translations.en["Apartment Name"]
                        : this.props.translations.ar["Apartment Name"]
                    }
                    value={this.props.checkoutData.apartment_name}
                    onChange={(e) => {
                      this.props.setDataVariable(
                        "apartment_name",
                        e.currentTarget.value
                      );
                    }}
                  />
                  {this.props.checkoutData.error_state.apartment_name ? (
                    <p className="error">
                      {this.props.lang == "en"
                        ? this.props.translations.en[
                            "Apartment Name is required"
                          ]
                        : this.props.translations.ar[
                            "Apartment Name is required"
                          ]}
                      Apartment Name is required
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    {this.props.lang == "en"
                      ? this.props.translations.en["Flat Number"]
                      : this.props.translations.ar["Flat Number"]}{" "}
                    <span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    placeholder={
                      this.props.lang == "en"
                        ? this.props.translations.en["Flat Number"]
                        : this.props.translations.ar["Flat Number"]
                    }
                    value={this.props.checkoutData.apartment_flat}
                    onChange={(e) => {
                      this.props.setDataVariable(
                        "apartment_flat",
                        e.currentTarget.value
                      );
                    }}
                  />
                  {this.props.checkoutData.error_state.apartment_flat ? (
                    <p className="error">
                      {this.props.lang == "en"
                        ? this.props.translations.en["Flat Number is required"]
                        : this.props.translations.ar["Flat Number is required"]}
                      Flat Number is required
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    {this.props.lang == "en"
                      ? this.props.translations.en["Floor"]
                      : this.props.translations.ar["Floor"]}
                    <span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    placeholder={
                      this.props.lang == "en"
                        ? this.props.translations.en["Floor"]
                        : this.props.translations.ar["Floor"]
                    }
                    value={this.props.checkoutData.apartment_floor}
                    onChange={(e) => {
                      this.props.setDataVariable(
                        "apartment_floor",
                        e.currentTarget.value
                      );
                    }}
                  />
                  {this.props.checkoutData.error_state.apartment_floor ? (
                    <p className="error">
                      {this.props.lang == "en"
                        ? this.props.translations.en["Floor is required"]
                        : this.props.translations.ar["Floor is required"]}
                      Floor is required
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // shop: state.shop,
    lang: state.lang.lang,
    settings: state.settings.data,
    shop: state.shop,
    cart: state.cart,
    lang: state.lang.lang,
    translations: state.translations,
    showSignInModal: state.handleSignInModal.show,
    selectedProduct: state.selectedProduct,
    countries: state.countries.data,
    branches: state.branches.items,
    governates: state.governates.data,
    delivery_location: state.deliveryLocation.data.area,
    pickup_location: state.deliveryLocation.data.branch,
    delivery_location_governate: state.deliveryLocation.data.governate,
    delivery_mode: state.deliveryMode,
    currency: state.currency,
    multiplier: state.multiplier,
    user: state.user,
    checkoutData: state.checkoutData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // updateShop: () => dispatch(updateShopDetails()),
    // getCategories: () => dispatch(getCategories()),
    setLang: (lang) =>
      dispatch({ type: actionTypes.SET_LANGUAGE, payload: { lang: lang } }),
    setShowProductPopUp: (show_product_popup) =>
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: show_product_popup },
      }),
    resetCart: () => dispatch({ type: actionTypes.RESET_CART, payload: null }),
    set_deliveryLocation: (location) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_LOCATION,
        payload: { location: location },
      }),
    set_deliveryMode: (modeData) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_MODE,
        payload: { modeData },
      }),
    set_tempDeliveryMode: (modeData) =>
      dispatch({
        type: actionTypes.SET_TEMP_DELIVERY_MODE,
        payload: { modeData },
      }),
    addItemToCart: (i, product) =>
      dispatch({
        type: actionTypes.ADD_CART_ITEM,
        payload: { item: product, index: i },
      }),
    updateCart: (product) =>
      dispatch({ type: actionTypes.ADD_CART_ITEM, payload: { item: product } }),
    removeItemFromCart: (i, product) =>
      dispatch({
        type: actionTypes.REMOVE_CART_ITEM,
        payload: { item: product, index: i },
      }),
    reloadCart: (cart) =>
      dispatch({
        type: actionTypes.SET_CART_ON_RELOAD,
        payload: { cart: cart },
      }),
    popItem: (i) =>
      dispatch({
        type: actionTypes.POPITEM,
        payload: { popIndex: i },
      }),
    handleAuthModal: (val) => {
      dispatch({
        type: actionTypes.HANDLE_AUTH_MODAL,
        payload: { showModal: val },
      });
    },
    updateCheckoutData: (checkoutData) => {
      dispatch({
        type: actionTypes.UPDATE_CHECKOUTDATA,
        payload: { checkoutData: checkoutData },
      });
    },
    // setCurrency: (currency) => dispatch({type: actionTypes.SET_CURRENCY, payload: { base_currency: currency}}),
    // setMultiplier: (multiplier) => dispatch({type: actionTypes.SET_MULTIPLIER, payload: { multiplier: multiplier}})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HouseTypeSelection);
