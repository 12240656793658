import React, { Component } from "react";
import SideMenu from "../SideMenu/SideMenu";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actions";
import { BASE_API_URL, ENVIRONMENT } from "../../../config";
import { callService } from "../../../services";
class MyOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      statuses: [
        "Pending",
        "Order Accepted",
        "Parcel Ready",
        "Delivered",
        "Cancelled",
      ],
      showNotFound: false,
    };
  }

  componentDidMount() {
    if (!this.props.user.isLoggedIn) {
      this.props.handleAuthModal(true);
      this.props.history.push("/");
    } else {
      this.getOrders();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.user.isLoggedIn !== prevProps.user.isLoggedIn) {
      if (!this.props.user.isLoggedIn) {
        this.props.handleAuthModal(true);
        this.props.history.push("/");
      } else {
        this.getOrders();
      }
    }
  }

  getOrders = () => {
    callService(`${BASE_API_URL}/my_orders/${this.props.user.userdata.id}`)
      .then((resp) => resp.json())
      .then((respJson) => {
        if (ENVIRONMENT == "development") {
          console.log(respJson);
        }

        this.setState((prevState) => {
          return {
            ...prevState,
            orders: respJson,
            showNotFound: respJson.length > 0 ? false : true,
          };
        });
      })
      .catch((err) => {
        if (ENVIRONMENT == "development") {
          console.log(err);
        }
      });
  };

  render() {
    let orders = this.state.orders.map((order, i) => {
      // let prods = order.name.map((prod, i) => {
      //   return (
      //     <p key={i}>
      //       {this.props.lang == "en" ? prod.product_name : prod.product_name_ar}
      //     </p>
      //   );
      // });
      return (
        <tr key={i}>
          <td>{order.id}</td>
          <td dangerouslySetInnerHTML={{ __html: order.address_str }}>
            {/* Test T<br />
            Area: Abbasiya, Block: 1<br />
            Street: 2, Avenue: 3<br />
            House: 4<br />
            Directions: 5<br />
            Ph : 12345678
            <br />E : chandu.guttikonda@gmail.com */}
          </td>
          {/* <td>
            <div className="row align-items-center">
              <div className="col-md-3">
                <figure className="entry-media">
                  <img
                    src="https://touchofjasminekw.com/uploads/products/1581854269CD69DCEE-3131-4505-9336-E1444A5742AC.jpeg"
                    width="100%"
                  />
                </figure>
              </div>
              <div className="col-md-9">
                <div className="entry-body">
                  <div className="entry-content">GR7</div>
                </div>
              </div>
            </div>
          </td> */}
          <td>{order.total_price} KD</td>
          <td>
            {/* 02-03-2020
            <br />
            12:41 PM */}
            {order.now}
          </td>
          <td>
            <Link to={`/view-order/${order.id}`}>
              <strong>
                {this.props.lang == "en"
                  ? this.props.translations.en["View"]
                  : this.props.translations.ar["View"]}
              </strong>
            </Link>
          </td>
        </tr>
      );
    });
    return (
      <main className="bg_gray">
        <div className="container margin_60_20">
          <div className="row justify-content-center">
            <SideMenu />
            <div className="col-lg-9">
              <div className="box_general write_review">
                <h1 className="add_bottom_15">
                  {this.props.lang == "en"
                    ? this.props.translations.en["My Orders"]
                    : this.props.translations.ar["My Orders"]}
                </h1>
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table
                        className="table table-bordered"
                        id="dataTable"
                        width="100%"
                        cellspacing="0"
                      >
                        <thead>
                          <tr>
                            <th>
                              {this.props.lang == "en"
                                ? this.props.translations.en["Order Id"]
                                : this.props.translations.ar["Order Id"]}
                            </th>
                            <th>
                              {this.props.lang == "en"
                                ? this.props.translations.en["Billing Address"]
                                : this.props.translations.ar["Billing Address"]}
                            </th>
                            {/* <th>
                              {this.props.lang == "en"
                                ? this.props.translations.en["Items"]
                                : this.props.translations.ar["Items"]}
                            </th> */}
                            <th>
                              {this.props.lang == "en"
                                ? this.props.translations.en["Price"]
                                : this.props.translations.ar["Price"]}
                            </th>
                            <th>
                              {this.props.lang == "en"
                                ? this.props.translations.en["Date"]
                                : this.props.translations.ar["Date"]}
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {orders}
                          {/* <tr>
                            <td>45</td>
                            <td>
                              Test T<br />
                              Area: Abbasiya, Block: 1<br />
                              Street: 2, Avenue: 3<br />
                              House: 4<br />
                              Directions: 5<br />
                              Ph : 12345678
                              <br />E : chandu.guttikonda@gmail.com
                            </td>
                            <td>
                              <div className="row align-items-center">
                                <div className="col-md-3">
                                  <figure className="entry-media">
                                    <img
                                      src="https://touchofjasminekw.com/uploads/products/1581854269CD69DCEE-3131-4505-9336-E1444A5742AC.jpeg"
                                      width="100%"
                                    />
                                  </figure>
                                </div>
                                <div className="col-md-9">
                                  <div className="entry-body">
                                    <div className="entry-content">GR7</div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>25.000 KD</td>
                            <td>
                              02-03-2020
                              <br />
                              12:41 PM
                            </td>
                            <td>
                              <Link to={`/view-order`}>
                                <strong>
                                  {this.props.lang == "en"
                                    ? this.props.translations.en["View"]
                                    : this.props.translations.ar["View"]}
                                </strong>
                              </Link>
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                      {this.state.showNotFound ? (
                        <p style={{ textAlign: "center" }}>no order found :(</p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations,
    lang: state.lang.lang,
    products: state.products.data,
    categories: state.categories.items,
    cart: state.cart,
    settings: state.settings.data,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setSelectedProduct: (prod_id) => dispatch(getSelectedProduct(prod_id)),
    setLang: (lang) =>
      dispatch({
        type: actionTypes.SET_LANGUAGE,
        payload: { lang: lang },
      }),
    setSelectedProduct: (prod_id) =>
      dispatch({
        type: actionTypes.SET_SELECTED_PRODUCT,
        payload: { product: prod_id },
      }),
    handleProductPopUp: (val) => {
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: val },
      });
    },
    handleAuthModal: (val) => {
      dispatch({
        type: actionTypes.HANDLE_AUTH_MODAL,
        payload: { showModal: val },
      });
    },
    updateUserData: (val) => {
      dispatch({
        type: actionTypes.UPDATE_USERDATA,
        payload: { userdata: val },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyOrders);
