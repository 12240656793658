import React, { Component } from "react";
import { Link } from "react-router-dom";
import SideMenu from "../SideMenu/SideMenu";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actions";
import { BASE_API_URL, ENVIRONMENT } from "../../../config";
import { callService } from "../../../services";

export class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opassword: "",
      password: "",
      cpassword: "",
      cpasswordSame: true,
      showSuccessMsg: false,
    };
  }

  componentDidMount() {
    if (!this.props.user.isLoggedIn) {
      this.props.handleAuthModal(true);
      this.props.history.push("/");
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.user.isLoggedIn !== prevProps.user.isLoggedIn) {
      if (!this.props.user.isLoggedIn) {
        this.props.handleAuthModal(true);
        this.props.history.push("/");
      }
    }
  }

  setDataVariable = (k, v) => {
    if (ENVIRONMENT == "development") {
      console.log("comparing passwords");
    }
    let obj = {};
    obj[k] = v;
    if (k == "cpassword") {
      if (this.state.password === v) {
        obj["cpasswordSame"] = true;
      } else {
        obj["cpasswordSame"] = false;
      }
    }
    this.setState((prevState) => {
      return {
        ...prevState,
        ...obj,
        // cpasswordSame: prevState.password ===
      };
    });
  };

  updatePassword = () => {
    if (this.state.password === this.state.cpassword) {
      callService(
        `${BASE_API_URL}/change_password`,
        "POST",
        {},
        {
          member_id: this.props.user.userdata.id,
          opassword: this.state.opassword,
          password: this.state.password,
          cpassword: this.state.cpassword,
        }
      )
        .then((resp) => {
          return resp.json();
        })
        .then((respJson) => {
          if (ENVIRONMENT == "development") {
            console.log(respJson);
          }
          if (respJson.status == "Success") {
            if (ENVIRONMENT == "development") {
              console.log("password updated successfully");
            }
            this.setState((prevState) => {
              return {
                ...prevState,
                password: "",
                cpassword: "",
                opassword: "",
                cpasswordSame: true,
                showSuccessMsg: true,
              };
            });
          }
        })
        .catch((err) => {
          if (ENVIRONMENT == "development") {
            console.log(err);
          }
        });
    }
  };

  render() {
    return (
      <main className="bg_gray">
        <div className="container margin_60_20">
          <div className="row justify-content-center">
            <SideMenu />
            <div className="col-lg-9">
              <div className="box_general write_review">
                <h1 className="add_bottom_15">
                  {this.props.lang == "en"
                    ? this.props.translations.en["Change Password"]
                    : this.props.translations.ar["Change Password"]}
                </h1>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        {this.props.lang == "en"
                          ? this.props.translations.en["Enter Old Password"]
                          : this.props.translations.ar["Enter Old Password"]}
                      </label>
                      <input
                        className="form-control"
                        type="password"
                        placeholder={
                          this.props.lang == "en"
                            ? this.props.translations.en["Enter Old Password"]
                            : this.props.translations.ar["Enter Old Password"]
                        }
                        onChange={(e) =>
                          this.setDataVariable(
                            "opassword",
                            e.currentTarget.value
                          )
                        }
                        value={this.state.opassword}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        {this.props.lang == "en"
                          ? this.props.translations.en["Enter New Password"]
                          : this.props.translations.ar["Enter New Password"]}
                      </label>
                      <input
                        className="form-control"
                        type="password"
                        placeholder={
                          this.props.lang == "en"
                            ? this.props.translations.en["Enter New Password"]
                            : this.props.translations.ar["Enter New Password"]
                        }
                        onChange={(e) =>
                          this.setDataVariable(
                            "password",
                            e.currentTarget.value
                          )
                        }
                        value={this.state.password}
                      />
                      {!this.state.cpasswordSame ? (
                        <p style={{ color: "red" }}>Passwords are not equal</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        {this.props.lang == "en"
                          ? this.props.translations.en["Confirm Password"]
                          : this.props.translations.ar["Confirm Password"]}
                      </label>
                      <input
                        className="form-control"
                        type="password"
                        placeholder={
                          this.props.lang == "en"
                            ? this.props.translations.en["Confirm Password"]
                            : this.props.translations.ar["Confirm Password"]
                        }
                        onChange={(e) =>
                          this.setDataVariable(
                            "cpassword",
                            e.currentTarget.value
                          )
                        }
                        value={this.state.cpassword}
                      />
                      {!this.state.cpasswordSame ? (
                        <p style={{ color: "red" }}>Passwords are not equal</p>
                      ) : null}
                    </div>
                  </div>
                </div>
                <a onClick={this.updatePassword} className="btn_1">
                  {this.props.lang == "en"
                    ? this.props.translations.en["Save"]
                    : this.props.translations.ar["Save"]}
                </a>
                {this.state.showSuccessMsg ? (
                  <p style={{ color: "green" }}>
                    Password Updated successfully
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations,
    lang: state.lang.lang,
    products: state.products.data,
    categories: state.categories.items,
    cart: state.cart,
    settings: state.settings.data,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setSelectedProduct: (prod_id) => dispatch(getSelectedProduct(prod_id)),
    setLang: (lang) =>
      dispatch({
        type: actionTypes.SET_LANGUAGE,
        payload: { lang: lang },
      }),
    setSelectedProduct: (prod_id) =>
      dispatch({
        type: actionTypes.SET_SELECTED_PRODUCT,
        payload: { product: prod_id },
      }),
    handleProductPopUp: (val) => {
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: val },
      });
    },
    handleAuthModal: (val) => {
      dispatch({
        type: actionTypes.HANDLE_AUTH_MODAL,
        payload: { showModal: val },
      });
    },
    updateUserData: (val) => {
      dispatch({
        type: actionTypes.UPDATE_USERDATA,
        payload: { userdata: val },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
