import { BASE_API_URL, getCookie } from "../../config";
import * as actionTypes from "../actions/actions";
import { secureStorage } from "../../locStore";
import { store } from "../../index";
import { cloneDeep } from "lodash";
import CryptoJS from 'crypto-js';
import { callService } from "../../services";
// const SID = getCookie('sid');
const SID = secureStorage.getItem("sid");
// export const updateshopState = () => {
//     return {

//     }
// }

export const syncUpdateDeliveryInfo = (resultJson) => {
  let currentStore = store.getState();
  let checkoutData = cloneDeep(currentStore.checkoutData);
  checkoutData.delivery_info = {
    ...checkoutData.delivery_info,
    ...resultJson,
  };
  return {
    type: actionTypes.UPDATE_CHECKOUTDATA,
    payload: {
      checkoutData,
    },
  };
};

export const updateDeliveryInfo = (areaId) => {
  return (dispatch) => {
    // console.log(`${BASE_API_URL}/categories/${SID}`);
    console.log("hit the delivery info action");
    console.log(`${BASE_API_URL}/delivery_info/${areaId}`);

    let date = new Date;
  let strTime = date.toLocaleString("en-US", {
    timeZone: `Asia/Kuwait`
  });
  let encKey = "jdsdas4gsdgs3323j"; 
  let text = "weby_"+strTime;
  let iv = CryptoJS.enc.Hex.parse("FgLFXEr1MZl2mEnk");
  //console.log(iv,"yy");
  var encryptedText = CryptoJS.AES.encrypt(text, encKey, { iv: iv }).toString();
     
  callService(`${BASE_API_URL}/delivery_info/${areaId}`,"GET")
      .then((result) => {
        return result.json();
      })
      .then((resultJson) => {
        console.log(resultJson);
        // this.props.updateShop(resultJson);
        console.log("got result and dispatching syncupdate");
        dispatch(syncUpdateDeliveryInfo(resultJson));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
