import React, { Component, Fragment } from "react";
import { Modal } from "react-bootstrap";
import * as actionTypes from "../../../store/actions/actions";
import { connect } from "react-redux";

class ErrorPop extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.closeErrPop}
        // backdrop="static"
        dialogClassName="checkoutErrorPop"
        keyboard={false}
        id="checkoutErrorPop"
      >
        <>
          <Modal.Body
            style={{
              textAlign: "center",
            }}
            className={this.props.lang == "ar" ? "arabic-modal-dialog" : null}
          >
            <p
              style={{
                color: "red",
              }}
            >
              <b>{this.props.errorMsg}</b>
            </p>
          </Modal.Body>
        </>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedProduct: state.selectedProduct,
    lang: state.lang.lang,
    translations: state.translations,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setSelectedProduct: (prod_id) => dispatch(getSelectedProduct(prod_id)),
    setSelectedProduct: (product) =>
      dispatch({
        type: actionTypes.SET_SELECTED_PRODUCT,
        payload: { product: product },
      }),
    handleProductPopUp: (val) => {
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: val },
      });
    },
    setShowProductPopUp: (show_product_popup) =>
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: show_product_popup },
      }),
    addItemToCart: (product) =>
      dispatch({ type: actionTypes.ADD_CART_ITEM, payload: { item: product } }),
    updateCart: (product) =>
      dispatch({ type: actionTypes.ADD_CART_ITEM, payload: { item: product } }),
    removeItemFromCart: (i, product) =>
      dispatch({
        type: actionTypes.REMOVE_CART_ITEM,
        payload: { item: product, index: i },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPop);
