import * as actionTypes from "../actions/actions";

const initialState = {
  items: [],
};

const branchesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_BRANCHES:
      let branches = action.payload.branches;
      return {
        items: branches,
      };
  }
  return state;
};

export default branchesReducer;
