import React, { Component } from "react";
import { BASE_API_URL, ENVIRONMENT } from "../../../config";
import { secureStorage } from "../../../locStore";
import { cloneDeep } from "lodash";
import Select from "react-select";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actions";
class AdditionalInfo extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="form-group">
        <label>
          {this.props.lang == "en"
            ? this.props.translations.en["Additional Information"]
            : this.props.translations.ar["Additional Information"]}
        </label>
        <input
          className="form-control"
          placeholder={
            this.props.lang == "en"
              ? this.props.translations.en["Additional Information"]
              : this.props.translations.ar["Additional Information"]
          }
          value={this.props.checkoutData.additional_information}
          onChange={(e) =>
            this.props.setDataVariable(
              "additional_information",
              e.currentTarget.value
            )
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // shop: state.shop,
    lang: state.lang.lang,
    settings: state.settings.data,
    shop: state.shop,
    cart: state.cart,
    lang: state.lang.lang,
    translations: state.translations,
    showSignInModal: state.handleSignInModal.show,
    selectedProduct: state.selectedProduct,
    countries: state.countries.data,
    branches: state.branches.items,
    governates: state.governates.data,
    delivery_location: state.deliveryLocation.data.area,
    pickup_location: state.deliveryLocation.data.branch,
    delivery_location_governate: state.deliveryLocation.data.governate,
    delivery_mode: state.deliveryMode,
    currency: state.currency,
    multiplier: state.multiplier,
    user: state.user,
    checkoutData: state.checkoutData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // updateShop: () => dispatch(updateShopDetails()),
    // getCategories: () => dispatch(getCategories()),
    setLang: (lang) =>
      dispatch({ type: actionTypes.SET_LANGUAGE, payload: { lang: lang } }),
    setShowProductPopUp: (show_product_popup) =>
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: show_product_popup },
      }),
    resetCart: () => dispatch({ type: actionTypes.RESET_CART, payload: null }),
    set_deliveryLocation: (location) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_LOCATION,
        payload: { location: location },
      }),
    set_deliveryMode: (modeData) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_MODE,
        payload: { modeData },
      }),
    set_tempDeliveryMode: (modeData) =>
      dispatch({
        type: actionTypes.SET_TEMP_DELIVERY_MODE,
        payload: { modeData },
      }),
    addItemToCart: (i, product) =>
      dispatch({
        type: actionTypes.ADD_CART_ITEM,
        payload: { item: product, index: i },
      }),
    updateCart: (product) =>
      dispatch({ type: actionTypes.ADD_CART_ITEM, payload: { item: product } }),
    removeItemFromCart: (i, product) =>
      dispatch({
        type: actionTypes.REMOVE_CART_ITEM,
        payload: { item: product, index: i },
      }),
    reloadCart: (cart) =>
      dispatch({
        type: actionTypes.SET_CART_ON_RELOAD,
        payload: { cart: cart },
      }),
    popItem: (i) =>
      dispatch({
        type: actionTypes.POPITEM,
        payload: { popIndex: i },
      }),
    handleAuthModal: (val) => {
      dispatch({
        type: actionTypes.HANDLE_AUTH_MODAL,
        payload: { showModal: val },
      });
    },
    updateCheckoutData: (checkoutData) => {
      dispatch({
        type: actionTypes.UPDATE_CHECKOUTDATA,
        payload: { checkoutData: checkoutData },
      });
    },
    // setCurrency: (currency) => dispatch({type: actionTypes.SET_CURRENCY, payload: { base_currency: currency}}),
    // setMultiplier: (multiplier) => dispatch({type: actionTypes.SET_MULTIPLIER, payload: { multiplier: multiplier}})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalInfo);
