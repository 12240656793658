import reportWebVitals from "./reportWebVitals";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { secureStorage } from "./locStore";
import * as actionTypes from "./store/actions/actions";
import SimpleReactLightbox from "simple-react-lightbox";
import thunk from "redux-thunk";

// import testReducer from './store/reducers/testReducer';
import cartReducer from "./store/reducers/cartReducer";
// import shopReducer from './store/reducers/shopReducer';
// import categoriesReducer from './store/reducers/categoriesReducer';
import languageReducer from "./store/reducers/languageReducer";
// import currencyReducer from './store/reducers/currencyreducer';
import authReducer from "./store/reducers/authReducer";
import cloneDeep from "lodash/cloneDeep";
// import multiplierReducer from './store/reducers/multiplierReducer';
// import translationReducer from './store/reducers/translationReducer';
import { BASE_API_URL, ENVIRONMENT } from "./config";
import bannersReducer from "./store/reducers/bannersReducer";
import branchesReducer from "./store/reducers/branchesReducer";
import categoriesReducer from "./store/reducers/categoriesReducer";
import translationReducer from "./store/reducers/translationReducer";
import settingsReducer from "./store/reducers/settingsReducer";
import governatesReducer from "./store/reducers/governatesReducer";
import deliveryLocationReducer from "./store/reducers/deliveryLocationReducer";
import selectedCategoryReducer from "./store/reducers/selectedCategoryReducer";
import qtyIndicatorReducer from "./store/reducers/quantityIndicator";
import searchInputReducer from "./store/reducers/searchInputReducer";
import countriesReducer from "./store/reducers/countriesReducer";
import productsLoaderReducer from "./store/reducers/productsLoaderReducer";
import handleSignInModaReducer from "./store/reducers/handleSignInModalReducer";
import popUpProductReducer from "./store/reducers/popUpProductReducer";
import shopReducer from "./store/reducers/shopReducer";
import currenciesReducer from "./store/reducers/currenciesReducer";
import currencyReducer from "./store/reducers/currencyreducer";
import multiplierReducer from "./store/reducers/multiplierReducer";
import productsReducer from "./store/reducers/productsReducer";
import userReducer from "./store/reducers/userReducer";
import { initialCheckoutData } from "./containers/CheckoutPage/CheckoutPage";
import deliveryModeReducer from "./store/reducers/deliveryModeReducer";
import pickupReducer from "./store/reducers/pickupReducer";
import tempDeliveryModeReducer from "./store/reducers/tempDeliveryModeReducer";
import checkoutDataReducer from "./store/reducers/checkoutDataReducer";
import galleryReducer from "./store/reducers/galleryReducer";

// // import { result } from 'lodash';
let domain_name = window.location.hostname;

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  return true;
}

function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

const rootReducer = combineReducers({
  // test: testReducer,
  cart: cartReducer,
  settings: settingsReducer,
  shop: shopReducer,
  categories: categoriesReducer,
  governates: governatesReducer,
  countries: countriesReducer,
  lang: languageReducer,
  banners: bannersReducer,
  branches: branchesReducer,
  selectedCategory: selectedCategoryReducer,
  qtyIndicator: qtyIndicatorReducer,
  currencies: currenciesReducer,
  currency: currencyReducer,
  multiplier: multiplierReducer,
  translations: translationReducer,
  deliveryLocation: deliveryLocationReducer,
  searchInput: searchInputReducer,
  productsLoader: productsLoaderReducer,
  handleSignInModal: handleSignInModaReducer,
  selectedProduct: popUpProductReducer,
  products: productsReducer,
  auth: authReducer,
  user: userReducer,
  deliveryMode: deliveryModeReducer,
  tempDeliveryMode: tempDeliveryModeReducer,
  pickupLocation: pickupReducer,
  checkoutData: checkoutDataReducer,
  gallery: galleryReducer,
});

const logBoss = (store) => {
  return (next) => {
    return (action) => {
      if (ENVIRONMENT == "development") {
        console.log(
          "we can do whatever we want here and it will be executed before action reaches reducer after dispatching"
        );
        console.log(store.getState());
        console.log("I just printed the current state");
      }
      const result = next(action);
      return result;
    };
  };
};

const composeEnhancers =
  ENVIRONMENT == "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;
// const composeEnhancers = compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(logBoss, thunk))
);

//   // if(secureStorage.getItem('shopId') == "undefined" || secureStorage.getItem('shopId') == null) {
//   //   console.log("shopId available", secureStorage.getItem('cart'));
//   //   secureStorage.setItem('shopId');
//   // }

if (
  secureStorage.getItem("base_currency") == "undefined" ||
  secureStorage.getItem("base_currency") == null
) {
  secureStorage.setItem("base_currency", "KWD");
} else {
  store.dispatch({
    type: actionTypes.SET_CURRENCY,
    payload: { base_currency: secureStorage.getItem("base_currency") },
  });
}
if (ENVIRONMENT == "development") {
  console.log(`--------------------------------------------------------`);
  console.log("index.js -----------------------------------------------");
  console.log(secureStorage.getItem("delivery_location"));
  console.log(`--------------------------------------------------------`);
}
if (
  secureStorage.getItem("delivery_location") === undefined ||
  secureStorage.getItem("delivery_location") === null
) {
  secureStorage.setItem("delivery_location", { governate: null, area: null });
  store.dispatch({
    type: actionTypes.SET_DELIVERY_LOCATION,
    payload: { location: { governate: null, area: null } },
  });
} else {
  let deliveryLocation = cloneDeep(secureStorage.getItem("delivery_location"));
  if (ENVIRONMENT == "development") {
    console.log(deliveryLocation);
    console.log("setting delivery location");
  }
  store.dispatch({
    type: actionTypes.SET_DELIVERY_LOCATION,
    payload: { location: deliveryLocation },
  });
}

if (
  secureStorage.getItem("delivery_mode") === undefined ||
  secureStorage.getItem("delivery_mode") === null
) {
  secureStorage.setItem("delivery_mode", {
    type: "delivery",
    delivery_schedule: false,
    scheduleDate: null,
    scheduleTime: null,
  });
  store.dispatch({
    type: actionTypes.SET_DELIVERY_MODE,
    payload: {
      modeData: {
        type: "delivery",
        delivery_schedule: false,
        scheduleDate: null,
        scheduleTime: null,
      },
    },
  });
} else {
  let deliveryMode = cloneDeep(secureStorage.getItem("delivery_mode"));
  if (ENVIRONMENT == "development") {
    if (ENVIRONMENT == "development") {
      console.log(
        deliveryMode,
        "------------delivery mode data in index.js ------",
        typeof deliveryMode
      );
    }
  }

  store.dispatch({
    type: actionTypes.SET_DELIVERY_MODE,
    payload: { modeData: deliveryMode },
  });

  // store.dispatch({
  //   type: actionTypes.UPDATE_SCHEDULE,
  //   payload: { scheduleData: deliveryMode },
  // });
}

if (
  secureStorage.getItem("pickup_location") === undefined ||
  secureStorage.getItem("pickup_location") === null
) {
  if (ENVIRONMENT == "development") {
    if (ENVIRONMENT == "development") {
      console.log(
        secureStorage.getItem("pickup_location"),
        "------------pick up locatoin if setting in index.js"
      );
    }
  }
  secureStorage.setItem("pickup_location", null);
  store.dispatch({
    type: actionTypes.SET_PICKUP_LOCATION,
    payload: { branch: null },
  });
} else {
  if (ENVIRONMENT == "development") {
    if (ENVIRONMENT == "development") {
      console.log(
        secureStorage.getItem("pickup_location"),
        "------------pick up locatoin setting in index.js"
      );
    }
  }
  let pickupLocation = cloneDeep(secureStorage.getItem("pickup_location"));

  store.dispatch({
    type: actionTypes.SET_PICKUP_LOCATION,
    payload: { branch: pickupLocation },
  });
}

if (
  secureStorage.getItem("cart") === undefined ||
  secureStorage.getItem("cart") === null
) {
  secureStorage.setItem("cart", { calculated_total: 0, items: [] });
  store.dispatch({
    type: actionTypes.SET_CART_ON_RELOAD,
    payload: { cart: { calculated_total: 0, items: [] } },
  });
} else {
  let cart = cloneDeep(secureStorage.getItem("cart"));

  store.dispatch({
    type: actionTypes.SET_CART_ON_RELOAD,
    payload: { cart: cart },
  });
}

if (
  secureStorage.getItem("lang") == "undefined" ||
  secureStorage.getItem("lang") == null
) {
  // console.log("got the cart", secureStorage.getItem('cart'));
  secureStorage.setItem("lang", "en");
} else {
  // store.dispatch({type: actionTypes.SET_LANGUAGE, payload: {lang: secureStorage.getItem('lang')}});
  let lang = secureStorage.getItem("lang");
  let mainBody = document.getElementById("root");
  let main = document.getElementsByTagName("body")[0];
  if (lang == "ar") {
    store.dispatch({ type: actionTypes.SET_LANGUAGE, payload: { lang: "ar" } });
    secureStorage.setItem("lang", "ar");
    mainBody.classList.remove("ltr");
    mainBody.classList.add("rtl");
    main.classList.remove("ltr");
    main.classList.add("rtl");
    //
    // // document.body.appendChild(`<link rel="stylesheet" href="%PUBLIC_URL%/assets/css/rtl.css">`);
    // var arabicDiv = document.getElementById('arabicDiv');
    // if(arabicDiv != null) {
    //     arabicDiv.remove();
    // }

    // let adders = document.createElement("div");
    // var att = document.createAttribute("id");       // Create a "class" attribute
    // att.value = "arabicDiv";                           // Set the value of the class attribute
    // adders.setAttributeNode(att);
    // let arabStyles = document.createElement('link');
    // arabStyles.type = 'text/css';
    // arabStyles.rel = 'stylesheet';
    // arabStyles.href =  '/assets/css/rtl.css';
    // adders.appendChild(arabStyles);

    // document.body.appendChild(adders);
  } else if (lang == "en") {
    store.dispatch({ type: actionTypes.SET_LANGUAGE, payload: { lang: "en" } });
    secureStorage.setItem("lang", "en");
    mainBody.classList.remove();
    mainBody.classList.add("ltr");
    main.classList.remove("rtl");
    main.classList.add("ltr");
    // mainBody.classList.remove("rtl");
  }
}

if (
  secureStorage.getItem("multiplier") == "undefined" ||
  secureStorage.getItem("multiplier") == null
) {
  // console.log("got the cart", secureStorage.getItem('cart'));
  secureStorage.setItem("multiplier", 1);
} else {
  store.dispatch({
    type: actionTypes.SET_MULTIPLIER,
    payload: { multiplier: secureStorage.getItem("multiplier") },
  });
}

if (
  secureStorage.getItem("userdata") == "undefined" ||
  secureStorage.getItem("userdata") == null
) {
  // console.log("got the cart", secureStorage.getItem('cart'));
  // secureStorage.setItem("userData", {
  //   isLoggedIn: false,
  //   userdata: null,
  // });
} else {
  if (ENVIRONMENT == "development") {
    if (ENVIRONMENT == "development") {
      console.log("userdata-------------------");
      console.log(secureStorage.getItem("userdata"));
    }
  }
  let userdata = secureStorage.getItem("userdata");
  store.dispatch({
    type: actionTypes.UPDATE_USERDATA,
    payload: { userdata: userdata.userdata },
  });
}

if (
  secureStorage.getItem("checkoutData") == "undefined" ||
  secureStorage.getItem("checkoutData") == null
) {
  // console.log("got the cart", secureStorage.getItem('cart'));

  initialCheckoutData.discount = 0;
  initialCheckoutData.coupon = "";
  initialCheckoutData.coupon_err = null;
  secureStorage.setItem("checkoutData", initialCheckoutData);
} else {
  let checkoutData = secureStorage.getItem("checkoutData");
  if (ENVIRONMENT == "development") {
    console.log("printing store");
  }

  let currentStore = store.getState();
  let deliveryLocation = currentStore.deliveryLocation.data.area;
  let currentLang = currentStore.lang.lang;
  if (deliveryLocation) {
    checkoutData.area = deliveryLocation;
    checkoutData.areaId = {
      label:
        currentLang == "en"
          ? deliveryLocation.title
          : deliveryLocation.title_ar,
      value: deliveryLocation.id,
    };
  }
  if (ENVIRONMENT == "development") {
    console.log(store.getState());
    console.log(checkoutData.discount);
  }
  checkoutData.discount = 0;
  checkoutData.coupon = "";
  checkoutData.coupon_err = null;
  checkoutData.address_list = [];
  checkoutData.selected_address = null;
  checkoutData.delivery_info.delivery_now = "1";
  secureStorage.setItem("checkoutData", checkoutData);
  if (ENVIRONMENT == "development") {
    console.log(checkoutData);
  }
  store.dispatch({
    type: actionTypes.UPDATE_CHECKOUTDATA,
    payload: { checkoutData },
  });

  // store.dispatch({
  //   type: actionTypes.SET_MULTIPLIER,
  //   payload: { multiplier: secureStorage.getItem("multiplier") },
  // });
}
if (ENVIRONMENT == "development") {
  console.log(store.getState());
}

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <SimpleReactLightbox>
      <App />
    </SimpleReactLightbox>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

export { store };

reportWebVitals();
