import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { BASE_API_URL, ENVIRONMENT } from "../../config";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import cloneDeep from "lodash/cloneDeep";
import { secureStorage } from "../../locStore";
// import carouselStyles from "./ViewPhotos.css";

import * as actionTypes from "../../store/actions/actions";
import DatePicker from "react-date-picker";
import { updateDeliveryInfo } from "../../store/actions/checkout";
import { callService } from "../../services";
// import DatePicker from "react-date-picker/dist/entry.nostyle";
class DeliveryMode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      governates: [],
      available_slots: [],
      loading: true,
      tempDeliveryMode: "delivery",
      currentDeliveryMethod: "delivery", // delivery, pickup,
      deliverySchedule: null,
      deliveryScheduleDate: null,
      deliveryScheduleTime: null,
      area: null,
      areaId: null,
      branch: null,
      branchId: null,
      delivery_charges: null,
      selectedDate: new Date(),
      selectedSlot: {
        label: null,
        value: null,
      },
      showTimeSlots: true,
      showPickupOptions: false,
      showDeliveryOptions: true,
    };
  }

  componentDidMount() {
    let requestDate = `${this.state.selectedDate.getDate()}-${
      this.state.selectedDate.getMonth() + 1
    }-${this.state.selectedDate.getFullYear()}`;
    let govbranch = [
      callService(`${BASE_API_URL}`).then((res) => res.json()),
      callService(`${BASE_API_URL}/branches`).then((res) => res.json()),
      callService(`${BASE_API_URL}/delivery_slots/${requestDate}`).then((res) =>
        res.json()
      ),
    ];

    Promise.all(govbranch)
      .then((resp) => {
        if (ENVIRONMENT == "development") {
          console.log(resp);
        }
        this.props.set_branches(resp[1]);
        this.props.set_governates(resp[0].areas);
        if (ENVIRONMENT == "development") {
          console.log(resp[1]);
        }
        this.setState((prevState) => {
          return {
            ...prevState,
            loading: false,
            available_slots: resp[2][0].timings,
          };
        });
      })
      .catch((err) => {
        if (ENVIRONMENT == "development") {
          console.log(err);
        }
      });
    // callService(`${BASE_API_URL}/gallery`)
    //   .then((resp) => {
    //     return resp.json();
    //   })
    //   .then((respJson) => {
    //     console.log(respJson);
    //     this.setState((prevState) => {
    //       return {
    //         ...prevState,
    //         loading: false,
    //         gallery: respJson.gallery,
    //       };
    //     });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }

  componentDidUpdate(prevProps) {
    let requestDate = `${this.state.selectedDate.getDate()}-${
      this.state.selectedDate.getMonth() + 1
    }-${this.state.selectedDate.getFullYear()}`;
    if (ENVIRONMENT == "development") {
      console.log(`${BASE_API_URL}/delivery_slots/${requestDate}`);
    }
    if (this.props.show != prevProps.show) {
      let govbranch = [
        callService(`${BASE_API_URL}`).then((res) => res.json()),
        callService(`${BASE_API_URL}/branches`).then((res) => res.json()),
        callService(`${BASE_API_URL}/delivery_slots/${requestDate}`).then((res) =>
          res.json()
        ),
      ];

      Promise.all(govbranch)
        .then((resp) => {
          if (ENVIRONMENT == "development") {
            console.log(resp);
          }
          this.props.set_branches(resp[1]);
          this.props.set_governates(resp[0].areas);
          this.setState(
            (prevState) => {
              return {
                ...prevState,
                loading: false,
                available_slots: resp[2][0].timings,
                // tempDeliveryMode:
                //   this.props.delivery_mode.type == "delivery"
                //     ? "pickup"
                //     : "delivery",
                tempDeliveryMode: this.props.temp_delivery_mode.type,
              };
            },
            () => {
              // this.props.set_tempDeliveryMode({
              //   type: this.state.currentDeliveryMethod,
              //   // scheduleDate:
              // });
            }
          );
        })
        .catch((err) => {
          if (ENVIRONMENT == "development") {
            console.log(err);
          }
        });
    }
  }

  setTempDeliveryMode = (val) => {
    let currentDeliveryMode = val;
    if (ENVIRONMENT == "development") {
      console.log(val);
    }
    this.props.set_tempDeliveryMode({
      type: val,
      // scheduleDate:
    });
    if (val == "pickup") {
      // this.props.set_tempDeliveryMode({
      //   type: this.state.currentDeliveryMethod,
      //   // scheduleDate:
      // });
      if (!this.props.pickup_location) {
        this.setState((prevState) => {
          return {
            ...prevState,
            tempDeliveryMode: currentDeliveryMode,
          };
        });
      } else {
        this.setState((prevState) => {
          return {
            ...prevState,
            tempDeliveryMode: currentDeliveryMode,
            currentDeliveryMethod: currentDeliveryMode,
          };
        });
      }
    } else if (val == "delivery") {
      if (!this.props.delivery_location) {
        this.setState((prevState) => {
          return {
            ...prevState,
            tempDeliveryMode: currentDeliveryMode,
          };
        });
      } else {
        this.setState((prevState) => {
          return {
            ...prevState,
            tempDeliveryMode: currentDeliveryMode,
            currentDeliveryMethod: currentDeliveryMode,
          };
        });
      }
    }
  };

  setDeliveryMode = (val) => {
    let currentDeliveryMode = val;
    if (ENVIRONMENT == "development") {
      console.log(val);
    }

    this.setState(
      (prevState) => {
        return {
          ...prevState,
          currentDeliveryMethod: currentDeliveryMode,
        };
      },
      () => {
        this.props.set_deliveryMode({
          type: this.state.currentDeliveryMethod,
          // scheduleDate:
        });
      }
    );
  };

  selectedBranch = (branchId) => {
    let branch = null;
    let isBranchExist = this.props.branches.filter((branch) => {
      return branch.id == branchId;
    });
    if (isBranchExist.length > 0) {
      branch = isBranchExist[0];
    }
    if (branch) {
      // secureStorage.setItem("pickup_location", branch);
      this.props.set_pickupLocation(branch);
      this.props.set_deliveryLocation({ governate: null, area: null });
      this.props.set_deliveryMode({
        type: "pickup",
        // scheduleDate:
      });
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            branch: branch,
            branchId: {
              label: this.props.lang == "en" ? branch.title : branch.title_ar,
              value: branch.id,
            },
            delivery_charges: branch.area.price,
            area: null,
            areaId: null,
            currentDeliveryMethod: "pickup",
          };
        },
        () => {
          this.props.setShow(false);
          let checkoutData = cloneDeep(this.props.checkoutData);
          checkoutData.delivery_info = {
            minimum: 0,
            title: null,
            title_ar: null,
            busy: 0,
            closed: 0,
            price: 0,
          };
          this.props.updateCheckoutData(checkoutData);
          this.props.set_pickupLocation(branch);
          this.props.set_deliveryLocation({ governate: null, area: null });
          this.props.set_deliveryMode({
            type: "pickup",
            // scheduleDate:
          });
          secureStorage.setItem("pickup_location", branch);
          if (ENVIRONMENT == "development") {
            setTimeout(() => {
              console.log(secureStorage.getItem("pickup_location"));
              console.log(
                "----------------------------------- set Location in two second ---------------------------"
              );
              console.log(
                secureStorage.getItem("delivery_mode"),
                "--------------------"
              );
            }, 3000);
          }
        }
      );
    }
  };

  selectedLocation = (areaId) => {
    let governate = null;
    let area = null;
    this.props.governates.map((gov) => {
      let isAreaExist = gov.list.filter((area) => {
        return area.id === areaId;
      });

      if (Array.isArray(isAreaExist) && isAreaExist.length > 0) {
        governate = gov;
        area = isAreaExist[0];
      }
    });
    if (ENVIRONMENT == "development") {
      console.log(governate);
      console.log(area);
      console.log({
        governate: governate,
        area: area,
      });
    }
    // secureStorage.setItem("delivery_location", {
    //   governate: governate,
    //   area: area,
    // });
    this.props.set_deliveryLocation({ governate: governate, area: area });
    this.props.set_pickupLocation(null);

    this.props.set_deliveryMode({
      type: "delivery",
      // scheduleDate:
    });
    if (ENVIRONMENT == "development") {
      console.log("setting area , ", area);
    }
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          area: area,
          areaId: {
            label: this.props.lang == "en" ? area.title : area.title_ar,
            value: area.id,
          },
          branch: null,
          branchId: null,
          delivery_charges: area.price,
          currentDeliveryMethod: "pickup",
        };
      },
      //   () => {
      //     let myState = cloneDeep(this.state);
      //     secureStorage.setItem("checkoutData", myState);
      //   }

      () => {
        if (ENVIRONMENT == "development") {
          setTimeout(() => {
            console.log(secureStorage.getItem("delivery_location"));
            console.log(
              "----------------------------------- set Location in one second ---------------------------"
            );
          }, 1000);
        }

        this.props.setShow(false);
        this.props.updateDeliveryInfo(areaId);
        this.props.set_deliveryLocation({ governate: governate, area: area });

        secureStorage.setItem("delivery_location", {
          governate: governate,
          area: area,
        });

        this.props.set_deliveryMode({ type: "delivery" });
        if (ENVIRONMENT == "development") {
          setTimeout(() => {
            console.log(secureStorage.getItem("delivery_location"));
            console.log(
              "----------------------------------- set Location in two second ---------------------------"
            );
            console.log(
              secureStorage.getItem("delivery_mode"),
              "--------------------"
            );
          }, 3000);
        }
      }
    );
  };

  handleDateChange = (event) => {
    if (ENVIRONMENT == "development") {
      console.log(event);
      console.log(new Date(event).getDate());
    }
    let d = new Date(event);
    let requestDate = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
    if (event) {
      callService(`${BASE_API_URL}/delivery_slots/${requestDate}`)
        .then((res) => res.json())
        .then((resJson) => {
          if (ENVIRONMENT == "development") {
            console.log(resJson);
          }
          this.setState(
            (prevState) => {
              let currentState = cloneDeep(prevState);
              return {
                ...currentState,
                selectedDate: event,
                available_slots: resJson[0].timings,
                showTimeSlots: true,
                selectedSlot: {
                  label: null,
                  value: null,
                },
              };
            },
            () => {
              this.props.update_schedule({
                selectedDate: event,
                selectedSlot: {
                  label: null,
                  value: null,
                },
              });
            }
          );
        })
        .catch((err) => {
          if (ENVIRONMENT == "development") {
            console.log(err);
          }
        });
    }
    // console.log(event);
    // let d = new Date(event);
    // console.log(
    //   `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}T${d.getHours()}-${d.getMinutes()}`
    // );
    // if (
    //   this.props.delivery_location !== null &&
    //   this.props.delivery_location !== undefined
    // ) {
    //   let area = this.props.delivery_location.id;
    //   let date = `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}`;
    // } else {
    //   alert("Please select delivery location first");
    // }
  };

  selectedTimeSlot = (timeSlot) => {
    if (ENVIRONMENT == "development") {
      console.log(timeSlot);
    }
    this.setState(
      (prevState) => {
        let currentState = cloneDeep(prevState);
        return {
          ...currentState,

          selectedSlot: timeSlot,
        };
      },
      () => {
        this.props.update_schedule({
          selectedDate: this.state.selectedDate,
          selectedSlot: timeSlot,
        });
        // scheduleDate:
      }
    );
  };

  render() {
    let mobile = false;
    if (window.matchMedia("screen and (max-width: 990px)").matches) {
      mobile = true;
    }

    let slotOptions = this.state.available_slots.map((slot, index) => {
      let retslot = {
        label: slot.string,
        value: slot.value,
      };
      return retslot;
    });
    let options = this.props.governates.map((governate, index) => {
      let item = {
        label: this.props.lang == "en" ? governate.title : governate.title_ar,
      };
      let options = governate.list.map((area, index) => {
        let option = {
          label: this.props.lang == "en" ? area.title : area.title_ar,
          value: area.id,
        };
        return option;
      });

      item["options"] = options;
      return item;
    });

    let branchOptions = this.props.branches.map((branch, index) => {
      let item = {
        label: this.props.lang == "en" ? branch.title : branch.title_ar,
        value: branch.id,
      };
      // let options = branch.list.map((area, index) => {
      //   let option = {
      //     label: this.props.lang == "en" ? area.title : area.title_ar,
      //     value: area.id,
      //   };
      //   return option;
      // });

      // item["options"] = options;
      return item;
    });

    let selectedArea = this.props.delivery_location
      ? {
          label:
            this.props.lang == "en"
              ? this.props.delivery_location.title
              : this.props.delivery_location.title_ar,
          value: this.props.delivery_location.id,
        }
      : null;
    let selectedBranch = this.props.pickup_location
      ? {
          label:
            this.props.lang == "en"
              ? this.props.pickup_location.title
              : this.props.pickup_location.title_ar,
          value: this.props.pickup_location.id,
        }
      : null;
    if (ENVIRONMENT == "development") {
      console.log("is mobile --- ", mobile);
    }
    return !mobile ? (
      <Modal
        show={this.props.show}
        onHide={() => this.props.setShow(false)}
        dialogClassName="deliveryModeModal"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {this.props.lang == "ar" &&
            this.props.translations.ar["Select Delivery Mode"] != null
              ? this.props.translations.ar["Select Delivery Mode"]
              : "Select Delivery Mode"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="box_order mobile_fixed" style={{ display: "block" }}> */}
          {/* <div className="head">
              <h3>Select Delivery Mode</h3>
              <a className="close_panel_mobile">
                <i
                  className="icon_close"
                  onClick={() => this.props.setShow(false)}
                ></i>
              </a>
            </div> */}
          <div className="main">
            <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
              <div
                className={
                  this.props.temp_delivery_mode.type == "delivery"
                    ? "btn_1 gradient full-width"
                    : "btn_1 full-width"
                }
                style={
                  this.props.temp_delivery_mode.type != "delivery"
                    ? {
                        textAlign: "center",
                        marginRight: "5px",
                        backgroundColor: "#adadad",
                      }
                    : {
                        textAlign: "center",
                        marginRight: "5px",
                        backgroundImage:
                          // "linear-gradient(to right, #f3723b, #e54750)",
                          "linear-gradient(to right, var(--third-color), var(--fourth-color))",
                      }
                }
                onClick={() => this.setTempDeliveryMode("delivery")}
              >
                {this.props.lang == "ar" &&
                this.props.translations.ar["Delivery"] != null
                  ? this.props.translations.ar["Delivery"]
                  : "Delivery"}
              </div>
              {this.props.settings.pickup == "1" ? (
                <div
                  className={
                    this.props.temp_delivery_mode.type == "pickup"
                      ? "btn_1 gradient full-width"
                      : "btn_1 full-width"
                  }
                  style={
                    this.props.temp_delivery_mode.type != "pickup"
                      ? {
                          textAlign: "center",
                          marginRight: "5px",
                          backgroundColor: "#adadad",
                        }
                      : {
                          textAlign: "center",
                          marginRight: "5px",
                          backgroundImage:
                            "linear-gradient(to right, var(--third-color), var(--fourth-color))",
                        }
                  }
                  onClick={() => this.setTempDeliveryMode("pickup")}
                >
                  {this.props.lang == "ar" &&
                  this.props.translations.ar["Pick Up"] != null
                    ? this.props.translations.ar["Pick Up"]
                    : "Pick Up"}
                </div>
              ) : null}
            </div>
            {!this.state.loading ? (
              <div
                style={{ display: "flex", flexDirection: "column", flex: 1 }}
              >
                {this.props.temp_delivery_mode.type == "delivery" ? (
                  <>
                    <label>
                      {this.props.lang == "en"
                        ? this.props.translations.en["Area"]
                        : this.props.translations.ar["Area"]}{" "}
                      <span className="required">*</span>
                    </label>

                    <Select
                      onChange={(e) => this.selectedLocation(e.value)}
                      placeholder={
                        this.props.lang === "en"
                          ? this.props.translations.en["Select Area"]
                          : this.props.translations.ar["Select Area"]
                      }
                      className="custom-select js-example-basic-single"
                      id="inputGroupSelect04"
                      options={options}
                      value={
                        this.props.delivery_location
                          ? {
                              label:
                                this.props.lang == "en"
                                  ? this.props.delivery_location.title
                                  : this.props.delivery_location.title_ar,
                              value: this.props.delivery_location.id,
                            }
                          : null
                      }
                    ></Select>
                  </>
                ) : null}
                {this.props.settings.pickup == "1" ? (
                  this.props.temp_delivery_mode.type == "pickup" ? (
                    <>
                      <label>
                        {this.props.lang == "en"
                          ? this.props.translations.en["Branch"]
                          : this.props.translations.ar["Branch"]}{" "}
                        <span className="required">*</span>
                      </label>

                      <Select
                        onChange={(e) => this.selectedBranch(e.value)}
                        placeholder={
                          this.props.lang === "en"
                            ? this.props.translations.en["Select Branch"]
                            : this.props.translations.ar["Select Branch"]
                        }
                        className="custom-select js-example-basic-single"
                        id="inputGroupSelect04"
                        options={branchOptions}
                        value={selectedBranch}
                      ></Select>
                    </>
                  ) : null
                ) : null}
              </div>
            ) : null}
          </div>
          {/* </div> */}
        </Modal.Body>
      </Modal>
    ) : this.props.show ? (
      <div className="box_order mobile_fixed" style={{ display: "block" }}>
        <div className="head">
          <h3>
            {this.props.lang == "ar" &&
            this.props.translations.ar["Select Delivery Mode"] != null
              ? this.props.translations.ar["Select Delivery Mode"]
              : "Select Delivery Mode"}
          </h3>
          <a className="close_panel_mobile">
            <i
              className="icon_close"
              onClick={() => this.props.setShow(false)}
            ></i>
          </a>
        </div>
        <div className="main">
          <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
            <div
              className={
                // this.state.tempDeliveryMode == "delivery"
                this.props.temp_delivery_mode.type == "delivery"
                  ? "btn_1 gradient full-width"
                  : "btn_1 full-width"
              }
              style={
                this.props.temp_delivery_mode.type != "delivery"
                  ? {
                      textAlign: "center",
                      marginRight: "5px",
                      backgroundColor: "#adadad",
                    }
                  : {
                      textAlign: "center",
                      marginRight: "5px",
                      backgroundImage:
                        "linear-gradient(to right, var(--third-color), var(--fourth-color))",
                    }
              }
              onClick={() => this.setTempDeliveryMode("delivery")}
            >
              {this.props.lang == "ar" &&
              this.props.translations.ar["Delivery"] != null
                ? this.props.translations.ar["Delivery"]
                : "Delivery"}
            </div>
            {this.props.settings.pickup == "1" ? (
              <div
                className={
                  this.props.temp_delivery_mode.type == "pickup"
                    ? "btn_1 gradient full-width"
                    : "btn_1 full-width"
                }
                style={
                  this.props.temp_delivery_mode.type != "pickup"
                    ? {
                        textAlign: "center",
                        marginRight: "5px",
                        backgroundColor: "#adadad",
                      }
                    : {
                        textAlign: "center",
                        marginRight: "5px",
                        backgroundImage:
                          "linear-gradient(to right, var(--third-color), var(--fourth-color))",
                      }
                }
                onClick={() => this.setTempDeliveryMode("pickup")}
              >
                {this.props.lang == "ar" &&
                this.props.translations.ar["Pick Up"] != null
                  ? this.props.translations.ar["Pick Up"]
                  : "Pick Up"}
              </div>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              marginTop: "20px",
            }}
          >
            {this.props.temp_delivery_mode.type == "delivery" ? (
              <>
                <label style={{ marginBottom: "15px" }}>
                  {this.props.lang == "en"
                    ? this.props.translations.en["Area"]
                    : this.props.translations.ar["Area"]}{" "}
                  <span className="required">*</span>
                </label>

                <Select
                  onChange={(e) => this.selectedLocation(e.value)}
                  placeholder={
                    this.props.lang === "en"
                      ? this.props.translations.en["Select Area"]
                      : this.props.translations.ar["Select Area"]
                  }
                  className="custom-select js-example-basic-single"
                  id="inputGroupSelect04"
                  options={options}
                  value={selectedArea}
                ></Select>
              </>
            ) : null}
            {this.props.temp_delivery_mode.type == "pickup" ? (
              <>
                <label>
                  {this.props.lang == "en"
                    ? this.props.translations.en["Branch"]
                    : this.props.translations.ar["Branch"]}{" "}
                  <span className="required">*</span>
                </label>

                <Select
                  onChange={(e) => this.selectedBranch(e.value)}
                  placeholder={
                    this.props.lang === "en"
                      ? this.props.translations.en["Select Branch"]
                      : this.props.translations.ar["Select Branch"]
                  }
                  className="custom-select js-example-basic-single"
                  id="inputGroupSelect04"
                  options={branchOptions}
                  value={selectedBranch}
                ></Select>
              </>
            ) : null}
          </div>
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state) => {
  return {
    // shop: state.shop,
    lang: state.lang.lang,
    settings: state.settings.data,
    shop: state.shop,
    cart: state.cart,
    lang: state.lang.lang,
    translations: state.translations,
    showSignInModal: state.handleSignInModal.show,
    selectedProduct: state.selectedProduct,
    countries: state.countries.data,
    branches: state.branches.items,
    governates: state.governates.data,
    delivery_location: state.deliveryLocation.data.area,
    delivery_location_governate: state.deliveryLocation.data.governate,
    delivery_mode: state.deliveryMode,
    temp_delivery_mode: state.tempDeliveryMode,
    pickup_location: state.pickupLocation.data.branch,
    currency: state.currency,
    multiplier: state.multiplier,
    user: state.user,
    checkoutData: state.checkoutData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // updateShop: () => dispatch(updateShopDetails()),
    // getCategories: () => dispatch(getCategories()),
    updateDeliveryInfo: (id) => dispatch(updateDeliveryInfo(id)),
    setLang: (lang) =>
      dispatch({ type: actionTypes.SET_LANGUAGE, payload: { lang: lang } }),
    setShowProductPopUp: (show_product_popup) =>
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: show_product_popup },
      }),
    resetCart: () => dispatch({ type: actionTypes.RESET_CART, payload: null }),
    set_deliveryLocation: (location) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_LOCATION,
        payload: { location: location },
      }),
    set_pickupLocation: (branch) =>
      dispatch({
        type: actionTypes.SET_PICKUP_LOCATION,
        payload: { branch: branch },
      }),
    update_schedule: (scheduleData) =>
      dispatch({
        type: actionTypes.UPDATE_SCHEDULE,
        payload: { scheduleData },
      }),
    set_deliveryMode: (modeData) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_MODE,
        payload: { modeData },
      }),
    set_tempDeliveryMode: (modeData) =>
      dispatch({
        type: actionTypes.SET_TEMP_DELIVERY_MODE,
        payload: { modeData },
      }),
    set_branches: (branches) =>
      dispatch({
        type: actionTypes.SET_BRANCHES,
        payload: { branches: branches },
      }),
    set_governates: (governates) =>
      dispatch({
        type: actionTypes.SET_GOVERNATES,
        payload: { governates: governates },
      }),
    addItemToCart: (i, product) =>
      dispatch({
        type: actionTypes.ADD_CART_ITEM,
        payload: { item: product, index: i },
      }),
    updateCart: (product) =>
      dispatch({ type: actionTypes.ADD_CART_ITEM, payload: { item: product } }),
    removeItemFromCart: (i, product) =>
      dispatch({
        type: actionTypes.REMOVE_CART_ITEM,
        payload: { item: product, index: i },
      }),
    reloadCart: (cart) =>
      dispatch({
        type: actionTypes.SET_CART_ON_RELOAD,
        payload: { cart: cart },
      }),
    popItem: (i) =>
      dispatch({
        type: actionTypes.POPITEM,
        payload: { popIndex: i },
      }),
    handleAuthModal: (val) => {
      dispatch({
        type: actionTypes.HANDLE_AUTH_MODAL,
        payload: { showModal: val },
      });
    },
    updateCheckoutData: (checkoutData) => {
      dispatch({
        type: actionTypes.UPDATE_CHECKOUTDATA,
        payload: { checkoutData: checkoutData },
      });
    },
    // setCurrency: (currency) => dispatch({type: actionTypes.SET_CURRENCY, payload: { base_currency: currency}}),
    // setMultiplier: (multiplier) => dispatch({type: actionTypes.SET_MULTIPLIER, payload: { multiplier: multiplier}})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryMode);
