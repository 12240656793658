import React, { Component } from "react";
import * as actionTypes from "../../store/actions/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { BASE_API_URL, ENVIRONMENT } from "../../config";

class CategoryNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heightSet: 0,
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("scroll", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.updateDimensions);
  }

  updateDimensions() {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    this.setState({ heightSet: winScroll });
    // console.log(winScroll);
  }

  handleActiveCat = (catIndex) => {
    // this.props.handleScroll(false);

    this.props.categories.map((cat, i) => {
      if (i == catIndex) {
        document.getElementById(`cat-${i}`).classList.add("active");
      } else {
        document.getElementById(`cat-${i}`).classList.add("remove");
      }
    });
  };

  render() {
   // console.log(this.props.categories);
    let categories = this.props.categories.map((c, i) => {
      return (
        <li key={i}>
          <a
            className="list-group-item list-group-item-action"
            id={`cat-${i}`}
            href={`#section-${i}`}
            onClick={() => this.handleActiveCat(i)}
          >
            {this.props.lang == "en" ? c.title : c.title_ar}
          </a>
        </li>
      );
    });
    return (
      <nav
        className={
          this.state.heightSet >= 500
            ? "secondary_nav sticky_horizontal is_stuck"
            : "secondary_nav sticky_horizontal "
        }
      >
        <div className="container">
          <ul id="secondary_nav">{categories}</ul>
        </div>
        <span></span>
      </nav>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations,
    lang: state.lang.lang,
    products: state.products.data,
    categories: state.categories.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    set_translations: (translations) =>
      dispatch({
        type: actionTypes.SET_TRANSLATIONS,
        payload: { translations: translations },
      }),
    set_shopdata: (shopdata) =>
      dispatch({
        type: actionTypes.UPDATE_SHOP,
        payload: { shopData: shopdata },
      }),
    set_categories: (categories) =>
      dispatch({
        type: actionTypes.SET_CATEGORIES,
        payload: { cats: categories },
      }),
    set_products: (products) =>
      dispatch({
        type: actionTypes.SET_PRODUCTS,
        payload: { products: products },
      }),
    set_banners: (banners) =>
      dispatch({
        type: actionTypes.SET_BANNERS,
        payload: { banners: banners },
      }),
    set_branches: (branches) =>
      dispatch({
        type: actionTypes.SET_BRANCHES,
        payload: { branches: branches },
      }),
    set_settings: (settings) =>
      dispatch({
        type: actionTypes.SET_SETTINGS,
        payload: { settings: settings },
      }),
    set_governates: (governates) =>
      dispatch({
        type: actionTypes.SET_GOVERNATES,
        payload: { governates: governates },
      }),
    set_countries: (countries) =>
      dispatch({
        type: actionTypes.SET_COUNTRIES,
        payload: { countries: countries },
      }),
    set_currencies: (currencies) =>
      dispatch({
        type: actionTypes.SET_CURRENCIES,
        payload: { currencies: currencies },
      }),
    set_selectedCategory: (category) =>
      dispatch({
        type: actionTypes.SET_SELECTED_CATEGORY,
        payload: { category: category },
      }),
  };
};

CategoryNav.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryNav);
