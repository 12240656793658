import * as actionTypes from '../actions/actions';



const initialState = {
    items: []
}

const categoriesReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_CATEGORIES:
            let categories = action.payload.cats;
            return {
                items: categories
            }

    }
    return state;
}

export default categoriesReducer;