import SecureStorage from "secure-web-storage";
import CryptoJS from "crypto-js";

var SECRET_KEY = "my secret key";

export const secureStorage = new SecureStorage(localStorage, {
  hash: function hash(key) {
    key = CryptoJS.SHA256(key, SECRET_KEY);

    return key.toString();
  },
  encrypt: function encrypt(data) {
    data = CryptoJS.AES.encrypt(data, SECRET_KEY);

    data = data.toString();

    return data;
  },
  decrypt: function decrypt(data) {
    data = CryptoJS.AES.decrypt(data, SECRET_KEY);

    data = data.toString(CryptoJS.enc.Utf8);

    return data;
  },
});

// if(secureStorage.getItem('cart') == "undefined" || secureStorage.getItem("cart") == null) {
//     console.log("is cart available", secureStorage.getItem('cart'));
//     secureStorage.setItem('cart', {items: [], calculated_total: 0});

// }
