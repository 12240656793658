import React, { Component } from "react";
import { BASE_API_URL, ENVIRONMENT } from "../../../config";
import { secureStorage } from "../../../locStore";
import { cloneDeep } from "lodash";
import Select from "react-select";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actions";
import HouseTypeSelection from "../HouseTypeSelection/HouseTypeSelection";
class AddressFields extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>
                {this.props.lang == "en"
                  ? this.props.translations.en["Block"]
                  : this.props.translations.ar["Block"]}{" "}
                <span className="required">*</span>
              </label>
              <input
                className="form-control"
                placeholder={
                  this.props.lang == "en"
                    ? this.props.translations.en["Block"]
                    : this.props.translations.ar["Block"]
                }
                value={this.props.checkoutData.block}
                onChange={(e) =>
                  this.props.setDataVariable("block", e.currentTarget.value)
                }
              />
              {this.props.checkoutData.error_state.block ? (
                <p className="error">
                  {this.props.lang == "en"
                    ? this.props.translations.en["Block is required"]
                    : this.props.translations.ar["Block is required"]}
                  Block is required
                </p>
              ) : null}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>
                {this.props.lang == "en"
                  ? this.props.translations.en["Street"]
                  : this.props.translations.ar["Street"]}{" "}
                <span className="required">*</span>
              </label>
              <input
                className="form-control"
                placeholder={
                  this.props.lang == "en"
                    ? this.props.translations.en["Street"]
                    : this.props.translations.ar["Street"]
                }
                value={this.props.checkoutData.street}
                onChange={(e) =>
                  this.props.setDataVariable("street", e.currentTarget.value)
                }
              />
              {this.props.checkoutData.error_state.street ? (
                <p className="error">
                  {this.props.lang == "en"
                    ? this.props.translations.en["Street is required"]
                    : this.props.translations.ar["Street is required"]}
                  Street is required
                </p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="form-group">
          <label>
            {this.props.lang == "en"
              ? this.props.translations.en["Avenue"]
              : this.props.translations.ar["Avenue"]}{" "}
            {/* <span className="required">*</span> */}
          </label>
          <input
            className="form-control"
            placeholder={
              this.props.lang == "en"
                ? this.props.translations.en["Avenue"]
                : this.props.translations.ar["Avenue"]
            }
            value={this.props.checkoutData.avenue}
            onChange={(e) =>
              this.props.setDataVariable("avenue", e.currentTarget.value)
            }
          />
        </div>

        <HouseTypeSelection
          setDataVariable={this.props.setDataVariable}
        ></HouseTypeSelection>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // shop: state.shop,
    lang: state.lang.lang,
    settings: state.settings.data,
    shop: state.shop,
    cart: state.cart,
    lang: state.lang.lang,
    translations: state.translations,
    showSignInModal: state.handleSignInModal.show,
    selectedProduct: state.selectedProduct,
    countries: state.countries.data,
    branches: state.branches.items,
    governates: state.governates.data,
    delivery_location: state.deliveryLocation.data.area,
    pickup_location: state.deliveryLocation.data.branch,
    delivery_location_governate: state.deliveryLocation.data.governate,
    delivery_mode: state.deliveryMode,
    currency: state.currency,
    multiplier: state.multiplier,
    user: state.user,
    checkoutData: state.checkoutData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // updateShop: () => dispatch(updateShopDetails()),
    // getCategories: () => dispatch(getCategories()),
    setLang: (lang) =>
      dispatch({ type: actionTypes.SET_LANGUAGE, payload: { lang: lang } }),
    setShowProductPopUp: (show_product_popup) =>
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: show_product_popup },
      }),
    resetCart: () => dispatch({ type: actionTypes.RESET_CART, payload: null }),
    set_deliveryLocation: (location) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_LOCATION,
        payload: { location: location },
      }),
    set_deliveryMode: (modeData) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_MODE,
        payload: { modeData },
      }),
    set_tempDeliveryMode: (modeData) =>
      dispatch({
        type: actionTypes.SET_TEMP_DELIVERY_MODE,
        payload: { modeData },
      }),
    addItemToCart: (i, product) =>
      dispatch({
        type: actionTypes.ADD_CART_ITEM,
        payload: { item: product, index: i },
      }),
    updateCart: (product) =>
      dispatch({ type: actionTypes.ADD_CART_ITEM, payload: { item: product } }),
    removeItemFromCart: (i, product) =>
      dispatch({
        type: actionTypes.REMOVE_CART_ITEM,
        payload: { item: product, index: i },
      }),
    reloadCart: (cart) =>
      dispatch({
        type: actionTypes.SET_CART_ON_RELOAD,
        payload: { cart: cart },
      }),
    popItem: (i) =>
      dispatch({
        type: actionTypes.POPITEM,
        payload: { popIndex: i },
      }),
    handleAuthModal: (val) => {
      dispatch({
        type: actionTypes.HANDLE_AUTH_MODAL,
        payload: { showModal: val },
      });
    },
    updateCheckoutData: (checkoutData) => {
      dispatch({
        type: actionTypes.UPDATE_CHECKOUTDATA,
        payload: { checkoutData: checkoutData },
      });
    },
    // setCurrency: (currency) => dispatch({type: actionTypes.SET_CURRENCY, payload: { base_currency: currency}}),
    // setMultiplier: (multiplier) => dispatch({type: actionTypes.SET_MULTIPLIER, payload: { multiplier: multiplier}})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressFields);

// <div className="row" style={{ marginTop: "15px" }}>
//           <>
//             <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
//               <div className="form-group">
//                 <label>
//                   {this.props.lang == "en"
//                     ? this.props.translations.en["Date"]
//                     : this.props.translations.ar["Date"]}{" "}
//                   <span className="required">*</span>
//                 </label>
//                 {/* <MuiPickersUtilsProvider
//                     utils={DateFnsUtils}
//                   >
//                     <DatePicker
//                       value={this.state.selectedDate}
//                       onChange={this.handleDateChange}
//                       placeholder={
//                         this.props.lang == "en"
//                           ? this.props.translations.en[
//                               "Select Date"
//                             ]
//                           : this.props.translations.ar[
//                               "Select Date"
//                             ]
//                       }
//                       disablePast={true}
//                     />
//                   </MuiPickersUtilsProvider> */}
//               </div>
//               {this.props.checkoutData.error_state.apartment_flat ? (
//                 <p className="error">
//                   {this.props.lang == "en"
//                     ? this.props.translations.en["Date is required"]
//                     : this.props.translations.ar["Date is required"]}
//                   Date is required
//                 </p>
//               ) : null}
//             </div>
//             <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
//               <div className="form-group">
//                 {/* <input type="text" className="form-control"  aria-describedby="" placeholder="Area*" value={this.state.area} onChange={(e) => this.setDataVariable("area", e.currentTarget.value)}/> */}
//                 <label>
//                   {this.props.lang == "en"
//                     ? this.props.translations.en["TimeSlot"]
//                     : this.props.translations.ar["TimeSlot"]}
//                   TimeSlot <span className="required">*</span>
//                 </label>
//                 <Select
//                   //   onChange={(e) => this.selectedTimeSlot(e.value)}
//                   placeholder={
//                     this.props.lang === "en"
//                       ? this.props.translations.en["Select Your TimeSlot"]
//                       : this.props.translations.ar["Select Your TimeSlot"]
//                   }
//                   className="custom-select js-example-basic-single"
//                   id="inputGroupSelectForTime"
//                   //   options={slotOptions}
//                   //   value={this.state.selected_slot.value}
//                 ></Select>
//               </div>
//               {this.props.checkoutData.error_state.apartment_flat ? (
//                 <p className="error">
//                   {this.props.lang == "en"
//                     ? this.props.translations.en["TimeSlot is required"]
//                     : this.props.translations.ar["TimeSlot is required"]}
//                   Timeslot is required
//                 </p>
//               ) : null}
//             </div>
//           </>
//         </div>
