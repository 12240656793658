import * as actionTypes from '../actions/actions';



const initialState = {
    data: []
}

const countriesReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_COUNTRIES:
            let countries = action.payload.countries;
            return {
                data: countries
            }

    }
    return state;
}

export default countriesReducer;