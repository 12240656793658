import React, { Component } from "react";
import ScrollTop from "../ScrollTop/ScrollTop";
import AuthModal from "../AuthModal/AuthModal";
import ProductModal from "../ProductModal/ProductModal";
import PopupSkeleton from "../ProductModal/PopupSkeleton/PopupSkeleton";
export class AfterFoot extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        {/* ScroolTop */}
        <ScrollTop></ScrollTop>
        {/* <PopupSkeleton></PopupSkeleton> */}
        {/* AuthModal */}
        <AuthModal></AuthModal>
        {/* ProductModal */}

        <ProductModal
          show={false}
          //   handleModal={this.handleModal}
        ></ProductModal>
      </>
    );
  }
}

export default AfterFoot;
