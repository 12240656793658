import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as actionTypes from "../../store/actions/actions";
import { connect } from "react-redux";
import { BASE_API_URL, ENVIRONMENT } from "../../config";

class MobileNav extends Component {
  constructor(props) {
    super(props);
    if (ENVIRONMENT == "development") {
      console.log(this.props);
    }
  }
  showAuthModal = () => {
    this.props.handleAuthModal(true);
  };
  render() {
    return (
      <nav className="main-menu show" style={{ width: "100%" }}>
        {/* <div id="header_menu">
          <a className="open_close" onClick={this.props.handleSidebar}>
            <i className="icon_close"></i>
            <span>Menu</span> 
          </a>
          <Link to={`/`} onClick={this.props.handleSidebar}>
            <img src={this.props.settings.image} height="35" alt="" />
          </Link>
        </div> */}
        <ul>
          <li onClick={this.props.handleSidebar}>
            <Link to="/">
              {this.props.lang == "en"
                ? this.props.translations.en["Home"]
                : this.props.translations.ar["Home"]}
            </Link>
          </li>
          {/* <li className="submenu">
            <a href="#0" className="show-submenu">
              Listing
            </a>
          </li> */}

              {this.props.user.isLoggedIn ? (
                  <li onClick={this.props.handleSidebar}>
                  <Link to={`/myaccount`}>
                    {this.props.lang == "en"
                      ? this.props.translations.en["My Account"]
                      : this.props.translations.ar["My Account"]}
                  </Link>
                </li>
                ) : <li onClick={this.props.handleSidebar}>
                <a onClick={this.showAuthModal}>
                  {this.props.lang == "en"
                    ? this.props.translations.en["My Account"]
                    : this.props.translations.ar["My Account"]}
                </a>
              </li>}

          
          <li onClick={this.props.handleSidebar}>
            <Link to={`/track-order`}>
              {this.props.lang == "en"
                ? this.props.translations.en["Track Order"]
                : this.props.translations.ar["Track Order"]}
            </Link>
          </li>
          <li onClick={this.props.handleSidebar}>
            <Link to={`/about`}>
              {this.props.lang == "en"
                ? this.props.translations.en["About Us"]
                : this.props.translations.ar["About Us"]}
              {/* About Us */}
            </Link>
          </li>
          <li onClick={this.props.handleSidebar}>
            <Link to={`/contact`}>
              {this.props.lang == "en"
                ? this.props.translations.en["Contact"]
                : this.props.translations.ar["Contact"]}
            </Link>
          </li>
          <li onClick={this.props.handleSidebar}>
            <Link to={`/terms-and-conditions`}>
              {this.props.lang == "en"
                ? this.props.translations.en["Terms And Conditions"]
                : this.props.translations.ar["Terms And Conditions"]}
            </Link>
          </li>
          <li onClick={this.props.handleSidebar}>
            <Link to={`/privacy-policy`}>
              {this.props.lang == "en"
                ? this.props.translations.en["Privacy Policy"]
                : this.props.translations.ar["Privacy Policy"]}
            </Link>
          </li>
          <li
            className="socialMedia"
            style={{
              display: "flex",
              flex: 1,
              height: "50px",
              alignItems: "center",
            }}
            onClick={this.props.handleSidebar}
          >
            {this.props.settings.whatsapp ? (
              <a href={`${this.props.settings.whatsapp}`} className="iconLink">
                <i
                  style={{ display: "flex", flex: 1, justifyContent: "center" }}
                  className="fab fa-whatsapp"
                ></i>
              </a>
            ) : null}
            {this.props.settings.facebook ? (
              <a href={`${this.props.settings.facebook}`} className="iconLink">
                <i
                  style={{ display: "flex", flex: 1, justifyContent: "center" }}
                  className="fab fa-facebook"
                ></i>
              </a>
            ) : null}
            {this.props.settings.twitter ? (
              <a href={`${this.props.settings.twitter}`} className="iconLink">
                <i
                  style={{ display: "flex", flex: 1, justifyContent: "center" }}
                  className="fab fa-twitter"
                ></i>
              </a>
            ) : null}
            {this.props.settings.instagram ? (
              <a href={`${this.props.settings.instagram}`} className="iconLink">
                <i
                  style={{ display: "flex", flex: 1, justifyContent: "center" }}
                  className="fab fa-instagram"
                ></i>
              </a>
            ) : null}
            {this.props.settings.snapchat ? (
              <a href={`${this.props.settings.snapchat}`} className="iconLink">
                <i
                  style={{ display: "flex", flex: 1, justifyContent: "center" }}
                  className="fab fa-snapchat"
                ></i>
              </a>
            ) : null}
          </li>
          <li onClick={this.props.handleSidebar}>
            <a href={`${this.props.settings.weby_link}`} target="_blank" rel="noreferrer">
              {this.props.lang == "en"
                ? this.props.translations.en["Powered By"]
                : this.props.translations.ar["Powered By"]}{" "}
              Weby
            </a>
          </li>
          {/* {this.props.menuItems} */}
        </ul>
      </nav>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations,
    lang: state.lang.lang,
    products: state.products.data,
    categories: state.categories.items,
    cart: state.cart,
    settings: state.settings.data,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    set_translations: (translations) =>
      dispatch({
        type: actionTypes.SET_TRANSLATIONS,
        payload: { translations: translations },
      }),
    set_shopdata: (shopdata) =>
      dispatch({
        type: actionTypes.UPDATE_SHOP,
        payload: { shopData: shopdata },
      }),
    set_categories: (categories) =>
      dispatch({
        type: actionTypes.SET_CATEGORIES,
        payload: { cats: categories },
      }),
    set_products: (products) =>
      dispatch({
        type: actionTypes.SET_PRODUCTS,
        payload: { products: products },
      }),
    set_banners: (banners) =>
      dispatch({
        type: actionTypes.SET_BANNERS,
        payload: { banners: banners },
      }),
    set_branches: (branches) =>
      dispatch({
        type: actionTypes.SET_BRANCHES,
        payload: { branches: branches },
      }),
    set_settings: (settings) =>
      dispatch({
        type: actionTypes.SET_SETTINGS,
        payload: { settings: settings },
      }),
    set_governates: (governates) =>
      dispatch({
        type: actionTypes.SET_GOVERNATES,
        payload: { governates: governates },
      }),
    set_countries: (countries) =>
      dispatch({
        type: actionTypes.SET_COUNTRIES,
        payload: { countries: countries },
      }),
    set_currencies: (currencies) =>
      dispatch({
        type: actionTypes.SET_CURRENCIES,
        payload: { currencies: currencies },
      }),
    set_selectedCategory: (category) =>
      dispatch({
        type: actionTypes.SET_SELECTED_CATEGORY,
        payload: { category: category },
      }),
    addItemToCart: (product) =>
      dispatch({ type: actionTypes.ADD_CART_ITEM, payload: { item: product } }),
    updateCart: (product) =>
      dispatch({ type: actionTypes.ADD_CART_ITEM, payload: { item: product } }),
    removeItemFromCart: (i, product) =>
      dispatch({
        type: actionTypes.REMOVE_CART_ITEM,
        payload: { item: product, index: i },
      }),
      handleAuthModal: (val) => {
        dispatch({
          type: actionTypes.HANDLE_AUTH_MODAL,
          payload: { showModal: val },
        });
      },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileNav);
