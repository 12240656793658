import React, { Component } from "react";
import { Link } from "react-router-dom";
import SideMenu from "./SideMenu/SideMenu";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions/actions";
import { BASE_API_URL, ENVIRONMENT } from "../../config";
import { callService } from "../../services";
class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: "",
      phone: "",
      email: "",
      showSuccessMsg: false,
    };
  }

  componentDidMount() {
    if (!this.props.user.isLoggedIn) {
      this.props.handleAuthModal(true);
      if (ENVIRONMENT == "development") {
        console.log(this.props);
      }
      this.props.history.push("/");
    } else {
      let mobile = false;
      if (window.matchMedia("screen and (max-width: 990px)").matches) {
        mobile = true;
      }
      if (!mobile) {
        this.props.history.push("/update-profile");
        return;
      }
      if (ENVIRONMENT == "development") {
        console.log();
      }
      callService(
        `${BASE_API_URL}/member_profile?member_id=${this.props.user.userdata.id}`
      )
        .then((resp) => {
          return resp.json();
        })
        .then((respJson) => {
          if (ENVIRONMENT == "development") {
            console.log(respJson);
          }
          this.setState((prevState) => {
            return {
              ...prevState,
              fname: respJson[0].fname,
              email: respJson[0].email,
              phone: respJson[0].phone,
            };
          });
        })
        .catch((err) => {
          if (ENVIRONMENT == "development") {
            console.log(err);
          }
        });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.user.isLoggedIn !== prevProps.user.isLoggedIn) {
      if (!this.props.user.isLoggedIn) {
        this.props.handleAuthModal(true);
        this.props.history.push("/");
      } else {
        let mobile = false;
        if (window.matchMedia("screen and (max-width: 990px)").matches) {
          mobile = true;
        }
        if (!mobile) {
          this.props.history.push("/update-profile");
        }
      }
    }
  }

  updateProfile = () => {
    callService(
      `${BASE_API_URL}/member`,
      "POST",
      {},
      {
        member_id: this.props.user.userdata.id,
        fname: this.state.fname,
        email: this.state.email,
        phone: this.state.phone,
      }
    )
      .then((resp) => {
        return resp.json();
      })
      .then((respJson) => {
        if (respJson.status == "Success") {
          this.setState((prevState) => {
            return {
              ...prevState,
              showSuccessMsg: true,
            };
          });
        }
      })
      .catch((err) => {
        if (ENVIRONMENT == "development") {
          console.log(err);
        }
      });
  };

  render() {
    let mobile = false;
    if (window.matchMedia("screen and (max-width: 990px)").matches) {
      mobile = true;
    }
    return (
      <main className="bg_gray">
        {/* <Container
          style={{ minHeight: "50px", backgroundColor: "skyblue" }}
        ></Container> */}
        <div className="container margin_60_20" style={{ paddingTop: "30px" }}>
          <div className="row justify-content-center">
            {/* <SideMenu /> */}

            <div className="col-lg-3">
              <div className="box_general write_review">
                {/* <h1 className="add_bottom_15">
                  {this.props.lang == "en"
                    ? this.props.translations.en["My Account"]
                    : this.props.translations.ar["My Account"]}
                </h1> */}
                <div className="row">
                  <div className="col-md-12 add_bottom_25">
                    <ul className="accoutUl">
                      <li>
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "row",
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              // justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <i
                              className="fa fa-user-circle"
                              style={{ fontSize: "30px" }}
                            ></i>
                          </div>
                          <div
                            style={{
                              flex: 5,
                            }}
                          >
                            <span>
                              {this.props.user.isLoggedIn ? (
                                <span>
                                  {this.props.user.userdata.fname[0].toUpperCase() +
                                    this.props.user.userdata.fname.slice(1)}
                                </span>
                              ) : null}
                              <br />
                              <span>
                                <Link to="/update-profile">edit profle</Link>
                              </span>
                            </span>
                          </div>
                        </div>

                        {/* <img src="/img/user.png" width="25"></img> */}
                      </li>
                      {/* <li>
                        <Link to="/update-profile">
                          {this.props.lang == "en"
                            ? this.props.translations.en["Update Profile"]
                            : this.props.translations.ar["Update Profile"]}
                        </Link>
                      </li> */}
                      <li>
                        <Link to="/my-orders">
                          {this.props.lang == "en"
                            ? this.props.translations.en["My Orders"]
                            : this.props.translations.ar["My Orders"]}
                        </Link>
                      </li>
                      <li>
                        <Link to="/my-addresses">
                          {this.props.lang == "en"
                            ? this.props.translations.en["My Addresses"]
                            : this.props.translations.ar["My Addresses"]}
                        </Link>
                      </li>
                      <li>
                        <Link to="/track-order">
                          {this.props.lang == "en"
                            ? this.props.translations.en["Track Order"]
                            : this.props.translations.ar["Track Order"]}
                        </Link>
                      </li>
                      <li>
                        <Link to="/change-password">
                          {this.props.lang == "en"
                            ? this.props.translations.en["Change Password"]
                            : this.props.translations.ar["Change Password"]}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {this.props.user.isLoggedIn ? (
                  <a
                    className="btn_1 full-width"
                    onClick={() => this.props.logout()}
                  >
                    {this.props.lang == "en"
                      ? this.props.translations.en["Logout"]
                      : this.props.translations.ar["Logout"]}
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations,
    lang: state.lang.lang,
    products: state.products.data,
    categories: state.categories.items,
    cart: state.cart,
    settings: state.settings.data,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setSelectedProduct: (prod_id) => dispatch(getSelectedProduct(prod_id)),
    setLang: (lang) =>
      dispatch({
        type: actionTypes.SET_LANGUAGE,
        payload: { lang: lang },
      }),
    setSelectedProduct: (prod_id) =>
      dispatch({
        type: actionTypes.SET_SELECTED_PRODUCT,
        payload: { product: prod_id },
      }),
    handleProductPopUp: (val) => {
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: val },
      });
    },
    handleAuthModal: (val) => {
      dispatch({
        type: actionTypes.HANDLE_AUTH_MODAL,
        payload: { showModal: val },
      });
    },
    updateUserData: (val) => {
      dispatch({
        type: actionTypes.UPDATE_USERDATA,
        payload: { userdata: val },
      });
    },
    logout: () => {
      dispatch({
        type: actionTypes.LOGOUT,
        payload: null,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
