import CryptoJS from 'crypto-js';


export const callService = (
  url,
  method = "GET",
  headers = {},
  reqData = {}
) => {
  let dataObj = {
    headers: { ...headers },
    method: method,
  };
  let date = new Date;
  let strTime = date.toLocaleString("en-US", {
    timeZone: `Asia/Kuwait`
  });
  let encKey = "asdfgqwert12345"; 
  let text = "weby_"+strTime;
  let iv = CryptoJS.enc.Hex.parse("FgLFXEr1MZl2mEnk");
  //console.log(iv,"yy");
  var encryptedText = CryptoJS.AES.encrypt(text, encKey, { iv: iv }).toString();
    //console.log(encryptedText);
  let dataMethods = ["post", "put", "patch", "POST", "PUT", "PATCH"];
  if (dataMethods.includes(method)) {
    dataObj["body"] = JSON.stringify({
      ...reqData,
    });
    //dataObj["headers"] = {...dataObj.headers,"Authorization" : `Bearer ${encryptedText}`}    
  }
  dataObj["headers"] = {...dataObj.headers,"Authorization" : `Bearer ${encryptedText}`} 
  let data = fetch(url, dataObj);
  // .then((res) => res.json())
  // .then((resJson) => {
  //   return resJson;
  // })
  // .catch((err) => {
  //   console.log(err);
  //   return err;
  // });
  
  return data;
};
