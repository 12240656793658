import React, { Component } from "react";
import { Link } from "react-router-dom";
class Failed extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // alert("Order failed, Please order again");
    // this.props.history.push("/checkout");
  }
  render() {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "50px",
        }}
      >
        <h2 style={{ flex: 1 }}>Your Order Has Failed</h2>

        <p style={{ flex: 1 }}>Please Try Placing Order Again</p>

        <Link to={`/checkout`}>
          <button
            className="btn_1"
          >
            Go To Checkout
          </button>
        </Link>
      </div>
    );
  }
}

export default Failed;
