import React, { Component } from "react";
import SideMenu from "../SideMenu/SideMenu";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actions";

import { BASE_API_URL, ENVIRONMENT } from "../../../config";
import { callService } from "../../../services";
export class MyAddresses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address_list: [],
    };
  }

  componentDidMount() {
    if (!this.props.user.isLoggedIn) {
      this.props.handleAuthModal(true);
      this.props.history.push("/");
    } else {
      callService(`${BASE_API_URL}/addresses/${this.props.user.userdata.id}`)
        .then((resp) => resp.json())
        .then((respJson) => {
          if (ENVIRONMENT == "development") {
            console.log(respJson);
          }
          this.setState((prevState) => {
            return {
              ...prevState,
              address_list: respJson,
            };
          });
        })
        .catch((err) => {
          if (ENVIRONMENT == "development") {
            console.log(err);
          }
        });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.user.isLoggedIn !== prevProps.user.isLoggedIn) {
      if (!this.props.user.isLoggedIn) {
        this.props.handleAuthModal(true);
        this.props.history.push("/");
      }
    }
  }

  deleteAddress = (address_id) => {
    callService(
      `${BASE_API_URL}/address_delete`,
      "POST",
      {},
      {
        member_id: this.props.user.userdata.id,
        address_id: address_id,
      }
    )
      .then((res) => res.json())
      .then((resJson) => {
        if (resJson.status == "Success") {
          // this.props.history.push("/my-addresses");
          callService(
            `${BASE_API_URL}/addresses/${this.props.user.userdata.id}`
          )
            .then((resp) => resp.json())
            .then((respJson) => {
              if (ENVIRONMENT == "development") {
                console.log(respJson);
              }
              this.setState((prevState) => {
                return {
                  ...prevState,
                  address_list: respJson,
                };
              });
            })
            .catch((err) => {
              if (ENVIRONMENT == "development") {
                console.log(err);
              }
            });
        } else {
          alert("cannot delete address");
        }
      })
      .catch((err) => {
        if (ENVIRONMENT == "development") {
          console.log(err);
        }
      });
  };
  render() {
    let addresses = [];
    addresses = this.state.address_list.map((address, i) => {
      return (
        <div className="col-md-6 add_bottom_25" key={i}>
          <div
            className="bg-white card addresses-item mb-4 shadow-sm"
            style={{ padding: "0px", margin: "0px", width: "unset" }}
          >
            <div className="gold-members p-4">
              <div className="media">
                <div className="mr-3">
                  <i className="icofont-ui-home icofont-3x"></i>
                </div>
                <div className="media-body">
                  {/* <h6 className="mb-1 text-secondary">{address.title}</h6> */}
                  <p
                    className="text-black"
                    dangerouslySetInnerHTML={{ __html: address.address_str }}
                  >
                    {/* Osahan House, Jawaddi Kalan, Ludhiana, Punjab 141002, India */}
                  </p>
                  <p className="mb-0 text-black font-weight-bold">
                    <Link
                      className="btn_1 small mr-3"
                      to={`/edit-address/${address.id}`}
                    >
                      <i className="icon_pencil-edit"></i>
                      {this.props.lang == "en"
                        ? this.props.translations.en["EDIT"]
                        : this.props.translations.ar["EDIT"]}
                    </Link>
                    <a
                      className="btn_1 small gray"
                      onClick={() => {
                        this.deleteAddress(address.id);
                      }}
                    >
                      <i className="icon_close_alt2"></i>
                      {this.props.lang == "en"
                        ? this.props.translations.en["DELETE"]
                        : this.props.translations.ar["DELETE"]}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
    return (
      <main className="bg_gray">
        <div className="container margin_60_20">
          <div className="row justify-content-center">
            <SideMenu />
            <div className="col-lg-9">
              <div className="box_general write_review">
                <h1 className="add_bottom_15">
                  {this.props.lang == "en"
                    ? this.props.translations.en["Addresses"]
                    : this.props.translations.ar["Addresses"]}
                  <Link
                    to="add-address"
                    className="btn_1 small float-right"
                    style={{ marginTop: "12px" }}
                  >
                    {this.props.lang == "en"
                      ? this.props.translations.en["Add Address"]
                      : this.props.translations.ar["Add Address"]}
                  </Link>
                </h1>
                <div className="row">
                  {/* <div className="col-md-6 add_bottom_25">
                    <div className="bg-white card addresses-item mb-4 shadow-sm">
                      <div className="gold-members p-4">
                        <div className="media">
                          <div className="mr-3">
                            <i className="icofont-ui-home icofont-3x"></i>
                          </div>
                          <div className="media-body">
                            <h6 className="mb-1 text-secondary">Work</h6>
                            <p className="text-black">
                              Osahan House, Jawaddi Kalan, Ludhiana, Punjab
                              141002, India
                            </p>
                            <p className="mb-0 text-black font-weight-bold">
                              <a className="btn_1 small mr-3" href="#">
                                <i className="icon_pencil-edit"></i>
                                {this.props.lang == "en"
                                  ? this.props.translations.en["EDIT"]
                                  : this.props.translations.ar["EDIT"]}
                              </a>{" "}
                              <a className="btn_1 small gray" href="#">
                                <i className="icon_close_alt2"></i>
                                {this.props.lang == "en"
                                  ? this.props.translations.en["DELETE"]
                                  : this.props.translations.ar["DELETE"]}
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                   */}
                  {addresses}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations,
    lang: state.lang.lang,
    products: state.products.data,
    categories: state.categories.items,
    cart: state.cart,
    settings: state.settings.data,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setSelectedProduct: (prod_id) => dispatch(getSelectedProduct(prod_id)),
    setLang: (lang) =>
      dispatch({
        type: actionTypes.SET_LANGUAGE,
        payload: { lang: lang },
      }),
    setSelectedProduct: (prod_id) =>
      dispatch({
        type: actionTypes.SET_SELECTED_PRODUCT,
        payload: { product: prod_id },
      }),
    handleProductPopUp: (val) => {
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: val },
      });
    },
    handleAuthModal: (val) => {
      dispatch({
        type: actionTypes.HANDLE_AUTH_MODAL,
        payload: { showModal: val },
      });
    },
    updateUserData: (val) => {
      dispatch({
        type: actionTypes.UPDATE_USERDATA,
        payload: { userdata: val },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAddresses);
