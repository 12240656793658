import * as actionTypes from "../actions/actions";

const initialState = {
  data: {},
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SETTINGS:
      let settings = action.payload.settings;
      return {
        data: settings,
      };
  }
  return state;
};

export default settingsReducer;
